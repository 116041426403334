import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { DomainModel } from 'src/models/mongo/domain.model';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { RequestResultModel } from 'src/models/request_result.model';
import { Seats } from 'src/models/subcription.model';
import { SubscriptionService } from 'src/servcies/api/subscription.service';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Unicode,
  Endpoint,
  PlanValues,
  Status,
  OrderAction,
  OrderStatus,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-customer-subscriptions',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-subscriptions.component.html',
  styleUrls: ['./customer-subscriptions.component.scss'],
})
export class CustomerSubscriptionsComponent implements OnInit {
  currentUser: UserModel;
  currentCustomer: DomainModel = new DomainModel();
  allSubcs = [];
  plans = [];
  googleProducts = [];
  selectSubscriptionId: string;
  @ViewChild('startPaidServiceModal') startPaidServiceModal: ModalDirective;
  @ViewChild('statusModal') statusModal: ModalDirective;
  @ViewChild('renewalSettingsModal') renewalSettingsModal: ModalDirective;
  @ViewChild('changeLicenseModal') changeLicenseModal: ModalDirective;
  loginUsersData;
  statusTextAction: string;
  newRenewalSettingsType: string;
  LicenseForm: UntypedFormGroup;
  prices = [];
  updateLicenseText: string = 'Additional Licenses Needed';
  globalData = [];
  opportunitySources = [];
  opportunityStatus = [];
  companySections = [];
  action: string = '';
  canCreateOrderAction: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.canCreateOrderAction =
      this.currentUser.isSales ||
      this.currentUser.isOperations ||
      this.currentUser.isFinanceTeam;
    //LICENSE POPUP FORM
    this.LicenseForm = this.fb.group({
      current_licenses: [{ value: 0, disabled: true }, , Validators.required],
      sku_name: [{ value: '', disabled: true }, Validators.required],
      plan_id: ['', Validators.required],
      plan_name: ['', Validators.required],
      sku_id: ['', Validators.required],
      additional_licenses: [0, Validators.required],
      po_id: [null, Validators.required],
      order_sale_invoice_nbr: [null, Validators.required],
      opportunity_status: [null, Validators.required],
      opportunity_source: [null, Validators.required],
      customer_email_notification: [''],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      is_flexible: [false],
      annual_months: [0],
      with_customer_offer: [false, Validators.required],
      customer_offer: [0],
      with_google_offer: [false, Validators.required],
      google_offer: [0],
      cost_price: [0],
      selling_price: [0],
      company_section: [null, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    var paramsJson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (paramsJson) {
      this.currentCustomer = JSON.parse(paramsJson);
    }
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    var usersInfojson = this.localStorage.get(Unicode.USERS_INFO);
    this.loginUsersData = usersInfojson ? JSON.parse(usersInfojson) : {};
    this.globalData = await Utilities.globalData(this.http, this.localStorage);
    if (this.globalData && this.globalData.length > 0) {
      this.opportunitySources =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_SOURCE.toLowerCase()
        )?.values ?? [];
      this.opportunityStatus =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY__OPPORTUNITY_STATUS.toLowerCase()
        )?.values ?? [];
      this.companySections =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY_COMPANY_SECTIONS.toLowerCase()
        )?.values ?? [];
    }
    /** GET SUBSC. BY CUSTOMER ID **/
    if (!Utilities.isNullOrEmpty(this.currentCustomer.customer_api_id)) {
      await this.subscriptionService
        .requestSubscriptions(this.currentCustomer.customer_api_id)
        .then((resp: any) => {
          if (resp) this.allSubcs.push(...resp);
          this.allSubcs.forEach((subs) => {
            if (!subs.seats) {
              subs.seats = new Seats();
              subs.seats.numberOfSeats = 0;
            }
            if (
              subs.billingMethod
                .toLowerCase()
                .includes(Unicode.OFFLINE_BILLING_METHOD)
            ) {
              if (
                subs.plan.planName
                  .toLowerCase()
                  .includes(PlanValues.ANNUAL.toLowerCase())
              )
                subs.displayedPlanName = 'Commitment Offline Plan';
              else subs.displayedPlanName = subs.plan.planName + '/Offline';
            } else {
              if (subs.plan.planName.toUpperCase() == PlanValues.ANNUAL)
                subs.displayedPlanName = PlanValues.ANNUAL;
              else subs.displayedPlanName = subs.plan.planName;
            }
            //
            //fileds
            //if (subs.suspensionReasons && subs.suspensionReasons.find(x => x == Unicode.RESELLER_INITIATED))
            if (
              subs.plan.planName == Unicode.FLEXIBLE_PLAN ||
              subs.plan.planName == Unicode.TRIAL_PLAN
            ) {
              subs.seatsNbr = subs.seats?.maximumNumberOfSeats ?? 0;
              if (subs.plan.planName == Unicode.FLEXIBLE_PLAN) {
                subs.licensesSuffixText = ' /month';
              } else subs.licensesSuffixText = '';
            } else {
              subs.seatsNbr = subs.seats?.numberOfSeats ?? 0;
              if (
                subs.plan.planName.toUpperCase() == PlanValues.ANNUAL_YEARLY_PAY
              )
                subs.licensesSuffixText = ' /year';
              else subs.licensesSuffixText = ' /month';
            }
            if (subs.status == Status.Active) {
              subs.txtStatusEvent = 'Suspend';
              subs.canActivate = true;
            } else if (subs.status == Status.Suspended) {
              subs.txtStatusEvent = 'Activate';
              if (
                subs.suspensionReasons &&
                subs.suspensionReasons.find(
                  (x) => x == Unicode.RESELLER_INITIATED
                )
              )
                subs.canActivate = true;
              else subs.canActivate = false;
            }
          });
        });
    }
  }

  changePlan(subId) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subId);
    this.router.navigate(['/orders/add-order'], {
      queryParams: {
        isGWS: true,
        customer_api_id: this.currentCustomer.customer_api_id,
        domain_rfid: this.currentCustomer._id,
        domain: this.currentCustomer.domain,
        organisation: this.currentCustomer.organisation_name,
        subAction: true,
        updatePlan: true,
        skuId: subs.skuId,
        planName: subs.plan.planName,
        planId: this.plans.find((x) => x.code == subs.plan?.planName)._id,
        license: subs.seatsNbr,
        subscription_api_id: subs.subscriptionId,
      },
      skipLocationChange: true,
    });
  }

  downUpgrade(subId, isUpgrade: boolean) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subId);
    var availableSKUs = this.googleProducts.find(
      (x) => subs.skuId && x.sku_id == subs.skuId
    );
    var availableSKUIDs = isUpgrade
      ? availableSKUs?.upgrade_ids
      : availableSKUs.downgrade_ids;
    if (!availableSKUIDs) {
      alert('No Available SKUs');
      return;
    }
    var params: any = {
      isGWS: true,
      customer_api_id: this.currentCustomer.customer_api_id,
      domain_rfid: this.currentCustomer._id,
      domain: this.currentCustomer.domain,
      organisation: this.currentCustomer.organisation_name,
      email: this.currentCustomer.contact_person_email,
      subAction: true,
      availableSKUIDs: availableSKUIDs,
      skuName: subs.skuName,
      skuId: subs.skuId,
      planId: this.plans.find((x) => x.code == subs.plan?.planName)._id,
      license: subs.seatsNbr,
      subscription_api_id: subs.subscriptionId,
    };
    if (isUpgrade) params.upgrade = true;
    else params.downgrade = true;
    this.router.navigate(['/orders/add-order'], {
      queryParams: params,
      skipLocationChange: true,
    });
  }
  renew(subId) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subId);
    this.router.navigate(['/orders/add-order'], {
      queryParams: {
        isGWS: true,
        customer_api_id: this.currentCustomer.customer_api_id,
        domain_rfid: this.currentCustomer._id,
        domain: this.currentCustomer.domain,
        organisation: this.currentCustomer.organisation_name,
        subAction: true,
        renew: true,
        skuId: subs.skuId,
        planId: this.plans.find((x) => x.code == subs.plan?.planName)._id,
        planName: subs.plan.planName,
        skuName: subs.skuName,
        license: subs.seatsNbr,
        subscription_api_id: subs.subscriptionId,
        end_api_date: subs.plan?.commitmentInterval?.endTime,
      },
      skipLocationChange: true,
    });
  }

  startPaidServiceConfirmation(subId) {
    this.selectSubscriptionId = subId;
    this.startPaidServiceModal.show();
  }
  async startPaidService() {
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    if (sub.plan.planName.toUpperCase() !== PlanValues.TRIAL) {
      alert('Start paid service only from a free trial subscription');
      return;
    }
    var order = new OrderModel();
    order.action = OrderAction.STRAT_PAID_SERVICE;
    this.action = OrderAction.STRAT_PAID_SERVICE;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_api_id = this.currentCustomer.customer_api_id;
    order.customer_domain = this.currentCustomer.domain;
    order.is_gcp = false;
    order.order_status = this.currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    var toExecute = this.currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers =
      Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.startPaidServiceModal.hide();
        }
      });
  }

  updateStatusConfirmation(subId, active: boolean) {
    this.selectSubscriptionId = subId;
    if (active == true) this.statusTextAction = 'suspend';
    else this.statusTextAction = 'activate';
    this.statusModal.show();
  }
  async updateStatus1() {
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    var order = new OrderModel();
    var toSuspend = sub.status == Status.Active;
    order.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    this.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_domain = this.currentCustomer.domain;
    order.customer_api_id = this.currentCustomer.customer_api_id;
    order.is_gcp = false;
    order.order_status = this.currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    var toExecute = this.currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers =
      Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.statusModal.hide();
        }
      });
  }

  async updateStatus() {
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    var order = new OrderModel();
    var toSuspend = sub.status == Status.Active;
    order.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    this.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_domain = this.currentCustomer.domain;
    order.is_gcp = false;
    order.order_status = OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    await this.http
      .post(Endpoint.GLOBAL_URL + '' + Endpoint.SEND_APPROVAL_REQUEST, order)
      .toPromise()
      .then(async (resp: any) => {
        if (resp?.success == true) {
          this.statusModal.hide();
          var that = this;
          var action = !toSuspend ? 'Activation' : 'Suspension';
          var actionVerb = !toSuspend ? 'activate' : 'suspend';
          var actionAdj = !toSuspend ? 'active' : 'suspended';
          that.confirmDialogService.confirmThis(
            'your request to ' +
              actionVerb +
              ' ' +
              sub.skuName +
              ' for ' +
              that.currentCustomer.domain +
              ' has been sent to the CEO. They will not get ' +
              actionAdj +
              ' until it is approved by the CEQ himself.',
            action + ' request sent',
            'info',
            function () {},
            function () {}
          );
        }
      });
  }

  updateRenewalSettingsConfirmation(subId) {
    this.newRenewalSettingsType = '';
    this.selectSubscriptionId = subId;
    this.renewalSettingsModal.show();
  }
  async updateRenewalSettings() {
    if (Utilities.isNullOrEmpty(this.newRenewalSettingsType)) {
      alert('Select Renewal type');
      return;
    }
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    var order = new OrderModel();
    order.action = OrderAction.UPDATE_RNEWAL_SETTNGS;
    this.action = OrderAction.UPDATE_RNEWAL_SETTNGS;
    order.created_by_rfid = this.currentUser._id;
    order.domain_rfid = this.currentCustomer._id;
    order.customer_domain = this.currentCustomer.domain;
    order.customer_api_id = this.currentCustomer.customer_api_id;
    order.is_gcp = false;
    order.order_status = this.currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
        renewel_setting_type: this.newRenewalSettingsType,
      },
    ];
    var toExecute = this.currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers =
      Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.renewalSettingsModal.hide();
          if (this.newRenewalSettingsType == 'RENEW_ON_PROPOSED_OFFER') {
            this.router.navigate(['deals/add-deal'], {
              queryParams: {},
              skipLocationChange: true,
            });
          }
        }
      });
  }

  async changeLicensePopup(subId) {
    this.LicenseForm = this.fb.group({
      current_licenses: [{ value: 0, disabled: true }, , Validators.required],
      sku_name: [{ value: '', disabled: true }, Validators.required],
      plan_id: ['', Validators.required],
      plan_name: ['', Validators.required],
      sku_id: ['', Validators.required],
      additional_licenses: [0, Validators.required],
      po_id: [null, Validators.required],
      order_sale_invoice_nbr: [null, Validators.required],
      opportunity_status: [null, Validators.required],
      opportunity_source: [null, Validators.required],
      customer_email_notification: [''],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      is_flexible: [false],
      annual_months: [null],
      with_customer_offer: [false, Validators.required],
      customer_offer: [0],
      with_google_offer: [false, Validators.required],
      google_offer: [0],
      cost_price: [0],
      selling_price: [0],
      company_section: [null, Validators.required],
    });
    this.selectSubscriptionId = subId;
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    if (sub.plan?.planName?.toLowerCase() == PlanValues.FLEXIBLE.toLowerCase())
      this.updateLicenseText = 'New License Cap';
    else this.updateLicenseText = 'Additional Licenses Needed';
    var isFlexible = sub.plan?.planName?.toLowerCase().indexOf('flexible') >= 0;
    this.LicenseForm.patchValue({
      current_licenses: sub.seatsNbr,
      sku_name: sub.skuName,
      plan_name: sub.plan?.planName,
      sku_id: sub.skuId,
      is_flexible: isFlexible,
      plan_id: this.plans.find((x) => x.code == sub.plan?.planName)._id,
      start_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      end_date: sub.plan?.commitmentInterval?.endTime
        ? this.datePipe.transform(
            new Date(Number.parseFloat(sub.plan?.commitmentInterval?.endTime)),
            'yyyy-MM-dd'
          )
        : null,
    });
    this.LicenseForm.updateValueAndValidity();
    this.changeLicenseModal.show();
  }

  async updateLicense() {
    var sub = this.allSubcs.find(
      (x) => x.subscriptionId == this.selectSubscriptionId
    );
    var order = new OrderModel();
    if (this.LicenseForm.valid) {
      var data = this.LicenseForm.value;
      if (
        (data.with_google_offer == true &&
          (!data.google_offer || data.google_offer == 0)) ||
        (data.with_customer_offer == true &&
          (!data.customer_offer || data.customer_offer == 0))
      ) {
        alert('you must add Offer percentage');
        return;
      }
      order.action = OrderAction.UPDATE_LICENSE;
      this.action = OrderAction.UPDATE_LICENSE;
      order.created_by_rfid = this.currentUser._id;
      order.domain_rfid = this.currentCustomer._id;
      order.customer_api_id = this.currentCustomer.customer_api_id;
      order.customer_domain = this.currentCustomer.domain;
      order.order_status = this.currentUser.isExecutor
        ? OrderStatus.Executed
        : OrderStatus.Pending;
      order.po_id = data.po_id;
      order.is_gcp = false;
      order.order_sale_invoice_nbr = data.order_sale_invoice_nbr;
      order.opportunity_source = data.opportunity_source;
      order.opportunity_status = data.opportunity_status;
      order.customer_email_notification = data.customer_email_notification;
      order.company_section = data.company_section;
      var newLicenses = sub.seatsNbr + data.additional_licenses;
      if (sub.plan?.planName.toLowerCase() == PlanValues.FLEXIBLE.toLowerCase())
        newLicenses = data.additional_licenses; // ON FLEX ADDITIONAL IS THE NEW NBR
      var plan = this.plans.find((x) => x._id && x._id == data.plan_id);
      var is_commitment = false;
      if (
        plan?.code &&
        plan.code.toLowerCase().indexOf(PlanValues.ANNUAL.toLowerCase()) >= 0
      )
        is_commitment = true;
      order.sku = [
        {
          sku_id: sub.skuId,
          sku_name: sub.skuName,
          plan_id: data.plan_id,
          license: newLicenses ?? 0,
          old_license: sub.seatsNbr,
          subscription_api_id: sub.subscriptionId,
          cost_price: data.cost_price,
          selling_price: data.selling_price,
          with_customer_offer: data.with_customer_offer,
          customer_offer: data.customer_offer,
          with_google_offer: data.with_google_offer,
          google_offer: data.google_offer,
          start_date: data.start_date,
          end_date: data.end_date,
          plan_code: plan?.code,
          plan_name: plan?.name,
          is_commitment: is_commitment,
        },
      ];
      var toExecute = this.currentUser.isExecutor ? '1' : '0';
      var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
      var receivers =
        Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
      await this.http
        .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_ORDER, order, {
          params: params,
        })
        .toPromise()
        .then(async (resp: any) => {
          console.log(resp);
          if (
            resp &&
            resp.success == true &&
            !Utilities.isNullOrEmpty(resp.id)
          ) {
            order._id = resp.id;
            this.changeLicenseModal.hide();
          }
        });
    } else {
      alert('Add required fields');
    }
  }

  createOrder() {
    this.router.navigate(['/orders/add-order'], {
      queryParams: {
        isGWS: true,
        customer_api_id: this.currentCustomer.id,
        domain_rfid: this.currentCustomer._id,
        domain: this.currentCustomer.domain,
        organisation: this.currentCustomer.organisation_name,
      },
      skipLocationChange: true,
    });
  }

  calculatePrices() {
    var sku = this.LicenseForm;
    if (
      !Utilities.isNullOrEmpty(sku.value.start_date) &&
      !Utilities.isNullOrEmpty(sku.value.annual_months) &&
      sku.value.annual_months > 0
    ) {
      var start = new Date(sku.value.start_date);
      var end_date: any = new Date(
        start.setMonth(start.getMonth() + sku.value.annual_months)
      );
      end_date = this.datePipe.transform(end_date, 'yyyy-MM-dd');
      sku.value.end_date = end_date;
      sku.controls['end_date'].setValue(end_date);
    }
    if (
      Utilities.isNullOrEmpty(sku.value.start_date) ||
      Utilities.isNullOrEmpty(sku.value.end_date)
    ) {
      return;
    }
    var from_ = new Date(sku.value.start_date);
    var to_ = new Date(sku.value.end_date);
    var mnoths = Utilities.calculateDiffMonths(from_, to_);
    var days = mnoths * 30;

    var skuObjectId = this.googleProducts.find(
      (x) =>
        sku.value.sku_id &&
        x.sku_id.toLowerCase() == sku.value.sku_id.toLowerCase()
    )?._id;
    var priceObj = this.prices.find(
      (x) =>
        skuObjectId &&
        sku.value.plan_id &&
        x.sku_rfid == skuObjectId &&
        x.plan_rfid == sku.value.plan_id
    );
    if (priceObj) {
      var price = priceObj.price / 30;
      var costMargin = Utilities.setOrderCostMargin(this.action);
      var costPrice: number = price * costMargin * days;
      var sellingPrice: number = price * days;

      //OFFERS
      if (
        sku.value.with_google_offer &&
        sku.value.google_offer &&
        sku.value.google_offer != 0
      ) {
        var offer = (100 - sku.value.google_offer) / 100;
        costPrice *= offer;
      }
      if (
        sku.value.with_customer_offer &&
        sku.value.customer_offer &&
        sku.value.customer_offer != 0
      ) {
        var offer = (100 - sku.value.customer_offer) / 100;
        sellingPrice *= offer;
      }
      //LICENSES
      var licencesNbr = 0;
      var currentLicenses =
        this.LicenseForm.get('current_licenses')?.value ?? 0;
      if (
        sku.value.plan_name.toUpperCase() ==
          PlanValues.FLEXIBLE.toUpperCase() &&
        sku.value.additional_licenses > currentLicenses
      ) {
        licencesNbr = sku.value.additional_licenses - currentLicenses;
      } else if (
        sku.value.plan_name.toUpperCase() !== PlanValues.FLEXIBLE.toUpperCase()
      ) {
        licencesNbr = sku.value.additional_licenses;
      }
      if (licencesNbr > 0) {
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;
        this.LicenseForm.patchValue({
          selling_price: Number.parseFloat(sellingPrice.toFixed(2)),
          cost_price: Number.parseFloat(costPrice.toFixed(2)),
        });
      }
    }
  }

  get current_licenses() {
    return this.LicenseForm.get('end_date');
  }
  get sku_name() {
    return this.LicenseForm.get('sku_name');
  }
  get plan_id() {
    return this.LicenseForm.get('plan_id');
  }
  get plan_name() {
    return this.LicenseForm.get('plan_name');
  }
  get sku_id() {
    return this.LicenseForm.get('sku_id');
  }
  get additional_licenses() {
    return this.LicenseForm.get('additional_licenses');
  }
  get po_id() {
    return this.LicenseForm.get('po_id');
  }
  get order_sale_invoice_nbr() {
    return this.LicenseForm.get('order_sale_invoice_nbr');
  }
  get opportunity_source() {
    return this.LicenseForm.get('opportunity_source');
  }
  get opportunity_status() {
    return this.LicenseForm.get('opportunity_status');
  }
  get customer_email_notification() {
    return this.LicenseForm.get('customer_email_notification');
  }
  get with_google_offer() {
    return this.LicenseForm.get('with_google_offer');
  }
  get google_offer() {
    return this.LicenseForm.get('google_offer');
  }
  get with_customer_offer() {
    return this.LicenseForm.get('with_customer_offer');
  }
  get customer_offer() {
    return this.LicenseForm.get('customer_offer');
  }
  get start_date() {
    return this.LicenseForm.get('start_date');
  }
  get annual_months() {
    return this.LicenseForm.get('annual_months');
  }
  get end_date() {
    return this.LicenseForm.get('end_date');
  }
  get company_section() {
    return this.LicenseForm.get('company_section');
  }
}
