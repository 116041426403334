import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { DealModel } from 'src/models/mongo/deal.model';
import { DomainModel } from 'src/models/mongo/domain.model';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Endpoint,
  Group,
  OrderStatus,
  PlanValues,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'app-deal-form',
  templateUrl: './deal-form.component.html',
  styleUrls: ['./deal-form.component.scss'],
})
export class DealFormComponent implements OnInit, AfterViewInit {
  dealForm: UntypedFormGroup;
  googleProducts = [];
  prices = [];
  currentUser: UserModel;
  plans = [];
  dealTypes = [];
  sources = [];
  decisionPhases = [];
  budgets = [];
  authorities = [];
  needs = [];
  timelines = [];
  opporunityStages = [];
  orderPhasesInfo;
  @Input() addDeal;
  @Input() onlyView;
  @Input() model = new DealModel();
  @Input() isGCP;
  totalAmount: number = 0;
  totalUsers: number = 0;
  searchResultCustomers = [];
  @Output() closeForm = new EventEmitter<any>();
  isSalesManager: boolean;
  @ViewChild('rejectionDealModal') rejectionDealModal: ModalDirective;
  dealRejectionReason: string;
  loginUsersData;
  currentDomain: DomainModel;
  canApprove: boolean = false;
  annualPlanId;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private ngzone: NgZone
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
    this.dealForm = fb.group({
      customer_domain: [null, Validators.required],
      customer_organisation: [null, Validators.required],
      opportunity_source: [null],
      type: [null, Validators.required],
      source: [null, Validators.required],
      estimated_close_date: [null, Validators.required],
      decision_phase: [null, Validators.required],
      public_tender: [false, Validators.required],
      budget: [null, Validators.required],
      authority: [null, Validators.required],
      need: [null, Validators.required],
      timeline: [null, Validators.required],
      google_rep: [null],
      description: [null],
      campaign_type: [null],
      dr_nbr: [''],
      opportunity_nbr: [''],
      opportunity_owner: [''],
      opportunity_stage: [''],
      opportunity_link: [''],
      opportunity_creation_date: [''],
      dv_requested: [false],
      campaign: [false],
      rebate_expiry_date: [''],
      sku: this.fb.array([]),
      project_numbers: [''],
      project_number_validated: [''],
      billing_id: [''],
      daf_available: [false],
      daf_amount: [''],
      not_existing_daf_reason: [''],
      attachedFileDaf: [null],
      attachedFileNameDaf: [null],
      psf_available: [false],
      psf_amount: [''],
      not_existing_psf_reason: [''],
      attachedFilePsf: [null],
      attachedFileNamePsf: [null],
    });
  }
  ngOnInit(): void {
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentDomain = JSON.parse(cstjson);
  }

  async ngAfterViewInit(): Promise<void> {
    var globalData = await Utilities.globalData(this.http, this.localStorage);
    var gData = Utilities.getGlobalDataValues(globalData);
    if (gData) {
      this.dealTypes = gData.dealTypes;
      this.needs = gData.needs;
      this.timelines = gData.timelines;
      this.authorities = gData.authorities;
      this.budgets = gData.budgets;
      this.decisionPhases = gData.decisionPhases;
      this.sources = gData.sources;
      this.opporunityStages = gData.opporunityStages;
    }
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    if (!this.isGCP) {
      this.ngzone.run(() => {
        this.dealForm.removeControl('project_numbers');
        this.dealForm.removeControl('project_number_validated');
        this.dealForm.removeControl('billing_id');
        this.dealForm.updateValueAndValidity();
      });
    }
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.annualPlanId = this.plans.find(
      (x) => (x.code ?? '').toUpperCase() == PlanValues.ANNUAL
    )?._id;
    if (this.isGCP)
      this.googleProducts = this.googleProducts.filter((x) => x.gcp == true);
    else
      this.googleProducts = this.googleProducts.filter(
        (x) => x.gcp == undefined || x.gcp == false
      );
    if (this.model && !Utilities.isNullOrEmpty(this.model.id)) {
      //MODEL NOT CONTAINS ALL DATA -- ELSE : FROM OPP PAGE AND IT HAS ALL INFO
      if (
        Utilities.isNullOrEmpty(this.model.created_by_rfid) &&
        Utilities.isNullOrEmpty(this.model.domain_rfid)
      ) {
        //GET DEAL BY ID FROM DB
        await this.http
          .get(
            Endpoint.GLOBAL_URL + '' + Endpoint.GET_DEAL_BY_ID + this.model.id
          )
          .toPromise()
          .then((resp: any) => {
            if (resp) {
              this.model = resp;
            }
          });
      }
      this.model = new DealModel(this.model);
      var that = this;
      if (this.model.approval_phases) {
        var managerPhase = this.model
          .getSortedApprovalPhasesByDateDesc()
          .find(
            (x) => x.role?.toLowerCase() == Group.Sale_Manager.toLowerCase()
          );
        var gpmPhase = this.model.approval_phases.find(
          (x) => x.role?.toLowerCase() == Group.GPM.toLowerCase()
        );
        this.model.approved_by_gpm = !Utilities.isNullOrEmpty(gpmPhase?.date);
        this.isSalesManager = this.currentUser._id == managerPhase?.id;
        if (
          this.isSalesManager &&
          managerPhase &&
          (!managerPhase.date || !managerPhase.approved)
        )
          this.canApprove = true;
      }

      this.model.estimated_close_date = this.model.getStringEstimatedClosedDate(
        this.datePipe
      );
      this.model.rebate_expiry_date = this.model.getStringRebateExpiryDate(
        this.datePipe
      );
      this.model.opportunity_creation_date =
        this.model.getStringOpportunityCreationDate(this.datePipe);
      this.dealForm.patchValue({
        ...this.model,
        customer_domain:
          this.model.domain?.domain ?? this.model.customer_domain,
        customer_organisation:
          this.model.domain?.organisation_name ??
          this.model.customer_organisation,
        daf_available: this.model.daf?.available,
        daf_amount: this.model.daf?.amount,
        not_existing_daf_reason: this.model.daf?.not_existing_reason,
        attachedFileNameDaf: this.model.daf?.attachment_proof_name,
        attachedFileDaf: this.model.daf?.attachment_proof_name
          ? { name: this.model.daf?.attachment_proof_name }
          : null,
        psf_available: this.model.psf?.available,
        psf_amount: this.model.psf?.amount,
        not_existing_psf_reason: this.model.psf?.not_existing_reason,
        attachedFileNamePsf: this.model.psf?.attachment_proof_name,
        attachedFilePsf: this.model.psf?.attachment_proof_name
          ? { name: this.model.psf?.attachment_proof_name }
          : null,
      });
      this.dafFileName = this.model.daf?.attachment_proof_name;
      this.psfFileName = this.model.psf?.attachment_proof_name;
      if (this.model.campaign == true) {
        this.dealForm.controls['campaign_type'].setValidators([
          Validators.required,
          Validators.minLength(1),
        ]);
        this.dealForm.controls['campaign_type'].updateValueAndValidity();
      }
      this.googleProducts.forEach((product) => {
        var sku = this.model.sku?.find(
          (x) => x.sku_id && x.sku_id == product.sku_id
        );
        if (this.isGCP) {
          if (sku) {
            var price = sku.amount ?? 0;
          } else {
            var price = this.prices.find(
              (x) =>
                x.sku_rfid == product._id && x.plan_rfid == this.annualPlanId
            )?.price;
            if (price > 0 && !Utilities.isNullOrEmpty(this.model.type)) {
              var costMargin = Utilities.setDealCostMargin(this.model.type);
              price = price * costMargin;
            }
          }
          price = price ? Number.parseFloat(price.toFixed(2)) : 0;
          this.sku.push(
            this.fb.group({
              sku_id: [product.sku_id, [Validators.required]],
              sku_name: [product.sku_name, [Validators.required]],
              license: [sku ? sku.license : 1, Validators.required],
              amount: [price],
              checked: [sku != null],
            })
          );
        } else {
          var pricePerUnit = 0;
          var price = this.prices.find(
            (x) => x.sku_rfid == product._id && x.plan_rfid == this.annualPlanId
          )?.price;
          if (price > 0 && !Utilities.isNullOrEmpty(this.model.type)) {
            var costMargin = Utilities.setDealCostMargin(this.model.type);
            pricePerUnit = Number.parseFloat((price * costMargin).toFixed(2));
          }
          if (sku) {
            price = sku.cost_price ?? 0;
          } else {
            price = pricePerUnit;
          }
          price = price ? Number.parseFloat(price.toFixed(2)) : 0;
          this.sku.push(
            this.fb.group({
              sku_id: [product.sku_id, [Validators.required]],
              sku_name: [product.sku_name, [Validators.required]],
              license: [sku ? sku.license : 1, Validators.required],
              cost_price: [price],
              cost_price_per_user: [pricePerUnit],
              checked: [sku != null],
            })
          );
        }
      });
    } else if (this.model) {
      this.canApprove = false;
      this.model.is_gcp = this.isGCP;
      this.model.created_by_rfid = this.currentUser['_id'];
      if (Utilities.isNullOrEmpty(this.model.domain) && this.currentDomain) {
        this.model.domain = this.currentDomain;
      }
      if (!Utilities.isNullOrEmpty(this.model.domain)) {
        this.dealForm.patchValue({
          customer_domain: this.model.domain.domain,
          customer_organisation: this.model.domain.organisation_name,
        });
      }
      if (this.isGCP) {
        this.googleProducts.forEach((product) => {
          var price =
            this.prices.find(
              (x) =>
                x.sku_rfid == product._id && x.plan_rfid == this.annualPlanId
            )?.price ?? 0;
          if (price > 0 && !Utilities.isNullOrEmpty(this.model.type)) {
            var costMargin = Utilities.setDealCostMargin(this.model.type);
            price = price * costMargin;
          }
          price = Number.parseFloat(price.toFixed(2));
          this.sku.push(
            this.fb.group({
              sku_id: [product.sku_id, [Validators.required]],
              sku_name: [product.sku_name, [Validators.required]],
              license: [1, Validators.required],
              amount: [price],
              checked: [false],
            })
          );
        });
      } else {
        this.googleProducts.forEach((product) => {
          var price =
            this.prices.find(
              (x) =>
                x.sku_rfid == product._id && x.plan_rfid == this.annualPlanId
            )?.price ?? 0;
          if (price > 0 && !Utilities.isNullOrEmpty(this.model.type)) {
            var costMargin = Utilities.setDealCostMargin(this.model.type);
            price = price * costMargin;
          }
          price = Number.parseFloat(price.toFixed(2));
          this.sku.push(
            this.fb.group({
              sku_id: [product.sku_id, [Validators.required]],
              sku_name: [product.sku_name, [Validators.required]],
              license: [1, Validators.required],
              cost_price: [price],
              cost_price_per_user: [price],
              checked: [false],
            })
          );
        });
      }
    }
    if (this.isGCP) {
      this.totalAmount = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.amount, 0)
        .toFixed(2);
    } else {
      this.totalAmount = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.cost_price, 0)
        .toFixed(2);
      this.totalUsers = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.license, 0);
    }
    if (!this.currentDomain) this.currentDomain = this.model.domain;
    if (!this.model.approval_phases) this.model.approval_phases = [];
    // this.orderPhasesInfo = await Utilities.dealApprovalPhases(
    //   this.localStorage,
    //   this.model,
    //   usersInfo
    // );
  }

  async saveDeal(draft: boolean = false) {
    var that = this;
    var dafFile = this.dealForm.get('attachedFileDaf').value;
    var psfFile = this.dealForm.get('attachedFilePsf').value;
    if (this.dealForm.valid) {
      var skus = this.dealForm.value.sku;
      if (!skus || skus.filter((x) => x.checked == true).length == 0) {
        alert('You must check at least one SKU');
        return;
      }
      var deal: DealModel = this.dealForm.value;
      deal.draft = draft;
      deal.is_gcp = this.isGCP;
      deal.deal_status = this.orderPhasesInfo?.status ?? OrderStatus.Submitted;
      deal.sku = deal.sku.filter((x) => x.checked == true);
      if (Utilities.isNullOrEmpty(this.model.id)) {
        deal.created_by_rfid = this.model.created_by_rfid;
        deal.created_date_string = this.model.created_date_string;
        deal.domain_rfid = this.model.domain._id;
        if (!Utilities.isNullOrEmpty(this.currentUser.manager_id))
          deal.approval_phases = [
            {
              role: this.isGCP ? Group.Sale_Manager : Group.GPM,
              name: this.currentUser.manager_name,
              email: this.currentUser.manager_email,
              id: this.currentUser.manager_id,
            },
          ];
        else deal.approval_phases = [{ role: Group.GPM }];
        //DAF AND PSF
        this.setDafPsfInfo(deal);
        //
        var receivers = [];
        if (deal.draft == false) {
          if (this.isGCP)
            receivers = [
              !Utilities.isNullOrEmpty(this.currentUser.manager_id)
                ? this.currentUser.manager_email
                : this.loginUsersData?.gpm_group_email,
            ];
          else
            receivers = [
              this.currentUser.manager_email,
              this.loginUsersData?.gpm_group_email,
            ];
        }
        var params = new HttpParams().set(
          Unicode.EMAIL_RECEIVERS,
          JSON.stringify(receivers)
        );
        if (this.closeForm && this.closeForm.observers.length > 0) {
          //OPPORTUNITY CASE
          deal.daf.file = dafFile;
          deal.psf.file = psfFile;
          this.closeForm.emit(deal);
        } else {
          const formData = new FormData();
          if (deal.daf.available && dafFile?.size)
            formData.append('daf_file', dafFile);
          if (deal.psf.available && psfFile?.size)
            formData.append('psf_file', psfFile);
          formData.append('deal', JSON.stringify(deal));
          await this.http
            .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_DEAL, formData, {
              params: params,
            })
            .toPromise()
            .then(async (resp: any) => {
              alert(resp.message);
              this.model.id = resp.id;
              this.model.draft = draft;
              this.router.navigate(['/deals/all-deal']);
              return true;
            });
        }
      }
    }
  }

  setDafPsfInfo(deal: DealModel) {
    deal.daf = {
      available: this.dealForm.value.daf_available,
      amount: this.dealForm.value.daf_available
        ? this.dealForm.value.daf_amount
        : null,
      not_existing_reason: this.dealForm.value.not_existing_daf_reason,
      attachment_proof_name: this.dafFileName,
      added_by_id: this.currentUser._id,
      added_by_email: this.currentUser.email,
      added_by_name: this.currentUser.getFullName(),
    };
    deal.psf = {
      available: this.dealForm.value.psf_available,
      amount: this.dealForm.value.psf_available
        ? this.dealForm.value.psf_amount
        : null,
      not_existing_reason: this.dealForm.value.not_existing_psf_reason,
      attachment_proof_name: this.psfFileName,
      added_by_id: this.currentUser._id,
      added_by_email: this.currentUser.email,
      added_by_name: this.currentUser.getFullName(),
    };
  }

  async editDeal(send: boolean = false) {
    var that = this;
    if (this.dealForm.valid) {
      var skus = this.dealForm.value.sku;
      if (!skus || skus.filter((x) => x.checked == true).length == 0) {
        alert('You must check at least one SKU');
        return;
      }
      var deal: DealModel = this.dealForm.value;
      var oppDependencyOn = null;
      deal.id = this.model.id;
      if (send) deal.draft = false;
      //GPM PART
      if (this.currentUser.isGPM) {
        //CHECK GOOGLE REGISTRATION AND APPROVAL
        if (
          Utilities.isNullOrEmpty(this.model.dr_nbr) &&
          !Utilities.isNullOrEmpty(deal.dr_nbr)
        ) {
          deal.deal_status = OrderStatus.REGISTERED;
          this.model.google_registration_by_rfid = this.currentUser._id;
          this.model.google_registration_date = new Date();
          this.model.show_deal_google_registration_phase = true;
          this.model.deal_google_registered = true;
          this.model.google_registration_user_name =
            this.currentUser.getFullName();
          this.model.show_deal_google_approval_phase = true;
        }
        //
        if (
          Utilities.isNullOrEmpty(this.model.opportunity_nbr) &&
          !Utilities.isNullOrEmpty(deal.opportunity_nbr)
        ) {
          deal.deal_status = OrderStatus.APPROVED_BY_GOOGLE;
          this.model.google_approval_by_rfid = this.currentUser._id;
          this.model.google_approval_date = new Date();
          this.model.show_deal_google_approval_phase = true;
          this.model.google_approved_deal = true;
          this.model.google_approval_user_name = this.currentUser.getFullName();
          oppDependencyOn = 'PreSales';
        }
        //
      }
      var dr: any = this.model.google_registration_date;
      var da: any = this.model.google_approval_date;
      if (dr && dr.$date?.$numberLong)
        this.model.google_registration_date = new Date(
          Number.parseInt(dr.$date?.$numberLong)
        );
      if (da && da.$date?.$numberLong)
        this.model.google_approval_date = new Date(
          Number.parseInt(da.$date?.$numberLong)
        );
      //
      deal.sku = deal.sku.filter((x) => x.checked == true);
      var that = this;
      this.mappingModel(
        deal,
        null,
        (this.currentUser.isGPM && this.model.approved_by_gpm) ||
          this.model.created_by_rfid == this.currentUser._id
      );
      if (deal.approval_phases)
        deal.approval_phases.forEach((phase) => {
          if (phase?.date && phase.date.$date?.$numberLong)
            phase.date = new Date(
              Number.parseInt(phase.date.$date?.$numberLong)
            );
        });
      var receivers = [];
      if (send) {
        receivers = [
          !Utilities.isNullOrEmpty(this.currentUser.manager_id)
            ? this.currentUser.manager_email
            : this.loginUsersData?.gpm_group_email,
        ];
      } else if (this.currentUser.isGPM) {
        deal.show_deal_google_registration_phase = true;
        if (!Utilities.isNullOrEmpty(deal.dr_nbr)) {
          deal.show_deal_google_approval_phase = true;
        }
        if (
          Utilities.isNullOrEmpty(deal.dr_nbr) &&
          Utilities.isNullOrEmpty(deal.opportunity_nbr)
        ) {
          deal.deal_status = OrderStatus.Approved_by_GPM;
        }
        receivers = [this.model.user_deal.created_user_email];
        receivers.push(
          ...deal.approval_phases
            ?.filter(
              (x) =>
                x.email &&
                x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
            )
            .map((x) => x.email)
        );
      } else {
        receivers = [this.model.user_deal.created_user_email];
        receivers.push(
          ...deal.approval_phases
            ?.filter(
              (x) =>
                x.email &&
                x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
            )
            .map((x) => x.email)
        );
      }
      var params = new HttpParams()
        .set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers))
        .set(Unicode.DEAL_ACTION, send ? 'send_draft' : 'edit')
        .set(Unicode.DEPENDENCY_ON, oppDependencyOn);
      //DAF /PSF
      this.setDafPsfInfo(deal);
      var dafFile = this.dealForm.get('attachedFileDaf').value;
      var psfFile = this.dealForm.get('attachedFilePsf').value;
      const formData = new FormData();
      if (deal.daf.available && dafFile?.size)
        formData.append('daf_file', dafFile);
      if (deal.psf.available && psfFile?.size)
        formData.append('psf_file', psfFile);
      formData.append('deal', JSON.stringify(deal));
      await this.http
        .post(
          Endpoint.GLOBAL_URL + '' + Endpoint.UPDATE_DEAL + '/' + this.model.id,
          formData,
          { params: params }
        )
        .toPromise()
        .then(async (resp: any) => {
          alert(resp.message);
          if (resp?.success == true) {
            deal.daf.attachment_proof_drive_id =
              resp?.daf_attachment_proof_drive_id;
            deal.psf.attachment_proof_drive_id =
              resp?.psf_attachment_proof_drive_id;
            if (this.closeForm && this.closeForm.observers.length > 0) {
              deal.managerApprovalPart = resp?.approval_phases?.find(
                (x) => x.role.toLowerCase() == Group.Sale_Manager.toLowerCase()
              );
              var gpmPhase = resp?.approval_phases?.find(
                (x) => x.role.toLowerCase() == Group.GPM.toLowerCase()
              );

              deal.show_deal_google_registration_phase = gpmPhase?.date;
              deal.show_deal_google_approval_phase =
                !Utilities.isNullOrEmpty(deal.dr_nbr) ||
                !Utilities.isNullOrEmpty(deal.opportunity_nbr);

              deal.gpm_consumed_time = gpmPhase?.consumed_time;
              deal.google_registration_consumed_time =
                resp.google_registration_consumed_time;
              deal.google_approval_consumed_time =
                resp.google_approval_consumed_time;
              if (
                this.currentUser.isGPM &&
                Utilities.isNullOrEmpty(this.model.gpm_approval_name)
              ) {
                deal.gpm_approval_date = new Date();
                deal.gpm_approval_string_date = Utilities.toStringDateFormat(
                  this.datePipe,
                  new Date(),
                  'dd MMM,yyyy-hh:mma'
                );
                deal.gpm_approval_name = this.currentUser.getFullName();
                deal.approved_by_gpm = true;
              } else {
                deal.gpm_approval_date = gpmPhase?.date;
              }
              this.closeForm.emit(deal);
            } else this.router.navigate(['/deals/all-deal']);
            return true;
          }
        });
    }
  }
  mappingModel(deal, reason: string, withoutPhases = false) {
    deal.id = this.model.id;
    deal.user_deal = this.model.user_deal;
    deal.created_at = this.model.created_at;
    deal.created_date_string = this.model.created_date_string;
    deal.managerApprovalPart = this.model.managerApprovalPart;
    deal.consumed_time = this.model.consumed_time;
    // deal.customer_domain = this.model.customer_domain;
    // deal.customer_organisation = this.model.customer_organisation;
    //
    deal.show_gpm_phase = this.model.show_gpm_phase;
    deal.approved_by_gpm = this.model.approved_by_gpm;
    deal.gpm_approval_name = this.model.gpm_approval_name;
    deal.gpm_approval_string_date = this.model.gpm_approval_string_date;
    //
    deal.google_registration_by_rfid = this.model.google_registration_by_rfid;
    deal.google_registration_date = this.model.google_registration_date;
    deal.deal_google_registered = this.model.deal_google_registered;
    deal.google_registration_user_name =
      this.model.google_registration_user_name;
    deal.google_registration_string_date =
      this.model.google_registration_string_date;
    deal.google_approval_by_rfid = this.model.google_approval_by_rfid;
    deal.google_approval_date = this.model.google_approval_date;
    deal.google_approved_deal = this.model.google_approved_deal;
    deal.google_approval_user_name = this.model.google_approval_user_name;
    deal.google_approval_string_date = this.model.google_approval_string_date;
    //
    var approvalPhases = this.model.approval_phases;
    if (!withoutPhases && this.model.approval_phases) {
      var mainGrpName =
        this.currentUser.groups.find((x) => x.main == true)?.name ?? '';
      var role = this.model.approval_phases.find(
        (x) =>
          x.role && x.role.toLowerCase().indexOf(mainGrpName.toLowerCase()) >= 0
      );
      if (role && Utilities.isNullOrEmpty(role.date)) {
        role.name = this.currentUser.getFullName();
        role.email = this.currentUser.email;
        role.id = this.currentUser._id;
        role.date = new Date();
        role.approved = Utilities.isNullOrEmpty(reason);
        if (!Utilities.isNullOrEmpty(reason)) role.rejection_reason = reason;
      } else {
        var row: any = {
          role: this.isSalesManager ? Group.Sale_Manager : Group.GPM,
          name: this.currentUser.getFullName(),
          email: this.currentUser.email,
          id: this.currentUser._id,
          date: new Date(),
          approved: Utilities.isNullOrEmpty(reason),
        };
        if (!Utilities.isNullOrEmpty(reason)) row.rejection_reason = reason;
        approvalPhases.push(row);
      }
    }
    deal.approval_phases = approvalPhases;
  }

  async approve(isRejection: boolean = false) {
    //MANAGER
    if (isRejection) {
      this.dealRejectionReason = null;
      this.rejectionDealModal.show();
    } else {
      if (this.dealForm.valid) {
        var skus = this.dealForm.value.sku;
        if (!skus || skus.filter((x) => x.checked == true).length == 0) {
          alert('You must check at least one SKU');
          return;
        }
        var deal: DealModel = this.dealForm.value;
        deal.sku = deal.sku.filter((x) => x.checked == true);
        deal.id = this.model.id;
        this.mappingModel(deal, null);
        var oppDependencyOn = null;
        deal.opportunity_rfid = this.model.opportunity_rfid;
        if (deal.opportunity_rfid) oppDependencyOn = Group.GPM;
        if (
          !deal.approval_phases?.find(
            (x) => x.role && x.role.toLowerCase() == Group.GPM.toLowerCase()
          )
        ) {
          deal.approval_phases.push({ role: Group.GPM });
        }
        this.model.approval_phases = deal.approval_phases;
        deal.deal_status = this.isSalesManager
          ? OrderStatus.Approved
          : OrderStatus.Approved_by_GPM;
        var receivers = [
          this.model.user_deal.created_user_email,
          this.loginUsersData?.gpm_group_email,
        ];
        receivers.push(
          ...deal.approval_phases
            .filter(
              (x) =>
                x.email &&
                x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
            )
            .map((x) => x.email)
        );
        var params = new HttpParams()
          .set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers))
          .set(Unicode.DEAL_ACTION, isRejection ? 'reject' : 'approve')
          .set(Unicode.DEPENDENCY_ON, oppDependencyOn);
        //DAF /PSF
        this.setDafPsfInfo(deal);
        var dafFile = this.dealForm.get('attachedFileDaf').value;
        var psfFile = this.dealForm.get('attachedFilePsf').value;
        const formData = new FormData();
        if (deal.daf.available && dafFile?.size)
          formData.append('daf_file', dafFile);
        if (deal.psf.available && psfFile?.size)
          formData.append('psf_file', psfFile);
        formData.append('deal', JSON.stringify(deal));
        await this.http
          .post(
            Endpoint.GLOBAL_URL +
              '' +
              Endpoint.UPDATE_DEAL +
              '/' +
              this.model.id,
            formData,
            { params: params }
          )
          .toPromise()
          .then(async (resp: any) => {
            console.log(resp);
            alert(resp.message);
            if (
              resp?.success == true &&
              this.closeForm &&
              this.closeForm.observers.length > 0
            ) {
              if (this.isSalesManager) {
                deal.show_gpm_phase = true;
                deal.managerApprovalPart = resp?.approval_phases?.find(
                  (x) =>
                    x.role.toLowerCase() == Group.Sale_Manager.toLowerCase()
                );
              }
              this.closeForm.emit(deal);
            } else if (resp?.success == true)
              this.router.navigate(['/deals/all-deal']);
            // return true;
          });
      }
    }
  }

  async rejectDeal(dealRejectionReason) {
    //MANAGER
    if (
      dealRejectionReason &&
      !Utilities.isNullOrEmpty(dealRejectionReason.value)
    ) {
      var reason = dealRejectionReason.value;
      var deal: DealModel = this.dealForm.value;
      deal.sku = deal.sku.filter((x) => x.checked == true);
      this.mappingModel(deal, reason);
      deal.deal_status = OrderStatus.Rejected;
      var receivers = [
        this.model.user_deal.created_user_email,
        this.loginUsersData?.gpm_group_email,
      ];
      receivers.push(
        ...deal.approval_phases
          ?.filter(
            (x) =>
              x.email &&
              x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
          )
          .map((x) => x.email)
      );
      var params = new HttpParams().set(
        Unicode.EMAIL_RECEIVERS,
        JSON.stringify(receivers)
      );
      //DAF /PSF
      this.setDafPsfInfo(deal);
      var dafFile = this.dealForm.get('attachedFileDaf').value;
      var psfFile = this.dealForm.get('attachedFilePsf').value;
      const formData = new FormData();
      if (deal.daf.available && dafFile?.size)
        formData.append('daf_file', dafFile);
      if (deal.psf.available && psfFile?.size)
        formData.append('psf_file', psfFile);
      formData.append('deal', JSON.stringify(deal));
      await this.http
        .post(
          Endpoint.GLOBAL_URL + '' + Endpoint.UPDATE_DEAL + '/' + this.model.id,
          formData,
          { params: params }
        )
        .toPromise()
        .then(async (resp: any) => {
          alert(resp.message);
          if (
            resp?.success == true &&
            this.closeForm &&
            this.closeForm.observers.length > 0
          ) {
            if (this.isSalesManager) {
              deal.managerApprovalPart = resp?.approval_phases?.find(
                (x) => x.role.toLowerCase() == Group.Sale_Manager.toLowerCase()
              );
            }
            this.closeForm.emit(deal);
          } else if (resp?.success == true)
            this.router.navigate(['/deals/all-deal']);
          // return true;
        });
    } else {
      alert('Add Rejection Reason please');
    }
  }

  addOppNbr(e) {
    var value = e?.target?.value;
    if (
      this.currentUser.isGPM &&
      Utilities.isNullOrEmpty(this.model.opportunity_nbr) &&
      !Utilities.isNullOrEmpty(value)
    ) {
      this.model.google_approval_by_rfid = this.currentUser._id;
      this.model.google_approval_date = new Date();
      this.model.show_deal_google_approval_phase = true;
      this.model.google_approved_deal = true;
      this.model.google_approval_user_name = this.currentUser.getFullName();
      this.model.google_approval_string_date = Utilities.toStringDateFormat(
        this.datePipe,
        new Date(),
        'dd MMM,yyyy-hh:mma'
      );
    }
  }

  async onSearchDomainEnter(e) {
    var value = e.term ?? e.target.value;
    if (value && value.length > 2) {
      if (!Utilities.isNullOrEmpty(value)) this.searchResultCustomers = [];
      var params = new HttpParams()
        .set(Unicode.CUSTOMER_FILTER, value)
        .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
      await this.http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.SEARCH_FOR_CUSTOMERS_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.domains?.length > 0) {
            this.searchResultCustomers = resp.domains ?? [];
          }
        });
    }
  }

  selectDomain() {
    var domainSelected: any = this.searchResultCustomers.find(
      (x) => x.domain == this.dealForm.value.customer_domain
    );
    if (domainSelected) {
      this.dealForm.patchValue({
        customer_organisation: domainSelected.organisation_name,
        customer_domain: domainSelected.domain,
      });
      this.model.domain = domainSelected;
      // this.localStorage.save(
      //   Unicode.CURRENT_CUSTOMER,
      //   JSON.stringify(this.domain)
      // );
    }
  }

  selectOrg() {
    var domainSelected = this.searchResultCustomers.find(
      (x) => x.organisation_name == this.dealForm.value.customer_organisation
    );
    if (domainSelected) {
      this.dealForm.patchValue({
        customer_organisation: domainSelected.organisation_name,
        customer_domain: domainSelected.domain,
      });
      this.model.domain = domainSelected;
      // this.localStorage.save(
      //   Unicode.CURRENT_CUSTOMER,
      //   JSON.stringify(this.domain)
      // );
    }
  }

  createNewCustomer() {
    this.router.navigate(['customers/customer-create'], {
      queryParams: { isCreationOrTransfer: true },
    });
  }

  viewCustomerDetails() {
    this.router.navigate(['customers/customer-create'], {
      state: { customerRfid: this.model.domain.customer_rfid },
      queryParams: {
        isCreationOrTransfer: Utilities.isNullOrEmpty(
          this.model.customer_auth_token
        ),
      },
    });
  }

  checkNew() {
    if (this.isGCP) {
      this.totalAmount = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.amount, 0)
        .toFixed(2);
    } else {
      this.totalAmount = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.cost_price, 0)
        .toFixed(2);
      this.totalUsers = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.license, 0);
    }
  }

  calculatePrices(sku) {
    if (this.isGCP) {
      this.totalAmount = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.amount, 0)
        .toFixed(2);
    } else {
      var skuObjectId = this.googleProducts.find(
        (x) =>
          sku.value.sku_id &&
          x.sku_id.toLowerCase() == sku.value.sku_id.toLowerCase()
      )?._id;
      var priceObj = this.prices.find(
        (x) =>
          skuObjectId &&
          x.sku_rfid == skuObjectId &&
          x.plan_rfid == this.annualPlanId
      );
      if (priceObj) {
        var costMargin = Utilities.setDealCostMargin(this.model.type);
        var price = priceObj.price * costMargin;
        price = price * sku.value.license;
        sku.controls['cost_price'].setValue(
          Number.parseFloat(price.toFixed(2))
        );
      }
      this.totalAmount = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.cost_price, 0)
        .toFixed(2);
      this.totalUsers = this.sku.controls
        .filter((x) => x.value.checked == true)
        .reduce((sum, current) => sum + current.value.license, 0);
    }
  }

  dafFileName: string;
  psfFileName: string;
  async onAttachDafPsfFile(daf: boolean, event) {
    const file = event.target.files[0];
    if (file) {
      if (daf) {
        this.dafFileName = file.name;
        this.dealForm.controls['attachedFileDaf'].setValue(file);
        this.dealForm.controls['attachedFileDaf'].updateValueAndValidity();
      } else {
        this.psfFileName = file.name;
        this.dealForm.controls['attachedFilePsf'].setValue(file);
        this.dealForm.controls['attachedFilePsf'].updateValueAndValidity();
      }
    }
  }

  dafPsfAvailableEvent(e, isDAF: boolean, yes: boolean) {
    if (e.target.checked) {
      if (isDAF) {
        if (yes) {
          this.dealForm.controls['attachedFileDaf'].setValidators([
            Validators.required,
          ]);
          this.dealForm.controls['attachedFileDaf'].updateValueAndValidity();
        } else {
          this.dealForm.controls['attachedFileDaf'].setValidators([]);
          this.dealForm.controls['attachedFileDaf'].updateValueAndValidity();
        }
      } else {
        if (yes) {
          this.dealForm.controls['attachedFilePsf'].setValidators([
            Validators.required,
          ]);
          this.dealForm.controls['attachedFilePsf'].updateValueAndValidity();
        } else {
          this.dealForm.controls['attachedFilePsf'].setValidators([]);
          this.dealForm.controls['attachedFilePsf'].updateValueAndValidity();
        }
      }
    }
  }

  campaignAction(e) {
    if (e?.target?.checked) {
      this.dealForm.controls['campaign_type'].setValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
      this.dealForm.controls['campaign_type'].updateValueAndValidity();
    } else {
      this.dealForm.controls['campaign_type'].setValidators([]);
      this.dealForm.controls['campaign_type'].updateValueAndValidity();
    }
  }
  //
  get customer_domain() {
    return this.dealForm.get('customer_domain');
  }
  get customer_organisation() {
    return this.dealForm.get('customer_organisation');
  }
  get type() {
    return this.dealForm.get('type');
  }
  get source() {
    return this.dealForm.get('source');
  }
  get estimated_close_date() {
    return this.dealForm.get('estimated_close_date');
  }
  get decision_phase() {
    return this.dealForm.get('decision_phase');
  }
  get public_tender() {
    return this.dealForm.get('public_tender');
  }
  get budget() {
    return this.dealForm.get('budget');
  }
  get authority() {
    return this.dealForm.get('authority');
  }
  get need() {
    return this.dealForm.get('need');
  }
  get timeline() {
    return this.dealForm.get('timeline');
  }
  get google_rep() {
    return this.dealForm.get('google_rep');
  }
  get description() {
    return this.dealForm.get('description');
  }
  get dr_nbr() {
    return this.dealForm.get('dr_nbr');
  }
  get opportunity_creation_date() {
    return this.dealForm.get('opportunity_creation_date');
  }
  get opportunity_owner() {
    return this.dealForm.get('opportunity_owner');
  }
  get opportunity_stage() {
    return this.dealForm.get('opportunity_stage');
  }
  get opportunity_nbr() {
    return this.dealForm.get('opportunity_nbr');
  }
  get opportunity_link() {
    return this.dealForm.get('opportunity_link');
  }
  get dv_requested() {
    return this.dealForm.get('dv_requested');
  }
  get campaign() {
    return this.dealForm.get('campaign');
  }
  get project_number_validated() {
    return this.dealForm.get('project_number_validated');
  }
  get project_numbers() {
    return this.dealForm.get('project_numbers');
  }
  get billing_id() {
    return this.dealForm.get('billing_id');
  }
  get rebate_expiry_date() {
    return this.dealForm.get('rebate_expiry_date');
  }
  get campaign_type() {
    return this.dealForm.get('campaign_type');
  }
  get sku(): FormArray {
    return this.dealForm.get('sku') as FormArray;
  }
  get attachedFilePsf(): FormArray {
    return this.dealForm.get('attachedFilePsf') as FormArray;
  }
  get attachedFileDaf(): FormArray {
    return this.dealForm.get('attachedFileDaf') as FormArray;
  }
}
