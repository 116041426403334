import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GCPProjectModel } from 'src/models/mongo/gcp_project.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { LinkProjectPopupComponent } from '../link-project-popup/link-project-popup.component';

@Component({
  selector: 'new-project-modal',
  templateUrl: './new-project-modal.component.html',
  styleUrls: ['./new-project-modal.component.scss'],
})
export class NewProjectModalComponent implements OnInit {
  @ViewChild('createProjectModal') createProjectModal: ModalDirective;
  newProjectModel: GCPProjectModel = new GCPProjectModel();
  @ViewChild('projectCreatedModal') projectCreatedModal: ModalDirective;
  mainBilling = [
    {
      name: 'Billing iSolutions',
      id: '0168AA-FB2069-41730B ',
    },
  ];
  billingAccountList = [];
  //
  folders = [];
  @Output() createProjectEmitter = new EventEmitter<GCPProjectModel>();
  @Input() pocID: string;
  @Input() oppID: string;
  @Input() startDate: Date;
  @ViewChild('linkProjectModal')
  linkProjectModal: LinkProjectPopupComponent;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    this.newProjectModel.organizationName = Endpoint.ORGANISATION_NAME;
  }

  async ngOnInit(): Promise<void> {
    await Utilities.listOfFolders(this.http, true, this.localStorage).then(
      (folders: any) => {
        if (
          folders &&
          folders.length > 0 &&
          !Utilities.isNullOrEmpty(folders[0].displayName)
        ) {
          folders.forEach((element) => {
            element.expand = false;
            element.checked = false;
            element.childs = [];
          });
          this.folders = folders;
        }
      }
    );
  }

  show() {
    this.createProjectModal.show();
  }
  hide() {
    this.createProjectModal.hide();
  }

  async projectCreatedStep() {
    if (
      Utilities.isNullOrEmpty(this.newProjectModel.name) ||
      Utilities.isNullOrEmpty(this.newProjectModel.project_id)
    ) {
      alert('Add Project Name and ID ' + this.pocID);
      return;
    }
    var project = new GCPProjectModel();
    project.name = this.newProjectModel.name;
    project.id = this.newProjectModel.project_id;
    project.parent = this.newProjectModel.parent;
    project.start_date = this.startDate;
    if (!Utilities.isNullOrEmpty(this.oppID))
      project.opportunity_rfid = this.oppID;
    if (!Utilities.isNullOrEmpty(this.pocID)) project.poc_rfid = this.pocID;
    var endpoint = !Utilities.isNullOrEmpty(this.pocID)
      ? Endpoint.CREATE_POC_PROJECT
      : Endpoint.CREATE_PROJECT;
    await this.http
      .post(Endpoint.GLOBAL_URL + endpoint, project)
      .toPromise()
      .then(async (resp: any) => {
        if (
          !Utilities.isNullOrEmpty(resp.project_rfid) &&
          !Utilities.isNullOrEmpty(resp.number)
        ) {
          this.newProjectModel.number = resp.number;
          this.newProjectModel._id = this.newProjectModel.id =
            resp.project_rfid;
          this.newProjectModel.name = this.newProjectModel.name;
          this.newProjectModel.created_date_string = resp.created_date_string;
          this.newProjectModel.created_user_name = resp.created_user_name;
          this.newProjectModel.consumed_time = resp.consumed_time;
          if (
            this.createProjectEmitter &&
            this.createProjectEmitter.observers.length > 0
          )
            this.createProjectEmitter.emit(this.newProjectModel);
          else {
            this.createProjectModal.hide();
            this.projectCreatedModal.show();
          }
        }
      });
  }

  async setBillingAccountStep() {
    this.projectCreatedModal.hide();
    this.newProjectModel.billingAccountId = null;
    this.linkProjectModal.show();
  }

  linkingDone(data) {}

  async expand(item) {
    // DISPLAYNAME AND NAME=ID
    item.expand = !item.expand;
    if (item.name && (!item.childs || item.childs.length == 0)) {
      this.foldersCheck(this.folders, item.name);
      var id = item.name.split('/')[1];
      await Utilities.listOfFolders(
        this.http,
        false,
        this.localStorage,
        id
      ).then((folders: any) => {
        if (folders) {
          folders.forEach((element) => {
            element.expand = false;
            element.checked = false;
            element.childs = [];
          });
          item.childs = folders;
          this.replaceNodeById(item.id, this.folders, item);
        }
      });
    }
  }

  async clickElt(e, item) {
    // DISPLAYNAME AND NAME=ID
    var checked = e.target.checked;
    if (checked) {
      this.newProjectModel.folderName = item.displayName;
      this.newProjectModel.parent = item.name;
      this.foldersCheck(this.folders, item.name);
    } else {
      this.newProjectModel.folderName = null;
      this.newProjectModel.parent = null;
    }
  }

  replaceNodeById(id, array, updated) {
    if (array)
      for (var i = 0; i < array.length; i++) {
        var element = array[i];
        if (element.name == id) {
          element = updated;
          break;
        } else if (element.childs) {
          for (var j = 0; j < element.childs.length; j++) {
            var child = element.childs[j];
            if (child.name == id) {
              child = updated;
              break;
            } else this.replaceNodeById(child.name, child.childs, updated);
          }
        }
      }
  }

  foldersCheck(array, id) {
    if (array)
      for (var i = 0; i < array.length; i++) {
        var element = array[i];
        if (element.name !== id) {
          element.checked = false;
        }
        for (var j = 0; j < element.childs.length; j++) {
          var child = element.childs[j];
          if (child.name !== id) {
            child.checked = false;
            this.foldersCheck(child.childs, id);
          }
        }
      }
  }
}
