import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NgForm,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpClient } from '@angular/common/http';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { Encryption } from 'src/servcies/Encryption';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { CustomersService } from 'src/servcies/backend-services/customers.service';
import { BillingAccountCreatedPopupComponent } from 'src/shared-components/billing-account-created-popup/billing-account-created-popup.component';
import { GwsGcpPopupComponent } from 'src/shared-components/gws-gcp-popup/gws-gcp-popup.component';
import { DomainModel } from '../../../../../src/models/mongo/domain.model';

@Component({
  selector: 'lib-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss'],
})
export class CustomerCreateComponent implements OnInit {
  @HostBinding('class') class = '';
  customerForm: UntypedFormGroup;
  currentUser: UserModel;
  countries = [];
  users = [];
  customerSector = [];
  industries = [];
  add: boolean = true;
  formGroup: any;
  active_tab: number = 0;
  channelName;
  encryption;
  canCreateOrder: boolean = false;
  canSave: boolean = false;
  loginUsersData;
  orderId;
  isCreationOrTransfer: boolean = true; // CREATION OR TRANSFER : DEFAULT = CREATE
  transferModel = { transferToken: '', domain: '', users: [] };
  domainExists: boolean = false;
  disableSave: boolean = false;
  domainValue: string;
  maindomain: string = '';
  disabledinput: boolean = true;
  formenabled: boolean = false;
  domains = [];
  domainModels = [];
  FormsSubmitted: boolean = false;
  domainEditing: boolean = false;
  newsubdomain: string = '';
  domainToEdit: number;
  domainEditingText: string = 'Add';
  @ViewChild('nextStepModal') nextStepModal: ModalDirective;
  @ViewChild('creatBillingModal') creatBillingModal: ModalDirective;
  @ViewChild('billingAccountCreated')
  billingAccountCreatedModal: BillingAccountCreatedPopupComponent;
  @ViewChild('gwsGcpOppModal')
  gwsGcpOppModal: GwsGcpPopupComponent;
  @ViewChild('AddSubDomainModal') AddSubDomainModal: ModalDirective;
  @ViewChild('AddDomainModal') AddDomainModal: ModalDirective;
  @ViewChild('orderDealModal') orderDealModal: ModalDirective;

  @ViewChildren(NgForm) formDirs: QueryList<NgForm>;

  nextStepAfterCreatingCustomer = {
    goToDashboard: false,
    goToCustomerList: false,
    createOpporunity: false,
  };
  isGWSSelected: boolean = true;
  initial_userIds = [];
  billingAccountCase: boolean = false;
  createdBillingData: any = {};
  title: string = 'Create Customer';
  domainforms: UntypedFormGroup[] = [];
  isToggled: boolean;
  currentCustomer: CustomerModel = new CustomerModel();
  initialCustomer: CustomerModel = new CustomerModel();
  customerExistenceMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    private fb: UntypedFormBuilder,
    private customersService: CustomersService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) {
    this.customerForm = this.fb.group({
      domain: ['', Validators.required],
      auth_token: [''],
      organisation_name: ['', Validators.required],
      country: [null, Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      alternate_email: [''],
      postal_code: [''],
      employees_nbr: [''],
      customer_sector: [null],
      industry: [null],
      contact_person_first_name: [''],
      contact_person_last_name: [''],
      contact_person_email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      user_ids: [[]],
      type: [''],
      // is_inherited:[''],
    });
    this.customerForm.get('type').setValue('main');

    this.encryption = new Encryption();
    const state = history.state;
    if (state?.customerRfid) {
      this.add = false;
      this.currentCustomer._id = state.customerRfid;
      this.title = 'Edit Customer';
    }
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    //
    this.route.queryParams.subscribe((params) => {
      this.billingAccountCase =
        params['billingAccountCase'] &&
        Utilities.isTrue(params['billingAccountCase']);
      if (this.billingAccountCase) {
        this.isCreationOrTransfer = true;
      } else if (
        params['isCreationOrTransfer'] &&
        Utilities.isTrue(params['isCreationOrTransfer'])
      ) {
        //CREATE NEW
        this.isCreationOrTransfer = true;
      } else {
        //TRANSFER
        this.isCreationOrTransfer = false;
        this.customerForm.controls['auth_token'].setValidators(
          Validators.required
        );
        this.customerForm.controls['auth_token'].updateValueAndValidity();
      }
      this.customerForm.updateValueAndValidity();
    });
  }

  async ngOnInit(): Promise<void> {
    var that = this;
    var globalData = await Utilities.globalData(this.http, this.localStorage);
    var usersInfojson = this.localStorage.get(Unicode.USERS_INFO);
    this.loginUsersData = usersInfojson ? JSON.parse(usersInfojson) : {};

    // Retrieve the customer ID from the router's state
    // const state = this.router.getCurrentNavigation()?.extras?.state;
    this.users = await Utilities.usersByGroup(
      Group.Sale,
      this.http,
      this.localStorage
    );
    if (!this.add) {
      this.disableSave = false;
      // Make a request to fetch the customer by ID
      var customer: any = await this.http
        .get(
          Endpoint.GLOBAL_URL +
            Endpoint.GET_CUSTOMER_BY_ID +
            `/${this.currentCustomer._id}`
        )
        .toPromise();
      if (customer) {
        if (!Utilities.isNullOrEmpty(customer.api_id))
          this.isCreationOrTransfer = true;
        this.currentCustomer = JSON.parse(JSON.stringify(customer));
        this.initialCustomer = JSON.parse(JSON.stringify(customer));
        // customer.country = customer.country?.iso2;
        // customer.user_ids = customer.user_ids.map((x) => x._id);
        // this.initial_userIds = customer.user_ids;
        // this.customerForm.patchValue(customer);
        // this.customerForm.updateValueAndValidity();
        //
        this.formenabled = true;
        this.active_tab = 0;
        this.maindomain = this.currentCustomer.domains[0].domain;

        this.currentCustomer.domains.forEach((domain: DomainModel) => {
          this.domains.push(domain.domain);
          var clonedGroup = this.fb.group({
            domain: [domain.domain, Validators.required],
            auth_token: [domain.auth_token],
            organisation_name: [domain.organisation_name, Validators.required],
            alternate_email: [domain.alternate_email],
            country: [domain?.country?.iso2, Validators.required],
            state: [domain.state, Validators.required],
            city: [domain.city, Validators.required],
            street: [domain.street, Validators.required],
            postal_code: [domain.postal_code],
            employees_nbr: [domain.employees_nbr],
            customer_sector: [domain.customer_sector],
            industry: [domain.industry],
            contact_person_first_name: [domain.contact_person_first_name],
            contact_person_last_name: [domain.contact_person_last_name],
            contact_person_email: [
              domain.contact_person_email,
              [Validators.email, Validators.required],
            ],
            phone: [domain.phone],
            user_ids: [domain.user_ids ?? []],
            is_inherited: [domain.is_inherited],
            type: [domain.type],
          });
          this.domainforms.push(clonedGroup);
          Object.keys(this.customerForm.controls).forEach((controlName) => {
            const control = this.domainforms[0].get(controlName);
            clonedGroup.get(controlName).setValidators(control.validator);
          });
        });
      }
      this.currentCustomer.isExecuted =
        !Utilities.isNullOrEmpty(this.currentCustomer.api_id) ||
        !Utilities.isNullOrEmpty(
          this.currentCustomer.domains.find(
            (x) =>
              !Utilities.isNullOrEmpty(x.channel_name) ||
              !Utilities.isNullOrEmpty(x.channel_name_gcp)
          )
        );
    } else {
      this.currentCustomer.isExecuted = false;
      this.domainforms.push(this.customerForm);
    }
    this.domainforms[0].valueChanges.subscribe((values) => {
      for (let index = 1; index < this.domainforms.length; index++) {
        if (this.domainforms[index].get('is_inherited').value == 'inherited') {
          this.domainforms[index].patchValue(values);
          this.domainforms[index].get('is_inherited').setValue('inherited');
        }
      }
    });
    //
    if (this.add) {
      this.canSave = true;
      this.canCreateOrder = true;
    } else if (
      this.currentUser.isExecutor ||
      Utilities.isNullOrEmpty(this.currentCustomer.api_id)
    )
      this.canSave = true;
    var gData = Utilities.getGlobalDataValues(globalData);
    this.countries = gData.countries;
    this.customerSector = gData.customerSector;
    this.industries = gData.industries;
  }

  async firstStepSave() {
    var that = this;
    this.FormsSubmitted = true;
    for (let formindex = 0; formindex < this.domainforms.length; formindex++) {
      if (
        !this.domainforms[formindex].valid ||
        !this.validateContactEmail(formindex)
      ) {
        return;
      }
      const model: DomainModel = this.domainforms[formindex].value;
      if (model.country) {
        model.country = this.countries.find((x) => x.iso2 == model.country);
      }
      if (formindex != 0) {
        model.type = 'sub';
        model.domain = this.domains[formindex];
        if (!this.add) {
          model.customer_rfid = this.currentCustomer._id;
          model.parent_domain_rfid = this.currentCustomer.domains[0]._id;
        }
      }
      model.address = model.state + ',' + model.street;

      if (
        !this.currentUser.canViewAll &&
        model.user_ids?.length == 0 &&
        this.currentUser.isSales
      )
        model.user_ids.push(this.currentUser._id);
      model._id = this.currentCustomer.domains.find(
        (x) => x.domain == model.domain
      )?._id;
      this.domainModels.push(model);
    }
    if (this.billingAccountCase) {
      this.currentCustomer.domains = this.domainModels.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.domain === obj.domain)
      );
      var billingDomain = this.domainModels[0];
      this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_CUSTOMER_BILLING_ACCOUNT, {
          domain: billingDomain,
        })
        .toPromise()
        .then(async (resp: any) => {
          if (!Utilities.isNullOrEmpty(resp.customer_rfid)) {
            billingDomain.channel_name_gcp = resp.channel_name_gcp;
            //  billingDomain._id = resp.id;
            this.currentCustomer._id = resp.customer_rfid;
            this.currentCustomer.mainDomaiRfid = resp.main_domain_rfid;
            var mainDomain = this.currentCustomer.domains.find(
              (x) => 'main' === x.type
            );
            mainDomain._id = this.currentCustomer.mainDomaiRfid;
            this.createdBillingData = {
              domain: billingDomain.domain,
              organization: billingDomain.organisation_name,
              channelName: billingDomain.channel_name_gcp,
            };
            //GET OFFERS / GCP SKUS-PLANS
            this.creatBillingModal.show();
            this.creatBillingModal.onHide.subscribe((res) => {
              this.billingAccountCreatedModal.show();
            });
          }
        })
        .catch((e) => {
          this.setDomainsCountry();
          throw e;
        });
    } else if (this.isCreationOrTransfer) {
      if (this.add) this.nextStepModal.show();
      else this.saveCustomer(null);
    } else {
      await this.saveCustomer(null);
      this.transferCustomer();
    }
  }

  onUpdatedBillingAccountData(eventData) {
    this.createdBillingData = eventData;
    this.creatBillingModal?.hide();
  }

  async secondStepSave(
    goToDashboard: boolean,
    goToCustomerList: boolean,
    createOpporunity: boolean
  ) {
    this.nextStepAfterCreatingCustomer.goToCustomerList = goToCustomerList;
    this.nextStepAfterCreatingCustomer.goToDashboard = goToDashboard;
    this.nextStepAfterCreatingCustomer.createOpporunity = createOpporunity;
    this.nextStepModal.hide();
    // if (goToCustomerList || goToDashboard) {
    //   this.saveCustomer(null);
    // } else if (createOpporunity) {
    //   this.gwsGcpOppModal.showPopup();
    // }
    if (goToCustomerList) {
      await this.saveCustomer(null);
      this.router.navigate(['/customers']);
    } else if (goToDashboard) {
      await this.saveCustomer(null);
      this.router.navigate(['/dashboard']);
    } else if (createOpporunity) {
      this.gwsGcpOppModal.showPopup();
      // this.localStorage.save(
      //   Unicode.CURRENT_CUSTOMER,
      //   JSON.stringify(model)
      // );
      // this.router.navigate(['/opportunities/opportunity-details'], {
      //   state: {
      //     isGWS: isGws,
      //     customer_id: this.customerId,
      //     customer_rfid: this.customerRfId,
      //     domain: model.domain,
      //     organization_name: model.organisation_name,
      //   },
      // });
    }
  }

  async goToOppunityPage(isGWS: boolean) {
    var that = this;
    if (isGWS) this.setGWSCustomerValidation();
    var saved = await this.saveCustomer(null);
    if (saved) {
      this.localStorage.save(
        Unicode.CURRENT_CUSTOMER,
        JSON.stringify(
          this.currentCustomer.domains.find((x) => x.type == 'main')
        )
      );
      if (isGWS) {
        this.orderDealModal.show();
      } else {
        this.router.navigate(['/opportunities/opportunity-details'], {
          state: {
            isGWS: isGWS,
          },
        });
      }
    }
  }

  GWSTakeAction(order: boolean) {
    this.orderDealModal.hide();
    //GWS ORDER OR DEAL
    if (order) {
      this.router.navigate(['orders/add-order'], {
        queryParams: { isGWS: true },
      });
    } else {
      this.router.navigate(['deals/add-deal'], {
        queryParams: { isGWS: true },
      });
    }
  }

  private setGWSCustomerValidation() {
    this.customerForm.controls['employees_nbr'].setValidators(
      Validators.required
    );
    this.customerForm.controls['customer_sector'].setValidators(
      Validators.required
    );
    this.customerForm.controls['industry'].setValidators(Validators.required);
    this.customerForm.controls['alternate_email'].setValidators(
      Validators.required
    );
    this.customerForm.controls['contact_person_first_name'].setValidators(
      Validators.required
    );
    this.customerForm.controls['contact_person_last_name'].setValidators(
      Validators.required
    );
    this.customerForm.controls['contact_person_email'].setValidators(
      Validators.required
    );
    this.customerForm.controls['phone'].setValidators(Validators.required);
    this.employees_nbr.updateValueAndValidity();
    this.customer_sector.updateValueAndValidity();
    this.industry.updateValueAndValidity();
    this.alternate_email.updateValueAndValidity();
    this.contact_person_first_name.updateValueAndValidity();
    this.contact_person_last_name.updateValueAndValidity();
    this.contact_person_email.updateValueAndValidity();
    this.phone.updateValueAndValidity();
    // this.cdr.detectChanges();
  }

  async saveCustomer(isGws: boolean) {
    var that = this;
    for (let formindex = 0; formindex < this.domainforms.length; formindex++) {
      if (
        !this.domainforms[formindex].valid ||
        !this.validateContactEmail(formindex)
      ) {
        return false;
      }
    }
    const model = new CustomerModel();
    model.domains = this.domainModels.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.domain === obj.domain)
    );
    model._id = this.currentCustomer._id;
    if (!this.add && Utilities.isNullOrEmpty(model.api_id)) {
      this.customersService.updateCustomer(model, false).subscribe(
        (response: any) => {
          if (response?.success == true) {
            console.log('Customer updated successfully:', response);
            this.router.navigate(['/customers']);
          }
        },
        (error) => {
          this.setDomainsCountry();
          throw error;
        }
      );
    } else {
      if (this.currentUser.isExecutor) {
        /**API **/
        // model.id = apiSuccessId.data;
        if (this.add) {
          var receivers =
            Utilities.setReceivers(this.currentUser, this.loginUsersData) ?? [];
          await this.customersService
            .createCustomer(model, this.currentUser.isExecutor)
            .subscribe(
              (response: any) => {
                if (
                  response?.success == true &&
                  !Utilities.isNullOrEmpty(response?.customer_rfid)
                ) {
                  this.currentCustomer.domains = this.domainModels.filter(
                    (obj, index, self) =>
                      index === self.findIndex((t) => t.domain === obj.domain)
                  );
                  this.currentCustomer._id = response.customer_rfid;
                  this.currentCustomer.local_id = response.customer_local_id;
                  this.currentCustomer.mainDomaiRfid =
                    response.main_domain_rfid;
                  var mainDomain = this.currentCustomer.domains.find(
                    (x) => 'main' === x.type
                  );
                  mainDomain._id = this.currentCustomer.mainDomaiRfid;
                  return true;
                } else {
                  this.setDomainsCountry();
                  alert(response.message ?? 'Internal Server Error');
                }
              },
              (error) => {
                this.setDomainsCountry();
                throw error;
              }
            );
        } else {
          // delete model.auth_token;
          // const customer_model=new CustomerModel();
          // customer_model.domains.push(model);
          this.customersService
            .updateCustomer(model, this.currentUser.isExecutor)
            .subscribe(
              (response: any) => {
                if (response?.success == true) {
                  console.log('Customer updated successfully:', response);
                  this.router.navigate(['/customers']);
                }
              },
              (error) => {
                this.setDomainsCountry();
                throw error;
              }
            );
        }
      }
      //SALES TEAM
      else {
        if (this.add) {
          //SAVE CUSTOMER IN DB
          // model.country = this.countries.find((x) => x.iso2 == model.country);
          var response: any = await this.customersService
            .createCustomer(model, this.currentUser.isExecutor)
            .toPromise();
          if (response) {
            if (
              response?.success == true &&
              !Utilities.isNullOrEmpty(response?.customer_rfid)
            ) {
              this.currentCustomer.domains = this.domainModels.filter(
                (obj, index, self) =>
                  index === self.findIndex((t) => t.domain === obj.domain)
              );
              this.currentCustomer._id = response.customer_rfid;
              this.currentCustomer.local_id = response.customer_local_id;
              this.currentCustomer.mainDomaiRfid = response.main_domain_rfid;
              var mainDomain = this.currentCustomer.domains.find(
                (x) => 'main' === x.type
              );
              mainDomain._id = this.currentCustomer.mainDomaiRfid;
              return true;
            } else {
              this.setDomainsCountry();
              alert(response.message ?? 'Internal Server Error');
            }
          }
        }
      }
    }
  }

  private setDomainsCountry() {
    for (let formindex = 0; formindex < this.domainforms.length; formindex++) {
      const model: DomainModel = this.domainforms[formindex].value;
      if (model.country) {
        model.country = model.country?.iso2;
      }
    }
  }

  transferCustomer() {
    const model: DomainModel = this.customerForm.value;
    if (!model.user_ids || model.user_ids.length == 0)
      model.user_ids = [this.currentUser._id];
    if (
      !this.currentUser.canViewAll &&
      Utilities.isNullOrEmpty(model.user_ids) &&
      this.currentUser.isSales
    )
      model.user_ids = [this.currentUser._id];
    model.country = this.countries.find((x) => x.iso2 == model.country);
    this.router.navigate(['customers/transferable-subscriptions'], {
      queryParams: {
        customer: JSON.stringify(model),
      },
      skipLocationChange: true,
    });
  }

  async assignSales() {
    var that = this;
    var currentDomain = this.currentCustomer.domains[this.active_tab];
    var initialDomain = this.initialCustomer.domains[this.active_tab];
    var currentForm = this.domainforms[this.active_tab];
    if (!initialDomain.user_ids) initialDomain.user_ids = [];
    if (
      currentDomain &&
      !this.add &&
      !Utilities.isNullOrEmpty(currentForm.value.user_ids)
    ) {
      var add_ids = currentForm.value.user_ids.filter(
        (x) => initialDomain.user_ids.indexOf(x) < 0
      );
      var delete_ids = initialDomain.user_ids.filter(
        (x) => currentForm.value.user_ids.indexOf(x) < 0
      );
      await this.http
        .put(Endpoint.GLOBAL_URL + '' + Endpoint.ASSIGN_USER_CUSTOMER, {
          user_ids_attach: add_ids ?? [],
          user_ids_detach: delete_ids ?? [],
          domain_rfid: currentDomain._id,
        })
        .toPromise()
        .then((resp: any) => {
          initialDomain.user_ids = currentForm.value.user_ids;
          alert(resp?.message);
        });
    }
  }

  cancel() {
    this.location.back();
  }
  async onBlur(event: Event) {
    if (this.add) {
      const inputElement = event.target as HTMLInputElement;
      this.domainValue = inputElement.value;
      if (!Utilities.isNullOrEmpty(this.domainValue)) {
        try {
          const alreadyExistsInPartner: any =
            await Utilities.checkIfCustomerAlreadyExists(
              this.http,
              this.httpRequestService,
              this.domainValue.trim()
            );
          console.log(alreadyExistsInPartner);
          if (alreadyExistsInPartner == true) {
            this.domainExists = true;
            this.disableSave = true;
          } else {
            this.domainExists = false;
            this.disableSave = false;
          }
        } catch (error) {
          console.error(error);
          console.log('An error occurred during the customer check');
        }
      }
    }
  }

  validateContactEmail(index) {
    // var email = this.domainforms[index].get('contact_person_email').value;
    // if (!Utilities.isNullOrEmpty(email)) {
    //   let domain = email.split('@');
    //   domain = domain[1];
    //   if (domain == this.domainforms[index].get('domain').value) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // } else return true;
    return true;
  }
  validateAlternateEmail(index) {
    // var email = this.domainforms[index].get('alternate_email').value;
    // if (!Utilities.isNullOrEmpty(email)) {
    //   let domain = email.split('@');
    //   domain = domain[1];
    //   if (domain == this.domainforms[index].get('domain').value) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // } else return true;
    return true;
  }

  phoneValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const phoneRegex = /^[0-9]{7,15}$/; // 7-15 digit phone numbers
    if (control.value && !phoneRegex.test(control.value)) {
      return { invalidPhone: true };
    }
    return null;
  }

  emptySubdomain(): boolean {
    return this.newsubdomain === '';
  }

  mainDomainPopupShow() {
    this.AddDomainModal.show();
  }
  subDomainPopupShow(index = null) {
    this.FormsSubmitted = true;
    for (let formindex = 0; formindex < this.domainforms.length; formindex++) {
      if (
        !this.domainforms[formindex].valid ||
        !this.validateContactEmail(formindex)
      ) {
        return;
      }
    }
    if (this.domainEditing) {
      this.newsubdomain = this.domains[index];
    }
    this.AddSubDomainModal.show();
  }
  async enableform() {
    if (this.domainEditing) {
      if (!this.add) {
        this.currentCustomer.domains[0].domain = this.maindomain;
      }
      console.log('main domain is ' + this.maindomain);
      this.domains[0] = this.maindomain;
      this.domainforms[0].get('domain').setValue(this.maindomain);

      this.AddDomainModal.hide();
      this.domainEditing = false;
      return;
    }
    if (this.add) {
      await this.customersService
        .checkCustomerExistenceDbAPI(this.maindomain)
        .toPromise()
        .then((resp) => {
          console.log(resp);
          if (resp) {
            if (resp['exists_api_our_account'] === true) {
              this.customerExistenceMessage =
                'Your domain name already exists under our GWS account,if you dont see it please ask your manager to assign it.';
              this.domainExists = true;
              this.disableSave = true;
            } else if (resp['exists_api_google'] === true) {
              if (this.isCreationOrTransfer) {
                this.customerExistenceMessage =
                  'Your domain name already exists as a GWS Google Customer, please go and create a transfer customer instead if it is a GWS order else click cancel and continue.';
                this.domainExists = true;
                this.disableSave = true;
              } else {
                this.domainExists = false;
                this.disableSave = false;
                this.formenabled = true;
                this.domains.push(this.maindomain);
                this.domainforms[0].get('domain').setValue(this.domains[0]);
                this.AddDomainModal.hide();
              }
            } else if (resp['exists_db'] === true) {
              this.customerExistenceMessage =
                'Your domain name already exists in our DB,if you dont see it please ask your manager to assign it.';
              this.domainExists = true;
              this.disableSave = true;
            } else {
              this.domainExists = false;
              this.disableSave = false;
              this.formenabled = true;
              this.domains.push(this.maindomain);
              this.domainforms[0].get('domain').setValue(this.domains[0]);
              this.AddDomainModal.hide();
            }
          }
        });
      // const alreadyExistsInPartner: any =
      //   await Utilities.checkIfCustomerAlreadyExists(
      //     this.http,
      //     this.httpRequestService,
      //     this.maindomain
      //   );
      // if (alreadyExistsInPartner == true) {
      //   this.domainExists = true;
      //   this.disableSave = true;
      // } else {
      //   this.domainExists = false;
      //   this.disableSave = false;
      //   this.formenabled = true;
      //   this.domains.push(this.maindomain);
      //   this.domainforms[0].get('domain').setValue(this.domains[0]);
      //   this.AddDomainModal.hide();
      // }
    }
  }

  HideSubdomainModal() {
    this.newsubdomain = '';
    this.domainEditing = false;
    this.AddSubDomainModal.hide();
  }
  Maindomainempty() {
    if (this.maindomain == '') {
      return true;
    }
    return false;
  }

  NewForm(data_type): void {
    if (this.domainEditing) {
      if (!this.add) {
        if (this.currentCustomer.domains[this.domainToEdit]?.domain != null) {
          this.currentCustomer.domains[this.domainToEdit].domain =
            this.newsubdomain;
        }
      }

      this.domains[this.domainToEdit] = this.newsubdomain;
      this.domainEditing = false;
      this.newsubdomain = '';
      this.AddSubDomainModal.hide();
      return;
    }
    let clonedGroup: UntypedFormGroup;
    this.domains.push(this.newsubdomain);
    clonedGroup = this.fb.group({
      domain: ['', Validators.required],
      auth_token: [''],
      organisation_name: ['', Validators.required],
      alternate_email: [''],
      country: [null, Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      street: ['', Validators.required],
      postal_code: [''],
      employees_nbr: [''],
      customer_sector: [null],
      industry: [null],
      contact_person_first_name: [''],
      contact_person_last_name: [''],
      contact_person_email: ['', [Validators.email]],
      phone: ['', [Validators.required]],
      user_ids: [[]],
      is_inherited: [''],
      type: [''],
      customer_rfid: [''],
      parent_domain_rfid: [''],
    });
    if (data_type == 'inherited') {
      if (!this.add) {
        console.log(this.domainforms[0]);
        clonedGroup.patchValue(this.domainforms[0].value);
      } else {
        clonedGroup.patchValue(this.customerForm.value);
      }

      clonedGroup.get('is_inherited').setValue('inherited');
      clonedGroup['iscloned'] = true;
      Object.keys(this.domainforms[0].controls).forEach((controlName) => {
        const control = this.domainforms[0].get(controlName);
        clonedGroup.get(controlName).setValidators(control.validator);
      });
    } else {
      Object.keys(this.domainforms[0].controls).forEach((controlName) => {
        const control = this.domainforms[0].get(controlName);
        clonedGroup.get(controlName).setValidators(control.validator);
      });
      // Object.keys(clonedGroup.controls).forEach((controlName) => {
      //   const control = clonedGroup.get(controlName);
      //   console.log(control.validator);
      // });
    }
    clonedGroup.get('domain').setValue(this.newsubdomain);

    this.domainforms.push(clonedGroup);
    this.active_tab = this.domainforms.length - 1;

    this.newsubdomain = '';
    this.AddSubDomainModal.hide();
  }
  ToggleSwitch(event: any, index): void {
    this.isToggled = event.checked;
    if (this.isToggled) {
      this.domainforms[index].patchValue(this.domainforms[0].value);
      this.domainforms[index].get('is_inherited').setValue('inherited');
    } else {
      this.domainforms[index].patchValue(this.domainforms[0].value);
      this.domainforms[index].get('is_inherited').setValue('');

      // this.domainforms[index].reset();
    }
  }
  SwitchForm(tab_number) {
    this.active_tab = tab_number;
    console.log(this.active_tab);
  }

  editDomain(index) {
    this.domainToEdit = index;
    this.domainEditing = true;

    if (index == 0) {
      this.domainEditingText = 'Update';

      this.mainDomainPopupShow();
      return;
    }

    this.subDomainPopupShow(index);
  }
  transferCust() {
    this.isCreationOrTransfer = false;

    this.customerForm.controls['auth_token'].setValidators(Validators.required);
    this.customerForm.controls['auth_token'].updateValueAndValidity();

    this.formenabled = true;
    this.domains.push(this.maindomain);
    this.domainforms[0].get('domain').setValue(this.domains[0]);
    this.AddDomainModal.hide();

    this.AddDomainModal.hide();
  }
  isValidDomain(): boolean {
    return this.domainExists;
  }
  closeVerificationDomainPopup() {
    this.domainExists = false;
    this.disableSave = false;
    this.formenabled = true;
    this.domains.push(this.maindomain);
    this.domainforms[0].get('domain').setValue(this.domains[0]);
    this.AddDomainModal.hide();
  }

  get domain() {
    return this.customerForm.get('domain');
  }

  get auth_token() {
    return this.customerForm.get('auth_token');
  }

  get password() {
    return this.customerForm.get('password');
  }

  get admin_first_name() {
    return this.customerForm.get('admin_first_name');
  }

  get admin_last_name() {
    return this.customerForm.get('admin_last_name');
  }
  get organisation_name() {
    return this.customerForm.get('organisation_name');
  }
  get alternate_email() {
    return this.customerForm.get('alternate_email');
  }
  get country() {
    return this.customerForm.get('country');
  }

  get state() {
    return this.customerForm.get('state');
  }

  get city() {
    return this.customerForm.get('city');
  }

  get street() {
    return this.customerForm.get('street');
  }
  get postal_code() {
    return this.customerForm.get('postal_code');
  }
  get employees_nbr() {
    return this.customerForm.get('employees_nbr');
  }
  get customer_sector() {
    return this.customerForm.get('customer_sector');
  }
  get industry() {
    return this.customerForm.get('industry');
  }
  get contact_person_first_name() {
    return this.customerForm.get('contact_person_first_name');
  }
  get contact_person_last_name() {
    return this.customerForm.get('contact_person_last_name');
  }
  get contact_person_email() {
    return this.customerForm.get('contact_person_email');
  }
  get phone() {
    return this.customerForm.get('phone');
  }
}
