import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode } from 'src/shared/Endpoint';
import { Location } from '@angular/common';
import { DomainModel } from 'src/models/mongo/domain.model';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-customer-services',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-services.component.html',
  styleUrls: ['./customer-services.component.scss'],
})
export class CustomerServicesComponent implements OnInit {
  @HostBinding('class') class = ' ';
  domain: DomainModel = new DomainModel();

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private router: Router,
    private location: Location
  ) {}

  async ngOnInit(): Promise<void> {
    var paramsJson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (paramsJson) {
      this.domain = JSON.parse(paramsJson);
      // const customerString = JSON.stringify(this.customer);
      // console.log(customerString);
    }
  }
  cancelOrder() {
    //  this.router.navigate(['/customers']);
    this.location.back();
  }
  editCustomer() {
    this.router.navigate(['customers/customer-create'], {
      state: { customerRfid: this.domain.customer_rfid },
      queryParams: {
        isCreationOrTransfer: Utilities.isNullOrEmpty(this.domain.auth_token),
      },
    });
  }
}
