<div class="col-12 row main_sections">
  <section id="main_section1" class="py-2 px-2 col-lg-6 col-md-12 col-12">
    <!--    <latest-section></latest-section>-->

    <div
      class="bg-white rounded-5 p-4 p-md-4 pending-orders-section box-shadow mt-2 mt-xxl-0 small-text"
    >
      <div class="d-flex justify-content-end">
        <img
          id="arrow_section1"
          (click)="section1()"
          src="./assets/img/double_arrow_left_icon.svg"
        />
      </div>
      <h1 class="h4 pt-3 pt-md-0">Pending Orders</h1>
      <div class="position-sticky top-0 bg-white z-9">
        <div class="d-flex border-bottom border-dark product-sans-medium">
          <div
            class="p-1 p-md-2 text-md-start ps-md-2 col-1 col-md-2 border-top-0"
          >
            Domain
          </div>
        </div>
      </div>
      <div class="d-flex flex-column" *ngFor="let order of pending_orders">
        <div
          class="d-flex flex-row justify-content-between border-bottom py-2 align-items-center"
        >
          <div
            class="d-flex flex-row align-items-center justify-content-xl-between ps-0 col-6 div_pending_order_domain"
          >
            <p class="mb-0 ms-2 col-11 col-xl-12 ps-0 text-nowrap">
              <span class="text-primary">{{ order.domain?.domain }} </span>
            </p>
          </div>
          <p
            class="mb-0 smaller-text"
            [ngClass]="{
            'd-none' : section1Closed,
            'dis-block' : !section1Closed,
           }"
          >
            <img
              class="px-1 px-md-2 mx-3 cursor-pointer"
              src="./assets/img/view-icon.svg"
              (click)="viewPendingOrder(false, order._id)"
              alt="view icon"
            />
            <img
              class="px-1 px-md-2 mx-3 cursor-pointer"
              (click)="viewPendingOrder(true, order._id)"
              src="./assets/img/edit-icon.svg"
              alt="edit icon"
            />
          </p>
        </div>
      </div>
    </div>
    <div
      class="bg-white rounded-5 p-md-4 latest-section box-shadow mt-2 small-text"
    >
      <h1 class="h4 pb-3 pt-3 pt-md-0">Latest</h1>
      <div class="d-flex flex-column" *ngFor="let log of logs">
        <div
          class="d-flex flex-row justify-content-between border-bottom py-2 align-items-center"
        >
          <div
            class="d-flex flex-row align-items-center col-6 justify-content-xl-between ps-0"
          >
            <img
              *ngIf="log.user_img_url; else img_default"
              src="{{ log.user_img_url }}"
              alt="user"
              class="rounded-5 log_user_img"
            />
            <ng-template #img_default>
              <img
                src="./assets/img/default_user_icon.svg"
                alt="fulan"
                class="rounded-5"
              />
            </ng-template>
            <p class="mb-0 ms-2 col-11 col-xl-12 ps-0 text-nowrap">
              <span
                class="text-primary cursor-pointer"
                (click)="ViewLogDetails('user', log.user_id)"
                >{{ log.user_fullname }} </span
              ><span>{{ log.action_type }}d</span>
              <br />
              <span
                class="text-primary cursor-pointer"
                (click)="ViewLogDetails(log.target_one_type, log.target_one_id)"
              >
                {{ log.target_one_type }}
              </span>
              <span
                class="text-primary cursor-pointer"
                (click)="ViewLogDetails(log.target_one_type, log.target_one_id)"
              >
                {{ log.target_one_name }}
              </span>
              <br />
              <span *ngIf="log.target_two_name"> from </span>
              <span
                *ngIf="log.target_two_name"
                class="text-primary cursor-pointer"
                (click)="ViewLogDetails(log.target_two_type, log.target_two_id)"
              >
                {{ log.target_two_type }} {{ log.target_two_name }}</span
              >
            </p>
          </div>
          <p
            class="mb-0 smaller-text"
            [ngClass]="{
            'd-none' : section1Closed,
            'dis-block' : !section1Closed,
           }"
          >
            {{ log.created_at }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-column flex-md-row justify-content-between gap-3 pe-4"
    >
      <div class="col-lg-4 col-md-4 col-12 px-0">
        <div
          class="mt-4 p-3 bg-white rounded-5 box-shadow tabs-data dash_odrs_section"
        >
          <div class="d-flex">
            <img src="./assets/img/orders-icon-section.svg" alt="orders icon" />
            <span
              class="ps-3"
              [ngClass]="{
              'd-none' : section1Closed,
              'dis-block' : !section1Closed,
             }"
            >
              <a
                class="cursor-pointer text-decoration-none"
                routerLink="/orders"
              >
                <h1 class="h5 mb-0" style="user-select: none">Orders</h1>
              </a>

              <p class="small-text mb-0">{{ total_orders }}</p>
            </span>
          </div>
          <div class="data_section">
            <p
              type="button"
              *ngFor="let order of orders"
              [ngbPopover]="
                order.customer_domain?.length > 20
                  ? order.customer_domain +
                    '  ' +
                    order.license_count +
                    (order.license_count === 1 ? 'license' : ' licenses')
                  : ''
              "
              popoverTitle=""
              triggers="mouseenter:mouseleave"
              placement="top"
              class="border-bottom text-primary pt-2 mb-2 might-overflow"
              (click)="viewOrder(true, order._id)"
            >
              {{ order.customer_domain }}
            </p>
          </div>
          <a
            [ngClass]="{
            'd-none' : section1Closed,
            'dis-block' : !section1Closed,
           }"
            routerLink="/orders"
            class="d-block text-decoration-none text-primary text-end mb-0"
            >View all</a
          >
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-12 px-0">
        <div
          class="mt-4 p-3 bg-white rounded-5 box-shadow tabs-data dash_custs_section"
        >
          <div class="d-flex">
            <img
              src="../../../assets/img/customers-icon-section.svg"
              alt="orders icon"
            />
            <span
              class="ps-3"
              [ngClass]="{
              'd-none' : section1Closed,
              'dis-block' : !section1Closed,
             }"
            >
              <a
                class="cursor-pointer text-decoration-none"
                routerLink="/customers"
              >
                <h1 class="h5 mb-0" style="user-select: none">Customers</h1>
              </a>
              <p class="small-text mb-0">{{ total_customers }}</p>
            </span>
          </div>
          <div class="data_section">
            <p
              type="button"
              *ngFor="let customer of customers"
              [ngbPopover]=" customer.domain.length>20 ? customer.domain: ''"
              popoverTitle=""
              triggers="mouseenter:mouseleave"
              placement="top"
              class="border-bottom text-primary pt-2 mb-2 might-overflow"
              (click)="viewCustomer(customer)"
            >
              {{ customer.domain }}
            </p>
          </div>
          <a
            [ngClass]="{
            'd-none' : section1Closed,
            'dis-block' : !section1Closed,
           }"
            routerLink="/customers"
            class="d-block text-decoration-none text-primary text-end mb-0"
            >View all</a
          >
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12 px-0">
        <div
          class="mt-4 p-3 px-2 bg-white rounded-5 box-shadow tabs-data dash_usrs_section"
        >
          <div class="d-flex">
            <img src="./assets/img/users-icon-section.svg" alt="orders icon" />
            <span
              class="ps-3"
              [ngClass]="{
              'd-none' : section1Closed,
              'dis-block' : !section1Closed,
             }"
            >
              <a
                class="cursor-pointer text-decoration-none"
                routerLink="/sales"
              >
                <h1 class="h5 mb-0" style="user-select: none">Users</h1>
              </a>
              <p class="small-text mb-0">{{ total_users }}</p>
            </span>
          </div>
          <div class="data_section">
            <p
              *ngFor="let user of users"
              class="border-bottom text-primary pt-2 mb-2 pb-2 d-flex"
            >
              <img
                *ngIf="user.image_url; else no_image_div"
                src="{{ user.image_url }}"
                class="rounded-circle usr_img"
                alt="user's icon"
              />
              <ng-template #no_image_div>
                <img
                  src="./assets/img/default_user_icon.svg"
                  class="rounded-circle"
                  alt="user's icon"
                />
              </ng-template>
              <span
                [ngClass]="{
              'd-none' : section1Closed,
              'dis-block' : !section1Closed,
             }"
                type="button"
                class="px-1 text-nowrap"
                (click)="ViewSale(user._id)"
                >{{ user.user_name }}</span
              >
            </p>
          </div>
          <a
            [ngClass]="{
            'd-none' : section1Closed,
            'dis-block' : !section1Closed,
           }"
            routerLink="/sales"
            class="d-block text-decoration-none text-primary text-end mb-0"
            >View all</a
          >
        </div>
      </div>

      <!--      <customers-section class="flex-fill mx-md-3 col-md-3"></customers-section>-->
      <!--      <users-section class="flex-fill ms-md-3 col-md-3"></users-section>-->
    </div>
  </section>
  <section id="main_section2" class="py-2 px-2 col-lg-6 col-md-12 col-12">
    <!--    <pie-chart></pie-chart>-->
    <div class="bg-white box-shadow rounded-5 p-4 mt-2 mt-xxl-0">
      <div class="d-flex">
        <img
          id="arrow_section2"
          (click)="section2()"
          src="./assets/img/double_arrow_right_icon.svg"
        />
      </div>
      <h1 class="h4 pt-3 pt-md-0">Order Status</h1>
      <div *ngIf="section1Opened" class="col-md-7 mx-auto small_charts">
        <canvas id="PieChart" width="150" height="150"></canvas>
      </div>
      <div class="col-md-7 mx-auto big_charts" *ngIf="!section1Opened">
        <canvas id="PieChart" width="600" height="400"></canvas>
      </div>
    </div>
    <div class="bg-white box-shadow rounded-5 p-4 mt-4">
      <h1 class="h5 mb-0">Orders by Dates</h1>

      <div
        class="row justify-content-md-between flex-wrap flex-md-nowrap align-items-center col-10 my-3 product-sans-light ps-0"
      >
        <div class="date-range-picker-container col-10">
          <mat-form-field class="d-flex">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                matInput
                placeholder="Start date"
                [(ngModel)]="startDate"
              />
              <input
                matEndDate
                matInput
                placeholder="End date"
                [(ngModel)]="endDate"
                (dateChange)="updateChartLabels()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <!--      <div class="form-floating col-7 me-2 col-md-4 px-0">-->
        <!--        <input-->
        <!--          type="date"-->
        <!--          class="form-control rounded-pill py-0"-->
        <!--          id="floatingDateFrom"-->
        <!--          [(ngModel)]="startDate"-->
        <!--          (ngModelChange)="updateChartLabels()"-->
        <!--          required-->
        <!--        />-->
        <!--        <label-->
        <!--          for="floatingDateFrom"-->
        <!--          class="customized-floating-label py-2 px-3"-->
        <!--          >Date (from)</label-->
        <!--        >-->
        <!--      </div>-->
        <!--      -->

        <!--      <div class="form-floating my-2 my-md-0 col-7 me-2 col-md-4 mx-md-2 px-0">-->
        <!--        <input-->
        <!--          type="date"-->
        <!--          class="form-control rounded-pill py-0"-->
        <!--          id="floatingDateTo"-->
        <!--          [(ngModel)]="endDate"-->
        <!--          (ngModelChange)="updateChartLabels()"-->
        <!--          required-->
        <!--        />-->
        <!--        <label for="floatingDateTo" class="customized-floating-label py-2 px-3"-->
        <!--          >Date (to)</label-->
        <!--        >-->
        <!--      </div>-->
        <div class="text-nowrap ms-4 col-2">
          <input
            type="checkbox"
            id="executed-orders"
            class="form-check-input"
            aria-label="executed orders checkbox"
            (click)="getExecutedOrders()"
            [(ngModel)]="onlyExecutedOrders"
          />
          <label for="executed-orders" class="text-secondary mx-1"
            >Only Executed
          </label>
        </div>
      </div>
      <div *ngIf="section1Opened" class="col-md-7 mx-auto small_charts">
        <canvas id="BarChart" width="150" height="150"></canvas>
      </div>
      <div class="col-md-7 mx-auto big_charts" *ngIf="!section1Opened">
        <canvas id="BarChart" width="600" height="350"></canvas>
      </div>
    </div>

    <!--    <bar-chart></bar-chart>-->

    <!--    <sku-table></sku-table>-->
    <div class="bg-white box-shadow rounded-5 p-4 mt-4">
      <h1 class="h5 mb-0">Customers by Country</h1>
      <div class="col-md-7 mx-auto w-100 h-100">
        <canvas id="countriesPie"></canvas>
      </div>
    </div>

    <!--    <countries-pie></countries-pie>-->

    <!--    <customers-table></customers-table>-->
  </section>
</div>
