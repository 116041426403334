import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  effect,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomainModel } from 'src/models/mongo/domain.model';
import { ProposalModel } from 'src/models/mongo/proposal.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { CustomerInfoPopupComponent } from 'src/shared-components/customer-info-popup/customer-info-popup.component';
import { Unicode, Group, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { OpportunityService } from '../../opportunity.service';
import { ProposalService } from 'projects/proposal/src/public-api';

@Component({
  selector: 'opporunity-proposal-phase',
  templateUrl: './opporunity-proposal-phase.component.html',
  styleUrls: ['./opporunity-proposal-phase.component.scss'],
})
export class OpporunityProposalPhaseComponent implements OnInit {
  @HostBinding('class') class = 'h-100';
  @Input() proposalPhaseData: ProposalModel;
  currentUser: UserModel;
  @Input() isGWS: boolean = true;
  @Input() opporunityID: string;
  @Input() skip: boolean;
  @Input() disabled: boolean;
  @Input() projectName: string;

  @Input() PocBillingBudget: any;


  isCreatedproposalDocCollapsed: boolean = true;
  isCustApprovedproposalCollapsed: boolean = true;
  isSendingCustproposalCollapsed: boolean = true;
  isManagerApprovedproposalCollapsed: boolean = true;
  isGcpPrjCreCollapsed: boolean = true;
  isGcpPrjLinkedCollapsed: boolean = true;
  currentDomain: DomainModel;
  loginUsersData: any;
  @ViewChild('googleproposalDoc') googleproposalDocModal: ModalDirective;
  add: boolean = false;
  approve: boolean = false;
  showSendToApprove: boolean = true;
  @ViewChild('proposalcustomerInfoPopup')
  proposalcustomerInfoPopup: CustomerInfoPopupComponent;
  googleDocData: any;
  @Output() skipEmitter = new EventEmitter<boolean>();
  oppService = inject(OpportunityService);
  customerInfoAdded = null;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private proposalService: ProposalService
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentDomain = JSON.parse(cstjson);
    if (this.currentDomain)
      this.currentDomain.contact_person_name = `${
        this.currentDomain.contact_person_first_name ?? ''
      } ${this.currentDomain.contact_person_last_name ?? ''}`;
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);

    effect(() => {
      if (!this.currentDomain) {
        this.currentDomain = this.oppService.opportunity().domain;
        localStorage.save(
          Unicode.CURRENT_CUSTOMER,
          JSON.stringify(this.currentDomain)
        );
      }
      this.customerInfoAdded =
        this.oppService.opportunity().customer_info_added;
    });
  }

  ngOnInit(): void {}

  createproposalGoogleDoc() {
    var startDate = new Date();
    var params = new HttpParams()
      .set(Unicode.CUSTOMER_NAME, this.currentDomain.organisation_name)
      .set(Unicode.PROJECT_NAME, this.projectName)
      .set(Unicode.IS_GCP, !this.isGWS);
    this.http
      .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_PROPOSAL_GOOGLE_DOC, null, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.doc_id) {
          this.proposalPhaseData = new ProposalModel();
          this.proposalPhaseData.google_doc_id = resp.doc_id;
          this.proposalPhaseData.googleDocUrl =
            'https://docs.google.com/document/d/' +
            this.proposalPhaseData.google_doc_id +
            '/edit?usp=sharing&embed=true';
          this.proposalPhaseData.googleDocUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.proposalPhaseData.googleDocUrl
            );
          this.add = true;
          this.googleDocData = {
            module: 'proposal',
            customer_domain: this.currentDomain.domain,
            customer_org: this.currentDomain.organisation_name,
            domain_rfid: this.currentDomain._id,
            googleDocUrl: this.proposalPhaseData.googleDocUrl,
            startDate: startDate,
          };
          this.googleproposalDocModal.show();
          this.googleproposalDocModal.onHide.subscribe((res) => {
            this.googleDocData = null;
          });
        } else {
          alert(resp.message);
        }
      });
  }

  createproposal(proposal: ProposalModel) {
    this.proposalPhaseData = proposal;
    this.googleproposalDocModal.hide();
  }

  approveproposal(proposal: ProposalModel) {
    this.proposalPhaseData = proposal;
    this.googleproposalDocModal.hide();
  }

  reviewproposal(approveRejectCase: boolean = false) {
    this.add = false;
    if (approveRejectCase) {
      this.showSendToApprove = false;
      this.approve = true;
    } else {
      if (
        this.proposalPhaseData &&
        !Utilities.isNullOrEmpty(this.proposalPhaseData.google_doc_id)
      ) {
        if (
          this.currentUser._id == this.proposalPhaseData.created_by_rfid &&
          this.proposalPhaseData.draft == true
        ) {
          this.showSendToApprove = true;
        } else this.showSendToApprove = false;
      }
    }
    this.proposalPhaseData.googleDocUrl =
      'https://docs.google.com/document/d/' +
      this.proposalPhaseData.google_doc_id +
      '/edit?usp=sharing&embed=true';
    this.proposalPhaseData.googleDocUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.proposalPhaseData.googleDocUrl
      );
    //
    this.googleDocData = {
      module: 'proposal',
      customer_domain: this.currentDomain.domain,
      customer_org: this.currentDomain.organisation_name,
      domain_rfid: this.currentDomain._id,
      googleDocUrl: this.proposalPhaseData.googleDocUrl,
      createdUser: {
        name: this.proposalPhaseData.user_proposal.created_user_name,
        role: 'PreSales',
        date: this.proposalPhaseData.created_at,
        text: 'Created Proposal',
        img: this.proposalPhaseData.user_proposal.created_user_img,
      },
      approval_phases: this.proposalPhaseData.approval_phases,
    };
    if (this.proposalPhaseData.approval_phases) {
      this.googleDocData.managerApprovalPart =
        this.proposalPhaseData.approval_phases
          .slice()
          .reverse()
          .find(
            (x) =>
              x.role &&
              x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
          );
      this.googleDocData.sendingToCustomerPart =
        this.proposalPhaseData.approval_phases
          .slice()
          .reverse()
          .find(
            (x) =>
              x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
          );
    }
    if (
      this.googleDocData.sendingToCustomerPart &&
      !this.googleDocData.sendingToCustomerPart.date
    )
      this.googleDocData.sendingToCustomerPart.current = true;
    if (
      this.googleDocData.managerApprovalPart &&
      !this.googleDocData.managerApprovalPart.date
    )
      this.googleDocData.managerApprovalPart.current = true;
    this.googleproposalDocModal.show();
    this.googleproposalDocModal.onHide.subscribe((res) => {
      this.googleDocData = null;
    });
  }

  sendDraftproposalForApproval(proposal: ProposalModel) {
    this.proposalPhaseData = proposal;
  }

  sendproposalToCustPopup() {
    this.proposalcustomerInfoPopup.show();
  }
  async sendproposalToCustomer(customerInfo) {
    var that = this;
    await this.proposalService
      .sendProposalToCustpmer(
        this.proposalPhaseData,
        customerInfo,
        this.currentUser,
        this.proposalPhaseData.opportunity_rfid
      )
      .then((result) => {
        if (result) {
          this.proposalPhaseData = result;
          this.googleproposalDocModal.hide();
          this.proposalcustomerInfoPopup.hide();
        }
      });
  }

  skipStep(e) {
    var skipped = e.target.checked;
    if (!Utilities.isNullOrEmpty(this.opporunityID)) {
      this.http
        .put(
          Endpoint.GLOBAL_URL +
            Endpoint.SKIP_OPP_STEP +
            '/' +
            this.opporunityID,
          {
            skipped: skipped,
            module: 'proposal',
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true) {
            this.skipEmitter.emit(skipped);
          } else {
            alert(Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    } else this.skipEmitter.emit(skipped);
  }
}
