<div class="">
  <button
    (click)="createOrder()"
    type="button"
    [disabled]="!canCreateOrderAction"
    class="btn btn-color text-white rounded-pill d-block ms-auto mt-4"
  >
    <img src="./assets/img/plus-white-icon.svg" alt="plus icon" class="pe-2" />
    <span>Create an Order</span>
  </button>
  <div class="subscriptions px-4 my-2 fluid-section w-100">
    <div
      class="row border-bottom product-sans-medium border-opacity-50 border-dark position-sticky top-0 bg-white py-3"
    >
      <p class="col-2 col-xl-3 mb-0 ps-0">SKU</p>
      <p class="col-2 mb-0 ps-0">Plan</p>
      <p class="col-1 mb-0 ps-0">Licenses</p>
      <p class="col-2 mb-0 ps-0">Status</p>
      <p class="col-2 mb-0 ps-0">Start & End Date</p>
      <p class="col mb-0 ps-0 position-relative">
        Edit
        <span
          class="w-100 h-100 bg-white position-absolute top-0 start-0"
        ></span>
      </p>
    </div>
    <div
      *ngFor="let sub of allSubcs"
      class="row pt-2 border-bottom border-opacity-10 border-dark flex-nowrap"
    >
      <p class="col-2 col-xl-3 mb-0 px-0 py-3">{{ sub.skuName }}</p>
      <p class="col-2 mb-0 px-0 py-2">
        {{ sub.displayedPlanName
        }}<img
          (click)="changePlan(sub.subscriptionId)"
          [ngClass]="{
            my_disabled: !canCreateOrderAction
          }"
          src="./assets/img/edit-icon.svg"
          alt="edit icon"
          class="cursor-pointer"
        />
      </p>
      <p
        class="col-1 mb-0 px-0 py-2"
        data-toggle="tooltip"
        title="Purchased Licenses:{{ sub.seatsNbr }}&#10;Assigned Licenses:{{
          sub.seats.licensedNumberOfSeats
        }}"
      >
        {{ sub.seats.licensedNumberOfSeats }}/{{ sub.seatsNbr
        }}<img
          src="./assets/img/edit-icon.svg"
          alt="edit icon"
          class="cursor-pointer"
          [ngClass]="{
            my_disabled: !canCreateOrderAction
          }"
          (click)="changeLicensePopup(sub.subscriptionId)"
        />
      </p>
      <p
        class="col-2 mb-0 px-0 py-3 text-success"
        [ngClass]="{
          div_suspend: !sub.status.toLowerCase().includes('act'),
          div_active: sub.status.toLowerCase().includes('act')
        }"
      >
        {{ sub.status }}
      </p>
      <p class="col-2 mb-0 px-0 py-2 d-grid">
        <label
          *ngIf="sub.plan.isCommitmentPlan && sub.plan.commitmentInterval"
          >{{
            sub.plan.commitmentInterval.startTime | date : "dd-MMM-yyyy"
          }}</label
        >
        <label *ngIf="sub.plan.isCommitmentPlan && sub.plan.commitmentInterval"
          >{{ sub.plan.commitmentInterval.endTime | date : "dd-MMM-yyyy" }}
        </label>
      </p>
      <p class="col mb-0 px-0 py-2" *ngIf="canCreateOrderAction">
        <img
          *ngIf="
            sub.plan &&
            sub.plan.planName &&
            sub.plan.planName.toLowerCase() == 'trial'
          "
          src="./assets/img/start-paid-service-icon.svg"
          alt="paid service icon"
          [ngbPopover]="'start paid service'"
          triggers="mouseenter:mouseleave"
          (click)="startPaidServiceConfirmation(sub.subscriptionId)"
          class="cursor-pointer"
        />
        <img
          *ngIf="sub.status.toLowerCase().includes('act')"
          src="./assets/img/suspend-icon.svg"
          alt="suspend icon"
          class="cursor-pointer"
          [ngbPopover]="'suspend'"
          triggers="mouseenter:mouseleave"
          (click)="updateStatusConfirmation(sub.subscriptionId, true)"
        />
        <ng-template [ngIf]="currentUser.isFinanceTeam">
          <img
            *ngIf="sub.status.toLowerCase().includes('suspend')"
            src="./assets/img/activate-icon.svg"
            alt="activate icon"
            [ngbPopover]="'activate'"
            triggers="mouseenter:mouseleave"
            class="cursor-pointer"
            (click)="updateStatusConfirmation(sub.subscriptionId, false)"
          />
          <img
            (click)="downUpgrade(sub.subscriptionId, true)"
            src="./assets/img/upgrade-icon.svg"
            alt="upgrade icon"
            class="cursor-pointer"
            [ngbPopover]="'upgrade'"
            triggers="mouseenter:mouseleave"
          />
        </ng-template>
        <img
          (click)="downUpgrade(sub.subscriptionId, false)"
          src="./assets/img/downgrade-icon.svg"
          alt="downgrade icon"
          [ngbPopover]="'downgrade'"
          triggers="mouseenter:mouseleave"
          class="cursor-pointer"
        />
        <img
          src="./assets/img/renewal-settings-icon.svg"
          alt="renewal settings icon"
          [ngbPopover]="'update renewal settings'"
          triggers="mouseenter:mouseleave"
          class="cursor-pointer"
          (click)="updateRenewalSettingsConfirmation(sub.subscriptionId)"
        />
        <img
          data-toggle="tooltip"
          (click)="renew(sub.subscriptionId)"
          title="renew this subscription"
          src="./assets/img/renew-icon.svg"
          alt="renew icon"
          class="cursor-pointer"
        />
      </p>
    </div>
  </div>
</div>

<!-- STATUS CONFIRMATION POPUP -->
<div
  class="modal fade"
  bsModal
  #statusModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="statusModalLabel"
        >
          {{ statusTextAction }} this Subscription
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="statusModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h5 text-center">
          Are you sure you want to {{ statusTextAction }} this Subscription? An
          Approval request will be send to the admin to approve/reject it.
        </h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="updateStatus()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="statusModal.hide()"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- START PAID SERVICE CONFIRMATION POPUP -->
<div
  class="modal fade"
  bsModal
  #startPaidServiceModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="startPaidServiceModalLabel"
        >
          Start Paid Service`
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="startPaidServiceModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h5 text-center">
          Are you sure you want to start paid service?
        </h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="startPaidService()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="startPaidServiceModal.hide()"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!-- RENEWAL SETTINGS CONFIRMATION POPUP -->
<div
  class="modal fade"
  bsModal
  #renewalSettingsModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="renewalSettingsModalLabel"
        >
          Update Renewal Settings
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="renewalSettingsModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark px-5">
        <div class="row my-3">
          <div
            (click)="newRenewalSettingsType = 'AUTO_RENEW_YEARLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Auto Renew Yearly Pay</h2>
            <img
              src="./assets/img/auto-renew-yearly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval,
                automatically renew the subscription's plan as a yearly payment
                with the same number of seats.
              </p>
            </div>
          </div>
          <div
            (click)="newRenewalSettingsType = 'AUTO_RENEW_MONTHLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Auto Renew Monthly Pay</h2>
            <img
              src="./assets/img/auto-renew-icons-monthly.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval,
                automatically renew the subscription's plan as monthly with the
                same number of seats.
              </p>
            </div>
          </div>
          <div
            (click)="newRenewalSettingsType = 'RENEW_CURRENT_USERS_YEARLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Renew Current Users Yearly Plan</h2>
            <img
              src="./assets/img/auto-renew-current-monthly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval, renew the
                subscription plan as a yearly payment with the total number of
                currently active user license seats. This is the default setting
                for active annual commitment plans (paid yearly).
              </p>
            </div>
          </div>
          <div
            (click)="newRenewalSettingsType = 'SWITCH_TO_PAY_AS_YOU_GO'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Switch to Pay As You Go</h2>
            <img
              src="./assets/img/pay-as-you-go-icon.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval, change the
                annual commitment plan to a flexible plan.
              </p>
            </div>
          </div>
        </div>
        <div class="row mx-5 px-5">
          <div
            (click)="newRenewalSettingsType = 'RENEW_CURRENT_USERS_MONTHLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-4 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Renew Current Users Monthly Plan</h2>
            <img
              src="./assets/img/auto-renew-current-yearly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval, renew the
                subscription plan as a monthly payment with the total number of
                currently active user license seats. This is the default setting
                for active annual commitment plans (paid monthly).
              </p>
            </div>
          </div>

          <div
            (click)="newRenewalSettingsType = 'CANCEL'"
            class="d-flex flex-column align-items-center justify-content-between col-4 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Cancel</h2>
            <img
              src="./assets/img/cancel-icon.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan interval, the
                subscription is suspended.
              </p>
            </div>
          </div>

          <div
            (click)="newRenewalSettingsType = 'RENEW_ON_PROPOSED_OFFER'"
            class="d-flex flex-column align-items-center justify-content-between col-4 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">RENEW ON PROPOSED OFFER</h2>
            <img
              src="./assets/img/auto-renew-current-yearly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of the current commitment plan's interval, renew on
                the latest renewal proposal with the number of currently active
                user licenses or proposed offer commitment whichever is higher.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-dark border-opacity-50">
        <input
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="updateRenewalSettings()"
          type="submit"
          value="Save"
        />
      </div>
    </div>
  </div>
</div>

<!-- CHANGE LICENSE MODAL -->
<div
  class="modal fade"
  bsModal
  #changeLicenseModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="changeLicenseLabel"
        >
          Change License Cap
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="changeLicenseModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <form
          [formGroup]="LicenseForm"
          role="form"
          #formDir="ngForm"
          id="add-order-license-form"
          novalidate
          class="d-flex flex-column flex-xl-row justify-content-xl-between ng-untouched ng-pristine ng-invalid ms-3 gap-3"
        >
          <div class="row g-3">
            <div class="col d-flex align-items-center">
              <label for="sku" class="me-2">SKU</label>
              <input
                type="text"
                class="form-control rounded-pill"
                id="sku"
                formControlName="sku_name"
              />
            </div>
            <div class="col d-flex align-items-center">
              <label for="licenses-nb" class="text-wrap"
                >Current Nb. of Licenses</label
              >
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="current_licenses"
                id="licenses-nb"
              />
            </div>

            <div class="row g-3">
              <label for="additional-licenses" class="col-2 text-wrap">{{
                updateLicenseText
              }}</label>
              <div class="col-10">
                <input
                  type="number"
                  (change)="calculatePrices()"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && additional_licenses.invalid
                  }"
                  class="form-control rounded-pill"
                  formControlName="additional_licenses"
                  id="additional-licenses"
                />
              </div>
            </div>

            <div class="row g-1 mb-2">
              <div class="col-2">Google Offer</div>
              <div class="col-2">
                <div class="form-check">
                  <input
                    formControlName="with_google_offer"
                    class="form-check-input"
                    type="radio"
                    [value]="true"
                    name="with_google_offer"
                    (change)="calculatePrices()"
                    id="google-offer-1"
                  />
                  <label class="form-check-label" for="google-offer-1">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    formControlName="with_google_offer"
                    class="form-check-input"
                    type="radio"
                    [value]="false"
                    name="with_google_offer"
                    (change)="calculatePrices()"
                    id="google-offer-2"
                    checked
                  />
                  <label class="form-check-label" for="google-offer-2">
                    No
                  </label>
                </div>
              </div>
              <div
                class="mb-2 d-flex align-items-center justify-content-between col"
              >
                <label
                  for="discount-percentage"
                  class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
                  >Discount %</label
                >
                <input
                  formControlName="google_offer"
                  type="number"
                  (change)="calculatePrices()"
                  min="0"
                  class="form-control rounded-pill"
                  placeholder="0"
                />
              </div>
            </div>
            <div class="row g-1 mb-2">
              <div class="col-2">Customer Offer</div>
              <div class="col-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="with_customer_offer"
                    name="with_customer_offer"
                    (change)="calculatePrices()"
                    [value]="true"
                    id="google-offer-21"
                  />
                  <label class="form-check-label" for="google-offer-21">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    formControlName="with_customer_offer"
                    class="form-check-input"
                    type="radio"
                    name="with_customer_offer"
                    id="google-offer-22"
                    (change)="calculatePrices()"
                    [value]="false"
                    checked
                  />
                  <label class="form-check-label" for="google-offer-22">
                    No
                  </label>
                </div>
              </div>
              <div
                class="mb-2 d-flex align-items-center justify-content-between col"
              >
                <label
                  for="discount-percentage"
                  class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
                  >Discount %</label
                >
                <input
                  type="number"
                  min="0"
                  (change)="calculatePrices()"
                  formControlName="customer_offer"
                  class="form-control rounded-pill"
                  placeholder="0"
                />
              </div>
            </div>

            <div class="row g-3">
              <label
                for="start-date"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >Start Date</label
              >
              <div class="col-6">
                <input
                  type="date"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && start_date.invalid
                  }"
                  (change)="calculatePrices()"
                  formControlName="start_date"
                  class="form-control rounded-pill"
                  id="start-date"
                />
              </div>
              <div class="col-4">
                <input
                  type="number"
                  step="1"
                  (ngModelChange)="calculatePrices()"
                  formControlName="annual_months"
                  class="form-control rounded-pill"
                  placeholder="Total Months"
                />
              </div>
            </div>
            <div class="row g-3">
              <label
                for="end-date"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >End Date</label
              >
              <div class="col-10">
                <input
                  type="date"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && end_date.invalid
                  }"
                  formControlName="end_date"
                  (change)="calculatePrices()"
                  class="form-control rounded-pill my_disabled_input"
                  id="end-date"
                />
              </div>
            </div>

            <div class="row g-3">
              <label
                for="po_id"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >P.O.#</label
              >
              <div class="col-10">
                <input
                  type="text"
                  required
                  placeholder="P.O. #"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && po_id.invalid
                  }"
                  class="form-control rounded-pill"
                  formControlName="po_id"
                  id="po_id"
                />
              </div>
            </div>
            <div class="row g-3">
              <label
                for="order_sale_invoice_nbr"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >Sale Invoice #</label
              >
              <div class="col-10">
                <input
                  type="text"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && order_sale_invoice_nbr.invalid
                  }"
                  placeholder="Sale Invoice #"
                  class="form-control rounded-pill col-10"
                  formControlName="order_sale_invoice_nbr"
                  id="order_sale_invoice_nbr"
                />
              </div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <label
                  for="source"
                  class="form-label text-nowrap col-2 me-3 mb-0 d-none d-md-inline"
                  >Opportunity Source</label
                >
                <ng-select
                  bindLabel="source"
                  appendTo="body"
                  [searchable]="true"
                  class="col-11 p-0"
                  [clearable]="true"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && opportunity_source.invalid
                  }"
                  formControlName="opportunity_source"
                  placeholder="Source"
                >
                  <ng-option
                    [value]="source"
                    *ngFor="let source of opportunitySources"
                  >
                    {{ source }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-6">
                <label
                  for="opportunity-status"
                  class="form-label text-nowrap col-2 me-3 mb-0 d-none d-md-inline"
                  >Opportunity Status</label
                >
                <ng-select
                  bindLabel="status"
                  appendTo="body"
                  [searchable]="true"
                  class="col-11 p-0"
                  [clearable]="true"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && opportunity_status.invalid
                  }"
                  formControlName="opportunity_status"
                  placeholder="Opportunity Status"
                >
                  <ng-option
                    [value]="status"
                    *ngFor="let status of opportunityStatus"
                  >
                    {{ status }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <label
                  for="section"
                  class="form-label text-nowrap col-2 me-3 mb-0 d-none d-md-inline"
                  >Company Section</label
                >
                <ng-select
                  bindLabel="section"
                  appendTo="body"
                  [searchable]="true"
                  class="col-11 p-0"
                  [clearable]="true"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && company_section.invalid
                  }"
                  formControlName="company_section"
                  placeholder="Company Section"
                >
                  <ng-option
                    [value]="section"
                    *ngFor="let section of companySections"
                  >
                    {{ section }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div
              class="d-flex border border-end-0 border-start-0 border-primary py-3"
            >
              <div class="col px-0">Selling Price</div>
              <div
                class="col-1 text-center product-sans-bold text-primary px-0"
              >
                {{ LicenseForm?.value?.selling_price }}$
              </div>
            </div>
            <div class="d-flex">
              <div class="col px-0">Cost Price</div>
              <div
                class="col-1 text-center product-sans-bold text-primary px-0"
              >
                {{ LicenseForm?.value?.cost_price }}$
              </div>
            </div>
            <div class="text-end">
              <input
                type="submit"
                class="btn btn-primary btn-color rounded-pill col-2"
                (click)="updateLicense()"
                value="Confirm"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <!-- <input
          type="submit"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="updateLicense()"
          value="Confirm"
        /> -->
      </div>
    </div>
  </div>
</div>
