import { OrdersService } from './../../../../../orders/src/lib/orders.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode } from 'src/shared/Endpoint';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoint } from 'src/shared/Endpoint';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainModel } from 'src/models/mongo/domain.model';

@Component({
  selector: 'lib-customer-orders',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-orders.component.html',
  styleUrls: ['./customer-orders.component.scss'],
})
export class CustomerOrdersComponent implements OnInit {
  currentCustomer: DomainModel;
  OrdersService: OrdersService;
  orders: any;

  constructor(
    private datePipe: DatePipe,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    var paramsJson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (paramsJson) {
      this.currentCustomer = JSON.parse(paramsJson);
      this.getOrdersOfCustomers(this.currentCustomer._id);
    }
  }

  async getOrdersOfCustomers(customerId: string): Promise<void> {
    var user = this.localStorage.getLoggedinUserModel();
    var params = new HttpParams().set(Unicode.VIEW_ALL, user.canViewAll);
    await this.http
      .get(
        Endpoint.GLOBAL_URL + Endpoint.ORDERS_OF_CUSTOMER + '/' + customerId,
        { params: params }
      )
      .subscribe(
        (response: any) => {
          this.orders = response;
          // console.log('orders:', this.orders);
        },
        (error) => {
          console.log('Error fetching orders:', error);
        }
      );
  }

  viewOrder(edit: boolean, id) {
    var order = this.orders.find((x) => x._id == id);
    this.router.navigate(['orders/order-view'], {
      state: { edit: edit, order_model: JSON.stringify(order) },
      skipLocationChange: false,
    });
  }
}
