import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent implements OnInit {

  content;
  newArray;

  constructor() { }

  ngOnInit(): void {
  }


}
