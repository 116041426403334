import { SettingsService } from '../../../../../src/servcies/backend-services/settings.service';
import { Unicode } from './../../../../../src/shared/Endpoint';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Endpoint } from 'src/shared/Endpoint';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { IntegrationModel } from '../../../../../src/models/mongo/integration.model';

@Component({
  selector: 'settings-integration',
  templateUrl: './integrations.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  integrations: IntegrationModel[] = [];
  pageTitle: string = '';
  integrationForm: UntypedFormGroup;
  @ViewChild('addOdooIntegrationModal') addOdooIntegrationModal: ModalDirective;

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService
  ) {
    this.integrationForm = fb.group({
      key: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.getAllIntegrations();
  }
  async getAllIntegrations(): Promise<void> {
    await this.settingsService.getAllIntegrations().subscribe(
      (response: any) => {
        if (response) {
          this.integrations = response.data ?? [];
        }
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
      }
    );
  }

  async change(id) {
    //var enabled = e.source.checked;
    var integration = this.integrations.find((x) => x._id == id);
    if (integration) {
      await this.settingsService
        .updateIntegration(integration)
        .toPromise()
        .then((resp: any) => {
          console.log(resp);
          alert(resp.message);
        })
        .catch((error) => {
          if (error.status === 401) {
            this.localStorage.logout();
            alert('Unauthorized Access');
            this.router.navigate(['./login']);
          } else alert(error.statusText ?? 'Internal server Error');
        });
    }
  }

  showOdooIntegPopup() {
    this.addOdooIntegrationModal.show();
  }

  get key() {
    return this.integrationForm.get('key');
  }
}
