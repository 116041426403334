import { SettingsModule } from './../../../projects/settings/src/lib/settings.module';
import { HelpModule } from './help/help.module';
import { CustomersModule } from './../../../projects/customers/src/lib/customers.module';
import { OrdersModule } from './../../../projects/orders/src/lib/orders.module';
import { ReportsModule } from './reports/reports.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LayoutsComponent } from './layouts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DealsModule } from 'projects/deals/src/public-api';
import { GcpModule } from 'projects/gcp/src/public-api';
import { SowModule } from 'projects/sow/src/public-api';
import { PocModule } from 'projects/poc/src/public-api';
import { ProposalModule } from 'projects/proposal/src/public-api';
import { NotificationListModule } from './notification-list/notification-list.module';
import { OpportunityModule } from 'projects/opportunity/src/public-api';
import { CustomerRequirementsModule } from 'projects/customer-requirements/src/public-api';
import { EngineerRecommendationsModule } from '../../../projects/engineer-recommendations/src/lib/engineer-recommendations.module';
import { SalesModule } from 'projects/sales/src/public-api';
import { BillingAccountListModule } from 'projects/gcp/src/lib/billing-accounts/billing-account-list/billing-account-list.module';
import { ProjectListModule } from 'projects/gcp/src/lib/project-list/project-list.module';

const routes: Routes = [
  {
    path: '',
    component: LayoutsComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => DashboardModule },
      { path: 'reports', loadChildren: () => ReportsModule },
      { path: 'orders', loadChildren: () => OrdersModule },
      { path: 'customers', loadChildren: () => CustomersModule },
      { path: 'users', loadChildren: () => SalesModule },
      { path: 'settings', loadChildren: () => SettingsModule },
      { path: 'help', loadChildren: () => HelpModule },
      { path: 'deals', loadChildren: () => DealsModule },
      { path: 'gcp', loadChildren: () => GcpModule },
      { path: 'sow', loadChildren: () => SowModule },
      { path: 'poc', loadChildren: () => PocModule },
      { path: 'proposal', loadChildren: () => ProposalModule },
      { path: 'opportunities', loadChildren: () => OpportunityModule },
      {
        path: 'customer-requirements',
        loadChildren: () => CustomerRequirementsModule,
      },
      {
        path: 'engineer-recommendations',
        loadChildren: () => EngineerRecommendationsModule,
      },
      { path: 'notifications', loadChildren: () => NotificationListModule },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutsRoutingModule {}
