import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { SetBillingPopupComponent } from '../set-billing-popup/set-billing-popup.component';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { DatePipe } from '@angular/common';
import { UserModel } from 'src/models/mongo/user.model';
import { DomainModel } from 'src/models/mongo/domain.model';
import { Router } from '@angular/router';

@Component({
  selector: 'link-project-popup',
  templateUrl: './link-project-popup.component.html',
  styleUrls: ['./link-project-popup.component.scss'],
})
export class LinkProjectPopupComponent implements OnInit {
  currentCustomer: DomainModel;
  @ViewChild('linkProjectModal') linkProjectModal: ModalDirective;
  @ViewChild('creatBillingModal') creatBillingModal: ModalDirective;
  @ViewChild('billingAccCreatedModal') billingAccCreatedModal: ModalDirective;
  @ViewChild('successfulyLinkedModal') successfulyLinkedModal: ModalDirective;
  @ViewChild('setBillingModal') setBillingModal: SetBillingPopupComponent;
  createdBillingData: any = {};
  @Input() pocID: string;
  @Input() oppID: string;
  @Input() projectRfId: string;
  @Input() projectName: string;
  @Output() linkProj = new EventEmitter<any>();
  linkDataExecution: any = {};
  @ViewChild('budgetCreModal')
  budgetCreationModal: ModalDirective;
  billingRfId: string;
  currentUser: UserModel;
  createBilling: boolean = false;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentCustomer = JSON.parse(cstjson);
  }

  ngOnInit(): void {}

  show() {
    this.linkProjectModal.show();
  }
  hide() {
    this.linkProjectModal.hide();
  }

  linkProjectPopup(existing: boolean) {
    this.linkDataExecution = {};
    this.createBilling = true;
    if (existing) {
      this.setBillingModal.show();
    } else {
      this.createdBillingData = {};
      if (
        !Utilities.isNullOrEmpty(this.oppID) ||
        !Utilities.isNullOrEmpty(this.pocID)
      ) {
        this.creatBillingModal.show();
        this.creatBillingModal.onHide.subscribe(() => {
          this.createBilling = false;
        });
      } else {
        this.router.navigate(['customers/customer-create'], {
          queryParams: {
            billingAccountCase: true,
          },
          skipLocationChange: true,
        });
      }
    }
  }

  billingAccountCreated(data) {
    this.createdBillingData = {
      domain: data.domain.domain,
      selectedSku: data.sku,
      selectedPaymentPlan: data.plan,
      mainBillingAccount: { name: data.parent },
      billingAccountName: data.billing_account_name,
      id: data.billing_account_rfid,
      projectName: this.projectName,
    };
    if (this.creatBillingModal) this.creatBillingModal.hide();
    this.billingAccCreatedModal.show();
  }

  async linkToProject() {
    if (
      !Utilities.isNullOrEmpty(this.projectRfId) &&
      !Utilities.isNullOrEmpty(this.createdBillingData.id)
    ) {
      var body = {
        prfid: this.projectRfId,
        id: this.createdBillingData.id,
        poc_id: this.pocID,
        opp_rfid: this.oppID,
        pname: this.projectName,
        bname: this.createdBillingData.billingAccountName,
        customer_rfid: this.currentCustomer?._id,
      };
      await this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.LINK_PROJECT_USING_RFID, body)
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.success == true) {
            if (resp.data)
              this.linkDataExecution = {
                linking_date: resp.data.date,
                linking_date_string: resp.data.string_date,
                consumed_time: resp.data.consumed_time,
                project_name: this.projectName,
                billing_account_name:
                  this.createdBillingData.billingAccountName,
                billing_account_rfid: this.createdBillingData.id,
                link_by: { name: resp.data.user_name },
              };
            else
              this.linkDataExecution = {
                billing_account_rfid: this.createdBillingData.id,
                billing_account_name:
                  this.createdBillingData.billingAccountName,
              };
            this.setBillingModal.hide();
            this.billingAccCreatedModal?.hide();
            this.successfulyLinkedModal.show();
          } else {
            alert(resp.message ?? Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    }
  }

  async linkToExistingBilling(billingAccount) {
    this.createdBillingData = {
      domain: this.currentCustomer?.domain,
      projectName: this.projectName,
      billingAccountName: billingAccount.name,
    };
    if (
      !Utilities.isNullOrEmpty(this.projectRfId) &&
      !Utilities.isNullOrEmpty(billingAccount.id)
    ) {
      var body = {
        prfid: this.projectRfId,
        id: billingAccount.id,
        name: billingAccount.name,
        poc_id: this.pocID,
        opp_rfid: this.oppID,
        pname: this.projectName,
        bname: billingAccount.name,
        customer_rfid: this.currentCustomer?._id,
      };
      await this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.LINK_PROJECT, body)
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.success == true) {
            if (resp.data)
              this.linkDataExecution = {
                linking_date: resp.data.date,
                linking_date_string: resp.data.string_date,
                consumed_time: resp.data.consumed_time,
                project_name: this.projectName,
                billing_account_name: billingAccount.name,
                billing_account_rfid: resp.data.billing_account_rfid,
                link_by: { name: resp.data.user_name },
              };
            else
              this.linkDataExecution = {
                billing_account_rfid: billingAccount.id,
                billing_account_name: billingAccount.name,
              };
            this.setBillingModal.hide();
            this.billingAccCreatedModal?.hide();
            this.successfulyLinkedModal.show();
          } else {
            alert(resp.message ?? Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    }
  }

  closeSuccessLinkingPopup() {
    this.successfulyLinkedModal.hide();
    this.linkProjectModal.hide();
    this.linkProj.emit({ link: this.linkDataExecution });
  }
  createBudgetPopup() {
    this.successfulyLinkedModal.hide();
    this.linkProjectModal.hide();
    this.billingRfId = this.linkDataExecution.billing_account_rfid;
    this.budgetCreationModal.show();
  }
  createBudget(budget) {
    if (budget?.name) {
      var billing_budget = {
        created_at: new Date(),
        created_date_string: Utilities.toStringDateFormat(
          this.datePipe,
          new Date(),
          'dd MMM,yyyy-hh:mma'
        ),
        created_user_name: this.currentUser.user_name,
      };
      this.localStorage.save(Unicode.CURRENT_BUDGET, JSON.stringify(budget));
      this.budgetCreationModal.hide();
      this.successfulyLinkedModal.hide();
      this.linkProjectModal.hide();
      this.linkDataExecution.createBudget = true;
      if (this.linkProj && this.linkProj.observers.length > 0)
        this.linkProj.emit({
          link: this.linkDataExecution,
          budget: billing_budget,
        });
    }
  }
}
