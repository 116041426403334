import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Component({
  selector: 'billing-account-created-popup',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './billing-account-created-popup.component.html',
  styleUrls: ['./billing-account-created-popup.component.scss'],
})
export class BillingAccountCreatedPopupComponent implements OnInit {
  @Input() createdBillingData: any = {};
  @ViewChild('accountCreated') public accountCreated: ModalDirective;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  show() {
    this.accountCreated.show();
    this.accountCreated.onHide.subscribe((res) => {
      this.router.navigateByUrl('/customers');
    });
  }
}
