import { OpportunityPocPhaseModule } from './opportunity-poc-phase/opportunity-poc-phase.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpporunityDetailsRoutingModule } from './opporunity-details-routing.module';
import { OpporunityDetailsComponent } from './opporunity-details.component';
import { NgbCollapseModule, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { OpporunityDealPhaseComponent } from './opporunity-deal-phase/opporunity-deal-phase.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DealFormModule } from 'src/shared-components/deal-form/deal-form.module';
import { OpportunitySolutionBuildingPhaseModule } from './opportunity-solution-building-phase/opportunity-solution-building-phase.module';
import { OpporunityProposalPhaseModule } from './opporunity-proposal-phase/opporunity-proposal-phase.module';
import { OpportunityOrderPhaseModule } from './opportunity-order-phase/opportunity-order-phase.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
@NgModule({
  declarations: [OpporunityDetailsComponent, OpporunityDealPhaseComponent],
    imports: [
        CommonModule,
        NgbCollapseModule,
        NgbModule,
        NgSelectModule,
        FormsModule,
        ModalModule.forRoot(),
        DealFormModule,
        OpportunitySolutionBuildingPhaseModule,
        OpportunityPocPhaseModule,
        OpporunityProposalPhaseModule,
        OpportunityOrderPhaseModule,
        OpporunityDetailsRoutingModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        NgbProgressbarModule,
        MatTooltipModule
    ],
})
export class OpporunityDetailsModule {}
