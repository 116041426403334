import {COMMA, ENTER, U} from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  Input,
  Output, signal,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Utilities } from 'src/shared/utilities';
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
@Component({
  selector: 'customer-info-popup',
  templateUrl: './customer-info-popup.component.html',
  styleUrls: ['./customer-info-popup.component.scss'],
})
export class CustomerInfoPopupComponent {
  @ViewChild('infoModal') infoModal: ModalDirective;
  @Output() sendListener = new EventEmitter<any>();
  @Input() customer_emails: any[];
  @Input() customer_name: string;
  @Input() scopes: string;
  @Input() withScopes: boolean;
  readonly addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  readonly emails = [];
  constructor() {}

  ngOnInit(): void {
   this.emails.push(...this.customer_emails);
  }

  show() {
    this.infoModal.show();
  }
  hide() {
    this.infoModal.hide();
  }




  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
this.emails.push(value)
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(email,index): void {
    this.emails.splice(index,1);
  }

  edit(email, event: MatChipEditedEvent,index) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      this.remove(email,index);
      return;
    }

    this.emails[index]=value;
  }


  send(name: string) {
    if (
      !Utilities.isNullOrEmpty(name) &&
      !Utilities.isNullOrEmpty(this.emails) &&
      (!this.withScopes || !Utilities.isNullOrEmpty(this.scopes))
    ) {
      this.sendListener.emit({ name: name, email: this.emails, scopes: this.scopes });
    } else alert('Add the Info Please');

  }


}
