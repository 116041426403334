<section class="box-shadow bg-white rounded-5 p-2 px-md-4 pb-2 fluid-section">
  <h1 class="h4 p-2 d-flex align-items-center mb-3">
    <a (click)="cancel()"
      ><img
        src="./assets/img/back-icon.svg"
        class="back-icon cursor-pointer"
        alt="back icon" /></a
    >Create a User
  </h1>
  <form
    class="d-flex flex-column flex-md-row col-12 flex-wrap needs-validation ps-2"
    novalidate
    [ngClass]="{ 'was-validated': isFormSubmitted }"
    (ngSubmit)="onSubmit()"
    id="frm_usr"
    [formGroup]="saleForm"
  >
    <div class="col-md-9 order-2 order-md-1">
      <h2 class="h5 mb-3 col-12 text-center text-md-start">User Information</h2>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label
          for="first-name"
          class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >First Name</label
        >
        <input
          type="text"
          class="form-control rounded-pill"
          id="first-name"
          formControlName="first_name"
          [readOnly]="formdisable"
          placeholder="First Name"
          required
        />
      </div>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label
          for="last-name"
          class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >Last Name</label
        >
        <input
          type="text"
          class="form-control rounded-pill"
          [readOnly]="formdisable"
          id="last-name"
          placeholder="Last Name"
          formControlName="last_name"
          required
        />
      </div>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label
          for="email-address"
          class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >Email</label
        >
        <input
          type="email"
          class="form-control rounded-pill"
          id="email-address"
          placeholder="Email"
          formControlName="email"
          [readOnly]="formdisable"
          required
        />
      </div>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label
          for="country"
          class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >Country</label
        >
        <ng-select
          bindLabel=" country.name"
          appendTo="body"
          id="countries_ngs"
          formControlName="country"
          [searchable]="true"
          class="form-select p-0 rounded-pill"
          [clearable]="true"
          placeholder="Country"
          [ngClass]="{
            'is-valid': saleForm.get('country').valid && isFormSubmitted,
            'is-invalid': saleForm.get('country').invalid && isFormSubmitted
          }"
        >
          <ng-option [value]="country.iso2" *ngFor="let country of countries">
            {{ country.name }}
          </ng-option>
        </ng-select>
      </div>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label
          for="groups"
          class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >Groups</label
        >

        <ng-select
          bindLabel="model.group_ids"
          appendTo="body"
          id="groups"
          formControlName="group_ids"
          [ngClass]="{
            'is-valid': saleForm.get('group_ids').valid && isFormSubmitted,
            'is-invalid': saleForm.get('group_ids').invalid && isFormSubmitted
          }"
          [multiple]="true"
          style="color: gray"
          [searchable]="true"
          (change)="selectGrp($event)"
          class="form-select p-0 rounded-pill"
          [clearable]="true"
          placeholder="Groups"
          aria-label="Select groups"
        >
          <ng-option [value]="group._id" *ngFor="let group of all_groups">
            {{ group.name }}
          </ng-option>
        </ng-select>
      </div>
      <div class="mb-2 d-flex align-items-center">
        <ng-template [ngIf]="isEnginner">
          <label
            for="usr_gcp"
            class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
            >GCP</label
          >
          <input
            id="usr_gcp"
            type="checkbox"
            class="form-check-input"
            formControlName="is_gcp"
          />
        </ng-template>
        <ng-template [ngIf]="isEnginner || isSales">
          <label
            for="usr_mng"
            class="form-label text-nowrap col-1 ms-5 mb-0 d-none d-xl-inline"
            >Manager</label
          >
          <input
            id="usr_mng"
            type="checkbox"
            class="form-check-input"
            formControlName="is_manager"
          />
        </ng-template>
      </div>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >Sector</label
        >
        <ng-select
          bindLabel="sector"
          appendTo="body"
          id="sectors_ngs"
          formControlName="sector"
          [searchable]="true"
          class="form-select p-0 rounded-pill"
          [clearable]="true"
          placeholder="Sectors"
          [ngClass]="{
            'is-valid': saleForm.get('sector').valid && isFormSubmitted,
            'is-invalid': saleForm.get('sector').invalid && isFormSubmitted
          }"
        >
          <ng-option [value]="sector" *ngFor="let sector of companySectors">
            {{ sector }}
          </ng-option>
        </ng-select>
      </div>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label
          for="phone-number"
          class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >Phone</label
        >
        <input
          type="tel"
          class="form-control rounded-pill"
          id="phone-number"
          placeholder="Phone"
          formControlName="phone"
          [readOnly]="formdisable"
        />
      </div>
      <div class="mb-2 d-flex align-items-center justify-content-between">
        <label
          for="manager"
          class="form-label text-nowrap col-1 me-3 mb-0 d-none d-xl-inline"
          >Manager</label
        >
        <ng-select
          bindLabel="manager.first_name"
          appendTo="body"
          id="managers_ngs"
          formControlName="manager"
          [searchable]="true"
          class="form-select p-0 rounded-pill"
          [clearable]="true"
          placeholder="Manager"
        >
          <ng-option [value]="manager.id" *ngFor="let manager of managers">
            {{ manager.first_name }} {{ manager.last_name }}
          </ng-option>
        </ng-select>
      </div>
      <div
        class="d-flex col-lg-6 justify-content-around justify-content-md-between"
      >
        <div class="py-1">
          <h5 class="h5 my-2 mt-2">Approval Level</h5>

          <div class="form-check lh-1">
            <input
              #myCheckbox1
              (change)="onCheckboxChange($event, 'EXC')"
              [checked]="
                model.permission_codes && model.permission_codes.includes('EXC')
              "
              class="form-check-input my-auto"
              type="checkbox"
              [attr.disabled]="formdisable ? 'true' : null"
              id="executionLevelCheck"
            />
            <label class="form-check-label" for="executionLevelCheck">
              Execution Level
            </label>
          </div>
        </div>
        <div class="py-1">
          <h5 class="h5 my-2 mt-2">Options</h5>

          <div class="form-check">
            <input
              #myCheckbox2
              (change)="onCheckboxChange($event, 'VIEW_ALL')"
              [checked]="
                model.permission_codes &&
                model.permission_codes.includes('VIEW_ALL')
              "
              class="form-check-input"
              type="checkbox"
              [attr.disabled]="formdisable ? 'true' : null"
              id="viewAllCheck"
            />
            <label class="form-check-label" for="viewAllCheck">
              Can view all
            </label>
          </div>
          <div class="form-check">
            <input
              #myCheckbox3
              (change)="onCheckboxChange($event, 'ASSIGN')"
              [checked]="
                model.permission_codes &&
                model.permission_codes.includes('ASSIGN')
              "
              class="form-check-input"
              type="checkbox"
              [attr.disabled]="formdisable ? 'true' : null"
              id="assignCheck"
            />
            <label class="form-check-label" for="assignCheck">
              Can Assign
            </label>
          </div>
          <div class="form-check">
            <input
              #myCheckbox3
              (change)="onViewerChange($event)"
              formControlName="viewer"
              class="form-check-input"
              type="checkbox"
              [attr.disabled]="formdisable ? 'true' : null"
              id="viewerCheck"
            />
            <label class="form-check-label" for="viewerCheck"> Viewer </label>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column align-items-center justify-content-between col-md-3 order-1 order-md-2"
    >
      <input
        *ngIf="!formdisable"
        class="btn btn-primary btn-color rounded-pill d-none d-md-inline col-7 col-lg-5 p-3 fs-6 align-self-end order-3"
        value="Save"
        type="submit"
      />
    </div>
  </form>
</section>
