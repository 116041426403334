<div
  class="fluid-section mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 px-2 px-md-4 rounded-5 w-100"
>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          (click)="goBack()"
          alt="back icon" /></a
      >Deals
    </h1>
    <div class="d-flex justify-content-end">
      <pagination-controls
        [responsive]="true"
        previousLabel=""
        nextLabel=""
        (pageChange)="renderPage($event)"
      ></pagination-controls>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-between align-items-start pt-3">
    <div
      class="d-flex flex-wrap align-items-center col-12 col-lg-6 mt-3 mt-sm-0 p-0"
    >
      <div class="col-6 col-md-6 col-lg-4 me-2 mb-2 mb-md-0 p-0">
        <input
          type="date"
          [(ngModel)]="fromSelectedDate"
          (ngModelChange)="selectDate()"
          placeholder="Date (from)"
          class="form-control rounded-pill py-0"
          id="deal-date-from"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
      <div class="col-6 col-md-6 col-lg-4 me-2 mb-2 mb-md-0 p-0">
        <input
          type="date"
          [(ngModel)]="toSelectedDate"
          (ngModelChange)="selectDate()"
          placeholder="Date (to)"
          class="form-control rounded-pill py-0"
          id="deal-date-to"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
    </div>
    <div
      class="position-relative col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 small-text p-0"
    >
      <img
        class="position-absolute pt-2 px-2 end-0"
        src="./assets/img/search-blue-icon.svg"
        alt="search icon"
      />
      <input
        id="txt-order-global-search"
        type="search"
        [(ngModel)]="customerSearch"
        (keyup.enter)="search()"
        class="form-control rounded-pill h-36"
        placeholder="Search by Domain"
      />
    </div>
  </div>

  <div class="table-responsive fluid-section w-100">
    <table
      class="table table-hover align-middle text-center small-text mt-3 min-h-200"
    >
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 border-top-0"
          >
            Domain
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-1 border-top-0">
            Deal Type
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Status
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            DR #
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 border-top-0">
            <div class="btn-group me-3" id="dal_type_db">
              <div class="btn-group" ngbDropdown role="group" aria-label="type">
                <button
                  type="button"
                  class="btn border-0 shadow-none"
                  ngbDropdownToggle
                  id="dropdownBasicdeal"
                >
                  Type
                </button>
                <div
                  class="dropdown-menu bg-light"
                  ngbDropdownMenu
                  aria-labelledby="dropdownBasicdeal"
                >
                  <button
                    ngbDropdownItem
                    *ngFor="let type of types"
                    (click)="applyFilter(type)"
                  >
                    {{ type }}
                  </button>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Opportunity #
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Actions
          </th>
          <!-- <th
            scope="col"
            class="p-1 p-md-2 col-1 col-md-2 position-relative border-top-0"
          >
            Edit
            <div class="w-100 h-100 bg-white position-absolute top-0"></div>
          </th> -->
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <tr
          *ngFor="
            let deal of GetData
              | paginate
                : {
                    itemsPerPage: GetPageSize,
                    currentPage: GetPagination,
                    totalItems: GetTotalCount
                  }
          "
        >
          <td class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 py-3">
            {{ deal.domain.domain }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-1">{{ deal.type }}</td>
          <td class="p-1 p-md-2 col-1 col-md-2">{{ deal.deal_status }}</td>
          <td class="p-1 p-md-2 col-1 col-md-2">{{ deal.dr_nbr }}</td>
          <td class="p-1 p-md-2 col-1">
            <div *ngIf="deal.is_gcp">GCP</div>
            <div *ngIf="!deal.is_gcp">GWS</div>
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">{{ deal.opportunity_nbr }}</td>
          <td
            class="p-1 p-md-2 text-end text-nowrap col-1 col-md-2 col-lg-2 text-md-end p-0 p-md-2"
          >
            <!-- <img
              class="px-1 px-md-2 cursor-pointer"
              [class.actions-alignment]="!(deal.deal_status && deal.deal_status.toUpperCase() === 'GPM APPROVED')"

              src="./assets/img/view-icon.svg"
              (click)="viewDeal(false, deal.id)"
              alt="view icon"
            /> -->
            <img
              [class.actions-alignment]="
                !(
                  deal.deal_status &&
                  deal.deal_status.toUpperCase() !== 'Submitted'
                )
              "
              class="px-1 px-md-2 cursor-pointer"
              src="./assets/img/edit-icon.svg"
              alt="edit icon"
              (click)="viewDeal(true, deal.id)"
            />
            <img
              *ngIf="
                currentUser?._id == deal?.created_by_rfid ||
                currentUser?._id == deal?.manager_user_id
              "
              (click)="deleteDeal(deal.id)"
              class="px-1 px-md-2 cursor-pointer"
              src="./assets/img/delete-icon.svg"
              alt="delete icon"
            />
            <button
              *ngIf="
                deal.deal_status &&
                !deal.is_gcp &&
                deal.deal_status.toUpperCase() !== 'Submitted'
              "
              (click)="makeOrder(deal.id)"
              class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text"
            >
              Make the Order
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
