import { Group, Permissions } from 'src/shared/Endpoint';
import { BaseModel } from '../mongo/base.model';

export class UserModel extends BaseModel {
  _id: string;
  user_id: number; //TO BE REMOVED
  first_name: string;
  last_name: string;
  user_name: string;
  country: string;
  country_iso2: string;
  country_object: any[] = [];
  phone: string;
  email: string;
  img: string;
  auth_token: string;
  manager_id: object;
  image_url: string;
  imageUrl: string;
  sector: string;
  is_gcp: boolean;
  is_manager: boolean;

  domains_ids = [];
  permissions = [];
  permission_ids = [];
  permission_codes = [];
  permissionsDeleted = [];
  permissionsAdded = [];
  groups = [];
  group_ids: [];
  mainGroup;
  group_names = [];
  existing_group_ids = [];
  domains = [];
  groupsDeleted = [];
  groupsAdded = [];

  isSuspenser: boolean; //TO BE REMOVED

  //
  isExecutor: boolean = false;
  isGPM: boolean = false;
  isEngineer: boolean = false;
  isSales: boolean = false;
  isFinanceTeam: boolean = false;
  isAdmin: boolean = false;
  isOperations: boolean = false;
  canViewAll: boolean = true;
  manager_email: string;
  manager_name: string;
  canAssign: boolean = false;
  isViewerOnly: boolean = false;

  constructor(json?: any) {
    super();
    if (json) {
      this._id = json._id;
      this.user_id = json.user_id;
      this.first_name = json.first_name;
      this.last_name = json.last_name;
      this.user_name = json.user_name;
      this.country = json.country;
      this.country_iso2 = json.country_iso2;
      this.country_object = json.country_object;
      this.phone = json.phone;
      this.email = json.email;
      this.img = json.img;
      this.auth_token = json.auth_token;
      this.manager_id = json.manager_id;
      this.imageUrl = json.imageUrl;
      this.image_url = json.image_url;
      this.sector = json.sector;
      this.is_gcp = json.is_gcp;
      this.is_manager = json.is_manager;
      this.domains_ids = json.domains_ids;
      this.permissions = json.permissions;
      this.permission_ids = json.permission_ids;
      this.permission_codes = json.permission_codes;
      this.permissionsDeleted = json.permissionsDeleted;
      this.permissionsAdded = json.permissionsAdded;
      this.groups = json.groups;
      this.group_ids = json.group_ids;
      this.group_ids = json.group_ids;
      this.mainGroup = json.mainGroup;
      this.group_names = json.group_names;
      this.existing_group_ids = json.existing_group_ids;
      this.domains = json.domains;
      this.groupsDeleted = json.groupsDeleted;
      this.groupsAdded = json.groupsAdded;
      this.isSuspenser = json.isSuspenser;
      this.isExecutor = json.isExecutor;
      this.isGPM = json.isGPM;
      this.isEngineer = json.isEngineer;
      this.isSales = json.isSales;
      this.isOperations = json.isOperations;
      this.isFinanceTeam = json.isFinanceTeam;
      this.isAdmin = json.isAdmin;
      this.canViewAll = json.canViewAll;
      this.canAssign = json.canAssign;
      this.manager_email = json.manager_email;
      this.manager_name = json.manager_name;
      this.isViewerOnly = json.isViewerOnly;
    }
  }

  public getFullName() {
    return this.first_name + ' ' + this.last_name;
  }

  public static setUserInfo(user: UserModel) {
    user.user_name = user.first_name + ' ' + user.last_name;
    user.isExecutor =
      user.permissions.find(
        (x) =>
          x.code &&
          x.code.toLowerCase() == Permissions.FINAL_EXECUTION.toLowerCase()
      ) != undefined;
    user.isGPM =
      user.groups?.find((x) => x.name && x.name.toLowerCase() == Group.GPM) !=
      undefined;
    user.isFinanceTeam =
      user.groups?.find(
        (x) => x.name && x.name.toLowerCase() == Group.Finance
      ) != undefined;
    user.isAdmin =
      user.groups?.find((x) => x.name && x.name.toLowerCase() == Group.Admin) !=
      undefined;
    user.isEngineer =
      user.groups?.find(
        (x) => x.name && x.name.toLowerCase().indexOf('engineer') >= 0
      ) != undefined;
    user.isSales =
      user.groups?.find(
        (x) =>
          x.name &&
          (x.name.toLowerCase() == Group.Sale ||
            x.name.toLowerCase() == Group.Sale_Manager)
      ) != undefined;
    user.isOperations =
      user.groups?.find(
        (x) => x.name && x.name.toLowerCase() == Group.OPERATIONS
      ) != undefined;
    user.canViewAll =
      user.permissions.find(
        (x) =>
          x.code &&
          x.code.toLowerCase() == Permissions.can_view_all.toLowerCase()
      ) != undefined ||
      user.groups.find((x) => x.name && x.name.toLowerCase() == Group.Admin) !=
        undefined ||
      user.groups.find((x) => x.name && x.name.toLowerCase() == Group.GPM) !=
        undefined ||
      user.groups.find(
        (x) => x.name && x.name.toLowerCase() == Group.Finance
      ) != undefined;
    user.canAssign =
      user.permissions.find(
        (x) =>
          x.code && x.code.toLowerCase() == Permissions.can_assign.toLowerCase()
      ) != undefined;
    user.isViewerOnly =
      user.permissions.find(
        (x) =>
          x.code && x.code.toLowerCase() == Permissions.VIEWER.toLowerCase()
      ) != undefined;
    if (user.isViewerOnly) {
      user.canViewAll = true;
    }
  }
}
