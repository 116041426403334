<form
  [formGroup]="orderForm"
  role="form"
  #formDir="ngForm"
  id="view-order-form"
  novalidate
  class="max-h-70 w-100 fluid-section d-flex flex-column flex-xl-row justify-content-xl-between ng-untouched ng-pristine ng-invalid gap-3"
>
  <div class="col-12 col-xl-6 pl-0" id="order-form-section1">
    <div class="mb-2 mt-1 d-flex align-items-center justify-content-between">
      <a target="_blank"
        (click)="viewCustomerServices()"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline cursor-pointer"
        >Domain</a
      >
      <div class="col-10">
        <input
          type="text"
          type="url"
          class="form-control rounded-pill"
          id="domain"
          formControlName="customer_domain"
          placeholder="Domain"
        />
      </div>
    </div>

    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="admin-name"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Email</label
      >
      <div class="col-10">
        <input
          type="email"
          class="form-control rounded-pill my_disabled_input"
          id="admin-name"
          formControlName="customer_email"
          placeholder="Email"
        />
      </div>
    </div>
    <div
      *ngIf="model.domain && model.domain.auth_token"
      class="mb-2 d-flex align-items-center justify-content-between"
    >
      <label
        for="transfer-token"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Transfer Token</label
      >
      <div class="col-10">
        <input
          type="email"
          class="form-control rounded-pill my_disabled_input"
          id="transfer-token"
          formControlName="auth_token"
          placeholder="Transfer Token"
        />
      </div>
    </div>
    <div
      *ngIf="model.is_gcp == true"
      class="mb-2 d-flex align-items-center justify-content-between"
    >
      <label
        for="source"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Engagement</label
      >
      <ng-select
        bindLabel="eng"
        appendTo="body"
        [searchable]="true"
        class="p-0 form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && engagement.invalid
        }"
        formControlName="engagement"
        placeholder="Engagement"
      >
        <ng-option [value]="eng" *ngFor="let eng of engagements">
          {{ eng }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="order-description"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline border-raduis-16"
        >Order Description</label
      >
      <div class="col-10">
        <textarea
          class="form-control rounded-4 border-raduis-xl-1rem"
          formControlName="description"
          placeholder="Order Description"
          [ngClass]="{
            'is-invalid': formDir.submitted && description.invalid
          }"
        ></textarea>
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="order-id"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Order ID</label
      >
      <div class="col-10">
        <input
          type="text"
          formControlName="id"
          [ngClass]="{ 'is-invalid': formDir.submitted && id.invalid }"
          class="form-control rounded-pill"
          placeholder="Order ID"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="customer-po"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Customer P.O. #</label
      >
      <div class="col-10">
        <input
          type="text"
          formControlName="po_id"
          [ngClass]="{ 'is-invalid': formDir.submitted && po_id.invalid }"
          class="form-control rounded-pill"
          placeholder="Customer P.O.#"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="sale-invoice"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Sale Invoice #</label
      >
      <div
        class="col-9"
        [ngClass]="{
          'col-9': odooIntegrationEnabled,
          'col-10': !odooIntegrationEnabled
        }"
      >
        <input
          type="text"
          formControlName="order_sale_invoice_nbr"
          [ngClass]="{
                'is-invalid':
                  formDir.submitted && order_sale_invoice_nbr.invalid,
              }"
          class="form-control rounded-pill"
          placeholder="Sale Invoice #"
        />
      </div>
      <div class="col-1 ic_check_integration" *ngIf="odooIntegrationEnabled">
        <img src="./assets/img/help-icon.svg" (click)="checkOdooInvoice()" />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="source"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Source</label
      >
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="p-0 form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && opportunity_source.invalid
        }"
        formControlName="opportunity_source"
        placeholder="Source"
      >
        <ng-option [value]="source" *ngFor="let source of opportunitySources">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="opportunity"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Opportunity #</label
      >
      <div class="col-10">
        <input
          type="text"
          formControlName="opportunity_nbr"
          [ngClass]="{
            'is-invalid': formDir.submitted && opportunity_nbr.invalid
          }"
          class="form-control rounded-pill"
          placeholder="Opportunity #"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="opportunity-status"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Opportunity Status</label
      >
      <ng-select
        bindLabel="status"
        appendTo="body"
        [searchable]="true"
        class="p-0 form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && opportunity_status.invalid
        }"
        formControlName="opportunity_status"
        placeholder="Opportunity Status"
      >
        <ng-option [value]="status" *ngFor="let status of opportunityStatus">
          {{ status }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="google-rep"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Google Rep</label
      >
      <div class="col-10">
        <input
          type="text"
          class="form-control rounded-pill"
          formControlName="google_rep"
          placeholder="Google Rep"
        />
      </div>
    </div>
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="company_section"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Company Section</label
      >
      <ng-select
        id="company_section"
        bindLabel="section"
        appendTo="body"
        [searchable]="true"
        class="p-0 flex-fill form-select rounded-pill"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && company_section.invalid
        }"
        formControlName="company_section"
        placeholder="Company Section"
      >
        <ng-option [value]="section" *ngFor="let section of companySections">
          {{ section }}
        </ng-option>
      </ng-select>
    </div>
    <!-- <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="customer-email"
            class="form-label text-wrap col-2  mb-0 d-none d-md-inline"
            >Customer Email to notify on execution</label
          >
          <input
            type="email"
            class="form-control rounded-pill col-10"
            formControlName="customer_email_notification"
            placeholder="Customer Email to notify on execution"
          />
        </div> -->
    <div
      class="mb-2 d-flex align-items-center justify-content-between"
      *ngIf="model.is_gcp == true"
    >
      <label
        for="gcp_credits_coupon"
        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
        >Credits Coupon</label
      >
      <input
        type="text"
        [ngClass]="{
          'is-invalid': formDir.submitted && gcp_credits_coupon.invalid
        }"
        [ngClass]="{
          my_disabled_input: !currentUser.isGPM
        }"
        class="form-control rounded-pill"
        formControlName="gcp_credits_coupon"
        placeholder="GCP Credits Coupon"
      />
    </div>

    <div class="row g-1 mb-2" *ngIf="model.is_gcp == true">
      <div class="col-2">Used</div>
      <div class="col-2">
        <div class="form-check">
          <input
            [ngClass]="{
              my_disabled_input: !currentUser.isFinanceTeam
            }"
            formControlName="used"
            class="form-check-input"
            type="radio"
            [value]="true"
            name="used"
            id="used1"
          />
          <label class="form-check-label" for="used1"> Yes </label>
        </div>
        <div class="form-check">
          <input
            formControlName="used"
            class="form-check-input"
            type="radio"
            [ngClass]="{
              my_disabled_input: !currentUser.isFinanceTeam
            }"
            [value]="false"
            name="used"
            id="used2"
          />
          <label class="form-check-label" for="used2"> No </label>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-xl-6">
    <div class="row g-1 mb-2" *ngIf="orderAction">
      <label
        for="action"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Action</label
      >
      <div class="col-9">
        <p class="form-control rounded-pill col-9 h-fit-content">
          {{ orderAction }}
        </p>
      </div>
    </div>
    <div class="row g-1 mb-2">
      <label
        for="created_user_email"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Created By</label
      >
      <div class="col-9">
        <label class="form-control rounded-pill col-9"
          >{{ model.user?.created_user_email }} at
          {{ model.created_at | date : "yyyy-MM-dd hh:mm" }}</label
        >
      </div>
    </div>
    <div
      class="row g-1 mb-2"
      *ngIf="
        model.approval_phases &&
        model.approval_phases.length > 0 &&
        model.approval_phases[0].date &&
        (model.approval_phases[0].email ||
          model.approval_phases[0].approval_email)
      "
    >
      <label
        for="created_user_email"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Approved By</label
      >
      <div class="col-9">
        <div class="form-control rounded-pill col-9 h-fit-content">
          <ng-container *ngFor="let user of model.approval_phases">
            <div *ngIf="user.email && user.date">
              {{ user.email }} at
              {{
                user.string_date
                  ? user.string_date
                  : (user.date | date : "yyyy-MM-dd hh:mm")
              }}
            </div>
            <div *ngIf="user.approval_email && user.approved_date">
              {{ user.approval_email }} at
              {{ user.approved_date | date : "yyyy-MM-dd hh:mm" }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div
      class="row g-1 mb-2"
      *ngIf="model.rejecter_email && model.reject_reason"
    >
      <label
        for="created_user_email"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Rejection Reason</label
      >
      <div class="col-9">
        <label class="form-control rounded-pill col-9"
          >{{ model.reject_reason }},rejected by
          {{ model.rejecter_email }}</label
        >
      </div>
    </div>
    <div class="row g-1 mb-2 col-12" *ngIf="model.is_gcp == true">
      <label
        for="customer-email"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Project Number</label
      >
      <div class="col-9">
        <input
          type="text"
          class="form-control rounded-4"
          formControlName="project_number"
          [ngClass]="{
            'is-invalid': formDir.submitted && project_number.invalid
          }"
          placeholder="Project Number"
        />
      </div>
    </div>
    <div class="row g-1 mb-2 col-12" *ngIf="model.is_gcp == true">
      <label
        for="customer-email"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Project Name</label
      >
      <div class="col-9">
        <input
          type="text"
          class="form-control rounded-4"
          formControlName="project_name"
          [ngClass]="{
            'is-invalid': formDir.submitted && project_name.invalid
          }"
          placeholder="Project Name"
        />
      </div>
    </div>
    <div class="row g-1 mb-2 col-12" *ngIf="model.is_gcp == true">
      <label
        for="customer-email"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Billing Name</label
      >
      <div class="col-9">
        <input
          type="text"
          class="form-control rounded-4"
          formControlName="billing_name"
          [ngClass]="{
            'is-invalid': formDir.submitted && billing_name.invalid
          }"
          placeholder="Billing ID"
        />
      </div>
    </div>

    <ng-container *ngIf="!model.is_gcp && model.sku" formArrayName="sku">
      <div
        class="accordion accordion-flush pr-4 col-11"
        id="sku-container"
        *ngFor="let skuModel of sku.controls; let index = index"
      >
        <div class="accordion-item border-0 mb-3">
          <div class="accordion-header">
            <div
              class="real-accordion-button d-flex flex-column border border-primary rounded-5 px-3 py-2"
            >
              <div class="row g-1 mb-2">
                <div
                  [formGroupName]="index"
                  class="align-items-center col-10 d-flex flex-row justify-content-between ng-invalid ng-pristine ng-untouched"
                >
                  <label
                    class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
                    >SKU</label
                  >
                  <ng-select
                    bindLabel="product.sku_name"
                    appendTo="body"
                    [searchable]="true"
                    [clearable]="false"
                    class="col-10 p-0"
                    (change)="calculatePrices(skuModel)"
                    formControlName="sku_id"
                    placeholder="SKU"
                  >
                    <ng-option
                      [value]="product.sku_id"
                      *ngFor="let product of googleProducts"
                    >
                      {{ product.sku_name }}
                    </ng-option>
                  </ng-select>
                </div>
                <img
                  (click)="deleteSKU(skuModel.value.sku_id)"
                  class="px-1 px-md-2 cursor-pointer btn_sku_delete"
                  src="./assets/img/delete-icon.svg"
                  alt="delete icon"
                />
                <!-- <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-label="expand icon"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    class="accordion-button cursor-pointer p-1"
                  ></button> -->
                <button
                  type="button"
                  class="btn btn-toggle btn_sku_toggle"
                  [class.active]="skuModel.value.expanded"
                  (click)="expandSku(skuModel.value.sku_id)"
                >
                  <img
                    [src]="
                      skuModel.value.expanded
                        ? './assets/img/expanded-icon.svg'
                        : './assets/img/expand-icon.svg'
                    "
                    alt="Toggle Image"
                    class="img-fluid"
                  />
                </button>
              </div>
              <div *ngIf="skuModel.value.expanded">
                <div
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="sku-container"
                >
                  <div class="accordion-body p-0 mt-2">
                    <div [formGroupName]="index" class="row g-1 mb-2">
                      <label
                        for="licenses-number"
                        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
                        >Number of Licenses</label
                      >
                      <div class="col-10">
                        <input
                          type="number"
                          min="1"
                          (change)="calculatePrices(skuModel)"
                          class="rounded-pill form-control"
                          formControlName="license"
                        />
                      </div>
                    </div>
                    <div class="row g-1 mb-2">
                      <div class="col-2">Google Offer</div>
                      <div class="col-2">
                        <div class="form-check" [formGroupName]="index">
                          <input
                            formControlName="with_google_offer"
                            class="form-check-input"
                            type="radio"
                            [ngClass]="{
                              my_disabled_input: !currentUser.isExecutor
                            }"
                            [value]="true"
                            (change)="calculatePrices(skuModel)"
                            name="with_google_offer"
                            id="google-offer-1"
                          />
                          <label
                            class="form-check-label"
                            for="google-offer-1"
                            [ngStyle]="{
                              'background-color': skuModel.get(
                                'with_google_offer'
                              ).value
                                ? 'yellow'
                                : ''
                            }"
                          >
                            Yes
                          </label>
                        </div>
                        <div class="form-check" [formGroupName]="index">
                          <input
                            formControlName="with_google_offer"
                            class="form-check-input"
                            type="radio"
                            (change)="calculatePrices(skuModel)"
                            [value]="false"
                            [ngClass]="{
                              my_disabled_input: !currentUser.isExecutor
                            }"
                            name="with_google_offer"
                            id="google-offer-2"
                            checked
                          />
                          <label class="form-check-label" for="google-offer-2">
                            No
                          </label>
                        </div>
                      </div>
                      <div
                        [formGroupName]="index"
                        class="mb-2 d-flex align-items-center justify-content-between col"
                      >
                        <label
                          for="discount-percentage"
                          class="form-label text-nowrap col-3 mb-0 d-none d-md-inline"
                          >Discount %</label
                        >
                        <div class="col-8">
                          <input
                            formControlName="google_offer"
                            type="number"
                            min="0"
                            [ngClass]="{
                              my_disabled_input: !currentUser.isExecutor
                            }"
                            (change)="calculatePrices(skuModel)"
                            class="form-control rounded-pill"
                            placeholder="0"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row g-1 mb-2">
                      <div class="col-2">Customer Offer</div>
                      <div class="col-2">
                        <div class="form-check" [formGroupName]="index">
                          <input
                            class="form-check-input"
                            type="radio"
                            formControlName="with_customer_offer"
                            name="with_customer_offer"
                            [value]="true"
                            [ngClass]="{
                              my_disabled_input: !currentUser.isExecutor
                            }"
                            (change)="calculatePrices(skuModel)"
                            id="google-offer-21"
                          />
                          <label class="form-check-label" for="google-offer-21">
                            Yes
                          </label>
                        </div>
                        <div class="form-check" [formGroupName]="index">
                          <input
                            formControlName="with_customer_offer"
                            class="form-check-input"
                            type="radio"
                            [ngClass]="{
                              my_disabled_input: !currentUser.isExecutor
                            }"
                            (change)="calculatePrices(skuModel)"
                            name="with_customer_offer"
                            id="google-offer-22"
                            [value]="false"
                            checked
                          />
                          <label class="form-check-label" for="google-offer-22">
                            No
                          </label>
                        </div>
                      </div>
                      <div
                        [formGroupName]="index"
                        class="mb-2 d-flex align-items-center justify-content-between col"
                      >
                        <label
                          for="discount-percentage"
                          class="form-label text-nowrap col-3 mb-0 d-none d-md-inline"
                          >Discount %</label
                        >
                        <div class="col-8">
                          <input
                            type="number"
                            min="0"
                            [ngClass]="{
                              my_disabled_input: !currentUser.isExecutor
                            }"
                            (change)="calculatePrices(skuModel)"
                            formControlName="customer_offer"
                            class="form-control rounded-pill"
                            placeholder="0"
                          />
                        </div>
                      </div>
                      <div class="row g-1 mb-2" [formGroupName]="index">
                        <div class="col-2">Plan</div>
                        <ng-select
                          bindLabel="plan.name"
                          appendTo="body"
                          [searchable]="true"
                          [clearable]="false"
                          (change)="calculatePrices(skuModel)"
                          class="p-0 col-10"
                          formControlName="plan_id"
                          placeholder="Plan"
                          [ngClass]="{
                            'is-invalid':
                              formDir.submitted &&
                              sku.controls[index].get('plan_id').invalid
                          }"
                        >
                          <ng-option
                            [value]="plan._id"
                            *ngFor="let plan of plans"
                          >
                            {{ plan.name }}
                          </ng-option>
                        </ng-select>
                        <!-- <div
                          *ngFor="let plan of plans"
                          class="form-check form-check-inline col"
                          [formGroupName]="index"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            [(ngModel)]="test"
                            formControlName="plan_id"
                          />
                          <label class="form-check-label" for="annual">{{
                            plan.code
                          }}</label>
                        </div> -->
                      </div>
                      <div
                        [formGroupName]="index"
                        class="mb-3 d-flex align-items-center"
                      >
                        <label
                          for="start-date"
                          class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                          >Start Date</label
                        >
                        <div class="col-10">
                          <input
                            [ngClass]="{
                              'is-invalid':
                                formDir.submitted &&
                                sku.controls[index].get('start_date').invalid
                            }"
                            type="date"
                            (ngModelChange)="calculatePrices(skuModel)"
                            formControlName="start_date"
                            class="form-control rounded-pill"
                          />
                        </div>
                      </div>
                      <div
                        [formGroupName]="index"
                        class="mb-2 d-flex align-items-center"
                      >
                        <label
                          for="end-date"
                          class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                          >End Date</label
                        >
                        <div class="col-10">
                          <input
                            type="date"
                            [ngClass]="{
                              'is-invalid':
                                formDir.submitted &&
                                sku.controls[index].get('end_date').invalid
                            }"
                            (ngModelChange)="calculatePrices(skuModel)"
                            formControlName="end_date"
                            class="form-control rounded-pill"
                          />
                        </div>
                      </div>
                      <div
                        [formGroupName]="index"
                        class="d-flex py-3 input_price"
                      >
                        <div class="col px-0">Selling Price</div>
                        <div class="col-1">
                          <input
                            [ngClass]="{
                              'pointer-events-none': !currentUser.isExecutor
                            }"
                            type="text"
                            formControlName="selling_price"
                            class="form-control text-center product-sans-bold text-primary px-0"
                          />
                        </div>
                        <span>$</span>
                      </div>
                      <div
                        class="d-flex py-3 input_price"
                        [formGroupName]="index"
                      >
                        <div class="col px-0">Cost Price</div>
                        <div class="col-1">
                          <input
                            [ngClass]="{
                              'pointer-events-none': !currentUser.isExecutor
                            }"
                            type="text"
                            formControlName="cost_price"
                            class="form-control text-center product-sans-bold text-primary px-0"
                          />
                        </div>
                        <span>$</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <button
      *ngIf="!model.is_gcp && !isNewCustomer"
      type="button"
      class="btn btn-outline-primary rounded-pill"
      (click)="addAnotherSKU()"
    >
      <img src="./assets/img/plus-icon.svg" alt="plus-icon" class="pe-2" />
      <span>Add another SKU</span>
    </button>

    <ng-container *ngIf="model.is_gcp == true" formArrayName="sku">
      <div
        class="accordion accordion-flush pr-4 col-11"
        id="sku-container"
        *ngFor="let skuModel of sku.controls; let index = index"
      >
        <div class="accordion-item border-0 mb-3">
          <div class="accordion-header">
            <div
              class="real-accordion-button d-flex flex-column border border-primary rounded-5 px-3 py-2"
            >
              <div class="row g-1 mb-2">
                <div
                  [formGroupName]="index"
                  class="align-items-center col-10 d-flex flex-row justify-content-between ng-invalid ng-pristine ng-untouched"
                >
                  <label
                    class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
                    >SKU</label
                  >
                  <ng-select
                    bindLabel="product.sku_name"
                    appendTo="body"
                    [searchable]="true"
                    [clearable]="false"
                    class="col-10 p-0"
                    [ngClass]="{
                      'is-invalid':
                        formDir.submitted &&
                        sku.controls[index].get('sku_id').invalid
                    }"
                    (change)="calculatePrices(skuModel)"
                    formControlName="sku_id"
                    placeholder="SKU"
                  >
                    <ng-option
                      [value]="product.sku_id"
                      *ngFor="let product of googleProducts"
                    >
                      {{ product.sku_name }}
                    </ng-option>
                  </ng-select>
                </div>
                <img
                  (click)="deleteSKU(skuModel.value.sku_id)"
                  class="px-1 px-md-2 cursor-pointer btn_sku_delete"
                  src="./assets/img/delete-icon.svg"
                  alt="delete icon"
                />
                <button
                  type="button"
                  class="btn btn-toggle btn_sku_toggle"
                  [class.active]="skuModel.value.expanded"
                  (click)="expandSku(skuModel.value.sku_id)"
                >
                  <img
                    [src]="
                      skuModel.value.expanded
                        ? './assets/img/expanded-icon.svg'
                        : './assets/img/expand-icon.svg'
                    "
                    alt="Toggle Image"
                    class="img-fluid"
                  />
                </button>
              </div>
              <div *ngIf="skuModel.value.expanded">
                <div
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="sku-container"
                >
                  <div class="accordion-body p-0 mt-2">
                    <div [formGroupName]="index" class="d-flex py-3">
                      <div
                        class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
                      >
                        Amount
                      </div>
                      <div class="col-9">
                        <input
                          type="number"
                          min="1"
                          [ngClass]="{
                            'is-invalid':
                              formDir.submitted &&
                              sku.controls[index].get('amount').invalid
                          }"
                          (change)="calculatePrices(skuModel)"
                          class="rounded-pill form-control"
                          formControlName="amount"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isNewCustomer == true && model.domain">
      <div class="row g-1 mb-2 col-12">
        <label
          for="customer-email"
          class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
          >Contact Person Email</label
        >
        <div class="col-9">
          <input
            type="text"
            readonly
            class="form-control rounded-4"
            value="{{ model.domain.contact_person_email }}"
          />
        </div>
      </div>
      <div class="row g-1 mb-2 col-12">
        <label
          for="customer-email"
          class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
          >Contact Person</label
        >
        <div class="col-9">
          <input
            readonly
            type="text"
            class="form-control rounded-4"
            value="{{ model.domain.contact_person_name }}"
          />
        </div>
      </div>
      <div class="row g-1 mb-2 col-12" *ngIf="model.domain.country">
        <label
          for="customer-email"
          class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
          >Country</label
        >
        <div class="col-9">
          <input
            readonly
            type="text"
            class="form-control rounded-4"
            value="{{ model.domain.country.name }}"
          />
        </div>
      </div>
    </ng-container>

    <div
      class="d-flex align-items-start justify-content-center justify-content-xl-end mt-xl-2 pt-xl-4 pb-2 me-3 product-sans-light"
    >
      <input
        type="submit"
        *ngIf="canApprove"
        class="btn btn-primary small-text btn-color rounded-pill col-5 col-xl-2 p-1 p-xl-2 mx-1 fs-6 align-self-end"
        value="{{ approvalText }}"
        (click)="saveOrder()"
      />
      <input
        type="submit"
        *ngIf="canEdit"
        class="btn btn-primary small-text btn-color rounded-pill col-5 col-xl-2 p-1 p-xl-2 mx-1 fs-6 align-self-end"
        value="Edit"
        (click)="editOrder()"
      />
      <input
        type="submit"
        *ngIf="currentUser._id == model.created_by_rfid && model.draft == true"
        class="btn btn-primary small-text btn-color rounded-pill col-5 col-xl-3 p-1 p-xl-2 mx-1 fs-6 align-self-end"
        value="Send For Approval"
        (click)="sendDraftOrderForApproval()"
      />
      <input
        *ngIf="canReject"
        class="btn btn-primary small-text btn-color rounded-pill col-5 col-xl-2 p-1 p-xl-2 mx-1 fs-6 align-self-end"
        value="{{ rejectText }}"
        (click)="showRejectConfirmationPopup()"
      />
      <div
        id="place_as_menus"
        class="btn-group col-5 col-xl-3 align-self-end mx-1 p-0 h-40"
        *ngIf="canPlaceIt"
      >
        <button
          type="button"
          data-popper-placement="top-start"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          class="btn btn-primary dropdown-toggle btn-color rounded-pill ps-4 text-start"
        >
          Place it as
        </button>
        <ul class="dropdown-menu">
          <li>
            <a
              (click)="placeOrder('Offline Order')"
              class="dropdown-item text-white"
              >Offline Order</a
            >
          </li>
          <li>
            <a
              (click)="placeOrder('Order with Offer')"
              class="dropdown-item text-white"
              >Order with Offer</a
            >
          </li>
          <li>
            <a
              (click)="placeOrder('Renewal Flex')"
              class="dropdown-item text-white"
              >Renewal Flex</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>
<!-- </div>
</div> -->

<!-- REJECTION CONFIRMATION POPUP -->
<div
  class="modal fade"
  bsModal
  #rejectionConfirmationModal="bs-modal"
  tabindex="-1"
  id="rejectionConfirmationModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="rejectionConfModalLabel"
        >
          Reject the Order
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="rejectionConfirmationModal.hide()"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h5 text-center">
          Are you sure you want to Reject this order?
        </h2>
        <!--   <div class="form-check mt-3">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="do-not-show-message"
          />
      <label
            class="form-check-label smaller-text"
            for="do-not-show-message"
          >
            Do not show this message again
          </label>
        </div> -->
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="showRejectReasonPopup()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="rejectionConfirmationModal.hide()"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- REJECTION REASONS POPUP -->
<div
  class="modal fade"
  bsModal
  #rejectionReasonModal="bs-modal"
  tabindex="-1"
  id="rejectionReasonModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="rejectionReasonModalLabel"
        >
          Order Rejection Reason
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="rejectionReasonModal.hide()"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h6">Please Select a reason for rejecting this order</h2>
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <label
            for="reason"
            class="form-label text-nowrap mb-0 d-none d-md-inline"
            >Reason</label
          >
          <ng-select
            id="reason"
            *ngIf="!selectOthers"
            bindLabel="rs"
            appendTo="body"
            [searchable]="true"
            class="col-10 p-0"
            (change)="selectRejectionReason()"
            [(ngModel)]="rejectReason"
            [clearable]="true"
            placeholder="Reason"
          >
            <ng-option [value]="rs" *ngFor="let rs of rejectionReasons">
              {{ rs }}
            </ng-option>
          </ng-select>
          <input
            *ngIf="selectOthers"
            class="col-9 form-control rounded-4"
            [(ngModel)]="rejectReason"
            placeholder="Add Reason"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="rejectOrder()"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ODOO COMPARISON POPUP -->
<div
  class="modal fade"
  bsModal
  #odooComparisonModal="bs-modal"
  tabindex="-1"
  id="odooComparisonModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="odooComparisonModalLabel"
        >
          Odoo System Comparison
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="odooComparisonModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <table>
          <thead>
            <tr>
              <td>Odoo</td>
              <td>Reseller</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><b>SKU</b>Starter</td>
              <td>Starter</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
