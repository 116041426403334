import { HttpRequestService } from 'src/servcies/http-request-service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RequestResultModel } from 'src/models/request_result.model';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { Seats, SubscriptionModel } from 'src/models/subcription.model';
import { Page } from 'src/models/page';
import { PagedData } from 'src/models/paged-data';
import { DatePipe } from '@angular/common';
import { retry } from 'rxjs/operators';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';
import { SubscriptionService } from 'src/servcies/api/subscription.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class ReportCustomersComponent implements OnInit {
  resellerApiToken: string;
  static customersDataReport = [];
  static subDataReport = [];
  static _http: HttpClient;
  static _httpRequestService: HttpRequestService;
  static _datePipe: DatePipe;
  static _subscriptionService: SubscriptionService;
  static cancelled: boolean;
  static allData = [];
  static currentUser: UserModel;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static pagination: number = 1;
  static total: number = 1;

  constructor(
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private datePipe: DatePipe,
    private localStorage: LocalStorageService,
    private subscriptionService: SubscriptionService
  ) {
    ReportCustomersComponent._http = http;
    ReportCustomersComponent._httpRequestService = httpRequestService;
    ReportCustomersComponent._datePipe = datePipe;
    ReportCustomersComponent._subscriptionService = subscriptionService;
  }

  async ngOnInit(): Promise<void> {
    ReportCustomersComponent.currentUser = JSON.parse(
      this.localStorage.getLoggedinUser()
    );
    ReportCustomersComponent.getReportData(false, false);
  }

  static async getReportData(
    fromSearchpart: boolean = false,
    toExport: boolean = false
  ) {
    ReportCustomersComponent.subDataReport = [];
    var dbOffset = -1;
    if (!toExport)
      dbOffset =
        (ReportCustomersComponent.pagination - 1) *
        ReportCustomersComponent.pageSize;
    await this.getCUstomers(dbOffset)
      .then(async (resp) => {
        if (resp && resp.customers) {
          ReportCustomersComponent.total = <number>resp['total'];
          var customers = resp.customers;
          await ReportCustomersComponent.getSubscriptions(
            customers,
            fromSearchpart,
            toExport
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static async getSubscriptions(
    customers: any[],
    fromSearchpart: boolean,
    toExport: boolean
  ) {
    var sub = [];

    for (var i = 0; i < customers.length; i++) {
      if (!toExport) {
        if (ReportCustomersComponent.cancelled && !fromSearchpart) {
          this.cancelled = false;
          break;
        }
        if (ReportCustomersComponent.cancelled && fromSearchpart) {
          this.cancelled = false;
        }
      }
      var customer = customers[i];
      var customerInfo = {
        domain: customer.domain,
        organisation_name: customer.organisation_name,
        totalNbrOfUsers: 0,
        subscriptions: [],
        details: '',
      };
      if (!Utilities.isNullOrEmpty(customer.customer_api_id)) {
        var prs = new HttpParams().set(Unicode.WITHOUT_LOADER, '1');
        var customer_api_id: string = customer.customer_api_id;
        await ReportCustomersComponent._subscriptionService
          .requestSubscriptions(customer_api_id, prs, '1')
          .then((resp: any) => {
            if (resp) {
              var subs: SubscriptionModel[] = resp ?? [];
              customerInfo.totalNbrOfUsers = subs.reduce(
                (accumulator, current) =>
                  accumulator +
                  ((current.plan?.planName ?? '') == Unicode.FLEXIBLE_PLAN ||
                  (current.plan?.planName ?? '') == Unicode.TRIAL_PLAN
                    ? current.seats?.maximumNumberOfSeats ?? 0
                    : current.seats?.numberOfSeats ?? 0),
                0
              );
              customerInfo.subscriptions = subs;
              var details = '';
              subs.forEach((x) => {
                var seats;
                if (
                  x.seats &&
                  Utilities.isNullOrEmpty(x.seats.licensedNumberOfSeats)
                )
                  x.seats.licensedNumberOfSeats = 0;
                if (
                  x.seats &&
                  Utilities.isNullOrEmpty(x.seats.maximumNumberOfSeats)
                )
                  x.seats.maximumNumberOfSeats = 0;
                if (x.seats && Utilities.isNullOrEmpty(x.seats.numberOfSeats))
                  x.seats.numberOfSeats = 0;
                if (
                  (x.plan?.planName ?? '') == Unicode.FLEXIBLE_PLAN ||
                  (x.plan?.planName ?? '') == Unicode.TRIAL_PLAN
                )
                  seats =
                    Utilities.displayCheck(x.seats?.licensedNumberOfSeats) +
                    '/' +
                    Utilities.displayCheck(x.seats?.maximumNumberOfSeats);
                else
                  seats =
                    Utilities.displayCheck(x.seats?.licensedNumberOfSeats) +
                    '/' +
                    Utilities.displayCheck(x.seats?.numberOfSeats);
                details +=
                  x.skuName +
                  ' ,' +
                  x.plan?.planName +
                  ' ,' +
                  seats +
                  (x.plan?.commitmentInterval &&
                  x.plan.commitmentInterval.endTime
                    ? ' ,' +
                      ReportCustomersComponent._datePipe.transform(
                        new Date(
                          Number.parseInt(x.plan.commitmentInterval.endTime)
                        ),
                        'yyyy-MM-dd'
                      )
                    : '') +
                  (x.renewalSettings?.renewalType
                    ? ' ,' + x.renewalSettings.renewalType
                    : '');
                if (toExport) details += '    ';
                else details += '<br>';
              });
              customerInfo.details = details;
            }
          });
        if (!toExport) {
          //  sub.push(customerInfo);
          ReportCustomersComponent.subDataReport.push(customerInfo);
        } else {
          ReportCustomersComponent.allData.push(customerInfo);
        }
      }
    }
  }

  public async search() {
    ReportCustomersComponent.cancelled = true;
    ReportCustomersComponent.pagination = 1;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    ReportCustomersComponent.getReportData(true, false);
  }

  static async getCUstomers(offset: number) {
    var prs = new HttpParams();
    if (offset >= 0) {
      prs = prs
        .set(Unicode.LIMIT, ReportCustomersComponent.pageSize + '')
        .set(Unicode.PAGE, offset + '');
    }
    prs = prs.set(Unicode.GWS_ONLY, true);
    var customerSearchvalue = (<HTMLInputElement>(
      document.getElementById('txt-cust-orders-search')
    ))?.value;
    if (!Utilities.isNullOrEmpty(customerSearchvalue))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearchvalue);
    if (!ReportCustomersComponent.currentUser.canViewAll) {
      prs = prs.set(Unicode.VIEW_ALL, '0');
    } else {
      prs = prs.set(Unicode.VIEW_ALL, '1');
    }
    return await this._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.CUSTOMER_LIST, {
        params: prs,
      })
      .toPromise();
  }

  get GetData() {
    return ReportCustomersComponent.subDataReport;
  }

  renderPage(event: number) {
    ReportCustomersComponent.pagination = event;
    ReportCustomersComponent.getReportData(false, false);
  }

  async exportReport() {
    alert(
      'Exporting data for all customers will take time.\nYou will receive an email with the sheet link once completed'
    );
    var prs = new HttpParams().set(Unicode.WITHOUT_LOADER, '1');
    var customerSearchvalue = (<HTMLInputElement>(
      document.getElementById('txt-cust-orders-search')
    ))?.value;
    if (!Utilities.isNullOrEmpty(customerSearchvalue))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearchvalue);
    if (!ReportCustomersComponent.currentUser.canViewAll) {
      prs = prs.set(Unicode.VIEW_ALL, '0');
    } else {
      prs = prs.set(Unicode.VIEW_ALL, '1');
    }
    await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.EXPORT_ALL_CUSTOMERS, {
        params: prs,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp && resp.spreadsheetUrl) window.open(resp.spreadsheetUrl);
      })
      .catch((e) => {});
  }

  get GetTotalCount() {
    return ReportCustomersComponent.total;
  }
  get GetPagination() {
    return ReportCustomersComponent.pagination;
  }
  get GetPageSize() {
    return ReportCustomersComponent.pageSize;
  }
}
