import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddBillingAccountRoutingModule } from './add-billing-account-routing.module';
import { AddBillingAccountComponent } from './add-billing-account.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddBillingAccountComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
  ],
  exports: [AddBillingAccountComponent],
})
export class AddBillingAccountModule {}
