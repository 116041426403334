import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddBudgetFormRoutingModule } from './add-budget-form-routing.module';
import { AddBudgetFormComponent } from './add-budget-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [AddBudgetFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    ModalModule.forRoot(),
  ],
  exports: [AddBudgetFormComponent],
})
export class AddBudgetFormModule {}
