<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Login Page</title>
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
      crossorigin="anonymous"
    />
    <script
      src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js"
      integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3"
      crossorigin="anonymous"
    ></script>
    <script
      src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js"
      integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V"
      crossorigin="anonymous"
    ></script>
    <link rel="stylesheet" href="login.component.scss" />
  </head>
  <body
    class="container-fluid py-5 main-bg d-flex flex-column align-items-center justify-content-between"
  >
    <main
      class="container col-9 bg-white rounded-4 d-flex flex-column flex-lg-row p-3 p-lg-5 mt-auto login-div"
    >
      <div
        class="col-10 col-md-8 col-lg-6 d-flex flex-column justify-content-evenly mx-auto"
      >
        <h1 class="h1 col-lg-8 product-sans-thin text-center text-lg-start">
          Welcome to <span class="product-sans-bold">Reseller App</span>
        </h1>
        <img
          src="./assets/img/login-image.png"
          alt="Login image"
          class="img-fluid d-inline d-lg-none w-75 mx-auto"
        />
        <section class="text-center text-lg-start mt-3">
          <h2 class="h2 mb-3">Sign in</h2>
          <h3 class="h3 fs-5 product-sans-light mb-4">
            Simply sign in using your Google Account
          </h3>
          <a type="button" id="customBtn">
            <img src="./assets/img/google-icon.svg" alt="google icon" />
            <span
              class="ps-3 product-sans-light text-white text-decoration-none"
              >Sign in with Google</span
            >
          </a>
        </section>
      </div>
      <img
        src="./assets/img/login-image.png"
        alt="Login image"
        class="img-fluid w-50 d-none d-lg-inline"
      />
    </main>
    <ngb-toast
      class="position-absolute bg-danger text-white text-center fs-6 col-2"
      [autohide]="false"
      *ngIf="show"
      >{{ errorLoginMsg }}
    </ngb-toast>
  </body>
</html>

<!--<div id="full-container">-->
<!--  <div id="outPopUp">-->
<!--    <div class="text-section">-->
<!--      <div class="welcome">-->
<!--        <p-->
<!--          style="-->
<!--            font: normal normal normal 36px/50px Product Sans Thin;-->
<!--            padding-top: 50px;-->
<!--          "-->
<!--        >-->
<!--          Welcome to-->
<!--        </p>-->
<!--        <p-->
<!--          style="-->
<!--            font: normal normal bold 54px/50px Product Sans;-->
<!--            color: #000000;-->
<!--            margin-top: -20px;-->
<!--          "-->
<!--        >-->
<!--          Reseller App-->
<!--        </p>-->
<!--      </div>-->

<!--      <p-->
<!--        style="-->
<!--          font: normal normal normal 36px/44px Product Sans;-->
<!--          letter-spacing: -0.72px;-->
<!--          padding-top: 30px;-->
<!--        "-->
<!--      >-->
<!--        Sign in-->
<!--      </p>-->
<!--      <p-->
<!--        style="-->
<!--          font: normal normal normal 20px/24px Product Sans Light;-->
<!--          letter-spacing: 0.12px;-->
<!--        "-->
<!--      >-->
<!--        Simply sign in using your Google Account-->
<!--      </p>-->

<!--      <div id="customBtn" class="row"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="footer">-->
<!--    <p>Copyright 2023 ©iSolutions. All Rights Reserved</p>-->
<!--  </div>-->
<!--</div>-->
