import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { UserModel } from 'src/models/mongo/user.model';
import { Page } from 'src/models/page';
import { PagedData } from 'src/models/paged-data';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Group, OrderStatus, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { OrderModel } from '../../../../models/mongo/order.model';
import { CustomerModel } from '../../../../models/mongo/customer.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./orders.component.scss'],
})
export class ReportOrdersComponent implements OnInit {
  @ViewChild('orderStatusSelect') orderStatusSelect: ElementRef;
  @ViewChild('SalesPerson') SalesPerson: ElementRef;
  @ViewChild('notCheckedOrdersCheckbox')
  notCheckedOrdersCheckbox: ElementRef<HTMLInputElement>;

  orders: OrderModel[] = [];
  customer: CustomerModel;
  @ViewChild(DatatableComponent) public table: DatatableComponent;
  page = new Page();
  currentPage = 1;
  pageSize: number = 20;
  minUserIndex: number;
  maxUserIndex: number;
  totalOrders: number;
  totalItems: number;
  currentUser: UserModel;
  singleOrder: OrderModel;
  plans = [];
  products = [];

  sales = [];

  htmlStatus;
  pageTitle: string = '';
  canCheck: boolean = false;
  currentUserRoleData: UserRoleDataModel;
  onlyNotCheckedOrders: boolean = false;
  orderStatus = [
    OrderStatus.Executed,
    OrderStatus.Pending,
    OrderStatus.Rejected,
  ];
  orderStatusChosen: string;
  created_by_rfid: string;
  default_date_to: string;
  default_date_from: string;
  date_from: string;
  date_to: string;
  sale_person: string;
  filtersearch: string;
  pagination: number = 1;

  constructor(
    private router: Router,
    private http: HttpClient,
    private datePipe: DatePipe,
    private datepipe: DatePipe,
    private confirmDialogService: ConfirmDialogService,
    private localStorage: LocalStorageService
  ) {
    this.htmlStatus = OrderStatus;
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;

    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());

    this.canCheck = this.currentUser.groups.find(
      (x) => x.name == Group.Finance
    );
  }

  @HostBinding('class') classAttribute: string = 'report-table orders-table';

  async ngOnInit(): Promise<void> {
    var menus = JSON.parse(this.localStorage.get(Unicode.MENUS));
    var mnu = menus.find(
      (x) =>
        x.link.toLowerCase().trim() ==
        document.location.pathname.toLowerCase().trim()
    );
    if (mnu) this.pageTitle = mnu.name;
    else this.pageTitle = 'Reports';
    if (
      this.localStorage.get(Unicode.SALES) == 'undefined' ||
      !this.localStorage.get(Unicode.SALES)
    ) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.READ_SALE_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(Unicode.SALES, JSON.stringify(resp.users));
      }
    }
    this.products = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.sales = JSON.parse(this.localStorage.get(Unicode.SALES));
    const today = new Date();
    this.date_to = this.datePipe.transform(today, 'yyyy-MM-dd');
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

    this.date_from = this.datePipe.transform(thirtyDaysAgo, 'yyyy-MM-dd');
    this.default_date_to = this.date_to;
    this.default_date_from = this.date_from;
    this.getOrders(this.currentPage);
  }
  get totalPages(): number {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  onPageChanged(newPage: number) {
    if (newPage < 1 || newPage > this.totalPages) {
      return; // Prevent going out of bounds
    }
    this.currentPage = newPage;
    this.minUserIndex = (this.currentPage - 1) * this.pageSize + 1;
    const endIndex = this.minUserIndex + this.pageSize - 1;
    this.maxUserIndex = Math.min(endIndex, this.totalItems);

    this.getOrders(this.currentPage);
  }
  // // Function to make the orders consistent with the first JSON format
  // makeOrdersConsistent() {
  //   return this.orders.map(order => ({
  //     ...order,
  //     customer_domain: order.customer_details?.domain || "", // Extract the domain from customer_details
  //   }));
  // }

  async viewOrder(edit: boolean, id) {
    const resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.GET_ORDER_BY_ID + id)
      .toPromise();
    if (resp?.order) {
      this.singleOrder = resp.order;
      this.router.navigate(['orders/order-view'], {
        state: { edit: edit, order_model: JSON.stringify(this.singleOrder) },
        skipLocationChange: false,
      });
    }
  }

  get GetData() {
    return this.orders;
  }
  async searchOrder(event) {
    this.filtersearch = event.target.value;
    this.minUserIndex = null;
    this.maxUserIndex = null;
    await this.getOrders(this.currentPage);
  }
  onCheck(event: any, order_id: any) {
    console.log(order_id);
    if (event.target.checked) {
      const prs = new HttpParams().set(Unicode.ORDER_ID, order_id);
      this.http
        .put(
          Endpoint.GLOBAL_URL + '' + Endpoint.CHECK_ORDER,
          {},
          { params: prs }
        )
        .toPromise();
      this.minUserIndex = null;
      this.maxUserIndex = null;
      this.orders.find((x) => x.order_id == order_id).checked =
        event.target.checked;
      this.getOrders(this.currentPage);
    } else {
    }
  }

  onVerify(event: any, order_id: any) {
    if (event.target.checked) {
      const prs = new HttpParams().set(Unicode.ORDER_ID, order_id);
      this.http
        .put(
          Endpoint.GLOBAL_URL + '' + Endpoint.VERIFY_ORDER,
          {},
          { params: prs }
        )
        .toPromise();
      this.minUserIndex = null;
      this.maxUserIndex = null;
      this.orders.find((x) => x.order_id == order_id).verified =
        event.target.checked;
      this.getOrders(this.currentPage);
    } else {
    }
  }

  async getOrders(pageNumber) {
    const startIndex = (pageNumber - 1) * this.pageSize;

    var prs = new HttpParams()
      .set(Unicode.PAGE, startIndex + '')
      .set(Unicode.LIMIT, this.pageSize + '');
    prs = prs.set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(this.orderStatusChosen)) {
      prs = prs.set(Unicode.ORDER_STATUS, this.orderStatusChosen);
    }
    if (!Utilities.isNullOrEmpty(this.created_by_rfid)) {
      prs = prs.set(Unicode.USER_ID, this.created_by_rfid);
    }
    if (this.onlyNotCheckedOrders) {
      prs = prs.set(Unicode.ONLY_NOT_CHECKED_ORDERS, 'true');
    }
    if (!Utilities.isNullOrEmpty(this.date_to)) {
      prs = prs.set(Unicode.TO_DATE, this.date_to);
    }
    if (!Utilities.isNullOrEmpty(this.date_from)) {
      prs = prs.set(Unicode.FROM_DATE, this.date_from);
    }
    if (!Utilities.isNullOrEmpty(this.filtersearch)) {
      prs = prs.set('searchFilter', this.filtersearch);
    }

    await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.REPORTS_ORDERS_FILE_NAME, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          this.totalItems = resp['total'];
          this.orders = resp.orders.map((order) => ({ ...order }));
          if (this.minUserIndex == null && this.orders.length > 0) {
            this.minUserIndex = 1;
          } else if (this.minUserIndex == null && this.orders.length == 0) {
            this.minUserIndex = 0;
          }
          if (this.maxUserIndex == null) {
            this.maxUserIndex = this.orders.length;
          }
          this.orders.forEach((order) => {
            const sale = this.sales.find(
              (sale) => sale.id === order.created_by_rfid
            );
            const saleName = sale ? sale.first_name + ' ' + sale.last_name : '';

            if (order.sku && order.sku.length > 0) {
              order.sku_names = [];
              for (const sku of order.sku) {
                const product = this.products.find(
                  (x) => x.sku_id === sku.sku_id
                );
                if (product) {
                  order.sku_names.push(product.sku_name);
                }
              }
            } else {
              order.sku_names = [];
            }
            const pattern = /Google Workspace/g; // Replace 'pattern-to-match' with your desired pattern

            order.sku_names.forEach((skuName, index) => {
              if (skuName.match(pattern)) {
                order.sku_names[index] = skuName.replace(pattern, 'GWS');
              }
            });
            order.sale_name = saleName;
            order.sale_email = sale.email;
          });
        }
      });
  }

  onlyNotCheckedHandle() {
    this.onlyNotCheckedOrders = !this.onlyNotCheckedOrders;
    this.currentPage = 1;
    this.minUserIndex = null;
    this.maxUserIndex = null;
    this.getOrders(this.currentPage);
  }

  OrderStatusSelect(event: any) {
    if (event.target.value == 'clear') {
      this.orderStatusChosen = '';
      this.currentPage = 1;
      this.orderStatusSelect.nativeElement.value = '';
      this.minUserIndex = null;
      this.maxUserIndex = null;
      this.getOrders(this.currentPage);
    } else {
      this.orderStatusChosen = event.target.value;

      this.currentPage = 1;
      this.minUserIndex = null;
      this.maxUserIndex = null;
      this.getOrders(this.currentPage);
    }
  }
  resetFilters() {
    this.orderStatusChosen = '';
    this.onlyNotCheckedOrders = false;
    this.created_by_rfid = '';
    // this.date_from = '';
    // this.date_to = '';
    const today = new Date();
    this.date_to = this.datePipe.transform(today, 'yyyy-MM-dd');
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

    this.date_from = this.datePipe.transform(thirtyDaysAgo, 'yyyy-MM-dd');
    this.currentPage = 1;
    this.orderStatusSelect.nativeElement.value = '';
    this.SalesPerson.nativeElement.value = '';
    this.notCheckedOrdersCheckbox.nativeElement.checked = false; // Uncheck the checkbox
    this.filtersearch = '';
    this.minUserIndex = null;
    this.maxUserIndex = null;
    // const dateFromInput = <HTMLInputElement>(
    //   document.getElementById('floating-date-from')
    // );
    // const dateToInput = <HTMLInputElement>(
    //   document.getElementById('floating-date-to')
    // );
    //
    // dateFromInput.value = this.date_from;
    // dateToInput.value = this.date_to;
    this.default_date_from = this.date_from;
    this.default_date_to = this.date_to;
    this.getOrders(this.currentPage);
  }

  SalesPersonSelect(event: any) {
    // Perform actions based on the selected value
    if (event.target.value == 'clear') {
      this.created_by_rfid = '';
      this.SalesPerson.nativeElement.value = '';

      this.currentPage = 1;
      this.minUserIndex = null;
      this.maxUserIndex = null;
      this.getOrders(this.currentPage);
    } else {
      this.minUserIndex = null;
      this.maxUserIndex = null;
      this.created_by_rfid = event.target.value;
      this.currentPage = 1;
      this.getOrders(this.currentPage);
    }
  }

  chooseDateOrders() {
    const fromDate = (<HTMLInputElement>(
      document.getElementById('floating-date-from')
    ))?.value;
    const toDate = (<HTMLInputElement>(
      document.getElementById('floating-date-to')
    ))?.value;

    if (fromDate != '' && toDate != '') {
      this.date_from = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
      this.date_to = this.datePipe.transform(toDate, 'yyyy-MM-dd');
      this.currentPage = 1;
      this.minUserIndex = null;
      this.maxUserIndex = null;
      this.getOrders(this.currentPage);
    }
  }

  async export() {
    var total = this.pageSize;
    alert('You will receive an email with the sheet link once completed');
    var prs = new HttpParams().set(Unicode.PAGE, '0');
    if (!this.currentUser.canViewAll) {
      prs = prs.set(Unicode.VIEW_ALL, '0');
    }
    if (!Utilities.isNullOrEmpty(this.orderStatusChosen)) {
      prs = prs.set(Unicode.ORDER_STATUS, this.orderStatusChosen);
    }
    if (!Utilities.isNullOrEmpty(this.created_by_rfid)) {
      prs = prs.set(Unicode.USER_ID, this.created_by_rfid);
    }
    if (this.onlyNotCheckedOrders) {
      prs = prs.set(Unicode.ONLY_NOT_CHECKED_ORDERS, 'true');
    }
    if (!Utilities.isNullOrEmpty(this.date_to)) {
      prs = prs.set(Unicode.TO_DATE, this.date_to);
    }
    if (!Utilities.isNullOrEmpty(this.date_from)) {
      prs = prs.set(Unicode.FROM_DATE, this.date_from);
    }
    if (!this.currentUser.canViewAll) {
      prs = prs.set(Unicode.VIEW_ALL, '0');
    }
    prs = prs.set(Unicode.WITHOUT_LOADER, '1');
    await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.REPORTS_ORDERS_EXPORT, {
        params: prs,
      })
      .toPromise()
      .then((resp: any) => {
        this.totalItems = resp['total'];
        if (resp && resp.spreadsheetUrl) window.open(resp.spreadsheetUrl);
      });
  }
}
