<form
  [formGroup]="dealForm"
  role="form"
  #formDir="ngForm"
  id="add-deal-form"
  novalidate
  class="max-h-70 w-100 fluid-section row justify-content-xl-between ng-untouched ng-pristine ng-invalid ms-1 mb-2 pt-1"
>
  <div class="col-12 col-xl-6 pl-0" id="deal-form-section">
    <div
      *ngIf="currentDomain?.domain"
      class="mb-2 align-items-center justify-content-between"
    >
      <a
        href="javascript:void(0)"
        (click)="viewCustomerDetails()"
        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
      >
      </a>
      <div class="col-10">
        <input
          type="text"
          class="form-control rounded-pill"
          id="admin-name"
          formControlName="customer_domain"
          placeholder="Domain"
          [ngClass]="{
            'is-invalid': formDir.submitted && customer_domain.invalid
          }"
        />
      </div>
    </div>

    <div *ngIf="!currentDomain?.domain" class="mb-2">
      <!--      <label-->
      <!--        for="domain"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--      >Domain</label-->
      <!--      >-->
      <div class="d-flex align-items-center">
        <ng-select
          bindLabel="domain.domain"
          appendTo="body"
          id="domain"
          placeholder="Domain"
          (search)="onSearchDomainEnter($event)"
          (change)="selectDomain()"
          [searchable]="true"
          [ngClass]="{
            'is-invalid': formDir.submitted && customer_domain.invalid
          }"
          class="col-10 p-0"
          [clearable]="true"
          formControlName="customer_domain"
        >
          <ng-option
            [value]="domain.domain"
            *ngFor="let domain of searchResultCustomers"
          >
            {{ domain.domain }}
          </ng-option>
        </ng-select>

        <div class="col-1 text-end">
          <img
            data-toggle="tooltip"
            title="add new customer"
            (click)="createNewCustomer()"
            src="./assets/img/plus-icon.svg"
            alt="plus icon"
            class="pe-2"
          />
        </div>
      </div>
    </div>

    <div class="mb-2 align-items-center justify-content-between">
      <!--        <label-->
      <!--          for="admin-name"-->
      <!--          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--        >Organisation</label-->
      <!--        >-->
      <ng-select
        bindLabel="domain.organisation_name"
        appendTo="body"
        id="org"
        (search)="onSearchDomainEnter($event)"
        (change)="selectOrg()"
        [searchable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && customer_organisation.invalid
        }"
        class="p-0 col-10"
        [clearable]="true"
        formControlName="customer_organisation"
        placeholder="Organization"
      >
        <ng-option
          [value]="domain.organisation_name"
          *ngFor="let domain of searchResultCustomers"
        >
          {{ domain.organisation_name }}
        </ng-option>
      </ng-select>
    </div>

    <div class="mb-2 align-items-center justify-content-between">
      <!--      <label-->
      <!--        for="admin-name"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--      >Deal Type</label-->
      <!--      >-->

      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && type.invalid
        }"
        formControlName="type"
        placeholder="Deal Type"
      >
        <ng-option [value]="source" *ngFor="let source of dealTypes">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>

    <div class="mb-2 align-items-center justify-content-between">
      <!--      <label-->
      <!--        for="admin-name"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--      >Source</label>-->

      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && source.invalid
        }"
        formControlName="source"
        placeholder="Source"
      >
        <ng-option [value]="source" *ngFor="let source of sources">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>

    <div class="mb-2 d-flex align-items-center justify-content-between">
      <div class="col-10 form-floating">
        <input
          class="form-control rounded-pill"
          id="admin-name"
          type="date"
          [ngClass]="{
            'is-invalid': formDir.submitted && estimated_close_date.invalid
          }"
          formControlName="estimated_close_date"
        />
        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Estimated Close Date</label
        >
      </div>
    </div>

    <div class="mb-2 align-items-center justify-content-between">
      <!--      <label-->
      <!--        for="admin-name"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--      >Decision Phase</label-->
      <!--      >-->
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && decision_phase.invalid
        }"
        formControlName="decision_phase"
        placeholder="Decision Phase"
      >
        <ng-option [value]="source" *ngFor="let source of decisionPhases">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>

    <div class="mb-2 d-flex align-items-center ms-2">
      <div class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline">
        Public Tender
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          formControlName="public_tender"
          type="radio"
          [value]="true"
          id="deal-sector-yes"
        />
        <label class="form-check-label" for="deal-sector-yes">Yes</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          formControlName="public_tender"
          type="radio"
          [value]="false"
          id="deal-sector-no"
        />
        <label class="form-check-label" for="deal-sector-no">No</label>
      </div>
    </div>

    <div class="mb-2 align-items-center justify-content-between">
      <!--      <label-->
      <!--        for="admin-name"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--        >Budget</label-->
      <!--      >-->
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && budget.invalid
        }"
        formControlName="budget"
        placeholder="Budget"
      >
        <ng-option [value]="source" *ngFor="let source of budgets">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>

    <div class="mb-2 align-items-center justify-content-between">
      <!--      <label-->
      <!--        for="admin-name"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--        >Authority</label-->
      <!--      >-->
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && authority.invalid
        }"
        formControlName="authority"
        placeholder="Authority"
      >
        <ng-option [value]="source" *ngFor="let source of authorities">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 align-items-center justify-content-between">
      <!--      <label-->
      <!--        for="admin-name"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--        >Need</label-->
      <!--      >-->
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && need.invalid
        }"
        formControlName="need"
        placeholder="Need"
      >
        <ng-option [value]="source" *ngFor="let source of needs">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>
    <div class="mb-2 align-items-center justify-content-between">
      <!--      <label-->
      <!--        for="admin-name"-->
      <!--        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"-->
      <!--        >Timeline</label-->
      <!--      >-->
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        [ngClass]="{
          'is-invalid': formDir.submitted && timeline.invalid
        }"
        formControlName="timeline"
        placeholder="Timeline"
      >
        <ng-option [value]="source" *ngFor="let source of timelines">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="col-12 col-xl-6 ps-l-5 ps-xl-5">
    <div class="mb-4 pb-2 row label_grey_border_bottom">
      <div class="col-7 pt-1">
        <label class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline">
          DAF Available?
        </label>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="daf_available"
            type="radio"
            (change)="dafPsfAvailableEvent($event, true, true)"
            [value]="true"
            #deal_daf_available_yes
            id="deal-daf-available-yes"
          />
          <label class="form-check-label" for="deal-daf-available-yes"
            >Yes</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="daf_available"
            type="radio"
            [value]="false"
            (change)="dafPsfAvailableEvent($event, true, false)"
            id="deal-daf-available-no"
          />
          <label class="form-check-label" for="deal-daf-available-no">No</label>
        </div>
      </div>
      <div class="d-flex col-5">
        <label
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline pt-1"
        >
          Amount
        </label>
        <div class="form-check form-check-inline">
          <input
            type="number"
            class="form-control rounded-pill form-control m-0"
            formControlName="daf_amount"
            min="0"
            [ngClass]="{
              my_disabled_input: !deal_daf_available_yes.checked
            }"
          />
        </div>
      </div>

      <div *ngIf="deal_daf_available_yes.checked" class="mt-1 row">
        <label
          class="form-label text-wrap mb-0 d-none d-md-inline"
          [ngClass]="{
            'col-3': !this.model.psf?.attachment_proof_drive_id,
            'col-6': this.model.psf?.attachment_proof_drive_id
          }"
        >
          Email Attachment Proof
        </label>
        <div
          *ngIf="
            this.model.daf?.attachment_proof_drive_id;
            then dafFilExists;
            else dafFilNotExists
          "
        ></div>
        <ng-template #dafFilNotExists>
          <div class="col-6">
            <input
              type="text"
              class="form-control rounded-pill m-0 h-36"
              [value]="dafFileName ?? ''"
              readonly
            />
          </div>
          <div class="col-3 text-end">
            <label for="daf-upload-profile" class="form-label"
              ><div
                [ngClass]="{
                  'is-invalid': formDir.submitted && attachedFileDaf.invalid
                }"
                type="button"
                class="btn rounded-pill border-primary d-flex align-items-center"
              >
                <span class="small pe-1 blue_1">Attach</span>
                <img src="./assets/img/ic_attach.svg" alt="attach icon" /></div
            ></label>
            <input
              class="form-control"
              (change)="onAttachDafPsfFile(true, $event)"
              type="file"
              formControlName="attachedFileDaf"
              id="daf-upload-profile"
              hidden
            />
          </div>
        </ng-template>
        <ng-template #dafFilExists>
          <div class="col-6 p-0">
            <a
              target="_blank"
              href="{{ this.model.daf.attachment_proof_drive_id }}"
              >{{ this.model.daf.attachment_proof_name }}</a
            >
          </div>
        </ng-template>
      </div>
      <div *ngIf="!deal_daf_available_yes.checked" class="mt-1 row">
        <div
          class="form-check form-check-inline col-9 m-0 p-0 row form-floating"
        >
          <input
            type="text"
            class="form-control rounded-pill form-control m-0"
            formControlName="not_existing_daf_reason"
            placeholder="Reason for not having Daf"
          />
          <label class="form-label text-wrap col-3 mb-0 d-none d-md-inline">
            Reason for not having DAF
          </label>
        </div>
      </div>
    </div>

    <div class="mb-4 pb-2 row label_grey_border_bottom">
      <div class="col-7 pt-1">
        <label class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline">
          PSF Available?
        </label>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="psf_available"
            type="radio"
            [value]="true"
            (change)="dafPsfAvailableEvent($event, false, true)"
            #deal_psf_available_yes
            id="deal-psf-available-yes"
          />
          <label class="form-check-label" for="deal-psf-available-yes"
            >Yes</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="psf_available"
            type="radio"
            [value]="false"
            (change)="dafPsfAvailableEvent($event, false, false)"
            id="deal-psf-available-no"
          />
          <label class="form-check-label" for="deal-psf-available-no">No</label>
        </div>
      </div>
      <div class="d-flex col-5">
        <label
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline pt-1"
        >
          Amount
        </label>
        <div class="form-check form-check-inline">
          <input
            type="number"
            class="form-control rounded-pill form-control m-0"
            formControlName="psf_amount"
            min="0"
            [ngClass]="{
              my_disabled_input: !deal_psf_available_yes.checked
            }"
          />
        </div>
      </div>

      <div *ngIf="deal_psf_available_yes.checked" class="mt-1 row">
        <label
          class="form-label text-wrap mb-0 d-none d-md-inline"
          [ngClass]="{
            'col-3': !this.model.psf?.attachment_proof_drive_id,
            'col-6': this.model.psf?.attachment_proof_drive_id
          }"
        >
          Email Attachment Proof
        </label>
        <div
          *ngIf="
            this.model.psf?.attachment_proof_drive_id;
            then psfFilExists;
            else psfFilNotExists
          "
        ></div>
        <ng-template #psfFilNotExists>
          <div class="col-6">
            <input
              type="text"
              class="form-control rounded-pill m-0 h-36"
              [value]="psfFileName ?? ''"
              readonly
            />
          </div>
          <div class="col-3 text-end">
            <label for="psf-upload-profile" class="form-label"
              ><div
                [ngClass]="{
                  'is-invalid': formDir.submitted && attachedFilePsf.invalid
                }"
                type="button"
                class="btn rounded-pill border-primary d-flex align-items-center"
              >
                <span class="small pe-1 blue_1">Attach</span>
                <img src="./assets/img/ic_attach.svg" alt="attach icon" /></div
            ></label>
            <input
              class="form-control"
              (change)="onAttachDafPsfFile(false, $event)"
              type="file"
              formControlName="attachedFilePsf"
              id="psf-upload-profile"
              hidden
            />
          </div>
        </ng-template>
        <ng-template #psfFilExists>
          <div class="col-6 p-0">
            <a
              target="_blank"
              href="{{ this.model.psf.attachment_proof_drive_id }}"
              >{{ this.model.psf.attachment_proof_name }}</a
            >
          </div>
        </ng-template>
      </div>
      <div *ngIf="!deal_psf_available_yes.checked" class="mt-1">
        <div
          class="form-check form-check-inline col-9 m-0 p-0 row form-floating"
        >
          <input
            type="text"
            class="form-control rounded-pill form-control m-0"
            formControlName="not_existing_psf_reason"
            placeholder="Reason for not having PSF"
          />
          <label class="form-label text-wrap col-3 mb-0 d-none d-md-inline">
            Reason for not having PSF
          </label>
        </div>
      </div>
    </div>

    <div class="row g-1 mb-2">
      <div class="col-10 form-floating">
        <textarea
          formControlName="description"
          placeholder="Deal Description"
          class="form-control rounded-4 deal_description_area"
        ></textarea>
        <label
          for="description"
          class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
        >
          Deal Description
        </label>
      </div>
    </div>
    <div class="row g-1 mb-2">
      <div class="col-10 form-floating">
        <input
          type="text"
          [ngClass]="{
            'is-invalid': formDir.submitted && google_rep.invalid
          }"
          formControlName="google_rep"
          placeholder="Google Rep"
          class="form-control rounded-pill form-control"
        />

        <label
          for="google_rep"
          class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
        >
          Google Rep
        </label>
      </div>
    </div>
    <div class="row g-1 mb-2" *ngIf="!addDeal">
      <label
        for="created_user_email"
        class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
        >Created By</label
      >
      <div class="col-10">
        <label class="form-control rounded-pill col-9">{{
          model.user_deal?.created_user_email
        }}</label>
      </div>
    </div>

    <div class="mb-2 d-flex row">
      <div class="text-wrap col-2 p-0 mb-0 d-none d-md-inline text-center">
        SKU
      </div>
      <div class="border rounded-4 col p-2 fluid-section sku-table">
        <div class="row border-bottom border-primary justify-content-end mx-0">
          <p class="col-2 mb-2 px-0">Total Amount</p>
          <p class="text-primary col-2 product-sans-bold mb-2 text-end">
            {{ totalAmount }}
          </p>
        </div>
        <div
          class="row border-bottom border-primary justify-content-end mx-0"
          *ngIf="!isGCP"
        >
          <p class="col-2 mb-2 px-0">Total User</p>
          <p class="text-primary col-2 product-sans-bold mb-2 text-end">
            {{ totalUsers }}
          </p>
        </div>
        <ng-conatiner
          formArrayName="sku"
          class=""
          *ngIf="!isGCP; else tmp_gws_skus"
        >
          <div
            class="row mx-0 mt-3 smaller-text flex-nowrap"
            *ngFor="let skuModel of sku.controls; let index = index"
            id="sku-container"
          >
            <div
              class="form-check col-6 d-flex align-items-center pe-1"
              [formGroupName]="index"
            >
              <input
                class="form-check-input me-2"
                type="checkbox"
                (change)="checkNew()"
                value=""
                formControlName="checked"
                id="flexCheckDefault"
              />
              <label
                class="form-check-label d-flex align-items-center"
                for="flexCheckDefault"
              >
                <span class="text-wrap col ps-2">
                  {{ skuModel.value.sku_name }}
                </span>
              </label>
            </div>
            <div
              class="d-flex align-items-center justify-content-between col-2 px-0"
              [formGroupName]="index"
            >
              <label for="quantity" class="form-label col-4 col-md-5 mb-0 p-0">
                Qty
              </label>
              <input
                formControlName="license"
                type="number"
                min="1"
                (change)="calculatePrices(skuModel)"
                class="form-control rounded-pill form-control-sm"
                id="quantity"
                placeholder="0"
              />
            </div>
            <div
              class="col-2 align-self-center ms-2 text-primary product-sans-bold text-end"
            >
              {{ skuModel.value.cost_price_per_user }}$
            </div>
            <div
              class="col-2 align-self-center ms-2 text-primary product-sans-bold text-end"
            >
              {{ skuModel.value.cost_price }}$
            </div>
          </div>
        </ng-conatiner>
        <ng-template #tmp_gws_skus>
          <ng-conatiner formArrayName="sku" class="">
            <div
              class="row mx-0 mt-3 smaller-text flex-nowrap"
              *ngFor="let skuModel of sku.controls; let index = index"
              id="sku-container"
            >
              <div
                class="form-check col-6 d-flex align-items-center pe-1"
                [formGroupName]="index"
              >
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  (change)="checkNew()"
                  value=""
                  formControlName="checked"
                  id="flexCheckDefault"
                />
                <label
                  class="form-check-label d-flex align-items-center"
                  for="flexCheckDefault"
                >
                  <span class="text-wrap col ps-2">
                    {{ skuModel.value.sku_name }}
                  </span>
                </label>
              </div>
              <div
                class="d-flex align-items-center justify-content-between col-6 px-0"
                [formGroupName]="index"
              >
                <label for="amount" class="form-label col-3 col-md-5 mb-0 p-0">
                  Amount
                </label>
                <input
                  (change)="calculatePrices(skuModel)"
                  formControlName="amount"
                  type="number"
                  class="form-control rounded-pill form-control-sm"
                  id="amount"
                  placeholder="0"
                />
              </div>
            </div>
          </ng-conatiner>
        </ng-template>
      </div>
    </div>
  </div>

  <div
    class="mb-2 d-flex align-items-center justify-content-between opacity-50 col-12 br_deal_sec12 mt-3 mb-3"
  ></div>

  <div class="col-12 col-xl-6">
    <div
      class="mb-2 d-flex align-items-center justify-content-between"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div class="col-10 form-floating">
        <input
          type="text"
          class="form-control rounded-pill"
          id="admin-name"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          formControlName="dr_nbr"
          placeholder="DR #"
        />
        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >DR #</label
        >
      </div>
    </div>
    <div
      class="mb-2 d-flex align-items-center justify-content-between"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div class="col-10 form-floating">
        <input
          type="text"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          class="form-control rounded-pill"
          id="admin-name"
          formControlName="opportunity_owner"
          placeholder="Google Rep"
        />
        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Google Rep</label
        >
      </div>
    </div>
    <div
      class="mb-2 d-flex align-items-center justify-content-between"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div class="col-10 form-floating">
        <input
          type="text"
          class="form-control rounded-pill"
          id="admin-name"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          formControlName="opportunity_nbr"
          placeholder="Opportunity #"
        />

        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Opportunity #</label
        >
      </div>
    </div>
    <div
      class="mb-2 d-flex align-items-center justify-content-between"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div
        class="col-10 form-floating"
        *ngIf="dealForm.controls['opportunity_nbr'].value"
      >
        <input
          type="date"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          class="form-control rounded-pill"
          id="admin-name"
          formControlName="opportunity_creation_date"
          placeholder="Opportunity Creation Date"
        />

        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Opportunity Creation Date</label
        >
      </div>
    </div>
    <div
      class="mb-2 d-flex align-items-center justify-content-between"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div
        class="col-10 form-floating"
        *ngIf="dealForm.controls['opportunity_nbr'].value"
      >
        <input
          type="text"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          class="form-control rounded-pill"
          id="admin-name"
          formControlName="opportunity_link"
          placeholder="Opportunity Link"
        />

        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Opportunity Link</label
        >
      </div>
    </div>
  </div>

  <div class="col-12 col-xl-6 ps-l-5 ps-xl-5">
    <div class="mb-2 d-flex align-items-center justify-content-between">
      <label
        for="admin-name"
        class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
        >Opportunity Stage</label
      >
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-10 p-0"
        [clearable]="true"
        formControlName="opportunity_stage"
        placeholder="Opportunity Stage"
      >
        <ng-option [value]="source" *ngFor="let source of opporunityStages">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>

    <div
      class="mb-2 d-flex align-items-center gap-5 mt-4 mb-4"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div class="col-6">
        <label class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline">
          DV Requested
        </label>
        <div class="form-check form-check-inline">
          <input
            [ngClass]="{
              my_disabled_input: !currentUser.isGPM
            }"
            class="form-check-input"
            formControlName="dv_requested"
            type="radio"
            [value]="true"
            id="deal-dv-requested-yes"
          />
          <label class="form-check-label" for="deal-dv-requested-yes"
            >Yes</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            [ngClass]="{
              my_disabled_input: !currentUser.isGPM
            }"
            class="form-check-input"
            formControlName="dv_requested"
            type="radio"
            [value]="false"
            id="deal-dv-requested-no"
          />
          <label class="form-check-label" for="deal-dv-requested-no">No</label>
        </div>
      </div>
      <div class="col-6" *ngIf="isGCP == true">
        <div class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline">
          Project Number Validated
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="project_number_validated"
            type="radio"
            [value]="true"
            id="deal-project-number-validated-yes"
          />
          <label
            class="form-check-label"
            for="deal-project-number-validated-yes"
            >Yes</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            [value]="false"
            class="form-check-input"
            formControlName="project_number_validated"
            type="radio"
            id="deal-project-number-validated-no"
          />
          <label class="form-check-label" for="deal-project-number-validated-no"
            >No</label
          >
        </div>
      </div>
    </div>

    <div class="mb-2 d-flex align-items-center gap-5 mt-4 mb-4">
      <div>
        <div class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline">
          Campaign
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="campaign"
            type="radio"
            (change)="campaignAction($event)"
            [value]="true"
            id="deal-compaign-yes"
          />
          <label class="form-check-label" for="deal-compaign-yes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            formControlName="campaign"
            type="radio"
            [value]="false"
            id="deal-compaign-no"
          />
          <label class="form-check-label" for="deal-compaign-no">No</label>
        </div>
      </div>
    </div>
    <div
      class="row g-1 mb-2"
      *ngIf="dealForm.controls['campaign'].value == true"
    >
      <div class="col-10 form-floating">
        <input
          type="text"
          [ngClass]="{
            'is-invalid': formDir.submitted && campaign_type.invalid
          }"
          formControlName="campaign_type"
          placeholder="Campaign Type"
          class="form-control rounded-pill form-control"
        />

        <label
          for="campaign_type"
          class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
        >
          Campaign Type
        </label>
      </div>
    </div>

    <div
      *ngIf="isGCP == true"
      class="mb-2 d-flex align-items-center justify-content-between"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div class="col-10 form-floating">
        <input
          type="text"
          class="form-control rounded-pill"
          id="admin-name"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          formControlName="project_numbers"
          placeholder="Project Number(s)"
        />

        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Project Number(s)</label
        >
      </div>
    </div>
    <div
      *ngIf="isGCP == true"
      class="mb-2 d-flex align-items-center justify-content-between mt-1"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div class="col-10 form-floating">
        <input
          type="text"
          class="form-control rounded-pill"
          id="admin-name"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          formControlName="billing_id"
          placeholder="Billing ID"
        />

        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Billing ID</label
        >
      </div>
    </div>
    <div
      class="mb-2 d-flex align-items-center justify-content-between"
      [ngClass]="{
        'opacity-50': !currentUser.isGPM
      }"
    >
      <div class="col-10 form-floating">
        <input
          type="text"
          class="form-control rounded-pill"
          id="admin-name"
          [ngClass]="{
            my_disabled_input: !currentUser.isGPM
          }"
          type="date"
          formControlName="rebate_expiry_date"
          placeholder="Rebate Expiry Date"
        />

        <label
          for="admin-name"
          class="form-label text-wrap col-2 me-3 mb-0 d-none d-md-inline"
          >Rebate Expiry Date</label
        >
      </div>
    </div>
  </div>

  <div
    *ngIf="addDeal"
    class="d-flex align-items-start justify-content-center justify-content-xl-end mt-5 mt-xl-2 pt-xl-4 pb-2 product-sans-light"
  >
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-4 col-sm-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Save & Send for Approval"
      type="submit"
      (click)="saveDeal()"
    />
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-2 col-md-3 col-sm-5 col-xl-2 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Save as draft"
      type="submit"
      (click)="saveDeal(true)"
    />
  </div>
  <div
    *ngIf="!addDeal && canApprove == true; else editbuttons"
    class="d-flex align-items-start justify-content-center justify-content-xl-end mt-5 mt-xl-2 pt-xl-4 pb-2 product-sans-light"
  >
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-2 col-md-3 col-sm-5 col-xl-2 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Approve"
      (click)="approve()"
    />
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-2 col-md-3 col-sm-5 col-xl-2 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Reject"
      (click)="approve(true)"
    />
  </div>
  <ng-template #editbuttons>
    <div
      *ngIf="!addDeal && !canApprove && !onlyView"
      class="d-flex align-items-start justify-content-center justify-content-xl-end mt-5 mt-xl-2 pt-xl-4 pb-2 product-sans-light"
    >
      <input
        *ngIf="model.draft == true"
        class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-sm-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
        value="Save & Send for Approval"
        type="submit"
        (click)="editDeal(true)"
      />
      <input
        *ngIf="
          currentUser.isSales || currentUser.isOperations || currentUser.isGPM
        "
        class="btn btn-primary small-text btn-color rounded-pill col-lg-2 col-md-3 col-sm-5 col-xl-2 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
        value="Save"
        type="submit"
        (click)="editDeal()"
      />
    </div>
  </ng-template>
</form>

<!-- REJECTION REASONS POPUP -->
<div
  class="modal fade"
  bsModal
  #rejectionDealModal="bs-modal"
  tabindex="-1"
  id="rejectionDealModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          Please specify why the deal has been rejected.
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="rejectionDealModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <textarea
            #dealRejectionReason
            rows="6"
            placeholder="Rejection reason"
            class="rounded-4 deal_description_area col-12 p-2"
          ></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="rejectDeal(dealRejectionReason)"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</div>
