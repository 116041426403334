import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GcpService } from 'projects/gcp/src/public-api';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'add-billing-account',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-billing-account.component.html',
  styleUrls: ['./add-billing-account.component.scss'],
})
export class AddBillingAccountComponent implements OnInit {
  @Input() createdBillingData: any = {};
  gcpSkus = [];
  mainBilling = [];
  gcpPlans = [];
  @Input('creatBillingModal') creatBillingModal: ModalDirective;
  @Output() additionalBillingAccountData = new EventEmitter<any>();
  offers = [];
  @Input() fullAction: boolean = false; // MEANS CREATE CUSTOMER AND ENTITLEMENT AT THE SAME TIME
  countries = [];
  @Input() pocID: string;
  @Input() opportunityID: string;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private gcpService: GcpService
  ) {}

  async ngOnInit(): Promise<void> {
    var that = this;
    var globalData = await Utilities.globalData(this.http, this.localStorage);
    var gData = Utilities.getGlobalDataValues(globalData);
    this.countries = gData.countries;
    this.offers = await Utilities.offerList(this.http, this.localStorage); //todo use n backend only
    if (this.offers) {
      this.gcpSkus = this.offers.map((offer) => ({
        name: offer.sku,
        isSelected: false,
        logo: offer.logo,
      }));
      this.gcpSkus = Array.from(new Set(this.gcpSkus));
      this.gcpPlans = this.offers.map((offer) => ({
        name: offer.plan,
        isSelected: false,
        description: offer.description,
      }));
      this.gcpPlans = Array.from(new Set(this.gcpPlans));
    }
    await this.gcpService
      .getListOfBillingAccount(true, null, null, null, null)
      .then((resp: any) => {
        this.mainBilling = resp.data ?? [];
      });
  }

  async saveBillingAccount() {
    var sku = this.gcpSkus.find((sku) => sku.isSelected)?.name;
    var plan = this.gcpPlans.find((plan) => plan.isSelected)?.name;
    if (Utilities.isNullOrEmpty(sku) || Utilities.isNullOrEmpty(plan)) {
      alert('Select SKU and Plan');
      return;
    }
    var id = this.offers.find((x) => x.sku == sku && x.plan == plan)?.id;
    if (
      id &&
      !Utilities.isNullOrEmpty(this.createdBillingData.billingAccountName)
    ) {
      if (this.fullAction == true) {
        var model;
        var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
        if (!Utilities.isNullOrEmpty(cstjson)) model = JSON.parse(cstjson);
        if (model) {
          var body: any = {
            billing_account_name: this.createdBillingData.billingAccountName,
            id: id,
            domain: model,
            domain_rfid: model._id,
            poc_id: this.pocID,
            sku: sku,
            plan: plan,
            parent: this.createdBillingData.mainBillingAccount?.name
              ? this.createdBillingData.mainBillingAccount.name.split('/')[1]
              : null,
            opportunity_rfid: this.opportunityID,
            credit_amount: this.createdBillingData.amount,
          };
          await this.http
            .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_FULL_GCP_SERVICE, body)
            .toPromise()
            .then((channelEntitlementsResult: any) => {
              if (
                channelEntitlementsResult?.success == true &&
                channelEntitlementsResult?.rfid
              ) {
                var billingAccountRfid = channelEntitlementsResult.rfid;
                body.billing_account_rfid = billingAccountRfid;
                //this.creatBillingModal.hide();
                this.additionalBillingAccountData.emit(body);
              }
            });
        }
      } else {
        if (!Utilities.isNullOrEmpty(this.createdBillingData.channelName)) {
          this.createdBillingData = {
            ...this.createdBillingData,
            selectedSku: sku,
            selectedPaymentPlan: plan,
          };
          var bodyy = {
            customer_channel_name: this.createdBillingData.channelName,
            billing_account_name: this.createdBillingData.billingAccountName,
            id: id,
            credit_amount: this.createdBillingData.amount,
            opportunity_rfid: this.opportunityID,
          };
          await this.http
            .post(
              Endpoint.GLOBAL_URL + Endpoint.CREATE_Entitlement_BILLING_ACCOUNT,
              bodyy
            )
            .toPromise()
            .then((channelEntitlementsResult: any) => {
              if (channelEntitlementsResult?.success == true) {
                // this.creatBillingModal.hide();
                this.additionalBillingAccountData.emit(this.createdBillingData);
              }
            });
        } else {
          alert('Missing Info');
        }
      }
    } else alert('Add Billing Account Name!!');
  }
}
