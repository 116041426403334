<div id="opp_deal_parent_step" class="container d-flex flex-column">
  <div
    class="opp_deal_step_new h-100"
    *ngIf="dealPhaseData?.type == null; else opp_deal_step"
  >
    <div class="step_count">
      <span class="notification-icon--fixed">
        <small class="notification-badge f-20">1</small>
      </span>
    </div>
    <div class="text-center mt-2">
      <img
        src="./assets/img/ic_step_deal.svg"
        class="back-icon icon_step"
        alt="deal icon"
      />
    </div>
    <div class="text-center txt_deal_phase mt-2">
      <span class="col-1">Deal Phase</span>
    </div>
    <div class="row">
      <button
        (click)="createDeal()"
        [ngClass]="{
          phases_disabled_btn: !currentUser.isSales && !currentUser.isOperations
        }"
        class="col-10 mt-2 offset-1 btn d-none d-md-inline rounded-pill btn-bg-white smaller-text"
      >
        Create Deal
      </button>
    </div>
    <div class="new-div mt-auto mb-3 offset-3 div_skip">
      <!-- <div class="product-sans-light f-12">
        <label class="switch mb-1">
          <input type="checkbox" />
          <span class="slider"></span>
        </label>
        Skip this step
      </div> -->
    </div>
  </div>

  <ng-template #opp_deal_step>
    <div class="opp_deal_step h-100">
      <div class="deal_step_header d-flex flex-row">
        <span class="flex-column col-1 f-20">1</span>
        <label class="text-nowrap f-15 mt-1 flex_auto">Deal</label>
        <div class="col-4 flex-column pe-2 text-end">
          <span class="f-12 d-grid"
            >time <span>{{ dealPhaseData.deal_time }}</span></span
          >
        </div>
        <img
          src="./assets/img/ic_time_expand.svg"
          class="ic_time col-2 text-end"
        />
      </div>
      <div class="deal_step_content product-sans">
        <!-- DEAL CREATION -->
        <div class="btn_collapse_parent mt-2" id="div_deal_creation">
          <div
            class="row opp_col_content align-items-center"
            style="padding: 5px 13px"
          >
            <div class="col-9 p-0 phase-content">
              <button
                type="button"
                class="btn_collapse_header col-10 w-100 d-inline-flex"
                (click)="isCreatedDealCollapsed = !isCreatedDealCollapsed"
                [attr.aria-expanded]="!isCreatedDealCollapsed"
                aria-controls="collapseDeal"
              >
                <span
                  class="fa p-1"
                  [ngClass]="{
                    'fa-angle-down': isCreatedDealCollapsed,
                    'fa-angle-up': !isCreatedDealCollapsed
                  }"
                ></span>
                Sales created Deal
              </button>
            </div>
            <div class="col-3 d-inline-flex time-content align-items-center">
              <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                dealPhaseData?.consumed_time ? dealPhaseData?.consumed_time : ""
              }}</span>

              <span
                class="check_green col-2 fa fa-check fa-thin float-end mt-1"
              ></span>
            </div>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isCreatedDealCollapsed">
            <div class="btn_collapse_content">
              <div class="d-grid p-1">
                <label class="phase_details_title">Sales Person Name</label>
                <label class="phase_details_data_txt">{{
                  dealPhaseData.user_deal?.created_user_name
                }}</label>
                <label class="phase_details_title mt-2"
                  >Date & Time of Creation</label
                >
                <label class="phase_details_data_txt">{{
                  dealPhaseData.created_at | date : "dd MMM,yyyy - hh:mma"
                }}</label>
                <label class="phase_details_title mt-2">Consumed Time</label>
                <label class="phase_details_data_txt">{{
                  dealPhaseData.consumed_time
                }}</label>
                <input
                  *ngIf="currentUser._id == dealPhaseData.created_by_rfid"
                  class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                  (click)="editDeal()"
                  value="Edit Deal"
                  type="submit"
                />
                <input
                  *ngIf="
                    currentUser._id == dealPhaseData.created_by_rfid &&
                    dealPhaseData.draft == true
                  "
                  class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                  (click)="sendForManagerApproval()"
                  value="Send for Manager Approval"
                  type="submit"
                />
                <input
                  *ngIf="
                    currentUser._id !== dealPhaseData.created_by_rfid &&
                    !currentUser.isGPM
                  "
                  class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                  (click)="reviewDeal()"
                  value="Review Deal"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </div>
        <ng-template [ngIf]="!dealPhaseData?.draft">
          <!-- DEAL MANAGER APPROVAL -->
          <div
            class="btn_collapse_parent mt-2"
            id="deal_manager_approval"
            *ngIf="dealPhaseData.managerApprovalPart"
          >
            <div
              class="row opp_col_content align-items-center"
              style="padding: 5px 13px"
            >
              <div class="col-9 p-0 phase-content">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': dealPhaseData.managerApprovalPart.date,
                    'col-12': !dealPhaseData.managerApprovalPart.date
                  }"
                  (click)="isManagerDealCollapsed = !isManagerDealCollapsed"
                  [attr.aria-expanded]="!isManagerDealCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isManagerDealCollapsed,
                      'fa-angle-up': !isManagerDealCollapsed
                    }"
                  ></span>
                  <label
                    *ngIf="dealPhaseData.managerApprovalPart.date"
                    title="Sales Manager Approval"
                    class="label-ellipsis"
                    >{{
                      dealPhaseData.managerApprovalPart.approved
                        ? "Approved"
                        : "rejected"
                    }}
                    by Sales Manager</label
                  >
                  <label
                    *ngIf="!dealPhaseData.managerApprovalPart.date"
                    title="Pending Sales Manager Approval"
                    class="label-ellipsis"
                    >Pending Sales Manager Approval</label
                  >
                </button>
              </div>

              <div class="col-3 time-content d-inline-flex align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                  dealPhaseData.managerApprovalPart.consumed_time
                    ? (dealPhaseData.managerApprovalPart.consumed_time
                      | opportunityConsumedTime)
                    : ""
                }}</span>

                <span
                  *ngIf="dealPhaseData.managerApprovalPart.date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isManagerDealCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title">Sales Manager Name</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.managerApprovalPart.name
                  }}</label>
                  <ng-template [ngIf]="dealPhaseData.managerApprovalPart.date">
                    <label class="phase_details_title mt-2"
                      >Date & Time of
                      {{
                        dealPhaseData.managerApprovalPart.approved
                          ? "Approval"
                          : "Rejection"
                      }}</label
                    >
                    <label class="phase_details_data_txt">{{
                      dealPhaseData.managerApprovalPart.date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      dealPhaseData.managerApprovalPart.consumed_time
                    }}</label>
                  </ng-template>
                  <div
                    class="d-grid mt-2"
                    *ngIf="
                      dealPhaseData.managerApprovalPart.date &&
                      dealPhaseData.managerApprovalPart.rejection_reason &&
                      dealPhaseData.managerApprovalPart.rejection_reason != ''
                    "
                  >
                    <label class="phase_details_title">Rejection Reason</label>
                    <label class="phase_details_data_txt">{{
                      dealPhaseData.managerApprovalPart.rejection_reason
                    }}</label>
                  </div>
                  <input
                    *ngIf="
                      currentUser._id == dealPhaseData.managerApprovalPart.id
                    "
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="reviewDeal()"
                    value="Review Deal"
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- DEAL GPM APPROVAL -->
          <div
            class="btn_collapse_parent mt-2"
            id="deal_gpm_approval"
            *ngIf="dealPhaseData.show_gpm_phase"
          >
            <div
              class="row opp_col_content align-items-center"
              style="padding: 5px 13px"
            >
              <div class="col-9 p-0 phase-content">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': dealPhaseData.approved_by_gpm,
                    'col-12': !dealPhaseData.approved_by_gpm
                  }"
                  (click)="isGPMDealCollapsed = !isGPMDealCollapsed"
                  [attr.aria-expanded]="!isGPMDealCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isGPMDealCollapsed,
                      'fa-angle-up': !isGPMDealCollapsed
                    }"
                  ></span>
                  <label
                    *ngIf="dealPhaseData.approved_by_gpm"
                    title="VRM added their input"
                    class="label-ellipsis"
                    >VRM added their input</label
                  >
                  <label
                    *ngIf="!dealPhaseData.approved_by_gpm"
                    title="Pending VRM Input"
                    class="label-ellipsis"
                    >Pending VRM Input</label
                  >
                </button>
              </div>

              <div class="col-3 time-content d-inline-flex align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                  dealPhaseData.gpm_consumed_time
                    ? (dealPhaseData.gpm_consumed_time
                      | opportunityConsumedTime)
                    : ""
                }}</span>

                <span
                  *ngIf="dealPhaseData.approved_by_gpm"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isGPMDealCollapsed">
              <div class="btn_collapse_content">
                <div class="d-grid p-1" *ngIf="dealPhaseData.approved_by_gpm">
                  <label class="phase_details_title">VRM Name</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.gpm_approval_name
                  }}</label>
                  <label class="phase_details_title mt-2">Date & Time</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.gpm_approval_date
                      | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.gpm_consumed_time
                  }}</label>
                </div>
                <input
                  *ngIf="currentUser.isGPM"
                  class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                  value="Add Input to Deal"
                  (click)="editDeal()"
                  type="submit"
                />
              </div>
            </div>
          </div>
          <!-- DEAL GOOGLE REGISTRATION -->
          <div
            class="btn_collapse_parent mt-2"
            id="deal_registration_approval"
            *ngIf="dealPhaseData.show_deal_google_registration_phase"
          >
            <div
              class="row opp_col_content align-items-center"
              style="padding: 5px 13px"
            >
              <div class="col-9 phase-content p-0">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': dealPhaseData.deal_google_registered,
                    'col-12': !dealPhaseData.deal_google_registered
                  }"
                  (click)="
                    isDealGoogleRegisteredCollapsed =
                      !isDealGoogleRegisteredCollapsed
                  "
                  [attr.aria-expanded]="!isDealGoogleRegisteredCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isDealGoogleRegisteredCollapsed,
                      'fa-angle-up': !isDealGoogleRegisteredCollapsed
                    }"
                  ></span>
                  <label
                    *ngIf="dealPhaseData.deal_google_registered"
                    title="Deal Registered on Google"
                    class="label-ellipsis"
                    >Deal Registered on Google</label
                  >
                  <label
                    *ngIf="!dealPhaseData.deal_google_registered"
                    title="Pending Google Registration"
                    class="label-ellipsis"
                    >Pending Google Registration</label
                  >
                </button>
              </div>

              <div class="col-3 d-inline-flex time-content align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    dealPhaseData?.google_registration_consumed_time
                      ? (dealPhaseData?.google_registration_consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>
                <span
                  *ngIf="dealPhaseData.deal_google_registered"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              *ngIf="dealPhaseData.deal_google_registered"
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isDealGoogleRegisteredCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title">VRM Name</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.google_registration_user_name
                  }}</label>
                  <label class="phase_details_title mt-2"
                    >Date & Time of Registration</label
                  >
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.google_registration_date
                      | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.google_registration_consumed_time
                  }}</label>
                  <!-- <input
                  *ngIf="currentUser.isGPM"
                  (click)="editDeal()"
                  class="btn btn-primary small-text btn-color rounded-pill p-1 f-14 align-self-end"
                  value="Edit Deal"
                  type="submit"
                /> -->
                </div>
              </div>
            </div>
          </div>
          <!-- DEAL GOOGLE APPROVAL -->
          <div
            class="btn_collapse_parent mt-2"
            id="deal_registration_approval"
            *ngIf="dealPhaseData.show_deal_google_approval_phase"
          >
            <div
              class="row opp_col_content align-items-center"
              style="padding: 5px 13px"
            >
              <div class="col-9 p-0 d-inline-flex">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': dealPhaseData.deal_google_registered,
                    'col-12': !dealPhaseData.deal_google_registered
                  }"
                  (click)="
                    isDealGoogleApprovedCollapsed =
                      !isDealGoogleApprovedCollapsed
                  "
                  [attr.aria-expanded]="!isDealGoogleApprovedCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isDealGoogleApprovedCollapsed,
                      'fa-angle-up': !isDealGoogleApprovedCollapsed
                    }"
                  ></span>
                  <label
                    *ngIf="dealPhaseData.google_approved_deal"
                    title="Google Approved Deal"
                    class="label-ellipsis"
                    >Google Approved Deal</label
                  >
                  <label
                    *ngIf="!dealPhaseData.google_approved_deal"
                    title="Pending Google Approval"
                    class="label-ellipsis"
                    >Pending Google Approval</label
                  >
                </button>
              </div>

              <div class="col-3 d-inline-flex time-content align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                  dealPhaseData.google_approval_consumed_time
                    ? (dealPhaseData.google_approval_consumed_time
                      | opportunityConsumedTime)
                    : ""
                }}</span>

                <span
                  *ngIf="dealPhaseData.google_approved_deal"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              *ngIf="dealPhaseData.google_approved_deal"
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isDealGoogleApprovedCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title">VRM Name</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.google_approval_user_name
                  }}</label>
                  <label class="phase_details_title mt-2"
                    >Date & Time of Approval</label
                  >
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.google_approval_date
                      | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    dealPhaseData.google_approval_consumed_time
                  }}</label>
                  <!-- <input
                  *ngIf="currentUser.isGPM"
                  (click)="editDeal()"
                  class="btn btn-primary small-text btn-color rounded-pill p-1 f-14 align-self-end"
                  value="Edit Deal"
                  type="submit"
                /> -->
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>

<!-- CRAETE DEAL FORM POPUP -->
<div
  class="modal fade"
  bsModal
  #dealForm="bs-modal"
  tabindex="-1"
  id="dealForm"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          New Deal
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="dealForm.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <app-deal-form
          *ngIf="domain != null && show"
          [addDeal]="addDeal"
          [onlyView]="false"
          [model]="dealPhaseData"
          [isGCP]="!isGWS"
          (closeForm)="saveDeal($event)"
        ></app-deal-form>
      </div>
    </div>
  </div>
</div>
