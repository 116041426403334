import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, HostBinding, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Group, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { DatePipe } from '@angular/common';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { UserModel } from 'src/models/mongo/user.model';
import { from, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class') class = 'h-100';

  title = 'reseller app';

  currentUser: UserModel;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    // if (typeof Worker !== 'undefined') {
    //   // Create a new
    //   const worker = new Worker(new URL('./app.worker', import.meta.url));
    //   worker.onmessage = ({ data }) => {
    //     // var params = new HttpParams().set(Unicode.GROUP, Group.Sale);
    //     // this.http
    //     //   .get(Endpoint.GLOBAL_URL + '' + Endpoint.GET_USERS_BY_GROUP, {
    //     //     params: params,
    //     //   })
    //     //   .subscribe((resp: any) => {
    //     //     if (resp) {
    //     //       localStorage.save(Unicode.SALES_USERS_LIST, JSON.stringify(resp));
    //     //     }
    //     //  });
    //   };
    //   worker.postMessage('hello');
    // } else {
    //   // Web Workers are not supported in this environment.
    //   // You should add a fallback so that your program still executes correctly.
    //   console.log('Web Workers are not supported in this environment');
    // }
  }

  async ngOnInit(): Promise<void> {}

  ngOnDestroy() {}
}
