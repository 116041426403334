import { Component } from '@angular/core';

@Component({
  selector: 'lib-opportunity',
  template: `
    <p>
      opportunity works!
    </p>
  `,
  styles: [
  ]
})
export class OpportunityComponent {

}
