import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Utilities } from 'src/shared/utilities';
import { SettingsService } from '../../../../../src/servcies/backend-services/settings.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sku-price',
  templateUrl: './sku-price.component.html',
  styleUrls: ['./sku-price.component.scss']
})
export class SkuPriceComponent implements OnInit {
  @ViewChild('AddModal')
  AddModal: ModalDirective;
  skuForm: UntypedFormGroup;
  SKUs: any;
  skuId: string;
  googleProducts: any = [];
  plans = [];


  constructor(
    private router:Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService,
  ) {
    this.skuForm = fb.group({
      price: ['', Validators.required],
      planRfid: ['', Validators.required],
      skuRfid: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {

    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );

    this.plans = await Utilities.planList(this.http, this.localStorage);

    this.getAllSkus();

}

  async getAllSkus(): Promise<void> {
    await this.settingsService.getAllSkus().subscribe(
       (response:any) => {
        this.SKUs = response;
         console.log('SKUs:', this.SKUs);
         const pattern = /Google Workspace/g;

         this.SKUs.forEach((sku) => {



             if (sku.sku_name && sku.sku_name.match(pattern)) {
             sku.sku_name = sku.sku_name.replace(pattern, 'GWS');
           }

         });
       },
       (error) => {
         if (error.status === 401) {
           this.localStorage.logout();
           alert('Unauthorized Access');
           this.router.navigate(['./login']);
         }
         console.log('Error fetching SKUs:', error);
       }
     );
   }

  AddNewSku() {
    var SkuData=this.skuForm.value;
    this.settingsService.createSku(SkuData).subscribe(
      (response: any) => {
        console.log('sku created successfully:', response);
        this.SKUs.push(response.skuPrice); // Add the newly created sku to the array
      },
      (error) => {
        console.log('Error creating sku:', error);
      }
    );
  }

  deleteConfirmation(){
    this.settingsService.deleteSku(this.skuId).subscribe(
      (response:any) => {
        console.log('Permission successfully deleted');
      },
      (error) => {
        console.log('Permission not found');
      });
  }

  updateSku(): void {
    const updatedSku = this.skuForm.value;

    this.settingsService.updateSku(this.skuId, updatedSku).subscribe(
      (response) => {
        console.log('Sku updated successfully:', response);
      },
      (error) => {
        console.log('Error updating sku:', error);
      }
    );
  }

  getRowIdToDelete(rowId: string){
    this.skuId = rowId;
  }

  edit(rowiId: string){
    this.skuId = rowiId;

    this.settingsService.getSkuById(this.skuId).subscribe(
      (response:any) => {
        this.skuForm.patchValue(response);
      },
      (error) => {
        console.log('Error fetching sku:', error);
      }
    );
  }

  get price() { return this.skuForm.get('price'); }
  get planRfid() { return this.skuForm.get('planRfid'); }
  get skuRfid() { return this.skuForm.get('skuRfid'); }

}
