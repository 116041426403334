import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { RequestResultModel } from 'src/models/request_result.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  PlanValues,
  OrderAction,
  OrderStatus,
  Endpoint,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'app-paid-service-modal',
  templateUrl: './paid-service-modal.component.html',
  styleUrls: ['./paid-service-modal.component.scss'],
})
export class PaidServiceModalComponent implements OnInit {
  @ViewChild('startPaidServiceModal')
  public startPaidServiceModal: ModalDirective;
  @Input() paidServiceInfo;
  googleProducts = [];
  plans = [];

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private http: HttpClient,
    private httpRequestService: HttpRequestService
  ) {}

  async ngOnInit(): Promise<void> {
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
  }

  show() {
    this.startPaidServiceModal.show();
  }
  hide() {
    this.startPaidServiceModal.hide();
  }
  async startPaidService() {
    if (this.paidServiceInfo.plan.planName.toUpperCase() !== PlanValues.TRIAL) {
      alert('Start paid service only from a free trial subscription');
      return;
    }
    var currentUser: UserModel = JSON.parse(
      this.localStorage.getLoggedinUser()
    );
    var plans = await Utilities.planList(this.http, this.localStorage);
    var googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    var order = new OrderModel();
    order.action = OrderAction.STRAT_PAID_SERVICE;
    order.created_by_rfid = currentUser._id;
    order.domain_rfid = this.paidServiceInfo.customerRfid;
    order.customer_api_id = this.paidServiceInfo.customerId;
    order.is_gcp = false;
    order.order_status = currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    var seatsNbr = 0;
    if (
      this.paidServiceInfo.plan.planName == Unicode.FLEXIBLE_PLAN ||
      this.paidServiceInfo.plan.planName == Unicode.TRIAL_PLAN
    ) {
      seatsNbr = this.paidServiceInfo.seats?.maximumNumberOfSeats ?? 0;
    } else {
      seatsNbr = this.paidServiceInfo.seats?.numberOfSeats ?? 0;
    }
    order.sku = [
      {
        sku_id: this.paidServiceInfo.skuId,
        sku_name: this.paidServiceInfo.skuName,
        plan_id: plans.find(
          (x) => x.code == this.paidServiceInfo.plan?.planName
        )._id,
        license: seatsNbr,
        subscription_api_id: this.paidServiceInfo.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    var toExecute = currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers = this.paidServiceInfo.receivers;
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.startPaidServiceModal.hide();
        } else {
          var msg = resp.message ?? 'Internal Server Error';
          try {
            msg = JSON.parse(resp.message).error.message;
          } catch (e) {}
          alert(msg);
        }
      });
  }
}
