import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { CustomerRequirementsModel } from 'src/models/mongo/customer_requirements.model';
import { EngineerRecommendationsModel } from 'src/models/mongo/engineer_recommendations.model';
import { OpportunityModel } from 'src/models/mongo/opportunity.model';
import { POCModel } from 'src/models/mongo/poc.model';
import { ProposalModel } from 'src/models/mongo/proposal.model';
import { SOWModel } from 'src/models/mongo/scope_of_work.model';
import { Endpoint } from 'src/shared/Endpoint';

@Injectable({
  providedIn: 'root',
})
export class OpportunityService {
  opportunity = signal<OpportunityModel>(new OpportunityModel());
  constructor(private http: HttpClient) {}

  async getOpportunityById(id) {
    var resp: OpportunityModel = await this.http
      .get<OpportunityModel>(
        Endpoint.GLOBAL_URL + Endpoint.GET_OPPORTUNITY_BY_ID + '/' + id
      )
      .toPromise();
    var opp = Object.assign(new OpportunityModel(), resp);
    if (opp.solution_building?.customer_requirements) {
      opp.solution_building.customer_requirements.domain = opp.domain;
      opp.solution_building.customer_requirements = Object.assign(
        new CustomerRequirementsModel(),
        opp.solution_building.customer_requirements
      );
    }
    if (opp.solution_building?.engineering_recommendations) {
      opp.solution_building.engineering_recommendations.domain = opp.domain;
      opp.solution_building.engineering_recommendations = Object.assign(
        new EngineerRecommendationsModel(),
        opp.solution_building.engineering_recommendations
      );
    }
    if (opp.solution_building?.sow) {
      opp.solution_building.sow.domain = opp.domain;
      opp.solution_building.sow = Object.assign(
        new SOWModel(),
        opp.solution_building.sow
      );
    }
    if (opp.poc_phase?.poc) {
      opp.poc_phase.poc.domain = opp.domain;
      opp.poc_phase.poc = Object.assign(new POCModel(), opp.poc_phase.poc);
    }
    if (opp.proposal) {
      opp.proposal.domain = opp.domain;
      opp.proposal = Object.assign(new ProposalModel(), opp.proposal);
    }
    this.opportunity.set(opp);
    return opp;
  }
}
