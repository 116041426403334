<div
  class="modal fade"
  bsModal
  #changeLicenseModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="changeLicenseLabel"
        >
          Change License Cap
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="changeLicenseModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <form
          [formGroup]="LicenseForm"
          role="form"
          #formDir="ngForm"
          id="add-order-license-form"
          novalidate
          class="d-flex flex-column flex-xl-row justify-content-xl-between ng-untouched ng-pristine ng-invalid ms-3 gap-3"
        >
          <div class="row g-3">
            <div class="col d-flex align-items-center">
              <label for="sku" class="me-2">SKU</label>
              <input
                type="text"
                class="form-control rounded-pill"
                id="sku"
                formControlName="sku_name"
              />
            </div>
            <div class="col d-flex align-items-center">
              <label for="licenses-nb" class="text-wrap"
                >Current Nb. of Licenses</label
              >
              <input
                type="text"
                class="form-control rounded-pill"
                formControlName="current_licenses"
                id="licenses-nb"
              />
            </div>

            <div class="row g-3">
              <label for="additional-licenses" class="col-2 text-wrap">{{
                updateLicenseText
              }}</label>
              <div class="col-10">
                <input
                  type="number"
                  (change)="calculatePrices()"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && additional_licenses.invalid
                  }"
                  class="form-control rounded-pill col-10"
                  formControlName="additional_licenses"
                  id="additional-licenses"
                />
              </div>
            </div>

            <div class="row g-1 mb-2">
              <div class="col-2">Google Offer</div>
              <div class="col-2">
                <div class="form-check">
                  <input
                    formControlName="with_google_offer"
                    class="form-check-input"
                    type="radio"
                    [value]="true"
                    name="with_google_offer"
                    (change)="calculatePrices()"
                    id="google-offer-1"
                  />
                  <label class="form-check-label" for="google-offer-1">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    formControlName="with_google_offer"
                    class="form-check-input"
                    type="radio"
                    [value]="false"
                    name="with_google_offer"
                    (change)="calculatePrices()"
                    id="google-offer-2"
                    checked
                  />
                  <label class="form-check-label" for="google-offer-2">
                    No
                  </label>
                </div>
              </div>
              <div
                class="mb-2 d-flex align-items-center justify-content-between col"
              >
                <label
                  for="discount-percentage"
                  class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
                  >Discount %</label
                >
                <input
                  formControlName="google_offer"
                  type="number"
                  (change)="calculatePrices()"
                  min="0"
                  class="form-control rounded-pill"
                  placeholder="0"
                />
              </div>
            </div>
            <div class="row g-1 mb-2">
              <div class="col-2">Customer Offer</div>
              <div class="col-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    formControlName="with_customer_offer"
                    name="with_customer_offer"
                    (change)="calculatePrices()"
                    [value]="true"
                    id="google-offer-21"
                  />
                  <label class="form-check-label" for="google-offer-21">
                    Yes
                  </label>
                </div>
                <div class="form-check">
                  <input
                    formControlName="with_customer_offer"
                    class="form-check-input"
                    type="radio"
                    name="with_customer_offer"
                    id="google-offer-22"
                    (change)="calculatePrices()"
                    [value]="false"
                    checked
                  />
                  <label class="form-check-label" for="google-offer-22">
                    No
                  </label>
                </div>
              </div>
              <div
                class="mb-2 d-flex align-items-center justify-content-between col"
              >
                <label
                  for="discount-percentage"
                  class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
                  >Discount %</label
                >
                <input
                  type="number"
                  min="0"
                  (change)="calculatePrices()"
                  formControlName="customer_offer"
                  class="form-control rounded-pill"
                  placeholder="0"
                />
              </div>
            </div>

            <div class="row g-3">
              <label
                for="start-date"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >Start Date</label
              >
              <div class="col-6">
                <input
                  type="date"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && start_date.invalid
                  }"
                  (change)="calculatePrices()"
                  formControlName="start_date"
                  class="form-control rounded-pill"
                  id="start-date"
                />
              </div>
              <div class="col-4">
                <input
                  type="number"
                  step="1"
                  (ngModelChange)="calculatePrices()"
                  formControlName="annual_months"
                  class="form-control rounded-pill col-3"
                  placeholder="Total Months"
                />
              </div>
            </div>
            <div class="row g-3">
              <label
                for="end-date"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >End Date</label
              >
              <div class="col-10">
                <input
                  type="date"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && end_date.invalid
                  }"
                  formControlName="end_date"
                  (change)="calculatePrices()"
                  class="form-control rounded-pill col-10 my_disabled_input"
                  id="end-date"
                />
              </div>
            </div>

            <div class="row g-3">
              <label
                for="po_id"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >P.O.#</label
              >
              <div class="col-10">
                <input
                  type="text"
                  required
                  placeholder="P.O. #"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && po_id.invalid
                  }"
                  class="form-control rounded-pill"
                  formControlName="po_id"
                  id="po_id"
                />
              </div>
            </div>
            <div class="row g-3">
              <label
                for="order_sale_invoice_nbr"
                class="form-label text-nowrap col-2 me-0 mb-0 d-none d-md-inline"
                >Sale Invoice #</label
              >
              <div class="col-10">
                <input
                  type="text"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && order_sale_invoice_nbr.invalid
                  }"
                  placeholder="Sale Invoice #"
                  class="form-control rounded-pill"
                  formControlName="order_sale_invoice_nbr"
                  id="order_sale_invoice_nbr"
                />
              </div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <label
                  for="source"
                  class="form-label text-nowrap col-2 me-3 mb-0 d-none d-md-inline"
                  >Opportunity Source</label
                >
                <ng-select
                  bindLabel="source"
                  appendTo="body"
                  [searchable]="true"
                  class="col-11 p-0"
                  [clearable]="true"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && opportunity_source.invalid
                  }"
                  formControlName="opportunity_source"
                  placeholder="Source"
                >
                  <ng-option
                    [value]="source"
                    *ngFor="let source of opportunitySources"
                  >
                    {{ source }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-6">
                <label
                  for="opportunity-status"
                  class="form-label text-nowrap col-2 me-3 mb-0 d-none d-md-inline"
                  >Opportunity Status</label
                >
                <ng-select
                  bindLabel="status"
                  appendTo="body"
                  [searchable]="true"
                  class="col-11 p-0"
                  [clearable]="true"
                  [ngClass]="{
                    'is-invalid':
                      formDir.submitted && opportunity_status.invalid
                  }"
                  formControlName="opportunity_status"
                  placeholder="Opportunity Status"
                >
                  <ng-option
                    [value]="status"
                    *ngFor="let status of opportunityStatus"
                  >
                    {{ status }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <label
                  for="section"
                  class="form-label text-nowrap col-2 me-3 mb-0 d-none d-md-inline"
                  >Company Section</label
                >
                <ng-select
                  bindLabel="section"
                  appendTo="body"
                  [searchable]="true"
                  class="col-11 p-0"
                  [clearable]="true"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && company_section.invalid
                  }"
                  formControlName="company_section"
                  placeholder="Company Section"
                >
                  <ng-option
                    [value]="section"
                    *ngFor="let section of companySections"
                  >
                    {{ section }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div
              class="d-flex border border-end-0 border-start-0 border-primary py-3"
            >
              <div class="col px-0">Selling Price</div>
              <div
                class="col-1 text-center product-sans-bold text-primary px-0"
              >
                {{ LicenseForm?.value?.selling_price }}$
              </div>
            </div>
            <div class="d-flex">
              <div class="col px-0">Cost Price</div>
              <div
                class="col-1 text-center product-sans-bold text-primary px-0"
              >
                {{ LicenseForm?.value?.cost_price }}$
              </div>
            </div>
            <div class="text-end">
              <input
                type="submit"
                class="btn btn-primary btn-color rounded-pill col-2"
                (click)="updateLicense()"
                value="Confirm"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
