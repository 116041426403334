<div
  class="px-md-4 rounded-5 w-100 mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 px-2"
>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          alt="back icon" /></a
      >Projects
    </h1>

    <div class="d-flex justify-content-center w-50">
      <input
        id="txt-order-global-search"
        type="search"
        (keyup.enter)="searchProject($event)"
        class="form-control rounded-pill h-36 w-100"
        placeholder="Search by Name"
      />
    </div>

    <div class="d-flex justify-content-end">
      <pagination-controls
        [responsive]="true"
        previousLabel=""
        nextLabel=""
        (pageChange)="renderPage($event)"
      ></pagination-controls>
    </div>
  </div>
  <div class="table-responsive fluid-section bg-white mt-3 w-100 max-h-75">
    <table class="table table-hover align-middle text-center smaller-text">
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 border-top-0"
          >
            Name
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">ID</th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Linked Billing Account
          </th>
          <th
            scope="col"
            class="p-1 p-md-2 col-1 col-md-2 position-relative border-top-0"
          >
            Edit
            <div class="w-100 h-100 bg-white position-absolute top-0"></div>
          </th>
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <ng-container
          *ngFor="
            let row of projectsData
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: pagination,
                    totalItems: total
                  };
            let i = index
          "
        >
          <tr>
            <td class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 py-3">
              {{ row.name }}
            </td>
            <td class="p-1 p-md-2 col-1 col-md-2">
              {{ row.project_id }}
            </td>
            <td
              class="p-1 p-md-2 col-1 col-md-2"
              *ngIf="
                row.billingAccountName && row.billingAccountName != '';
                else nobillingAccounttemp
              "
            >
              {{ row.billingAccountName }}
            </td>
            <ng-template #nobillingAccounttemp>
              <td class="p-1 p-md-2 col-1 col-md-2">-</td>
            </ng-template>
            <td
              class="p-1 p-md-2 text-end text-nowrap col-1 col-md-3 col-lg-2 text-md-end p-0 p-md-2"
            >
              <a *ngIf="currentUser.isEngineer">
                <img
                  class="px-1 px-md-2 cursor-pointer"
                  src="./assets/img/edit-icon.svg"
                  data-bs-toggle="tooltip"
                  (click)="editProjectPopup(row.number, row.name)"
                  title="edit project"
                  alt="edit icon"
                />
              </a>
              <ng-template [ngIf]="currentUser.isFinanceTeam">
                <button
                  *ngIf="row.billingAccountName && row.billingAccountName != ''"
                  (click)="linkProjectPopup(row.number, row.name)"
                  class="btn btn-primary d-md-inline rounded-pill btn-color smaller-text w-165"
                >
                  Change Billing Account
                </button>
                <button
                  *ngIf="
                    row.billingAccountName == '' ||
                    row.billingAccountName == null
                  "
                  (click)="linkProjectPopup(row.number, row.name)"
                  class="btn btn-primary d-md-inline rounded-pill btn-color smaller-text w-165"
                >
                  Link To Billing
                </button>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<link-project-popup
  [projectRfId]="selectedProject._id"
  [projectName]="selectedProject.name"
  (linkProj)="linkingDone($event)"
  #linkProjectModal
></link-project-popup>

<div
  class="modal fade"
  bsModal
  #editProjectModal="bs-modal"
  tabindex="-1"
  id="editProjectModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 col-11 product-sans-medium">Project</h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="editProjectModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <span class="blue_1 fs-5">Edit the Display Name</span>
        <input
          type="text"
          class="rounded-pill form-control mt-3"
          id="project_display_name"
          [(ngModel)]="selectedProject.name"
          placeholder="Project Display Name"
          required
        />
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="editProject()"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
