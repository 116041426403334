<div id="opp_solution_parent_step" class="container d-flex flex-column">
  <div
    class="opp_solution_step_new h-100"
    [ngClass]="{ step_skipped: skip == true }"
    *ngIf="
      solutionPhaseData?.customer_requirements == null &&
        solutionPhaseData?.sow == null;
      else opp_solution_building_step
    "
  >
    <div class="step_count">
      <span class="notification-icon--fixed">
        <small class="notification-badge f-20">2</small>
      </span>
    </div>
    <div class="text-center mt-2">
      <img
        src="./assets/img/ic_step_solution_building.svg"
        class="back-icon icon_step"
        alt="solution building icon"
      />
    </div>
    <div class="text-center txt_deal_phase mt-2">
      <span class="col-1">Solution Building Phase</span>
    </div>
    <div class="row">
      <button
        [ngClass]="{
          phases_disabled_btn: !currentUser.isPreSales || disabled == true,
          cursor_none: skip == true
        }"
        (click)="addCustomerRequirements()"
        class="col-10 mt-2 offset-1 btn d-none d-md-inline rounded-pill btn-bg-white smaller-text"
      >
        Add Customer Requirements
      </button>
    </div>
    <div class="new-div mt-auto mb-3 offset-3 div_skip">
      <div
        class="product-sans-light f-12"
        [ngClass]="{
          f_black: skip == true,
          'product-sans-medium': skip == true
        }"
      >
        <label class="switch mb-1">
          <input
            type="checkbox"
            [(ngModel)]="skip"
            (change)="skipStep($event)"
          />
          <span class="slider"></span>
        </label>
        Skip this step
      </div>
    </div>
  </div>

  <ng-template #opp_solution_building_step>
    <div class="opp_solution_step h-100">
      <div class="solution_step_header d-flex flex-row">
        <span class="flex-column col-1 f-20">2</span>
        <label class="text-nowrap mt-1 f-15 flex_auto col-4"
          >Solution Building</label
        >
        <div class="flex-column pe-2 text-end col-4">
          <span class="f-12 d-grid"
            >time
            <span>{{ solutionPhaseData?.solution_building_time }}</span></span
          >
        </div>
        <img
          src="./assets/img/ic_time_expand.svg"
          class="ic_time col-2 text-end"
        />
      </div>
      <div class="solution_step_content product-sans">
        <!-- CUSTOMER REQ -->
        <ng-template [ngIf]="solutionPhaseData?.customer_requirements">
          <div class="btn_collapse_parent mt-2" id="cust_req_creation">
            <div class="row opp_col_content align-items-center">
              <div class="col-9 phase-content p-0">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 label-ellipsis"
                  (click)="isAddedCustReqCollapsed = !isAddedCustReqCollapsed"
                  data-toggle="tooltip"
                  title="PreSales added Customer Requirements"
                  [attr.aria-expanded]="!isAddedCustReqCollapsed"
                  aria-controls="collapseCustReq"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                  'fa-angle-down': isAddedCustReqCollapsed,
                  'fa-angle-up': !isAddedCustReqCollapsed,
                }"
                  ></span>
                  PreSales added Customer Requirements
                </button>
              </div>
              <div class="col-3 time-content d-inline-flex">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                  solutionPhaseData?.customer_requirements.consumed_time
                    ? (solutionPhaseData?.customer_requirements.consumed_time
                      | opportunityConsumedTime)
                    : ""
                }}</span>

                <span
                  class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isAddedCustReqCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title">PreSales Name</label>
                  <label class="phase_details_data_txt">{{
                    solutionPhaseData.customer_requirements.user
                      ?.created_user_name
                  }}</label>
                  <label class="phase_details_title mt-2"
                    >Date & Time of Creation</label
                  >
                  <label class="phase_details_data_txt">{{
                    solutionPhaseData.customer_requirements.created_at
                      | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    solutionPhaseData.customer_requirements.consumed_time
                  }}</label>
                  <input
                    (click)="reviewCustReq()"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    value="Review"
                  />
                </div>
              </div>
            </div>
          </div>
          <ng-template [ngIf]="!solutionPhaseData.customer_requirements.draft">
            <!-- PRESALES MANAGER APPROVAL -->
            <div
              class="btn_collapse_parent mt-2"
              id="presales_manager_approval"
              *ngIf="
                solutionPhaseData.customer_requirements.managerApprovalPart
              "
            >
              <div class="row opp_col_content align-items-center">
                <div class="col-9 phase-content p-0">
                  <button
                    type="button"
                    class="btn_collapse_header w-100 d-inline-flex"
                    [ngClass]="{
                      'col-10':
                        solutionPhaseData.customer_requirements
                          .managerApprovalPart.date,
                      'col-12':
                        !solutionPhaseData.customer_requirements
                          .managerApprovalPart.date
                    }"
                    (click)="
                      isManagerCustReqApprovalCollapsed =
                        !isManagerCustReqApprovalCollapsed
                    "
                    [attr.aria-expanded]="!isManagerCustReqApprovalCollapsed"
                    aria-controls="collapseManager"
                  >
                    <span
                      class="fa p-1"
                      [ngClass]="{
                        'fa-angle-down': isManagerCustReqApprovalCollapsed,
                        'fa-angle-up': !isManagerCustReqApprovalCollapsed
                      }"
                    ></span>
                    <label
                      data-toggle="tooltip"
                      title="Approved by PreSales Manager"
                      class="label-ellipsis col-10"
                      *ngIf="
                        solutionPhaseData.customer_requirements
                          .managerApprovalPart.date
                      "
                      >{{
                        solutionPhaseData.customer_requirements
                          .managerApprovalPart.approved
                          ? "Approved"
                          : "rejected"
                      }}
                      by PreSales Manager</label
                    >
                    <label
                      data-toggle="tooltip"
                      title="Pending PreSales Manager Approval"
                      class="label-ellipsis col-10"
                      *ngIf="
                        !solutionPhaseData.customer_requirements
                          .managerApprovalPart.date
                      "
                      >Pending PreSales Manager Approval</label
                    >
                  </button>
                </div>
                <ng-template
                  [ngIf]="
                    solutionPhaseData.customer_requirements.managerApprovalPart
                      .date
                  "
                >
                  <div class="col-3 time-content d-inline-flex">
                    <span
                      class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                      >{{
                        solutionPhaseData?.customer_requirements
                          .managerApprovalPart.consumed_time
                          ? (solutionPhaseData?.customer_requirements
                              .managerApprovalPart.consumed_time
                            | opportunityConsumedTime)
                          : ""
                      }}</span
                    >
                    <span
                      class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                    ></span>
                  </div>
                </ng-template>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isManagerCustReqApprovalCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <label class="phase_details_title"
                      >PreSales Manager Name</label
                    >
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.customer_requirements
                        .managerApprovalPart.name
                    }}</label>
                    <ng-template
                      [ngIf]="
                        solutionPhaseData.customer_requirements
                          .managerApprovalPart.date
                      "
                    >
                      <label class="phase_details_title mt-2"
                        >Date & Time of
                        {{
                          solutionPhaseData.customer_requirements
                            .managerApprovalPart.approved
                            ? "Approval"
                            : "Rejection"
                        }}</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.customer_requirements
                          .managerApprovalPart.date
                          | date : "dd MMM,yyyy - hh:mma"
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.customer_requirements
                          .managerApprovalPart.consumed_time
                      }}</label>
                      <div
                        class="d-grid mt-2"
                        *ngIf="
                          solutionPhaseData.customer_requirements
                            .managerApprovalPart.rejection_reason &&
                          solutionPhaseData.customer_requirements
                            .managerApprovalPart.rejection_reason != ''
                        "
                      >
                        <label class="phase_details_title"
                          >Rejection Reason</label
                        >
                        <label class="phase_details_data_txt">{{
                          solutionPhaseData.customer_requirements
                            .managerApprovalPart
                            .rejection_reasonrejection_reason
                        }}</label>
                      </div>
                    </ng-template>
                    <input
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      (click)="reviewCustReq()"
                      value="Review"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- SENDING TO CUSTOMER PART -->
            <div
              class="btn_collapse_parent mt-2"
              id="cust_req_customer1"
              *ngIf="
                solutionPhaseData.customer_requirements.sendingToCustomerPart
              "
            >
              <div class="row opp_col_content align-items-center">
                <div class="col-9 phase-content p-0">
                  <button
                    type="button"
                    class="btn_collapse_header w-100 d-inline-flex"
                    [ngClass]="{
                      'col-10':
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date,
                      'col-12':
                        !solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date
                    }"
                    (click)="
                      isSendingCustReqCollapsed = !isSendingCustReqCollapsed
                    "
                    [attr.aria-expanded]="!isSendingCustReqCollapsed"
                    aria-controls="collapseManager"
                  >
                    <span
                      class="fa p-1"
                      [ngClass]="{
                        'fa-angle-down': isSendingCustReqCollapsed,
                        'fa-angle-up': !isSendingCustReqCollapsed
                      }"
                    ></span>
                    <label
                      class="col-10 label-ellipsis"
                      data-toggle="tooltip"
                      title="PreSales sent Customer Requirements to Customer"
                      *ngIf="
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date
                      "
                      >PreSales sent Customer Requirements to Customer
                    </label>
                    <label
                      class="col-10 label-ellipsis"
                      data-toggle="tooltip"
                      title="Pending Sending Customer Requirements to Customer by
                  PreSales"
                      *ngIf="
                        !solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date
                      "
                      >Pending Sending Customer Requirements to Customer by
                      PreSales</label
                    >
                  </button>
                </div>
                <ng-template
                  [ngIf]="
                    solutionPhaseData.customer_requirements
                      .sendingToCustomerPart.date
                  "
                >
                  <div class="col-3 time-content d-inline-flex">
                    <span
                      class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                      >{{
                        solutionPhaseData?.customer_requirements
                          .sendingToCustomerPart.consumed_time
                          ? (solutionPhaseData?.customer_requirements
                              .sendingToCustomerPart.consumed_time
                            | opportunityConsumedTime)
                          : ""
                      }}</span
                    >
                    <span
                      class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                    ></span>
                  </div>
                </ng-template>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isSendingCustReqCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <ng-template
                      [ngIf]="
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date
                      "
                    >
                      <label class="phase_details_title">PreSales Name</label>
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.name
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Date & Time of Sending</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date
                          | date : "dd MMM,yyyy - hh:mma"
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.consumed_time
                      }}</label>
                    </ng-template>
                    <input
                      *ngIf="
                        !solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date
                      "
                      [ngClass]="{
                        phases_disabled_btn: !currentUser.isPreSales
                      }"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      (click)="sendCustReqToCustPopup()"
                      value="Send to Customer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--CUSTOMER APPROVAL -->
            <div
              class="btn_collapse_parent mt-2"
              id="customer_requirements_customer2"
              *ngIf="
                solutionPhaseData.customer_requirements.sendingToCustomerPart
                  ?.date
              "
            >
              <div class="row opp_col_content align-items-center">
                <div class="col-9 p-0 phase-content">
                  <button
                    type="button"
                    class="btn_collapse_header w-100 d-inline-flex"
                    [ngClass]="{
                      'col-10':
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date,
                      'col-12':
                        !solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.date
                    }"
                    (click)="
                      isCustApprovedCustReqCollapsed =
                        !isCustApprovedCustReqCollapsed
                    "
                    [attr.aria-expanded]="!isCustApprovedCustReqCollapsed"
                    aria-controls="collapseManager"
                  >
                    <span
                      class="fa p-1"
                      [ngClass]="{
                        'fa-angle-down': isCustApprovedCustReqCollapsed,
                        'fa-angle-up': !isCustApprovedCustReqCollapsed
                      }"
                    ></span>
                    <label
                      data-toggle="tooltip"
                      title="Customer Approved Customer Requirements"
                      class="col-10 label-ellipsis"
                      *ngIf="
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.approval_date
                      "
                      >Customer
                      {{
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.approved
                          ? "Approved"
                          : "rejected"
                      }}
                      Customer Requirements
                    </label>
                    <label
                      data-toggle="tooltip"
                      title="Pending Customer Approval on Customer Requirements"
                      class="col-10 label-ellipsis"
                      *ngIf="
                        !solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.approval_date
                      "
                    >
                      Pending Customer Approval on Customer Requirements</label
                    >
                  </button>
                </div>
                <ng-template
                  [ngIf]="
                    solutionPhaseData.customer_requirements
                      .sendingToCustomerPart.approval_date
                  "
                >
                  <div class="col-3 time-content d-inline-flex">
                    <span
                      class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                    >
                      {{
                        solutionPhaseData?.customer_requirements
                          ?.sendingToCustomerPart
                          .customer_approval_consumed_time
                          ? (solutionPhaseData.customer_requirements
                              .sendingToCustomerPart
                              .customer_approval_consumed_time
                            | opportunityConsumedTime)
                          : ""
                      }}
                    </span>

                    <span
                      class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                    ></span>
                  </div>
                </ng-template>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isCustApprovedCustReqCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <label class="phase_details_title">Customer Name</label>
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.customer_requirements
                        .sendingToCustomerPart.customer_name
                    }}</label>
                    <ng-template
                      [ngIf]="
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.approval_date
                      "
                    >
                      <label class="phase_details_title mt-2"
                        >Date & Time of
                        {{
                          solutionPhaseData.customer_requirements
                            .sendingToCustomerPart.approved
                            ? "Approval"
                            : "Rejection"
                        }}</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.approval_date
                          | date : "dd MMM,yyyy - hh:mma"
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.customer_requirements
                          .sendingToCustomerPart.customer_approval_consumed_time
                      }}</label>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
        <!-- ENG RECOMD -->
        <ng-template
          [ngIf]="
            solutionPhaseData?.customer_requirements?.sendingToCustomerPart
              ?.approval_date &&
            solutionPhaseData?.customer_requirements?.sendingToCustomerPart
              .approved
          "
        >
          <div class="btn_collapse_parent mt-2" id="eng_recomd_creation">
            <div class="row opp_col_content align-items-center">
              <div class="col-9 phase-content p-0">
                <button
                  type="button"
                  class="btn_collapse_header w-100 d-inline-flex"
                  (click)="isAddedEngRecCollapsed = !isAddedEngRecCollapsed"
                  [attr.aria-expanded]="!isAddedEngRecCollapsed"
                  aria-controls="collapseCustReq"
                  [ngClass]="{
                    'col-10': solutionPhaseData.engineering_recommendations,
                    'col-12': !solutionPhaseData.engineering_recommendations
                  }"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                  'fa-angle-down': isAddedEngRecCollapsed,
                  'fa-angle-up': !isAddedEngRecCollapsed,
                }"
                  ></span>
                  <label
                    data-toggle="tooltip"
                    title="Engineer added their Recommendations"
                    class="col-10 label-ellipsis"
                    *ngIf="solutionPhaseData.engineering_recommendations"
                    >Engineer added their Recommendations</label
                  >
                  <label
                    data-toggle="tooltip"
                    title="Pending Engineer Recommendations"
                    class="col-10 label-ellipsis"
                    *ngIf="!solutionPhaseData.engineering_recommendations"
                    >Pending Engineer Recommendations</label
                  >
                </button>
              </div>
              <ng-template
                [ngIf]="solutionPhaseData.engineering_recommendations"
              >
                <div class="col-3 time-content d-inline-flex">
                  <span
                    class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                    >{{
                      solutionPhaseData?.engineering_recommendations
                        .consumed_time
                        ? (solutionPhaseData?.engineering_recommendations
                            .consumed_time | opportunityConsumedTime)
                        : ""
                    }}</span
                  >
                  <span
                    class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                  ></span>
                </div>
              </ng-template>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isAddedEngRecCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <ng-template
                    [ngIf]="solutionPhaseData.engineering_recommendations"
                  >
                    <label class="phase_details_title">Engineer Name</label>
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.engineering_recommendations.user
                        ?.created_user_name
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Date & Time of Creation</label
                    >
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.engineering_recommendations.created_at
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.engineering_recommendations
                        .consumed_time
                    }}</label>
                    <input
                      (click)="reviewEngRecom()"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      value="Review"
                    />
                  </ng-template>
                  <ng-template
                    [ngIf]="!solutionPhaseData.engineering_recommendations"
                  >
                    <input
                      [ngClass]="{
                        phases_disabled_btn: !currentUser.isPreSales
                      }"
                      (click)="addEngRecommendations()"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      value="Add the Recommendations"
                    />
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <ng-template
            [ngIf]="!solutionPhaseData?.engineering_recommendations?.draft"
          >
            <!-- PRESALES MANAGER APPROVAL -->
            <div
              class="btn_collapse_parent mt-2"
              id="eng_recomd_manager_approval"
              *ngIf="
                solutionPhaseData?.engineering_recommendations
                  ?.managerApprovalPart
              "
            >
              <div class="row opp_col_content align-items-center">
                <div class="col-9 phase-content p-0">
                  <button
                    type="button"
                    class="btn_collapse_header w-100 d-inline-flex"
                    [ngClass]="{
                      'col-10':
                        solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.date,
                      'col-12':
                        !solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.date
                    }"
                    (click)="
                      isManagerEngRecApprovalCollapsed =
                        !isManagerEngRecApprovalCollapsed
                    "
                    [attr.aria-expanded]="!isManagerEngRecApprovalCollapsed"
                    aria-controls="collapseManager"
                  >
                    <span
                      class="fa p-1"
                      [ngClass]="{
                        'fa-angle-down': isManagerEngRecApprovalCollapsed,
                        'fa-angle-up': !isManagerEngRecApprovalCollapsed
                      }"
                    ></span>
                    <label
                      data-toggle="tooltip"
                      title="Approved by Engineer Manager"
                      class="col-10 label-ellipsis"
                      *ngIf="
                        solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.date
                      "
                      >{{
                        solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.approved
                          ? "Approved"
                          : "rejected"
                      }}
                      by Engineer Manager</label
                    >
                    <label
                      data-toggle="tooltip"
                      title="Pending Engineer Manager Approval"
                      class="col-10 label-ellipsis"
                      *ngIf="
                        !solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.date
                      "
                      >Pending Engineer Manager Approval</label
                    >
                  </button>
                </div>
                <ng-template
                  [ngIf]="
                    solutionPhaseData.engineering_recommendations
                      .managerApprovalPart.date
                  "
                >
                  <div class="col-3 time-content d-inline-flex">
                    <span
                      class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                      >{{
                        solutionPhaseData?.engineering_recommendations
                          .managerApprovalPart.consumed_time
                          ? (solutionPhaseData?.engineering_recommendations
                              .managerApprovalPart.consumed_time
                            | opportunityConsumedTime)
                          : ""
                      }}</span
                    >
                    <span
                      class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                    ></span>
                  </div>
                </ng-template>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isManagerEngRecApprovalCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <label class="phase_details_title"
                      >Engineer Manager Name</label
                    >
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.engineering_recommendations
                        .approval_phases[
                        solutionPhaseData.engineering_recommendations
                          .approval_phases.length - 1
                      ].name
                    }}</label>
                    <ng-template
                      [ngIf]="
                        solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.date
                      "
                    >
                      <label class="phase_details_title mt-2"
                        >Date & Time of
                        {{
                          solutionPhaseData.engineering_recommendations
                            .managerApprovalPart.approved
                            ? "Approval"
                            : "Rejection"
                        }}</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.date
                          | date : "dd MMM,yyyy - hh:mma"
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.engineering_recommendations
                          .managerApprovalPart.consumed_time
                      }}</label>
                      <div
                        class="d-grid mt-2"
                        *ngIf="
                          solutionPhaseData.engineering_recommendations
                            .managerApprovalPart.rejection_reason &&
                          solutionPhaseData.engineering_recommendations
                            .managerApprovalPart.rejection_reason != ''
                        "
                      >
                        <label class="phase_details_title"
                          >Rejection Reason</label
                        >
                        <label class="phase_details_data_txt">{{
                          solutionPhaseData.engineering_recommendations
                            .managerApprovalPart.rejection_reason
                        }}</label>
                      </div>
                    </ng-template>
                    <input
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      (click)="reviewEngRecom()"
                      value="Review"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- SENDING TO CUSTOMER PART -->
            <div
              class="btn_collapse_parent mt-2"
              id="eng_reco_customer1"
              *ngIf="
                solutionPhaseData.engineering_recommendations
                  ?.sendingToCustomerPart
              "
            >
              <div class="row opp_col_content align-items-center">
                <div class="col-9 p-0 phase-content">
                  <button
                    type="button"
                    class="btn_collapse_header col-10 w-100 d-inline-flex"
                    [ngClass]="{
                      'col-10':
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date,
                      'col-12':
                        !solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date
                    }"
                    (click)="
                      isSendingCustEngRecCollapsed =
                        !isSendingCustEngRecCollapsed
                    "
                    [attr.aria-expanded]="!isSendingCustEngRecCollapsed"
                    aria-controls="collapseManager"
                  >
                    <span
                      class="fa p-1"
                      [ngClass]="{
                        'fa-angle-down': isSendingCustEngRecCollapsed,
                        'fa-angle-up': !isSendingCustEngRecCollapsed
                      }"
                    ></span>
                    <label
                      data-toggle="tooltip"
                      title="PreSales sent Engineer Recommendations to Customer"
                      class="label-ellipsis col-10"
                      *ngIf="
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date
                      "
                      >PreSales sent Engineer Recommendations to Customer
                    </label>
                    <label
                      data-toggle="tooltip"
                      title="Pending Sending Engineer Recommendations to Customer by
                  PreSales"
                      class="label-ellipsis col-10"
                      *ngIf="
                        !solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date
                      "
                      >Pending Sending Engineer Recommendations to Customer by
                      PreSales</label
                    >
                  </button>
                </div>
                <ng-template
                  [ngIf]="
                    solutionPhaseData.engineering_recommendations
                      .sendingToCustomerPart.date
                  "
                >
                  <div class="col-3 time-content d-inline-flex">
                    <span
                      class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                      >{{
                        solutionPhaseData?.engineering_recommendations
                          .sendingToCustomerPart.consumed_time
                          ? (solutionPhaseData?.engineering_recommendations
                              .sendingToCustomerPart.consumed_time
                            | opportunityConsumedTime)
                          : ""
                      }}</span
                    >
                    <span
                      class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                    ></span>
                  </div>
                </ng-template>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isSendingCustEngRecCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <ng-template
                      [ngIf]="
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date
                      "
                    >
                      <label class="phase_details_title">PreSales Name</label>
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.name
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Date & Time of Sending</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date
                          | date : "dd MMM,yyyy - hh:mma"
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.consumed_time
                      }}</label>
                    </ng-template>
                    <input
                      *ngIf="
                        !solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date
                      "
                      [ngClass]="{
                        phases_disabled_btn: !currentUser.isPreSales
                      }"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      (click)="sendEngRecToCustPopup()"
                      value="Send to Customer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--CUSTOMER APPROVAL -->
            <div
              class="btn_collapse_parent mt-2"
              id="eng_reco_customer2"
              *ngIf="
                solutionPhaseData.engineering_recommendations
                  ?.sendingToCustomerPart?.date
              "
            >
              <div class="row opp_col_content align-items-center">
                <div class="col-9 p-0 phase-content">
                  <button
                    type="button"
                    class="btn_collapse_header w-100 d-inline-flex"
                    [ngClass]="{
                      'col-10':
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date,
                      'col-12':
                        !solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.date
                    }"
                    (click)="
                      isCustApprovedEngRecCollapsed =
                        !isCustApprovedEngRecCollapsed
                    "
                    [attr.aria-expanded]="!isCustApprovedEngRecCollapsed"
                    aria-controls="collapseManager"
                  >
                    <span
                      class="fa p-1"
                      [ngClass]="{
                        'fa-angle-down': isCustApprovedEngRecCollapsed,
                        'fa-angle-up': !isCustApprovedEngRecCollapsed
                      }"
                    ></span>
                    <label
                      data-toggle="tooltip"
                      title="Customer Approved Engineer Recommendations"
                      class="label-ellipsis col-10"
                      *ngIf="
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.approval_date
                      "
                      >Customer
                      {{
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.approved
                          ? "Approved"
                          : "rejected"
                      }}
                      Engineer Recommendations
                    </label>
                    <label
                      data-toggle="tooltip"
                      title="Pending Customer Approval on Engineer Recommendations"
                      class="label-ellipsis col-10"
                      *ngIf="
                        !solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.approval_date
                      "
                      >Pending Customer Approval on Engineer
                      Recommendations</label
                    >
                  </button>
                </div>

                <ng-template
                  [ngIf]="
                    solutionPhaseData.engineering_recommendations
                      .sendingToCustomerPart.approval_date
                  "
                >
                  <div class="col-3 d-inline-flex time-content">
                    <span
                      class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                      >{{
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.customer_approval_consumed_time
                          ? (solutionPhaseData.engineering_recommendations
                              .sendingToCustomerPart
                              .customer_approval_consumed_time
                            | opportunityConsumedTime)
                          : ""
                      }}</span
                    >
                    <span
                      class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                    ></span>
                  </div>
                </ng-template>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isCustApprovedEngRecCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <label class="phase_details_title">Customer Name</label>
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.engineering_recommendations
                        .sendingToCustomerPart.customer_name
                    }}</label>
                    <ng-template
                      [ngIf]="
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.approval_date
                      "
                    >
                      <label class="phase_details_title mt-2"
                        >Date & Time of
                        {{
                          solutionPhaseData.engineering_recommendations
                            .sendingToCustomerPart.approved
                            ? "Approval"
                            : "Rejection"
                        }}</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.approval_date
                          | date : "dd MMM,yyyy - hh:mma"
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.engineering_recommendations
                          .sendingToCustomerPart.customer_approval_consumed_time
                      }}</label>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
        <!-- SOW  -->
        <ng-template
          [ngIf]="
            solutionPhaseData.customer_requirements?.sendingToCustomerPart
              ?.approval_date &&
            solutionPhaseData.engineering_recommendations?.sendingToCustomerPart
              ?.approval_date
          "
        >
          <div class="btn_collapse_parent mt-2" id="sow_creation">
            <div class="row opp_col_content align-items-center">
              <div class="col-9 p-0 phase-content">
                <button
                  type="button"
                  class="btn_collapse_header w-100 d-inline-flex"
                  (click)="isCreatedSOWCollapsed = !isCreatedSOWCollapsed"
                  [attr.aria-expanded]="!isCreatedSOWCollapsed"
                  aria-controls="collapseCustReq"
                  [ngClass]="{
                    'col-10': solutionPhaseData.sow,
                    'col-12': !solutionPhaseData.sow
                  }"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                  'fa-angle-down': isCreatedSOWCollapsed,
                  'fa-angle-up': !isCreatedSOWCollapsed,
                }"
                  ></span>
                  <label
                    data-toggle="tooltip"
                    title="PreSales Created Customer SOW"
                    class="label-ellipsis col-10"
                    *ngIf="solutionPhaseData.sow"
                    >PreSales Created Customer SOW</label
                  >
                  <label
                    data-toggle="tooltip"
                    title="Pending Customer SOW by PreSales"
                    class="label-ellipsis col-10"
                    *ngIf="!solutionPhaseData.sow"
                    >Pending Customer SOW by PreSales</label
                  >
                </button>
              </div>
              <ng-template [ngIf]="solutionPhaseData.sow">
                <div class="col-3 d-inline-flex time-content">
                  <span
                    class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                    >{{
                      solutionPhaseData.sow.consumed_time
                        ? (solutionPhaseData.sow.consumed_time
                          | opportunityConsumedTime)
                        : ""
                    }}</span
                  >
                  <span
                    class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                  ></span>
                </div>
              </ng-template>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isCreatedSOWCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <ng-template
                    [ngIf]="
                      solutionPhaseData.sow && solutionPhaseData.sow.user_sow
                    "
                  >
                    <label class="phase_details_title">PreSales Name</label>
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.sow.user_sow.created_user_name
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Date & Time of Creation</label
                    >
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.sow.created_at
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label
                    ><label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.sow.consumed_time
                    }}</label>
                    <input
                      (click)="reviewSOW()"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      value="Review Customer SOW"
                    />
                  </ng-template>
                  <ng-template [ngIf]="!solutionPhaseData.sow">
                    <input
                      [ngClass]="{
                        phases_disabled_btn: !currentUser.isPreSales
                      }"
                      (click)="createSOWGoogleDoc()"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      value="Create Customer SOW"
                    />
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <!-- SOW APPROVAL PROCESS -->
          <ng-template
            [ngIf]="
              solutionPhaseData?.sow?.approval_phases &&
              !solutionPhaseData.sow.draft
            "
          >
            <!-- PRESALES MANAGER APPROVAL -->
            <div
              class="btn_collapse_parent mt-2"
              id="sow_manager_approval"
              *ngIf="solutionPhaseData.sow.managerApprovalPart"
            >
              <div class="row opp_col_content align-items-center">
                <div class="col-9 phase-content p-0">
                  <button
                    type="button"
                    class="btn_collapse_header col-10 w-100 d-inline-flex"
                    [ngClass]="{
                      'col-10': solutionPhaseData.sow.managerApprovalPart.date,
                      'col-12': !solutionPhaseData.sow.managerApprovalPart.date
                    }"
                    (click)="
                      isManagerApprovedSOWCollapsed =
                        !isManagerApprovedSOWCollapsed
                    "
                    [attr.aria-expanded]="!isManagerApprovedSOWCollapsed"
                    aria-controls="collapseManager"
                  >
                    <span
                      class="fa p-1"
                      [ngClass]="{
                        'fa-angle-down': isManagerApprovedSOWCollapsed,
                        'fa-angle-up': !isManagerApprovedSOWCollapsed
                      }"
                    ></span>
                    <label
                      data-toggle="tooltip"
                      title="Customer SOW Approved by PreSales Manager"
                      class="label-ellipsis col-10"
                      *ngIf="solutionPhaseData.sow.managerApprovalPart.date"
                      >Customer SOW
                      {{
                        solutionPhaseData.sow.managerApprovalPart.approved
                          ? "Approved"
                          : "rejected"
                      }}
                      by PreSales Manager</label
                    >
                    <label
                      data-toggle="tooltip"
                      title="Pending Customer SOW Approved by PreSales Manager"
                      class="label-ellipsis col-10"
                      *ngIf="!solutionPhaseData.sow.managerApprovalPart.date"
                      >Pending Customer SOW Approved by PreSales Manager</label
                    >
                  </button>
                </div>
                <ng-template
                  [ngIf]="solutionPhaseData.sow.managerApprovalPart.date"
                >
                  <div class="col-3 time-content d-inline-flex">
                    <span
                      class="col-1 mt-1 float-end p-0 text-end w-fit-content"
                      >{{
                        solutionPhaseData.sow.managerApprovalPart.consumed_time
                          ? (solutionPhaseData.sow.managerApprovalPart
                              .consumed_time | opportunityConsumedTime)
                          : ""
                      }}</span
                    >
                    <span
                      class="check_green col-1 fa fa-check fa-thin float-end text-end mt-2 w-fit-content p-0"
                    ></span>
                  </div>
                </ng-template>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isManagerApprovedSOWCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <label class="phase_details_title"
                      >Engineer Manager Name</label
                    >
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.sow.managerApprovalPart.name
                    }}</label>
                    <ng-template
                      [ngIf]="solutionPhaseData.sow.managerApprovalPart.date"
                    >
                      <label class="phase_details_title mt-2"
                        >Date & Time of
                        {{
                          solutionPhaseData.sow.managerApprovalPart.approved
                            ? "Approval"
                            : "Rejection"
                        }}</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.sow.managerApprovalPart.date
                          | date : "dd MMM,yyyy - hh:mma"
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.sow.managerApprovalPart.consumed_time
                      }}</label>
                      <div
                        class="d-grid mt-2"
                        *ngIf="
                          solutionPhaseData.sow.managerApprovalPart
                            .rejection_reason &&
                          solutionPhaseData.sow.managerApprovalPart
                            .rejection_reason != ''
                        "
                      >
                        <label class="phase_details_title"
                          >Rejection Reason</label
                        >
                        <label class="phase_details_data_txt">{{
                          solutionPhaseData.sow.managerApprovalPart
                            .rejection_reason
                        }}</label>
                      </div>
                    </ng-template>
                    <input
                      *ngIf="
                        currentUser._id ==
                        solutionPhaseData?.sow?.managerApprovalPart?.id
                      "
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      (click)="reviewSOW()"
                      value="Review Customer SOW"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- SENDING TO CUSTOMER PART -->
            <!-- <div
              class="btn_collapse_parent mt-2"
              id="sow_customer1"
              *ngIf="solutionPhaseData.sow.sendingToCustomerPart"
            >
              <div class="row" style="padding: 5px 13px">
                <button
                  type="button"
                  class="btn_collapse_header col-10"
                  [ngClass]="{
                    'col-10': solutionPhaseData.sow.sendingToCustomerPart.date,
                    'col-12': !solutionPhaseData.sow.sendingToCustomerPart.date
                  }"
                  (click)="
                    isSendingCustSOWCollapsed = !isSendingCustSOWCollapsed
                  "
                  [attr.aria-expanded]="!isSendingCustSOWCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isSendingCustSOWCollapsed,
                      'fa-angle-up': !isSendingCustSOWCollapsed
                    }"
                  ></span>
                  <label
                    class="label-ellipsis"
                    *ngIf="solutionPhaseData.sow.sendingToCustomerPart.date"
                    >PreSales sent SOW to Customer
                  </label>
                  <label
                    class="label-ellipsis"
                    *ngIf="!solutionPhaseData.sow.sendingToCustomerPart.date"
                    >Pending Sending SOW to Customer by PreSales</label
                  >
                </button>
                <span
                  *ngIf="solutionPhaseData.sow.sendingToCustomerPart.date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isSendingCustSOWCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <ng-template
                      [ngIf]="solutionPhaseData.sow.sendingToCustomerPart.date"
                    >
                      <label class="phase_details_title">PreSales Name</label>
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.sow.sendingToCustomerPart.name
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Date & Time of Sending</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.sow.sendingToCustomerPart.string_date
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.sow.sendingToCustomerPart
                          .consumed_time
                      }}</label>
                    </ng-template>
                    <input
                      *ngIf="!solutionPhaseData.sow.sendingToCustomerPart.date"
                      [ngClass]="{
                        phases_disabled_btn: !currentUser.isPreSales
                      }"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      (click)="sendSOWToCustPopup()"
                      value="Send SOW to Customer"
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <!--CUSTOMER APPROVAL -->
            <!-- <div
              class="btn_collapse_parent mt-2"
              id="sow_customer2"
              *ngIf="solutionPhaseData.sow.sendingToCustomerPart?.date"
            >
              <div class="row" style="padding: 5px 13px">
                <button
                  type="button"
                  class="btn_collapse_header col-10"
                  [ngClass]="{
                    'col-10': solutionPhaseData.sow.sendingToCustomerPart.date,
                    'col-12': !solutionPhaseData.sow.sendingToCustomerPart.date
                  }"
                  (click)="
                    isCustApprovedSOWCollapsed = !isCustApprovedSOWCollapsed
                  "
                  [attr.aria-expanded]="!isCustApprovedSOWCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isCustApprovedSOWCollapsed,
                      'fa-angle-up': !isCustApprovedSOWCollapsed
                    }"
                  ></span>
                  <label
                    class="label-ellipsis"
                    *ngIf="
                      solutionPhaseData.sow.sendingToCustomerPart.approval_date
                    "
                    >Customer Approved SOW
                  </label>
                  <label
                    class="label-ellipsis"
                    *ngIf="
                      !solutionPhaseData.sow.sendingToCustomerPart.approval_date
                    "
                    >Pending Customer Approval on SOW</label
                  >
                </button>
                <span
                  *ngIf="
                    solutionPhaseData.sow.sendingToCustomerPart.approval_date
                  "
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
              <div
                #collapse="ngbCollapse"
                [(ngbCollapse)]="isCustApprovedSOWCollapsed"
              >
                <div class="btn_collapse_content">
                  <div class="d-grid p-1">
                    <label class="phase_details_title">Customer Name</label>
                    <label class="phase_details_data_txt">{{
                      solutionPhaseData.sow.sendingToCustomerPart.customer_name
                    }}</label>
                    <ng-template
                      [ngIf]="
                        solutionPhaseData.sow.sendingToCustomerPart
                          .approval_date
                      "
                    >
                      <label class="phase_details_title mt-2"
                        >Date & Time of Approval</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.sow.sendingToCustomerPart
                          .approval_string_date
                      }}</label>
                      <label class="phase_details_title mt-2"
                        >Consumed Time</label
                      >
                      <label class="phase_details_data_txt">{{
                        solutionPhaseData.sow.sendingToCustomerPart
                          .customer_approval_consumed_time
                      }}</label>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div> -->
          </ng-template>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>

<!-- EMBEDED SOW GOOGLE DOC -->
<div
  class="modal fade"
  bsModal
  #googlesowDoc="bs-modal"
  tabindex="-1"
  id="googlesowDoc"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-xl modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 product-sans col-11">Scope Of Work</h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="googlesowDoc.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mx-2" *ngIf="solutionPhaseData?.sow?.googleDocUrl">
          <sow-google-doc-card
            *ngIf="googleDocData"
            [sow]="solutionPhaseData.sow"
            [cardData]="googleDocData"
            (createEmitter)="createSOW($event)"
            (approveEmitter)="approveSOW($event)"
            (custEmitter)="sendSOWToCust($event)"
            (draftEmitter)="sendDraftSOWForApproval($event)"
            [opporunityID]="opporunityID"
          ></sow-google-doc-card>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- EMBEDED GOOGLE DOC FOR CUSTOMER REQ -->
<div
  class="modal fade"
  bsModal
  #googleDocCustReq="bs-modal"
  tabindex="-1"
  id="googleDocCustReq"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-xl modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 product-sans col-11">
          Customer Requirements
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="googleDocCustReq.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div
          class="mx-2"
          *ngIf="solutionPhaseData?.customer_requirements?.googleDocUrl"
        >
          <customer-requirements-google-doc-card
            *ngIf="googleDocData"
            [cardData]="googleDocData"
            [model]="solutionPhaseData.customer_requirements"
            (createEmitter)="createCustReq($event)"
            (approveEmitter)="approveCustReq($event)"
            (custEmitter)="sendCustReqToCust($event)"
            (draftEmitter)="sendDraftCustReqForApproval($event)"
            [opporunityID]="opporunityID"
          ></customer-requirements-google-doc-card>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- EMBEDED GOOGLE DOC FOR ENGINEER RECOM -->
<div
  class="modal fade"
  bsModal
  #googleDocEngRecomdModal="bs-modal"
  tabindex="-1"
  id="googleDocEngRecomdModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-xl modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 product-sans col-11">
          Engineer Recommendations
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="googleDocEngRecomdModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div
          class="mx-2"
          *ngIf="solutionPhaseData?.engineering_recommendations?.googleDocUrl"
        >
          <engineering_recommendations-google-doc-card
            *ngIf="googleDocData"
            [cardData]="googleDocData"
            [model]="solutionPhaseData.engineering_recommendations"
            (createEmitter)="createEngRec($event)"
            (approveEmitter)="approveEngRec($event)"
            (custEmitter)="sendEngRecoToCust($event)"
            (draftEmitter)="sendDraftEngRecomForApproval($event)"
            [opporunityID]="opporunityID"
          ></engineering_recommendations-google-doc-card>
        </div>
      </div>
    </div>
  </div>
</div>

<customer-info-popup
  *ngIf="customerInfoAdded"
  #customerInfoPopup
  (sendListener)="sendToCust_back($event)"
  [customer_emails]="customerInfoAdded.customer_email"
  [customer_name]="customerInfoAdded.customer_name"
></customer-info-popup>
