import { BaseModel } from '../base.model';
import { OrderModel } from './order.model';
import { DealModel } from './deal.model';
import { GCPProjectModel } from './gcp_project.model';
import { POCModel } from './poc.model';
import { ProposalModel } from './proposal.model';
import { CustomerModel } from './customer.model';
import { SOWModel } from './scope_of_work.model';
import { DomainModel } from './domain.model';
import { BillingAccountModel } from './billing-account';
import { UserModel } from './user.model';

export class OpportunityModel extends BaseModel {
  _id: string;
  opportunity_id: string;
  skip_steps: any;
  engineer_engagement_not_required: boolean;
  opportunity_status: string;
  approval_phases: any[];
  created_by_rfid: string;
  domain_rfid: string;
  sow_rfid: string;
  is_gcp: boolean;
  daf_request: any;
  existing_project: boolean;
  dependency_on: string;
  created_at: Date;
  customer_requirements_rfid: string;
  engineer_recommendations_rfid: string;
  deal_rfid: string;
  poc_rfid: string;
  proposal_rfid: string;
  order_project_rfid: string;
  order_billing_account_rfid: string;
  link_project_billing;
  budget_rfid: string;
  deleted: boolean;
  start_date: Date;
  updated_at: Date;
  draft: boolean;
  //CUSTOM
  deal: DealModel;
  startDealDate: Date;
  // customer_id: string;
  customer_domain: string;
  customer_email: string;
  customer_organisation: string;
  customer_alternate_email: string;
  customer_contact_name: string;
  domain: DomainModel;
  customer_requirements: any;
  engineering_recommendations;
  solution_building: any;
  poc: POCModel;
  poc_phase: PocPart;
  proposal: ProposalModel;
  sow: SOWModel;
  order_phase: any; //order , gcp_project
  user_ids = [];
  disabled: any;
  daf_required: boolean;
  psf_required: boolean;

  picked_user_ids: any[];

  customer_rfid: string;
  customer_local_id: string;
  customer_api_id: string;
  customer_info_added: any;

  constructor() {
    super();
  }
}
export class PocPart {
  poc: POCModel;
  daf_request: any;
  gcp_project: GCPProjectModel;
  link_project_billing: any;
  billing_budget: any;
  credit: any;
  billing_account: BillingAccountModel;
  user_poc:UserModel;
}
