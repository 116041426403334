import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode } from 'src/shared/Endpoint';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoint } from 'src/shared/Endpoint';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DealModel } from 'src/models/mongo/deal.model';
import { DomainModel } from 'src/models/mongo/domain.model';

@Component({
  selector: 'lib-customer-deals',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-deals.component.html',
  styleUrls: ['./customer-deals.component.scss'],
})
export class CustomerDealsComponent implements OnInit {
  currentCustomer: DomainModel;
  // OrdersService: OrdersService;
  deals: any;

  constructor(
    private datePipe: DatePipe,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {
    var paramsJson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (paramsJson) {
      this.currentCustomer = JSON.parse(paramsJson);
      this.getDealsOfCustomers(this.currentCustomer._id);
    }
  }

  async getDealsOfCustomers(customerId: string): Promise<void> {
    var user = this.localStorage.getLoggedinUserModel();
    var params = new HttpParams().set(Unicode.VIEW_ALL, user.canViewAll);
    await this.http
      .get(
        Endpoint.GLOBAL_URL + Endpoint.DEALS_OF_CUSTOMER + '/' + customerId,
        { params: params }
      )
      .subscribe(
        (response: any) => {
          this.deals = response.deals;
          console.log('deals:', this.deals);
        },
        (error) => {
          console.log('Error fetching deals:', error);
        }
      );
  }

  viewDeal(edit: boolean, id) {
    var deal: DealModel = this.deals.find((x) => x.id == id);
    if (deal.is_gcp) {
      this.router.navigate(['deals/add-deal-gcp'], {
        state: {
          edit: edit,
          dealId: id,
          organisation: this.currentCustomer.organisation_name,
          domain: this.currentCustomer.domain,
          customer_api_id: this.currentCustomer.customer_api_id,
          domain_rfid: this.currentCustomer._id,
          contact_person_name: this.currentCustomer.contact_person_name,
          contact_person_email: this.currentCustomer.contact_person_email,
          country_name: this.currentCustomer.country_name,
        },
        skipLocationChange: false,
      });
    } else {
      this.router.navigate(['deals/add-deal'], {
        state: {
          edit: edit,
          dealId: id,
          organisation: this.currentCustomer.organisation_name,
          domain: this.currentCustomer.domain,
          customer_api_id: this.currentCustomer.customer_api_id,
          domain_rfid: this.currentCustomer._id,
          contact_person_name: this.currentCustomer.contact_person_name,
          contact_person_email: this.currentCustomer.contact_person_email,
          country_name: this.currentCustomer.country_name,
        },
        skipLocationChange: false,
      });
    }
  }
}
