import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Unicode } from 'src/shared/Endpoint';
import { LocalStorageService } from './localstorage-service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
  constructor(
    private localStorage: LocalStorageService,
    private router: Router
  ) {}
  handleError(error: any): void {
    console.error(error);
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }

    const url = new URL(window.location.href);
    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(url.searchParams);
    const value = params.get('withoutLoader');

    var message = 'Unauthorized Access';
    if (error && error.status) {
      if (error.status === 401) {
        this.localStorage.logout();
        this.router.navigate(['./login']);
      } else {
        message =
          error?.error?.message ??
          error.statusText ??
          Unicode.INTERNAL_SERVER_ERROR;
      }
      if (!message.includes('oauth2.googleapis.com/token')) alert(message); //todo remove all catch
    }
  }
}
