import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { SOWModel } from 'src/models/mongo/scope_of_work.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Group, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { DomainModel } from '../../../../../src/models/mongo/domain.model';
import { SowService } from '../sow.service';

@Component({
  selector: 'sow-details',
  templateUrl: './sow-details.component.html',
  styleUrls: ['./sow-details.component.scss'],
})
export class SowDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = '';
  currentUser: UserModel;
  SOW: SOWModel = new SOWModel();
  title: string = 'Create Scope Of Work';
  googleDocData: any;
  currentCustomer: CustomerModel = new CustomerModel();
  loginUsersData: any;
  currentDomain: DomainModel = new DomainModel();

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private sowService: SowService
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(async (routeParams) => {
      const state = history.state;
      if (state?.sow) this.SOW = state.sow;
      else if (routeParams['Id']) {
        //EMAIL LINK CASE
        await this.http
          .get(Endpoint.GLOBAL_URL + Endpoint.GET_SOW_BY_ID + routeParams['Id'])
          .toPromise()
          .then((resp: any) => {
            if (resp) {
              this.SOW = resp;
            }
          });
      }
      this.SOW = Object.assign(new SOWModel(), this.SOW);
      if (this.SOW?._id) {
        this.title = 'Scope Of Work';
        this.googleDocData = {
          module: 'sow',
          customer_domain: this.SOW.domain.domain,
          customer_org: this.SOW.domain.organisation_name,
          googleDocUrl: this.SOW.googleDocUrl,
          createdUser: {
            name: this.SOW.user_sow.created_user_name,
            role: 'PreSales',
            date: this.SOW.created_date_string,
            text: 'Created SOW',
            img: this.SOW.user_sow.created_user_img,
          },
          approval_phases: this.SOW?.approval_phases,
        };
      } else if (this.SOW) {
        this.googleDocData = {
          module: 'sow',
          customer_domain: null,
          customer_org: null,
        };
      }
      this.googleDocData.googleDocUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://docs.google.com/document/d/' +
            this.SOW.google_doc_id +
            '/edit?usp=sharing&embed=true'
        );
    });
  }

  ngOnInit(): void {}

  selectCustomer(dm: DomainModel) {
    this.currentDomain = dm;
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.currentDomain)
    );
  }

  createSOW(sow: SOWModel) {
    this.router.navigate(['sow']);
  }

  approveSOW(sow: SOWModel) {
    this.router.navigate(['sow']);
  }

  sendDraftSOWForApproval(sow: SOWModel) {
    this.router.navigate(['sow']);
  }

  async sendSOWToCust(custInfo) {
    var that = this;
    await this.sowService
      .sendSOWToCustpmer(
        this.SOW,
        custInfo,
        this.currentUser,
        this.SOW.opportunity_rfid
      )
      .then((result) => {
        if (result) {
          this.router.navigate(['sow']);
        }
      });
  }

  cancel() {
    this.router.navigate(['sow']);
  }
}
