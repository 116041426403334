import { map } from 'rxjs/operators';
import { SettingsService } from '../../../../../src/servcies/backend-services/settings.service';
import { Utilities } from './../../../../../src/shared/utilities';
import { CurrencyModel } from './../../../../../src/models/currency.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { VwExchangeRate } from 'src/models/vw_exchange_rate';
import {Router} from '@angular/router';

@Component({
  selector: 'settings-exchange-rate',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss']
})
export class ExchangeRateComponent implements OnInit {

  exchForm: UntypedFormGroup;
  currencies: CurrencyModel[] = [];
  exchanges: any[] = [];
  initialRates: VwExchangeRate[] = [];
  rates: VwExchangeRate[] = [];
  exchangeRateId: string;

  constructor(
    private router:Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService,

    ) {
    this.exchForm = fb.group({
      currencyFromRfid: ['', Validators.required],
      currencyToRfid: ['', Validators.required],
      rate: ['', Validators.required],
      inverseRate: [''],
    });

  }

  async ngOnInit(): Promise<void> {
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.GET_CURRENCIES)
      .toPromise();
    if (resp) {
      this.currencies = resp.data;
    }

    this.getAllExchanges();
  }

  async getAllExchanges(): Promise<void> {
    await this.settingsService.getAllExchanges().subscribe(
       (response:any) => {
         this.exchanges = response.data;
         console.log('Exchanges:', this.exchanges);
       },
       (error) => {
         if (error.status === 401) {
           this.localStorage.logout();
           alert('Unauthorized Access');
           this.router.navigate(['./login']);
         }
         console.log('Error fetching exchanges:', error);
       }
     );
   }



   addNew() {
    var exchangeData = this.exchForm.value;
    this.settingsService.createExchangeRate(exchangeData).subscribe(
      (response: any) => {
        console.log('Exchange rate created successfully:', response);
        this.exchanges.push(response.model); // Add the newly created exchange rate to the array
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error creating Exchange rate:', error);
      }
    );

  }

  changeRate(event) {
    var value = event.target.value;
    this.inverseRate.setValue(value);
  }

  deleteConfirmation(){
    this.settingsService.deleteExchangeRate(this.exchangeRateId).subscribe(
      (response:any) => {
        console.log('Exchange rate successfully deleted');
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Exchange rate not found');
      });
  }

  updateExchangeRate(): void {
    const updatedRate = this.exchForm.value;

    this.settingsService.updateExchangeRate(this.exchangeRateId, updatedRate).subscribe(
      (response) => {
        console.log('Currency updated successfully:', response);
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error updating currency:', error);
      }
    );
  }

  getRowIdToDelete(rowId){
    this.exchangeRateId = rowId;
  }

  edit(rowId){
    this.exchangeRateId = rowId;
    this.settingsService.getExchangeRateById(this.exchangeRateId).subscribe(
      (response:any) => {
        // patch the form
        this.exchForm.patchValue(response.data);
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error fetching currency:', error);
      }
    );

  }

  get currencyFromRfid() { return this.exchForm.get('currencyFromRfid'); }
  get currencyToRfid() { return this.exchForm.get('currencyToRfid'); }
  get rate() { return this.exchForm.get('rate'); }
  get inverseRate() { return this.exchForm.get('inverseRate'); }
}
