import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { CustomerInfoPopupComponent } from '../customer-info-popup/customer-info-popup.component';
import { RejectionPopupComponent } from '../rejection-popup/rejection-popup.component';
import { CustomerRequirementsModel } from 'src/models/mongo/customer_requirements.model';
import { DomainModel } from 'src/models/mongo/domain.model';
import { OpportunityService } from 'projects/opportunity/src/public-api';

@Component({
  selector: 'customer-requirements-google-doc-card',
  templateUrl: './customer-requirements-google-doc-card.component.html',
  styleUrls: ['./customer-requirements-google-doc-card.component.scss'],
})
export class CustomerRequirementsGoogleDocCardComponent
  implements OnInit, AfterViewInit
{
  currentUser: UserModel;
  domainSelected;
  @Input() cardData: any = {
    module: '',
    customer_domain: null,
    customer_org: null,
    googleDocUrl: '',
    createdUser: {},
    approval_phases: [],
  };
  @Input() model: CustomerRequirementsModel = new CustomerRequirementsModel();
  @Input() opporunityID: string;
  searchResultCustomers = [];
  @Output() selectCustomerEmitter = new EventEmitter<CustomerModel>();
  add: boolean = false;
  approve: boolean = false;
  send: boolean = false;
  withoutAction: boolean = false;
  showSendToApprove: boolean = true;
  loginUsersData: any;
  @Output() createEmitter = new EventEmitter<any>();
  @Output() approveEmitter = new EventEmitter<any>();
  @Output() editEmitter = new EventEmitter<any>();
  @Output() draftEmitter = new EventEmitter<any>();
  @Output() custEmitter = new EventEmitter<any>();
  @ViewChild('rejectCustRecPopupModal')
  rejectCustRecPopupModal: RejectionPopupComponent;
  canSendToCustomer: boolean = false;
  @ViewChild('recomdcustomerInfoPopup')
  recomdcustomerInfoPopup: CustomerInfoPopupComponent;
  customerInfoAdded: any;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private oppService: OpportunityService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();

    effect(() => {
      this.customerInfoAdded =
        this.oppService.opportunity().customer_info_added;
    });
  }

  ngAfterViewInit(): void {
    if (this.model?._id) {
      this.add = false;
      this.showSendToApprove =
        this.model.draft && this.currentUser._id == this.model.created_by_rfid;
      if (!this.model.draft) {
        if (!this.model.managerApprovalPart) this.setCustReqPhasesData();
        this.approve = this.model.canApprove(this.currentUser);
        this.canSendToCustomer = this.model.canSendToCustomer(this.currentUser);
      }
      this.cardData = {
        module: 'customer requirements',
        customer_domain: this.cardData.customer_domain,
        customer_org: this.cardData.customer_org,
        domain_rfid: this.cardData.domain_rfid,
        googleDocUrl: this.model.googleDocUrl,
        createdUser: {
          name: this.model.user.created_user_name,
          role: 'PreSales',
          date: this.model.created_at,
          text: 'Added customer requirements',
          img: this.model.user.created_user_img,
        },
        approval_phases: this.model?.approval_phases,
      };
      this.setCustReqPhasesData();
    } else if (this.model) {
      this.add = true;
      this.cardData = {
        module: 'customer requirements',
        customer_domain: this.cardData.customer_domain,
        customer_org: this.cardData.customer_org,
        domain_rfid: this.cardData.domain_rfid,
        start_date: this.cardData.startDate,
        googleDocUrl: this.model.googleDocUrl,
      };
    }
    this.cardData.googleDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/document/d/' +
        this.model.google_doc_id +
        '/edit?usp=sharing&embed=true'
    );
  }

  ngOnInit(): void {}

  async onSearchCustomerEnter(e) {
    var value = e.term ?? e.target.value;
    if (value && value.length > 2) {
      if (!Utilities.isNullOrEmpty(value)) this.searchResultCustomers = [];
      var params = new HttpParams()
        .set(Unicode.CUSTOMER_FILTER, value)
        .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
      await this.http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.SEARCH_FOR_CUSTOMERS_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.domains && resp.domains.length > 0) {
            this.searchResultCustomers = resp.domains ?? [];
          }
        });
    }
  }

  async selectCustomer() {
    if (this.domainSelected) {
      var domain = this.domainSelected;
      this.cardData.customer_domain = domain.domain;
      this.cardData.customer_org = domain.organisation_name;
      this.cardData.domain_rfid = domain._id;
      this.selectCustomerEmitter.emit(domain);

      var new_doc_name =
        this.datePipe.transform(new Date(), 'YYYY-MM-dd') +
        ' Customer Requirements - ' +
        domain.organisation_name;

      var params = new HttpParams()
        .set(Unicode.CUSTOMER_NAME, domain.organisation_name)
        .set(Unicode.FILE_ID, this.model.google_doc_id)
        .set(Unicode.NEW_FILE_NAME, new_doc_name);
      await this.http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.RENAME_DOCUMENT, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.success == true) {
            console.log('updated successfully');
          }
        });
    }
  }

  createCustReq(draft: boolean = false) {
    if (Utilities.isNullOrEmpty(this.cardData.domain_rfid)) {
      alert('No Customer Selected');
      return;
    }
    var receivers = [];
    var params = new HttpParams();
    if (!draft) {
      if (!Utilities.isNullOrEmpty(this.currentUser.manager_id))
        receivers.push(this.currentUser.manager_email);
      //  else receivers.push(this.loginUsersData?.gpm_group_email);
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    }
    var custReq = new CustomerRequirementsModel();
    custReq.created_by_rfid = this.currentUser._id;
    custReq.domain_rfid = this.cardData.domain_rfid;
    custReq.opportunity_rfid = this.opporunityID;
    custReq.is_gcp = this.model.is_gcp;
    custReq.draft = draft;
    custReq.google_doc_id = this.model.google_doc_id;
    custReq.approval_phases = [];
    custReq.start_date = this.cardData.start_date;
    var oppDependencyOn = null;
    if (!Utilities.isNullOrEmpty(this.currentUser.manager_id)) {
      custReq.approval_phases = [
        {
          role: Group.Engineer_Manager,
          name: this.currentUser.manager_name,
          email: this.currentUser.manager_email,
          id: this.currentUser.manager_id,
        },
      ];
      oppDependencyOn = Group.ENGINEER;
    } else {
      custReq.approval_phases = [{ role: Group.CUSTOMER }];
      oppDependencyOn = Group.ENGINEER;
    }
    if (!Utilities.isNullOrEmpty(this.opporunityID))
      params = params.set(Unicode.DEPENDENCY_ON, oppDependencyOn);
    this.http
      .post(Endpoint.GLOBAL_URL + Endpoint.ADD_CUSTOMER_REQUIREMENTS, custReq, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.rfid) {
          this.model = custReq;
          this.model.draft = draft;
          this.model._id = resp.rfid;
          this.model.consumed_time = resp.consumed_time;
          this.model.user = {
            created_user_name: this.currentUser.user_name,
          };
          this.model.created_at = new Date();
          this.model.creation_date_string = Utilities.toStringDateFormat(
            this.datePipe,
            new Date(),
            'dd MMM,yyyy-hh:mma'
          );
          this.add = false;
          //
          this.setCustReqPhasesData();
          this.createEmitter.emit(this.model);
        } else {
          alert(resp.message);
        }
      });
  }

  approveCustReq(reason: string = null) {
    var oppDependencyOn = null;
    var mainGrpName =
      this.currentUser.groups.find((x) => x.main == true)?.name ?? '';
    var approvalPhases = this.model.approval_phases ?? [];
    var role = this.model.approval_phases.find(
      (x) =>
        x.role && x.role.toLowerCase().indexOf(mainGrpName.toLowerCase()) >= 0
    );
    if (role && Utilities.isNullOrEmpty(role.date)) {
      role.name = this.currentUser.user_name;
      role.email = this.currentUser.email;
      role.id = this.currentUser._id;
      role.date = new Date();
      role.approved = Utilities.isNullOrEmpty(reason);
      if (!Utilities.isNullOrEmpty(reason)) role.rejection_reason = reason;
    } else {
      var row: any = {
        role: this.currentUser.isGPM ? Group.GPM : Group.Engineer_Manager,
        name: this.currentUser.getFullName(),
        email: this.currentUser.email,
        id: this.currentUser._id,
        date: new Date(),
        approved: Utilities.isNullOrEmpty(reason),
      };
      if (!Utilities.isNullOrEmpty(reason)) row.rejection_reason = reason;
      approvalPhases.push(row);
    }
    if (Utilities.isNullOrEmpty(reason)) {
      oppDependencyOn = 'PreSales';
      approvalPhases.push({ role: Group.CUSTOMER });
    }
    this.model.approval_phases = approvalPhases;
    var receivers = [this.model.user?.created_user_email];
    receivers.push(
      ...this.model?.approval_phases
        ?.filter(
          (x) =>
            x.email &&
            x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
        )
        .map((x) => x.email)
    );
    this.model.approval_phases.forEach((phase) => {
      if (phase?.date && phase.date.$date?.$numberLong)
        phase.date = new Date(Number.parseInt(phase.date.$date?.$numberLong));
    });
    var body = {
      receivers: JSON.stringify(receivers),
      approval_phases: this.model.approval_phases,
      customer_requirements_action: !Utilities.isNullOrEmpty(reason)
        ? 'reject'
        : 'approve',
      opportunity_rfid: this.model.opportunity_rfid,
    };
    var params = new HttpParams();
    if (!Utilities.isNullOrEmpty(this.model.opportunity_rfid))
      params = params.set(Unicode.DEPENDENCY_ON, oppDependencyOn);
    this.http
      .put(
        Endpoint.GLOBAL_URL +
          Endpoint.UPDATE_CUSTOMER_REQUIREMENTS +
          this.model._id,
        body,
        {
          params: params,
        }
      )
      .toPromise()
      .then((resp: any) => {
        if (resp?.approval_phases) {
          this.model.approval_phases = resp.approval_phases;
          //
          this.setCustReqPhasesData();
          this.approveEmitter.emit(this.model);
        } else {
          alert(resp.message);
        }
      });
  }

  rejectPopup() {
    this.rejectCustRecPopupModal.show();
  }

  reject(reason) {
    if (!Utilities.isNullOrEmpty(reason)) {
      this.rejectCustRecPopupModal.hide();
      this.approveCustReq(reason);
    } else alert('Add Rejection Reason please');
  }

  sendCUstReqToCustPopup() {
    console.log('solutionPhaseData:' + this.model);
    this.recomdcustomerInfoPopup.show();
  }

  sendCustReqCToCustomer(customerInfo) {
    this.custEmitter.emit(customerInfo);
    this.recomdcustomerInfoPopup.hide();
  }

  sendDraftForApproval() {
    var receivers = [];
    if (!Utilities.isNullOrEmpty(this.currentUser.manager_id))
      receivers.push(this.currentUser.manager_email);
    // else receivers.push(this.loginUsersData?.gpm_group_email);
    var body = {
      receivers: JSON.stringify(receivers),
    };
    this.http
      .put(
        Endpoint.GLOBAL_URL +
          Endpoint.SEND_CUSTOMER_REQUIREMENTS_FOR_APPROVAl +
          this.model._id,
        body
      )
      .toPromise()
      .then((resp: any) => {
        if (resp?.customer_requirements_rfid) {
          this.model.draft = false;
          //
          this.setCustReqPhasesData();
          this.draftEmitter.emit(this.model);
        } else {
          alert(resp.message);
        }
      });
  }

  setCustReqPhasesData() {
    if (this.model.approval_phases) {
      this.model.managerApprovalPart = this.model
        .getSortedApprovalPhasesByDateDesc()
        .find(
          (x) =>
            x.role &&
            x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
        );
      this.model.sendingToCustomerPart = this.model
        .getSortedApprovalPhasesByDateDesc()
        .find(
          (x) => x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
        );
    }
    if (
      this.model.sendingToCustomerPart &&
      !this.model.sendingToCustomerPart.date
    )
      this.model.sendingToCustomerPart.current = true;
    if (this.model.managerApprovalPart && !this.model.managerApprovalPart.date)
      this.model.managerApprovalPart.current = true;

    this.cardData.managerApprovalPart = this.model.managerApprovalPart;
    this.cardData.sendingToCustomerPart = this.model.sendingToCustomerPart;
  }
}
