import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { SettingsService } from '../../../../../src/servcies/backend-services/settings.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

@Component({
  selector: 'settings-permissions',
  templateUrl: './permissions.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
  @ViewChild('AddModal')
  AddModal: ModalDirective;
  permissionForm: UntypedFormGroup;
  permissions: any[] = [];
  permissionId: string;

  constructor(
    private router:Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService,
  ) {
    this.permissionForm = fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {

    this.getAllPermissions();
  }

  async getAllPermissions(): Promise<void> {
    await this.settingsService.getAllPermissions().subscribe(
       (response:any) => {
         this.permissions = response.permissions;
         console.log('Permissions:', this.permissions);
       },
       (error) => {
         if (error.status === 401) {
           this.localStorage.logout();
           alert('Unauthorized Access');
           this.router.navigate(['./login']);
         }
         console.log('Error fetching permissions:', error);
       }
     );
   }

  AddNewPermission() {
    var PermissionData=this.permissionForm.value;
    this.settingsService.createPermission(PermissionData).subscribe(
      (response: any) => {
        console.log('Permission created successfully:', response);
        // this.permissions.push(PermissionData);
        this.permissions.push(response.permission); // Add the newly created permission to the array
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error creating Permission:', error);
      }
    );
  }

  deleteConfirmation(){
    this.settingsService.deletePermission(this.permissionId).subscribe(
      (response:any) => {
        console.log('Permission successfully deleted');
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Permission not found');
      });
  }

  updatePermission(): void {
    const updatedPermission = this.permissionForm.value;

    this.settingsService.updatePermission(this.permissionId, updatedPermission).subscribe(
      (response) => {
        console.log('Currency updated successfully:', response);
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error updating currency:', error);
      }
    );
  }

  getRowIdToDelete(rowId){
    this.permissionId = rowId;
  }

  edit(rowiId){
    this.permissionId = rowiId;

    this.settingsService.getPermissionById(this.permissionId).subscribe(
      (response:any) => {
        this.permissionForm.patchValue(response.permission);
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error fetching currency:', error);
      }
    );
  }

  get name() { return this.permissionForm.get('name'); }
  get description() { return this.permissionForm.get('description'); }

}
