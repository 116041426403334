import { DatePipe } from '@angular/common';
import { BaseModel } from '../base.model';
import { DomainModel } from './domain.model';
import { Utilities } from 'src/shared/utilities';
import { UserModel } from './user.model';

export class DealModel extends BaseModel {
  _id: string;
  type: string;
  source: string;
  decision_phase: string;
  public_tender: string;
  budget: string;
  authority: string;
  need: string;
  timeline: string;
  description: string;
  google_rep: string;
  marketing_campaign: string;
  campaign_type: string;
  dr_nbr: string;
  opportunity_creation_date;
  opportunity_nbr: string;
  opportunity_owner: string;
  opportunity_stage: string;
  opportunity_link: string;
  dv_requested: string;
  project_number_validated: string;
  project_numbers: string;
  campaign;
  billing_id: string;
  rebate_expiry_date;
  sku;
  is_gcp: boolean;
  deteted: string;
  created_by_rfid: string;
  domain_rfid: string;
  estimated_close_date;
  deal_status: string;
  approval_phases = [];
  created_at: Date;
  google_registration_date: Date;
  google_registration_by_rfid: string;
  google_approval_date: Date;
  google_approval_by_rfid: string;
  draft: boolean;
  opportunity_rfid: boolean;
  start_date: Date;
  daf: any;
  psf: any;
  consumed_time: string;
  //
  customer_domain: string;
  customer_email: string;
  customer_api_id: string;
  customer_organisation: string;
  customer_contact_person_name: string;
  country_name: string;
  estimated_close_date_string: string;
  // customer_id: string;
  customer_auth_token: string;
  // created_user_email: string;
  // created_user_name: string;
  // manager_user_id: string;
  // manager_user_email: string;
  // manager_user_name: string;
  // manager_of_manager_user_id: string;
  // manager_of_manager_user_email: string;
  customer_details;
  created_date_string: string;
  google_registration_string_date: string;
  google_registration_user_name: string;
  google_approval_string_date: string;
  google_approval_user_name: string;
  deal_time: string;
  //
  // approved_by_sales_manager: boolean;
  // sales_manager_approval_string_date: string;
  // sales_manager_consumed_time: string;
  // sales_manager_rejection_reason: string;
  user_deal: any;
  domain: DomainModel;
  managerApprovalPart: any;
  show_gpm_phase: boolean;
  approved_by_gpm: boolean;
  gpm_approval_name: string;
  gpm_approval_date;
  gpm_approval_string_date: string;
  gpm_consumed_time: string;
  show_deal_google_registration_phase: boolean;
  deal_google_registered: boolean;
  google_registration_consumed_time: string;
  show_deal_google_approval_phase: boolean;
  google_approved_deal: boolean;
  google_approval_consumed_time: string;

  constructor(json?: any) {
    super();
    if (json) {
      this.id = this._id = json.id;
      this.customer_domain = json.customer_domain;
      this.customer_email = json.customer_email;
      this.customer_organisation = json.customer_organisation;
      this.customer_contact_person_name = json.customer_contact_person_name;
      this.country_name = json.country_name;
      this.type = json.type;
      this.need = json.need;
      this.source = json.source;
      this.decision_phase = json.decision_phase;
      this.public_tender = json.public_tender;
      this.timeline = json.timeline;
      this.budget = json.budget;
      this.authority = json.authority;
      this.description = json.description;
      this.google_rep = json.google_rep;
      this.dr_nbr = json.dr_nbr;
      this.opportunity_creation_date = json.opportunity_creation_date;
      this.opportunity_nbr = json.opportunity_nbr;
      this.opportunity_owner = json.opportunity_owner;
      this.opportunity_stage = json.opportunity_stage;
      this.opportunity_link = json.opportunity_link;
      this.dv_requested = json.dv_requested;
      this.project_number_validated = json.project_number_validated;
      this.project_numbers = json.project_numbers;
      this.campaign = json.campaign;
      this.rebate_expiry_date = json.rebate_expiry_date;
      this.marketing_campaign = json.marketing_campaign;
      this.campaign_type = json.campaign_type;
      this.source = json.source;
      this.billing_id = json.billing_id;
      this.sku = json.sku;
      this.is_gcp = json.is_gcp;
      this.deteted = json.deteted;
      this.created_by_rfid = json.created_by_rfid;
      this.domain_rfid = json.domain_rfid;
      this.estimated_close_date = json.estimated_close_date;
      this.approval_phases = json.approval_phases;
      this.created_at = json.created_at;
      this.google_registration_date = json.google_registration_date;
      this.google_registration_by_rfid = json.google_registration_by_rfid;
      this.google_registration_string_date =
        json.google_registration_string_date;
      this.google_registration_user_name = json.google_registration_user_name;
      this.google_approval_date = json.google_approval_date;
      this.google_approval_by_rfid = json.google_approval_by_rfid;
      this.google_approval_string_date = json.google_approval_string_date;
      this.google_approval_user_name = json.google_approval_user_name;
      this.draft = json.draft;
      this.opportunity_rfid = json.opportunity_rfid;
      this.estimated_close_date_string = json.estimated_close_date_string;
      this.customer_auth_token = json.customer_auth_token;
      this.customer_details = json.customer_details;
      this.created_date_string = json.created_date_string;
      this.opportunity_rfid = json.opportunity_rfid;
      this.deal_time = json.deal_time;
      this.domain = json.domain;
      this.user_deal = json.user_deal;
      this.managerApprovalPart = json.managerApprovalPart;
      this.show_gpm_phase = json.show_gpm_phase;
      this.approved_by_gpm = json.approved_by_gpm;
      this.gpm_approval_name = json.gpm_approval_name;
      this.gpm_approval_string_date = json.gpm_approval_string_date;
      this.gpm_consumed_time = json.gpm_consumed_time;
      this.show_deal_google_registration_phase =
        json.show_deal_google_registration_phase;
      this.deal_google_registered = json.deal_google_registered;
      this.google_registration_consumed_time =
        json.google_registration_consumed_time;
      this.show_deal_google_approval_phase =
        json.show_deal_google_approval_phase;
      this.google_approved_deal = json.google_approved_deal;
      this.google_approval_consumed_time = json.google_approval_consumed_time;
      this.daf = json.daf;
      this.psf = json.psf;
      this.consumed_time = json.consumed_time;
    }
  }

  getStringEstimatedClosedDate(datePipe: DatePipe) {
    return this.estimated_close_date &&
      this.estimated_close_date.$date?.$numberLong
      ? Utilities.fromMongoDateToStringDateInput(
          datePipe,
          this.estimated_close_date
        )
      : this.estimated_close_date;
  }
  getStringRebateExpiryDate(datePipe: DatePipe) {
    return this.rebate_expiry_date && this.rebate_expiry_date.$date?.$numberLong
      ? Utilities.fromMongoDateToStringDateInput(
          datePipe,
          this.rebate_expiry_date
        )
      : this.rebate_expiry_date;
  }
  getStringOpportunityCreationDate(datePipe: DatePipe) {
    return this.opportunity_creation_date &&
      this.opportunity_creation_date.$date?.$numberLong
      ? Utilities.fromMongoDateToStringDateInput(
          datePipe,
          this.opportunity_creation_date
        )
      : this.opportunity_creation_date;
  }

  getSortedApprovalPhasesByDateDesc(): any[] {
    return this.approval_phases
      ? this.approval_phases.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        })
      : [];
  }
}
