<button
  type="button"
  class="btn btn-color text-white rounded-pill d-block ms-auto"
>
  <img src="./assets/img/plus-white-icon.svg" alt="plus icon" class="pe-2" />
  <span>Create an Order</span>
</button>

<div class="orders px-4 my-2 fluid-section w-100">
  <div
    class="row border-bottom product-sans-medium border-opacity-50 border-dark position-sticky top-0 bg-white py-3"
  >
    <p class="col-2 mb-0 ps-0 text-center">Domain</p>
    <p class="col-2 mb-0 ps-0">Order Date</p>
    <p class="col-2 mb-0 ps-0">SKU</p>
    <p class="col-1 mb-0 ps-0">Status</p>
    <p class="col-2 mb-0 ps-0">Action</p>
    <p class="col-2 mb-0 ps-0">Order Description</p>
    <p class="col-1 mb-0 ps-0 position-relative">
      Edit
      <span class="w-100 h-100 bg-white position-absolute top-0 start-0"></span>
    </p>
  </div>

  <div
    class="row pt-2 border-bottom border-opacity-10 border-dark"
    *ngFor="let order of orders"
  >
    <p class="col-2 mb-0 ps-0 py-3 text-center">{{ currentCustomer.domain }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ order.created_date_string }}</p>
    <!-- <p class="col mb-0 ps-0 py-3">{{ order.sku && order.sku.length > 0 ? order.sku[0].sku_id : '' }}</p> -->
    <p class="col-2 mb-0 ps-0 py-3">
      <span *ngFor="let sku of order.sku"
        >{{ sku.sku_name ?? sku.sku_id }},
      </span>
    </p>
    <!-- <p class="col mb-0 ps-0 py-3" *ngIf="order.sku && order.sku.length > 1">
      <span *ngFor="let sku of order.sku">{{ sku.sku_id }}, </span>
    </p>
    <p class="col mb-0 ps-0 py-3" *ngIf="order.sku && order.sku.length === 1">
      {{ order.sku[0].sku_id }}
    </p> -->
    <p class="col-1 mb-0 ps-0 py-3">{{ order.order_status }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ order.action }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ order.description }}</p>
    <p class="col-1 mb-0 ps-0 py-2 text-end">
      <img
        class="cursor-pointer"
        src="./assets/img/view-icon.svg"
        (click)="viewOrder(false, order._id)"
        alt="view icon"
      />
      <img
        class="cursor-pointer"
        (click)="viewOrder(true, order._id)"
        src="./assets/img/edit-icon.svg"
        alt="edit icon"
      />
    </p>
  </div>
</div>
