import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { materialize, delay, dematerialize, mergeMap } from "rxjs/operators";
import {Endpoint} from '../shared/Endpoint';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // wrap in delayed observable to simulate server api call
        return of(null).pipe(mergeMap(() => {

          let baseUrl = document.getElementsByTagName('base')[0].href;
          // alert(baseUrl);

          // let token = localStorage.getItem('token');
          if(localStorage.getItem('Logged in user') !=null){
            const userData = JSON.parse(localStorage.getItem('Logged in user'));
            const token= userData.auth_token;
            if(req.url.startsWith(Endpoint.GLOBAL_URL)){
              req = req.clone({
                  url: `${req.url}`,
                  // ${baseUrl}

                  setHeaders:{Authorization:`Bearer ${token}`},

                }
              );
            }else{
              return next.handle(req);
            }
          }else{
            return next.handle(req);
          }






          // if (req.headers.get('No-Auth') == "True")
          //     return next.handle(req.clone());




          // alert("req =" + req);
          return next.handle(req);
          //   .pipe(
          //   catchError((error) => {
          //     console.log('error is intercept');
          //     alert('access restricted');
          //     console.error(error);
          //    // this.localStorage.logout();
          //     localStorage.clear();
          //     this.router.navigate(['./login']);
          //
          //     return throwError(error.message)})
          // );
        }))

            // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(materialize())
            .pipe(delay(500))
            .pipe(dematerialize());
    }
}
