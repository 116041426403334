import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  effect,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomainModel } from 'src/models/mongo/domain.model';
import { GCPProjectModel } from 'src/models/mongo/gcp_project.model';
import { PocPart } from 'src/models/mongo/opportunity.model';
import { POCModel } from 'src/models/mongo/poc.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { CustomerInfoPopupComponent } from 'src/shared-components/customer-info-popup/customer-info-popup.component';
import { LinkProjectPopupComponent } from 'src/shared-components/link-project-popup/link-project-popup.component';
import { NewProjectModalComponent } from 'src/shared-components/new-project-modal/new-project-modal.component';
import { RejectionPopupComponent } from 'src/shared-components/rejection-popup/rejection-popup.component';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { OpportunityService } from '../../opportunity.service';
import { PocService } from 'projects/poc/src/public-api';

@Component({
  selector: 'opportunity-poc-phase',
  templateUrl: './opportunity-poc-phase.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./opportunity-poc-phase.component.scss'],
})
export class OpportunityPocPhaseComponent implements OnInit {
  @HostBinding('class') class = 'h-100';
  @Input() pocPhaseData: PocPart;
  currentUser: UserModel;
  @Input() isGWS: boolean = true;
  @Input() opporunityID: string;
  @Input() skip: boolean;
  @Input() disabled: boolean;
  isDAFRequestSentCollapsed: boolean = true;
  isCreatedPOCDocCollapsed: boolean = true;
  isCustApprovedpocCollapsed: boolean = true;
  isSendingCustpocCollapsed: boolean = true;
  isManagerApprovedpocCollapsed: boolean = true;
  isGcpPrjCreCollapsed: boolean = true;
  isGcpPrjLinkedCollapsed: boolean = true;
  isBudgetCreCollapsed: boolean = true;
  currentDomain: DomainModel;
  loginUsersData: any;
  @ViewChild('googlepocDoc') googlepocDocModal: ModalDirective;
  add: boolean = false;
  approve: boolean = false;
  showSendToApprove: boolean = true;
  @ViewChild('poccustomerInfoPopup')
  poccustomerInfoPopup: CustomerInfoPopupComponent;
  @ViewChild('rejectPOCPopupModal')
  rejectPOCPopupModal: RejectionPopupComponent;
  @ViewChild('poc_new_project_modal')
  pocNewProjectModal: NewProjectModalComponent;
  @ViewChild('poclinkProjectModal')
  linkProjectModal: LinkProjectPopupComponent;
  googleDocData: any;
  billingRfId: string;
  @ViewChild('pocBudgetCreModal')
  budgetCreationModal: ModalDirective;
  @Output() skipEmitter = new EventEmitter<boolean>();
  oppService = inject(OpportunityService);
  customerInfoAdded = null;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private pocService: PocService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentDomain = JSON.parse(cstjson);
    if (this.currentDomain)
      this.currentDomain.contact_person_name = `${
        this.currentDomain.contact_person_first_name ?? ''
      } ${this.currentDomain.contact_person_last_name ?? ''}`;
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);

    effect(() => {
      if (!this.currentDomain) {
        this.currentDomain = this.oppService.opportunity().domain;
        localStorage.save(
          Unicode.CURRENT_CUSTOMER,
          JSON.stringify(this.currentDomain)
        );
      }
      this.customerInfoAdded =
        this.oppService.opportunity().customer_info_added;
    });
  }

  ngOnInit(): void {}

  createPOCGoogleDoc() {
    var startDate = new Date();
    var params = new HttpParams()
      .set(Unicode.CUSTOMER_NAME, this.currentDomain.organisation_name)
      .set(Unicode.IS_GCP, !this.isGWS);
    this.http
      .post(Endpoint.GLOBAL_URL + Endpoint.CREATE_POC_GOOGLE_DOC, null, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.doc_id) {
          this.pocPhaseData.poc = new POCModel();
          this.pocPhaseData.poc.google_doc_id = resp.doc_id;
          this.pocPhaseData.poc.googleDocUrl =
            'https://docs.google.com/document/d/' +
            this.pocPhaseData.poc.google_doc_id +
            '/edit?usp=sharing&embed=true';
          this.pocPhaseData.poc.googleDocUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.pocPhaseData.poc.googleDocUrl
            );
          this.add = true;
          this.googleDocData = {
            module: 'poc',
            customer_domain: this.currentDomain.domain,
            customer_org: this.currentDomain.organisation_name,
            domain_rfid: this.currentDomain._id,
            googleDocUrl: this.pocPhaseData.poc.googleDocUrl,
            startDate: startDate,
          };
          this.googlepocDocModal.show();
          this.googlepocDocModal.onHide.subscribe((res) => {
            this.googleDocData = null;
          });
        } else {
          alert(resp.message);
        }
      });
  }

  createPOC(poc) {
    this.pocPhaseData.poc = poc;
    this.googlepocDocModal.hide();
  }

  reviewPOC(approveRejectCase: boolean = false) {
    this.add = false;
    if (approveRejectCase) {
      this.showSendToApprove = false;
      this.approve = true;
    } else {
      if (
        this.pocPhaseData?.poc &&
        !Utilities.isNullOrEmpty(this.pocPhaseData.poc.google_doc_id)
      ) {
        if (
          this.currentUser._id == this.pocPhaseData.poc.created_by_rfid &&
          this.pocPhaseData.poc.draft == true
        ) {
          this.showSendToApprove = true;
        } else this.showSendToApprove = false;
      }
    }
    this.pocPhaseData.poc.googleDocUrl =
      'https://docs.google.com/document/d/' +
      this.pocPhaseData.poc.google_doc_id +
      '/edit?usp=sharing&embed=true';
    this.pocPhaseData.poc.googleDocUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pocPhaseData.poc.googleDocUrl
      );
    //
    this.googleDocData = {
      module: 'poc',
      customer_domain: this.currentDomain.domain,
      customer_org: this.currentDomain.organisation_name,
      domain_rfid: this.currentDomain._id,
      googleDocUrl: this.pocPhaseData.poc.googleDocUrl,
      createdUser: {
        name: this.pocPhaseData.poc.user_poc.created_user_name,
        role: 'PreSales',
        date: this.pocPhaseData.poc.created_at,
        text: 'Created POC',
        img: this.pocPhaseData.poc.user_poc.created_user_img,
      },
      approval_phases: this.pocPhaseData.poc.approval_phases,
    };
    if (this.pocPhaseData.poc.approval_phases) {
      this.googleDocData.managerApprovalPart =
        this.pocPhaseData.poc.approval_phases.find(
          (x) =>
            x.role &&
            x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
        );
      this.googleDocData.sendingToCustomerPart =
        this.pocPhaseData.poc.approval_phases.find(
          (x) => x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
        );
    }
    if (
      this.googleDocData.sendingToCustomerPart &&
      !this.googleDocData.sendingToCustomerPart.date
    )
      this.googleDocData.sendingToCustomerPart.current = true;
    if (
      this.googleDocData.managerApprovalPart &&
      !this.googleDocData.managerApprovalPart.date
    )
      this.googleDocData.managerApprovalPart.current = true;
    this.googlepocDocModal.show();
    this.googlepocDocModal.onHide.subscribe((res) => {
      this.googleDocData = null;
    });
  }

  approvePoc(poc) {
    this.pocPhaseData.poc = poc;
    this.googlepocDocModal.hide();
  }

  sendpocToCustPopup() {
    this.poccustomerInfoPopup.show();
  }

  async sendPOCToCustomer(customerInfo) {
    var that = this;
    await this.pocService
      .sendPOCToCustpmer(
        this.pocPhaseData?.poc,
        customerInfo,
        this.currentUser,
        this.opporunityID
      )
      .then((result) => {
        if (result) {
          this.pocPhaseData.poc = result;
          this.googlepocDocModal.hide();
          this.poccustomerInfoPopup.hide();
        }
      });
  }

  sendDraftPocForApproval(poc) {
    this.pocPhaseData.poc = poc;
    this.googlepocDocModal.hide();
  }
  /*** GCP PART***/
  createProjectPopup() {
    this.pocNewProjectModal.show();
  }
  createProject(project: GCPProjectModel) {
    this.pocPhaseData.gcp_project = project;
    this.pocPhaseData.gcp_project.created_at = new Date();
    this.pocNewProjectModal.hide();
  }
  linkProjectPopup() {
    this.linkProjectModal.show();
  }
  linkingDone(data) {
    if (data) {
      this.pocPhaseData.link_project_billing = data.link;
      this.pocPhaseData.billing_budget = data.budget;
    }
  }
  createBudgetPopup() {
    this.billingRfId =
      this.pocPhaseData.link_project_billing.billing_account_rfid;
    this.localStorage.save(
      Unicode.CURRENT_BUDGET,
      JSON.stringify(this.pocPhaseData.billing_budget)
    );
    this.budgetCreationModal.show();
    // this.budgetCreationModal.onHide.subscribe(() => {
    //   this.localStorage.remove(Unicode.CURRENT_BUDGET);
    // });
  }
  createBudget(created) {
    if (created) {
      if (!this.pocPhaseData.billing_budget) {
        created.created_at = new Date();
        created.created_user_name = this.currentUser.user_name;
      }
      this.pocPhaseData.billing_budget = created;
      this.localStorage.save(Unicode.CURRENT_BUDGET, JSON.stringify(created));
      this.budgetCreationModal.hide();
    }
  }

  skipStep(e) {
    var skipped = e.target.checked;
    if (!Utilities.isNullOrEmpty(this.opporunityID)) {
      this.http
        .put(
          Endpoint.GLOBAL_URL +
            Endpoint.SKIP_OPP_STEP +
            '/' +
            this.opporunityID,
          {
            skipped: skipped,
            module: 'poc',
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true) {
            this.skipEmitter.emit(skipped);
          } else {
            alert(Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    } else this.skipEmitter.emit(skipped);
  }
}
