<div id="opp_proposal_parent_step" class="container d-flex flex-column">
  <div
    class="opp_proposal_step_new h-100"
    [ngClass]="{ step_skipped: skip == true }"
    *ngIf="proposalPhaseData == null; else opp_proposal_step"
  >
    <div class="step_count">
      <span class="notification-icon--fixed">
        <small class="notification-badge f-20">4</small>
      </span>
    </div>
    <div class="text-center mt-2">
      <img
        src="./assets/img/ic_step_proposal.svg"
        class="back-icon icon_step"
        alt="proposal building icon"
      />
    </div>
    <div class="text-center txt_deal_phase mt-2">
      <span class="col-1">Proposal Phase</span>
    </div>
    <div class="row">
      <button
        (click)="createproposalGoogleDoc()"
        [ngClass]="{
          phases_disabled_btn: !currentUser.isPreSales || disabled == true
        }"
        class="col-10 mt-2 offset-1 btn d-none d-md-inline rounded-pill btn-bg-white smaller-text"
      >
        Create a Proposal
      </button>
    </div>
    <div class="new-div mt-auto mb-3 offset-3 div_skip">
      <!-- <div
        class="product-sans-light f-12"
        [ngClass]="{
          f_black: skip == true,
          'product-sans-medium': skip == true
        }"
      >
        <label class="switch mb-1">
          <input
            type="checkbox"
            [(ngModel)]="skip"
            (change)="skipStep($event)"
          />
          <span class="slider"></span>
        </label>
        Skip this step
      </div> -->
    </div>
  </div>

  <ng-template #opp_proposal_step>
    <div class="opp_proposal_step h-100">
      <div class="proposal_step_header d-flex flex-row">
        <span class="flex-column col-1 f-20">4</span>
        <label class="text-nowrap mt-1 f-15 flex_auto">Proposal</label>
        <div class="flex-column pe-2 text-end col-4">
          <span class="f-12 d-grid"
            >time <span>{{ proposalPhaseData.proposal_time }}</span></span
          >
        </div>
        <img
          src="./assets/img/ic_time_expand.svg"
          class="ic_time col-2 text-end"
        />
      </div>
      <div class="proposal_step_content product-sans">
        <div
          class="btn_collapse_parent mt-2"
          id="proposal_creation"
          *ngIf="
            !proposalPhaseData.draft ||
            currentUser._id == proposalPhaseData.created_by_rfid
          "
        >
          <div
            class="row opp_col_content align-items-center"
            style="padding: 5px 13px"
          >
            <div class="col-9 p-0 d-inline-flex">
              <button
                type="button"
                class="btn_collapse_header w-100 d-inline-flex"
                (click)="
                  isCreatedproposalDocCollapsed = !isCreatedproposalDocCollapsed
                "
                [attr.aria-expanded]="!isCreatedproposalDocCollapsed"
                aria-controls="collapseCustReq"
                [ngClass]="{
                  'col-10': proposalPhaseData,
                  'col-12': !proposalPhaseData
                }"
              >
                <span
                  class="fa p-1"
                  [ngClass]="{
                  'fa-angle-down': isCreatedproposalDocCollapsed,
                  'fa-angle-up': !isCreatedproposalDocCollapsed,
                }"
                ></span>
                <label
                  title="PreSales Created Proposal"
                  class="label-ellipsis"
                  *ngIf="proposalPhaseData"
                  >PreSales Created Proposal</label
                >
                <label
                  title="Pending Proposal Creation by PreSales"
                  class="label-ellipsis"
                  *ngIf="!proposalPhaseData"
                  >Pending Proposal Creation by PreSales</label
                >
              </button>
            </div>

            <div class="col-3 time-content d-inline-flex align-items-center">
              <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                proposalPhaseData.consumed_time
                  ? (proposalPhaseData.consumed_time | opportunityConsumedTime)
                  : ""
              }}</span>

              <span
                *ngIf="proposalPhaseData"
                class="check_green col-2 fa fa-check fa-thin float-end mt-1"
              ></span>
            </div>
          </div>
          <div
            #collapse="ngbCollapse"
            [(ngbCollapse)]="isCreatedproposalDocCollapsed"
          >
            <div class="btn_collapse_content">
              <div class="d-grid p-1">
                <ng-template [ngIf]="proposalPhaseData.user_proposal">
                  <label class="phase_details_title">PreSales Name</label>
                  <label class="phase_details_data_txt">{{
                    proposalPhaseData.user_proposal.created_user_name
                  }}</label>
                  <label class="phase_details_title mt-2"
                    >Date & Time of Creation</label
                  >
                  <label class="phase_details_data_txt">{{
                    proposalPhaseData.created_at | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    proposalPhaseData.consumed_time
                  }}</label>
                  <!-- <input
                      *ngIf="currentUser._id == proposalPhaseData.created_by_rfid"
                      (click)="editproposal()"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      value="Edit proposal"
                    /> -->
                  <input
                    (click)="reviewproposal()"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    value="Review proposal"
                  />
                </ng-template>
                <ng-template [ngIf]="!proposalPhaseData">
                  <input
                    [ngClass]="{
                      phases_disabled_btn: !currentUser.isPreSales
                    }"
                    (click)="createproposalGoogleDoc()"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    value="Create proposal"
                  />
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!-- proposal APPROVAL PROCESS -->
        <ng-template
          [ngIf]="proposalPhaseData.approval_phases && !proposalPhaseData.draft"
        >
          <!-- PRESALES MANAGER APPROVAL -->
          <div
            class="btn_collapse_parent mt-2"
            id="proposal_manager_approval"
            *ngIf="proposalPhaseData.managerApprovalPart"
          >
            <div
              class="row opp_col_content align-items-center"
              style="padding: 5px 13px"
            >
              <div class="col-9 p-0 d-inline-flex">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': proposalPhaseData.managerApprovalPart.date,
                    'col-12': !proposalPhaseData.managerApprovalPart.date
                  }"
                  (click)="
                    isManagerApprovedproposalCollapsed =
                      !isManagerApprovedproposalCollapsed
                  "
                  [attr.aria-expanded]="!isManagerApprovedproposalCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isManagerApprovedproposalCollapsed,
                      'fa-angle-up': !isManagerApprovedproposalCollapsed
                    }"
                  ></span>
                  <label
                    title="Customer proposal
                  {{
                      proposalPhaseData.managerApprovalPart.approved
                        ? 'Approved'
                        : 'rejected'
                    }}
                  by PreSales Manager"
                    class="label-ellipsis"
                    *ngIf="proposalPhaseData.managerApprovalPart.date"
                    >Customer proposal
                    {{
                      proposalPhaseData.managerApprovalPart.approved
                        ? "Approved"
                        : "rejected"
                    }}
                    by PreSales Manager</label
                  >
                  <label
                    *ngIf="!proposalPhaseData.managerApprovalPart.date"
                    title="Pending Customer proposal Approved by PreSales Manager"
                    class="label-ellipsis"
                    >Pending Customer proposal Approved by PreSales
                    Manager</label
                  >
                </button>
              </div>

              <div class="col-3 time-content d-inline-flex align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">{{
                  proposalPhaseData.managerApprovalPart.consumed_time
                    ? (proposalPhaseData.managerApprovalPart.consumed_time
                      | opportunityConsumedTime)
                    : ""
                }}</span>
                <span
                  *ngIf="proposalPhaseData.managerApprovalPart.date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isManagerApprovedproposalCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title"
                    >Engineer Manager Name</label
                  >
                  <label class="phase_details_data_txt">{{
                    proposalPhaseData.managerApprovalPart.name
                  }}</label>
                  <ng-template
                    [ngIf]="proposalPhaseData.managerApprovalPart.date"
                  >
                    <label class="phase_details_title mt-2"
                      >Date & Time of
                      {{
                        proposalPhaseData.managerApprovalPart.approved
                          ? "Approval"
                          : "Rejection"
                      }}</label
                    >
                    <label class="phase_details_data_txt">{{
                      proposalPhaseData.managerApprovalPart.date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      proposalPhaseData.managerApprovalPart.consumed_time
                    }}</label>
                    <div
                      class="d-grid mt-2"
                      *ngIf="
                        proposalPhaseData.managerApprovalPart
                          .rejection_reason &&
                        proposalPhaseData.managerApprovalPart
                          .rejection_reason != ''
                      "
                    >
                      <label class="phase_details_title"
                        >Rejection Reason</label
                      >
                      <label class="phase_details_data_txt">{{
                        proposalPhaseData.managerApprovalPart.rejection_reason
                      }}</label>
                    </div>
                  </ng-template>
                  <input
                    *ngIf="
                      currentUser._id ==
                      proposalPhaseData.managerApprovalPart?.id
                    "
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="reviewproposal(true)"
                    value="Review Customer proposal"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- SENDING TO CUSTOMER PART -->
          <div
            class="btn_collapse_parent mt-2"
            id="proposal_customer1"
            *ngIf="proposalPhaseData.sendingToCustomerPart"
          >
            <div
              class="row opp_col_content align-items-center"
              style="padding: 5px 13px"
            >
              <div class="col-9 phase-content p-0">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': proposalPhaseData.sendingToCustomerPart.date,
                    'col-12': !proposalPhaseData.sendingToCustomerPart.date
                  }"
                  (click)="
                    isSendingCustproposalCollapsed =
                      !isSendingCustproposalCollapsed
                  "
                  [attr.aria-expanded]="!isSendingCustproposalCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isSendingCustproposalCollapsed,
                      'fa-angle-up': !isSendingCustproposalCollapsed
                    }"
                  ></span>
                  <label
                    title="PreSales sent proposal to Customer"
                    class="label-ellipsis"
                    *ngIf="proposalPhaseData.sendingToCustomerPart.date"
                    >PreSales sent proposal to Customer
                  </label>
                  <label
                    title="Pending Sending proposal to Customer by PreSales"
                    class="label-ellipsis"
                    *ngIf="!proposalPhaseData.sendingToCustomerPart.date"
                    >Pending Sending proposal to Customer by PreSales</label
                  >
                </button>
              </div>

              <div class="col-3 d-inline-flex time-content align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    proposalPhaseData.sendingToCustomerPart.consumed_time
                      ? (proposalPhaseData.sendingToCustomerPart.consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>
                <span
                  *ngIf="proposalPhaseData.sendingToCustomerPart.date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isSendingCustproposalCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <ng-template
                    [ngIf]="proposalPhaseData.sendingToCustomerPart.date"
                  >
                    <label class="phase_details_title">PreSales Name</label>
                    <label class="phase_details_data_txt">{{
                      proposalPhaseData.sendingToCustomerPart.name
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Date & Time of Sending</label
                    >
                    <label class="phase_details_data_txt">{{
                      proposalPhaseData.sendingToCustomerPart.date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      proposalPhaseData.sendingToCustomerPart.consumed_time
                    }}</label>
                  </ng-template>
                  <input
                    *ngIf="
                      currentUser.isPreSales &&
                      !proposalPhaseData.sendingToCustomerPart.date
                    "
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="sendproposalToCustPopup()"
                    value="Send proposal to Customer"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--CUSTOMER APPROVAL -->
          <div
            class="btn_collapse_parent mt-2"
            id="proposal_customer2"
            *ngIf="proposalPhaseData.sendingToCustomerPart?.date"
          >
            <div
              class="row opp_col_content align-items-center"
              style="padding: 5px 13px"
            >
              <div class="col-9 p-0 phase-content">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': proposalPhaseData.sendingToCustomerPart.date,
                    'col-12': !proposalPhaseData.sendingToCustomerPart.date
                  }"
                  (click)="
                    isCustApprovedproposalCollapsed =
                      !isCustApprovedproposalCollapsed
                  "
                  [attr.aria-expanded]="!isCustApprovedproposalCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isCustApprovedproposalCollapsed,
                      'fa-angle-up': !isCustApprovedproposalCollapsed
                    }"
                  ></span>
                  <label
                    title="Customer
                {{
                      proposalPhaseData.sendingToCustomerPart.approved
                        ? 'Approved'
                        : 'rejected'
                    }}
                proposal"
                    class="label-ellipsis"
                    *ngIf="
                      proposalPhaseData.sendingToCustomerPart.approval_date
                    "
                    >Customer
                    {{
                      proposalPhaseData.sendingToCustomerPart.approved
                        ? "Approved"
                        : "rejected"
                    }}
                    proposal
                  </label>
                  <label
                    title="Pending Customer Approval on proposal"
                    class="label-ellipsis"
                    *ngIf="
                      !proposalPhaseData.sendingToCustomerPart.approval_date
                    "
                    >Pending Customer Approval on proposal</label
                  >
                </button>
              </div>

              <div class="col-3 time-content d-inline-flex align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    proposalPhaseData.sendingToCustomerPart
                      .customer_approval_consumed_time
                      ? (proposalPhaseData.sendingToCustomerPart
                          .customer_approval_consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>

                <span
                  *ngIf="proposalPhaseData.sendingToCustomerPart.approval_date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isCustApprovedproposalCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title">Customer Name</label>
                  <label class="phase_details_data_txt">{{
                    proposalPhaseData.sendingToCustomerPart.customer_name
                  }}</label>
                  <ng-template
                    [ngIf]="
                      proposalPhaseData.sendingToCustomerPart.approval_date
                    "
                  >
                    <label class="phase_details_title mt-2"
                      >Date & Time of
                      {{
                        proposalPhaseData.sendingToCustomerPart.approved
                          ? "Approval"
                          : "Rejection"
                      }}</label
                    >
                    <label class="phase_details_data_txt">{{
                      proposalPhaseData.sendingToCustomerPart.approval_date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      proposalPhaseData.sendingToCustomerPart
                        .customer_approval_consumed_time
                    }}</label>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>

<!-- EMBEDED GOOGLE DOC -->
<div
  class="modal fade"
  bsModal
  #googleproposalDoc="bs-modal"
  tabindex="-1"
  id="googleproposalDoc"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-xl modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 product-sans col-11">Proposal Document</h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="googleproposalDoc.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mx-2" *ngIf="proposalPhaseData?.googleDocUrl">
          <proposal-google-doc-card
            *ngIf="googleDocData"
            [cardData]="googleDocData"
            [proposal]="proposalPhaseData"
            (createEmitter)="createproposal($event)"
            (approveEmitter)="approveproposal($event)"
            (custEmitter)="sendproposalToCustomer($event)"
            (draftEmitter)="sendDraftproposalForApproval($event)"
            [opporunityID]="opporunityID"
          ></proposal-google-doc-card>
        </div>
      </div>
    </div>
  </div>
</div>

<customer-info-popup
  *ngIf="customerInfoAdded"
  #proposalcustomerInfoPopup
  (sendListener)="sendproposalToCustomer($event)"
  [customer_emails]="customerInfoAdded.customer_email"
  [customer_name]="customerInfoAdded.customer_name"
  [withScopes]="true"
></customer-info-popup>
