import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { RequestResultModel } from 'src/models/request_result.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Endpoint,
  OrderAction,
  OrderStatus,
  PlanValues,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'app-license-update-modal',
  templateUrl: './license-update-modal.component.html',
  styleUrls: ['./license-update-modal.component.scss'],
})
export class LicenseUpdateModalComponent implements OnInit {
  @ViewChild('changeLicenseModal') public changeLicenseModal: ModalDirective;
  @Input() changeLicenseInfo;
  LicenseForm: UntypedFormGroup;
  prices = [];
  updateLicenseText: string = '';
  currentUser: UserModel;
  googleProducts = [];
  plans = [];
  globalData;
  opportunitySources = [];
  opportunityStatus = [];
  companySections = [];

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {
    this.LicenseForm = this.fb.group({
      current_licenses: [{ value: 0, disabled: true }, , Validators.required],
      sku_name: [{ value: '', disabled: true }, Validators.required],
      plan_id: ['', Validators.required],
      plan_name: ['', Validators.required],
      sku_id: ['', Validators.required],
      additional_licenses: [0, Validators.required],
      po_id: [null, Validators.required],
      order_sale_invoice_nbr: [null, Validators.required],
      opportunity_status: [null, Validators.required],
      opportunity_source: [null, Validators.required],
      customer_email_notification: [''],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      is_flexible: [false],
      annual_months: [0],
      with_customer_offer: [false, Validators.required],
      customer_offer: [0],
      with_google_offer: [false, Validators.required],
      google_offer: [0],
      cost_price: [0],
      selling_price: [0],
      company_section: [null, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    this.globalData = await Utilities.globalData(this.http, this.localStorage);
    if (this.globalData && this.globalData.length > 0) {
      this.opportunitySources =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_SOURCE.toLowerCase()
        )?.values ?? [];
      this.opportunityStatus =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY__OPPORTUNITY_STATUS.toLowerCase()
        )?.values ?? [];
      this.companySections =
        this.globalData.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY_COMPANY_SECTIONS.toLowerCase()
        )?.values ?? [];
    }
  }

  show(data) {
    this.changeLicenseInfo = data;
    if (this.changeLicenseInfo) {
      if (
        this.changeLicenseInfo.plan?.planName?.toLowerCase() ==
        PlanValues.FLEXIBLE.toLowerCase()
      )
        this.updateLicenseText = 'New License Cap';
      else this.updateLicenseText = 'Additional Licenses Needed';
      var isFlexible =
        this.changeLicenseInfo.plan?.planName
          ?.toLowerCase()
          .indexOf('flexible') >= 0;
      this.LicenseForm.patchValue({
        current_licenses: this.changeLicenseInfo.seatsNbr,
        sku_name: this.changeLicenseInfo.skuName,
        plan_name: this.changeLicenseInfo.plan?.planName,
        sku_id: this.changeLicenseInfo.skuId,
        is_flexible: isFlexible,
        plan_id: this.plans.find(
          (x) => x.code == this.changeLicenseInfo.plan?.planName
        )._id,
        start_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        end_date: this.changeLicenseInfo.plan?.commitmentInterval?.endTime
          ? this.datePipe.transform(
              new Date(
                Number.parseFloat(
                  this.changeLicenseInfo.plan?.commitmentInterval?.endTime
                )
              ),
              'yyyy-MM-dd'
            )
          : null,
      });
      this.LicenseForm.updateValueAndValidity();
      this.changeLicenseModal.show();
    }
  }
  hide() {
    this.changeLicenseModal.hide();
  }

  async updateLicense() {
    var order = new OrderModel();
    if (this.LicenseForm.valid) {
      var data = this.LicenseForm.value;
      if (
        (data.with_google_offer == true &&
          (!data.google_offer || data.google_offer == 0)) ||
        (data.with_customer_offer == true &&
          (!data.customer_offer || data.customer_offer == 0))
      ) {
        alert('you must add Offer percentage');
        return;
      }
      order.action = OrderAction.UPDATE_LICENSE;
      order.is_gcp = false;
      order.created_by_rfid = this.currentUser._id;
      order.domain_rfid = this.changeLicenseInfo.customerRfid;
      order.customer_api_id = this.changeLicenseInfo.customerId;
      order.customer_domain = this.changeLicenseInfo.customer_domain;
      order.order_status = this.currentUser.isExecutor
        ? OrderStatus.Executed
        : OrderStatus.Pending;
      order.po_id = data.po_id;
      order.order_sale_invoice_nbr = data.order_sale_invoice_nbr;
      order.opportunity_source = data.opportunity_source;
      order.opportunity_status = data.opportunity_status;
      order.company_section = data.company_section;
      order.customer_email_notification = data.customer_email_notification;
      var newLicenses =
        this.changeLicenseInfo.seatsNbr + data.additional_licenses;
      if (
        this.changeLicenseInfo.plan?.planName.toLowerCase() ==
        PlanValues.FLEXIBLE.toLowerCase()
      )
        newLicenses = data.additional_licenses; // ON FLEX ADDITIONAL IS THE NEW NBR
      var plan = this.plans.find((x) => x._id && x._id == data.plan_id);
      var is_commitment = false;
      if (
        plan?.code &&
        plan.code.toLowerCase().indexOf(PlanValues.ANNUAL.toLowerCase()) >= 0
      )
        is_commitment = true;
      order.sku = [
        {
          sku_id: this.changeLicenseInfo.skuId,
          sku_name: this.changeLicenseInfo.skuName,
          plan_id: data.plan_id,
          license: newLicenses ?? 0,
          old_license: this.changeLicenseInfo.seatsNbr,
          subscription_api_id: this.changeLicenseInfo.subscriptionId,
          cost_price: data.cost_price,
          selling_price: data.selling_price,
          with_customer_offer: data.with_customer_offer,
          customer_offer: data.customer_offer,
          with_google_offer: data.with_google_offer,
          google_offer: data.google_offer,
          start_date: data.start_date,
          end_date: data.end_date,
          plan_code: plan?.code,
          plan_name: plan?.name,
          is_commitment: is_commitment,
        },
      ];
      var toExecute = this.currentUser.isExecutor ? '1' : '0';
      var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
      var receivers = this.changeLicenseInfo.receivers;
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
      await this.http
        .post(Endpoint.GLOBAL_URL + '' + Endpoint.ADD_ORDER, order, {
          params: params,
        })
        .toPromise()
        .then(async (resp: any) => {
          console.log(resp);
          if (
            resp &&
            resp.success == true &&
            !Utilities.isNullOrEmpty(resp.id)
          ) {
            order._id = resp.id;
            this.changeLicenseModal.hide();
          } else {
            var msg = resp.message ?? 'Internal Server Error';
            try {
              msg = JSON.parse(resp.message).error.message;
            } catch (e) {}
            alert(msg);
          }
        });
    } else {
      alert('Add required fields');
    }
  }

  calculatePrices() {
    var sku = this.LicenseForm;
    if (
      !Utilities.isNullOrEmpty(sku.value.start_date) &&
      !Utilities.isNullOrEmpty(sku.value.annual_months) &&
      sku.value.annual_months > 0
    ) {
      var start = new Date(sku.value.start_date);
      var end_date: any = new Date(
        start.setMonth(start.getMonth() + sku.value.annual_months)
      );
      end_date = this.datePipe.transform(end_date, 'yyyy-MM-dd');
      sku.value.end_date = end_date;
      sku.controls['end_date'].setValue(end_date);
    }
    if (
      Utilities.isNullOrEmpty(sku.value.start_date) ||
      Utilities.isNullOrEmpty(sku.value.end_date)
    ) {
      return;
    }
    var from_ = new Date(sku.value.start_date);
    var to_ = new Date(sku.value.end_date);
    var mnoths = Utilities.calculateDiffMonths(from_, to_);
    var days = mnoths * 30;

    var skuObjectId = this.googleProducts.find(
      (x) =>
        sku.value.sku_id &&
        x.sku_id.toLowerCase() == sku.value.sku_id.toLowerCase()
    )?._id;
    var priceObj = this.prices.find(
      (x) =>
        skuObjectId &&
        sku.value.plan_id &&
        x.sku_rfid == skuObjectId &&
        x.plan_rfid == sku.value.plan_id
    );
    if (priceObj) {
      var price = priceObj.price / 30;
      var costMargin = Utilities.setOrderCostMargin(OrderAction.UPDATE_LICENSE);
      var costPrice: number = price * costMargin * days;
      var sellingPrice: number = price * days;

      //OFFERS
      if (
        sku.value.with_google_offer &&
        sku.value.google_offer &&
        sku.value.google_offer != 0
      ) {
        var offer = (100 - sku.value.google_offer) / 100;
        costPrice *= offer;
      }
      if (
        sku.value.with_customer_offer &&
        sku.value.customer_offer &&
        sku.value.customer_offer != 0
      ) {
        var offer = (100 - sku.value.customer_offer) / 100;
        sellingPrice *= offer;
      }
      //LICENSES
      var licencesNbr = 0;
      var currentLicenses =
        this.LicenseForm.get('current_licenses')?.value ?? 0;
      if (
        sku.value.plan_name.toUpperCase() ==
          PlanValues.FLEXIBLE.toUpperCase() &&
        sku.value.additional_licenses > currentLicenses
      ) {
        licencesNbr = sku.value.additional_licenses - currentLicenses;
      } else if (
        sku.value.plan_name.toUpperCase() !== PlanValues.FLEXIBLE.toUpperCase()
      ) {
        licencesNbr = sku.value.additional_licenses;
      }
      if (licencesNbr > 0) {
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;
        this.LicenseForm.patchValue({
          selling_price: Number.parseFloat(sellingPrice.toFixed(2)),
          cost_price: Number.parseFloat(costPrice.toFixed(2)),
        });
      }
    }
  }

  get current_licenses() {
    return this.LicenseForm.get('end_date');
  }
  get sku_name() {
    return this.LicenseForm.get('sku_name');
  }
  get plan_id() {
    return this.LicenseForm.get('plan_id');
  }
  get plan_name() {
    return this.LicenseForm.get('plan_name');
  }
  get sku_id() {
    return this.LicenseForm.get('sku_id');
  }
  get additional_licenses() {
    return this.LicenseForm.get('additional_licenses');
  }
  get po_id() {
    return this.LicenseForm.get('po_id');
  }
  get order_sale_invoice_nbr() {
    return this.LicenseForm.get('order_sale_invoice_nbr');
  }
  get opportunity_source() {
    return this.LicenseForm.get('opportunity_source');
  }
  get opportunity_status() {
    return this.LicenseForm.get('opportunity_status');
  }
  get customer_email_notification() {
    return this.LicenseForm.get('customer_email_notification');
  }
  get with_google_offer() {
    return this.LicenseForm.get('with_google_offer');
  }
  get google_offer() {
    return this.LicenseForm.get('google_offer');
  }
  get with_customer_offer() {
    return this.LicenseForm.get('with_customer_offer');
  }
  get customer_offer() {
    return this.LicenseForm.get('customer_offer');
  }
  get start_date() {
    return this.LicenseForm.get('start_date');
  }
  get annual_months() {
    return this.LicenseForm.get('annual_months');
  }
  get end_date() {
    return this.LicenseForm.get('end_date');
  }
  get company_section() {
    return this.LicenseForm.get('company_section');
  }
}
