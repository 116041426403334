import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Endpoint } from 'src/shared/Endpoint';

@Component({
  selector: 'app-healthy',
  templateUrl: './healthy.component.html',
  styleUrls: ['./healthy.component.scss'],
})
export class HealthyComponent implements OnInit {
  constructor(private http: HttpClient) {}

  async ngOnInit(): Promise<void> {
    await this.http
      .get(Endpoint.GLOBAL_URL + '' + Endpoint.TEST_H_C)
      .toPromise()
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
