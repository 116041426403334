import { BaseModel } from '../base.model';

export class BillingAccountModel extends BaseModel {
  _id: string;
  billing_account_id: string;
  name: string;
  parent: string;
  deleted: boolean;
  created_at: Date;
  created_by_rfid: string;
  customer_rfid;
  poc_rfid;
  opportunity_rfid: string;
  entitlement_name;
  sku_name;
  plan_name;
  gcp_offer_id;
  main_billing_account;
  credits:number;
  //CUSTOM
  created_date_string: string;

  amount: number;

  constructor() {
    super();
  }
}
