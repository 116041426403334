import { HttpClient, HttpParams } from '@angular/common/http';
import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Status,
  OrderAction,
  OrderStatus,
  Endpoint,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'app-sku-status-modal',
  templateUrl: './sku-status-modal.component.html',
  styleUrls: ['./sku-status-modal.component.scss'],
})
export class SkuStatusModalComponent implements OnInit {
  @ViewChild('statusModal') public statusModal: ModalDirective;
  @Input() statusInfo;
  googleProducts = [];
  plans = [];

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private confirmDialogService: ConfirmDialogService
  ) {}

  async ngOnInit(): Promise<void> {
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
  }

  show() {
    this.statusModal.show();
  }
  hide() {
    this.statusModal.hide();
  }

  async updateStatus() {
    var sub = this.statusInfo;
    var currentUser: UserModel = JSON.parse(
      this.localStorage.getLoggedinUser()
    );
    var plans = await Utilities.planList(this.http, this.localStorage);
    var googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    var order = new OrderModel();
    var toSuspend = sub.status == Status.Active;
    var statusTextAction: string;
    if (toSuspend == true) statusTextAction = 'suspend';
    else statusTextAction = 'activate';
    order.action = toSuspend ? OrderAction.SUSPEND : OrderAction.ACTIVATE;
    order.is_gcp = false;
    order.created_by_rfid = currentUser._id;
    order.domain_rfid = sub.customerRfid;
    order.customer_domain = sub.customer_domain;
    order.order_status = OrderStatus.Pending;
    order.sku = [
      {
        sku_id: sub.skuId,
        sku_name: sub.skuName,
        plan_id: plans.find((x) => x.code == sub.plan?.planName)._id,
        license: sub.seatsNbr,
        subscription_api_id: sub.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
      },
    ];
    await this.http
      .post(Endpoint.GLOBAL_URL + '' + Endpoint.SEND_APPROVAL_REQUEST, order)
      .toPromise()
      .then(async (resp: any) => {
        if (resp?.success == true) {
          this.statusModal.hide();
          var that = this;
          var action = !toSuspend ? 'Activation' : 'Suspension';
          var actionVerb = !toSuspend ? 'activate' : 'suspend';
          var actionAdj = !toSuspend ? 'active' : 'suspended';
          that.confirmDialogService.confirmThis(
            'your request to ' +
              actionVerb +
              ' ' +
              sub.skuName +
              ' for ' +
              sub.customer_domain +
              ' has been sent to the CEO. They will not get ' +
              actionAdj +
              ' until it is approved by the CEQ himself.',
            action + ' request sent',
            'info',
            function () {},
            function () {}
          );
        }
      });
  }
}
