import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerSubscriptionsRoutingModule } from './customer-subscriptions-routing.module';
import { CustomerSubscriptionsComponent } from './customer-subscriptions.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [CustomerSubscriptionsComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    CustomerSubscriptionsRoutingModule,
    NgbPopoverModule
  ],
})
export class CustomerSubscriptionsModule {}
