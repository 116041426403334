import { CustomerModel } from 'src/models/mongo/customer.model';
import { BaseModel } from '../base.model';
import { DomainModel } from './domain.model';

export class OrderModel extends BaseModel {
  _id: string;
  id: string;
  order_id: string; // TO BE REMOVED
  created_by_rfid: string;
  domain_rfid: string;
  customer_email_notification: string;
  action: string;
  trial_expiry_date;
  order_status: string;
  po_id: string;
  order_sale_invoice_nbr: string;
  description: string = null;
  transfer_to_direct: boolean = false;
  opportunity_nbr: string = null;
  opportunity_source: string = null;
  opportunity_status: string = null;
  google_rep: string = null;
  checked: boolean = false;
  verified: boolean;
  draft: boolean;
  opportunity_rfid: string;
  created_at: Date;
  project_number: string;
  project_name: string;
  billing_name: string;
  place_as: boolean;

  reject_date: string;
  exported: boolean = false;
  is_withdraw: boolean = false;
  billing_method: string;
  rejecter_email: string = ''; //to be deleted
  reject_reason: string;
  execution_note: string = '';
  invoice_file_rfid: string;
  currency_rfid: string;
  gcp_credits_coupon: string;
  used: boolean;
  DV_requested: boolean;
  approval_phases = [];
  sku: any = [];
  sku_names: any = [];
  support: any = [];
  sale_name: string;
  sale_email: string;
  is_gcp: boolean;
  customer_details: CustomerModel;
  company_section: string;
  //
  created_date_string: string;
  user: any;
  domain: DomainModel;
  customer_api_id: string;
  customer_domain:string;
  customer_organisation:string;
  skuName: string;
  skuNames: [];
  license_count: number;
  paramsData: any = {};
  projectNumber: any;
  link_project_billing: any;
  managerApprovalPart: any;
  gpmApprovalPart: any;
  financePart: any;

  constructor() {
    super();
  }
}
