<div class="container-fluid">
  <div class="row" *ngIf="cardData?.customer_domain; else custdrop">
    <div class="col-md-6 col-12 mb-2">
      <label class="me-1 text-wrap">Customer Domain</label>
      <label class="divider_label">{{ cardData.customer_domain }}</label>
    </div>
    <div class="col-md-6 col-12 mb-2">
      <label class="me-1 text-wrap">Company Name</label>
      <label class="divider_label">{{ cardData.customer_org }}</label>
    </div>
  </div>
  <ng-template #custdrop>
    <div class="row">
      <ng-select
        bindLabel="customer.domain"
        appendTo="body"
        id="domain"
        (search)="onSearchCustomerEnter($event)"
        (change)="selectCustomer()"
        [(ngModel)]="domainSelected"
        [searchable]="true"
        class="col-12 col-md-6 mb-2"
        [clearable]="true"
        placeholder="Customer Domain"
      >
        <ng-option
          [value]="customer"
          *ngFor="let customer of searchResultCustomers"
        >
          {{ customer.domain }}
        </ng-option>
      </ng-select>
    </div>
  </ng-template>

  <div class="d-flex flex-wrap">
    <div
      id="card_google_doc"
      class="mt-2 card_border p-1 flex-grow-1"
      [ngClass]="{
        'col-12 col-md-9': cardData.createdUser,
        'col-12': !cardData.createdUser
      }"
    >
      <iframe
        class="my-iframe-content mb-4"
        height="500px"
        width="100%"
        [src]="cardData.googleDocUrl"
      ></iframe>
    </div>
    <div class="col-md-3 col-12 p-3" *ngIf="cardData.createdUser">
      <div class="card_doc_section_approved p-1">
        <div class="row">
          <div class="col-3">
            <img
              *ngIf="cardData.createdUser.img"
              src="{{ cardData.createdUser.img }}"
              class="section_usr_img rounded-pill"
            />
            <img
              *ngIf="!cardData.createdUser.img"
              src="./assets/img/default_user_icon.svg"
            />
          </div>
          <label class="p-0 col-8 d-grid f-13 product-sans-bold">
            {{ cardData.createdUser.name }}
            <span>{{ cardData.createdUser.role }}</span>
          </label>
        </div>
        <div class="row f-13 product-sans-medium">
          <label
            [ngClass]="{
              'col-3': cardData.createdUser.date,
              'col-12': !cardData.createdUser.date
            }"
          >
            {{ cardData.createdUser.text }}
          </label>
          <label
            class="text-end col-8 flex_auto"
            *ngIf="cardData.createdUser.date"
          >
            {{ cardData.createdUser.date | date : "dd MMM,yyyy - hh:mma" }}
          </label>
        </div>
      </div>
      <div
        id="section_phases"
        *ngIf="cardData.approval_phases && cardData.approval_phases.length > 0"
      >
        <span class="fa fa-long-arrow-down px-3 section_arrow"></span>
        <div>
          <div
            id="sec_mng"
            class="p-1"
            [ngClass]="{
              card_doc_section_disabled:
                !cardData.managerApprovalPart?.date &&
                !cardData.managerApprovalPart?.current,
              card_doc_section_approved:
                cardData.managerApprovalPart?.date ||
                cardData.managerApprovalPart?.current == true
            }"
          >
            <div class="row">
              <div class="col-3">
                <img
                  *ngIf="cardData.managerApprovalPart?.img"
                  src="{{ cardData.managerApprovalPart.img }}"
                  class="section_usr_img rounded-pill"
                />
                <img
                  *ngIf="!cardData.managerApprovalPart?.img"
                  src="./assets/img/default_user_icon.svg"
                />
              </div>
              <div class="p-0 col-8 d-grid f-13 product-sans-bold">
                <label *ngIf="cardData.managerApprovalPart?.name">
                  {{ cardData.managerApprovalPart.name }}
                </label>
                <span>Manager</span>
              </div>
            </div>
            <div class="row f-13 product-sans-medium">
              <label
                [ngClass]="{
                  'col-3': cardData.managerApprovalPart?.date,
                  'col-12': !cardData.managerApprovalPart?.date
                }"
              >
                {{
                  cardData.managerApprovalPart?.date
                    ? cardData.managerApprovalPart.approved
                      ? "Approved"
                      : "Rejected"
                    : "Pending Approval"
                }}
              </label>
              <label
                class="text-end col-8 flex_auto"
                *ngIf="cardData.managerApprovalPart?.date"
              >
                {{
                  cardData.managerApprovalPart?.date
                    | date : "dd MMM,yyyy - hh:mma"
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="d-flex flex-wrap align-items-start justify-content-center justify-content-xl-end col-12 mt-5 mt-xl-2 pt-xl-4 pb-2 product-sans-light"
>
  <!-- <ng-template [ngIf]="canSendToCustomer == true">
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-2 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Send To Customer"
      (click)="sendSOWToCustPopup()"
    />
  </ng-template> -->
  <ng-template [ngIf]="add == true">
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Save & Send for Approval"
      (click)="createSOW()"
    />
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-2 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Save"
      (click)="createSOW(true)"
    />
  </ng-template>
  <ng-template
    [ngIf]="add == false && showSendToApprove == false && approve == true"
  >
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Approve"
      (click)="approveSOW()"
    />
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Reject"
      (click)="rejectSOWPopup()"
    />
  </ng-template>
  <ng-template [ngIf]="add == false && showSendToApprove == true">
    <input
      class="btn btn-primary small-text btn-color rounded-pill col-lg-3 col-md-3 col-5 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
      value="Send for Approval"
      (click)="sendDraftSOWForApproval()"
    />
  </ng-template>
</div>

<rejection-popup
  #rejectSOWPopupModal
  (rejectListener)="rejectSOW($event)"
></rejection-popup>

<customer-info-popup
  *ngIf="sow.domain"
  #customerInfoPopup
  (sendListener)="sendSOWToCust($event)"
  [customer_emails]="
    customerInfoAdded?.customer_email ?? [sow.domain?.contact_person_email]
  "
  [customer_name]="
    customerInfoAdded?.customer_name ?? sow.domain?.contact_person_name
  "
></customer-info-popup>
