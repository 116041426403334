<div
  class="px-md-4 rounded-5 w-100 mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 px-2"
>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          (click)="goBack()"
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          alt="back icon" /></a
      >Customers
    </h1>

    <div class="d-flex justify-content-center w-50">
      <input
        id="txt-order-global-search"
        type="search"
        (keyup.enter)="searchCustomer($event)"
        class="form-control rounded-pill h-36 w-100"
        placeholder="Search.."
      />
    </div>

    <div class="d-flex justify-content-end">
      <pagination-controls
        [responsive]="true"
        previousLabel=""
        nextLabel=""
        (pageChange)="renderPage($event)"
      ></pagination-controls>
    </div>
  </div>
  <div class="table-responsive fluid-section bg-white mt-3 w-100 max-h-75">
    <table class="table table-hover align-middle text-center smaller-text">
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 border-top-0"
          >
            Domain
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Organization
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Account Manager
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Region
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Status
          </th>
          <th
            scope="col"
            class="p-1 p-md-2 col-1 col-md-2 position-relative border-top-0"
          >
            Edit
            <div class="w-100 h-100 bg-white position-absolute top-0"></div>
          </th>
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <ng-container
          *ngFor="
            let row of customers
              | paginate
                : {
                    itemsPerPage: GetPageSize,
                    currentPage: GetPagination,
                    totalItems: GetTotalCount
                  };
            let i = index
          "
        >
          <tr>
            <td class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 py-3">
              <a (click)="expandCustomer(i, row.customer_api_id)">
                <img
                  alt="expand icon"
                  id="img_expand_sku"
                  aria-label="expand-icon"
                  src="./assets/img/expand-icon.svg"
                  class="ms-auto cursor-pointer mx-1"
                />
              </a>
              {{ row.domain }}
            </td>

            <td class="p-1 p-md-2 col-1 col-md-2">
              {{ row.organisation_name }}
            </td>
            <td class="p-1 p-md-2 col-1 col-md-2">
              <div
                class="image-container"
                *ngIf="row.account_manager_image_url"
              >
                <img
                  class="account-manager-image"
                  [src]="row.account_manager_image_url"
                  [alt]="row.account_manager"
                />
                <div class="tooltip bg-primary">{{ row.account_manager }}</div>
              </div>
              <div *ngIf="!row.account_manager_image_url">
                {{ row.account_manager }}
              </div>
            </td>
            <td class="p-1 p-md-2 col-1 col-md-2">{{ row.country_name }}</td>
            <td class="p-1 p-md-2 col-1 col-md-2">
              {{ row.deleted === false ? "active" : "inactive" }}
            </td>

            <td
              class="p-1 p-md-2 text-end text-nowrap col-1 col-md-3 col-lg-2 text-md-end p-0 p-md-2"
            >
              <a (click)="viewDetails(row)">
                <img
                  class="px-1 px-md-2 cursor-pointer"
                  src="./assets/img/view-icon.svg"
                  data-bs-toggle="tooltip"
                  title="view customer s skus,orders and deals"
                  alt="view icon"
                />
              </a>

              <img
                *ngIf="currentUser._id == row.created_by_rfid"
                src="./assets/img/delete-icon.svg"
                class="ps-1 ps-md-2 cursor-pointer"
                alt="delete icon"
                [attr.data-bs-toggle]="row.deleted ? null : 'modal'"
                [attr.data-bs-target]="
                  row.deleted ? null : '#rejectionReasonModal'
                "
                (click)="this.deleteCustomerConfirmation(row.customer_rfid)"
                data-bs-toggle="tooltip"
                title="delete customer"
              />
              <a (click)="editCustomer(row.customer_rfid, row.auth_token)">
                <img
                  class="px-1 px-md-2 cursor-pointer"
                  src="./assets/img/edit-icon.svg"
                  data-bs-toggle="tooltip"
                  title="edit customer info"
                  alt="edit icon"
                />
              </a>

              <button
                *ngIf="canCreateOrderAction"
                (click)="createNewOpportunityClick(row)"
                class="dropdown-item text-white"
                class="btn btn-primary rounded-pill btn-color me-2"
              >
                Add Opportunity
              </button>
              <button
                class="btn btn-primary btn-sm d-inline d-md-none btn-sm rounded-pill btn-color smaller-text"
              >
                Create
              </button>
            </td>
          </tr>
          <tr
            id="customer-collapse-{{ i }}"
            class="collapse my-3 td-intern-sku"
          >
            <td colspan="7">
              <tr *ngFor="let sku of row.skus" class="td-intern-sku">
                <td
                  [ngClass]="{
                    color_red: !sku.status?.toLowerCase().includes('active'),
                    color_green: sku.status?.toLowerCase().includes('active')
                  }"
                  class="p-1 p-md-2 text-md-start ps-md-5 col-2 py-3 border-0 product-sans-bold"
                >
                  {{ sku.skuName }}
                </td>
                <td class="p-1 col-3 border-0 product-sans-bold">
                  {{ sku.plan?.planName }}
                  <img
                    src="./assets/img/edit-icon.svg"
                    alt="edit icon"
                    data-bs-toggle="tooltip"
                    [ngClass]="{
                      my_disabled: !canCreateOrderAction
                    }"
                    title="update plan"
                    (click)="
                      changePlan(
                        sku,
                        row._id,
                        row.domain,
                        row.organisation_name
                      )
                    "
                    class="cursor-pointer"
                  />
                </td>
                <td
                  title="Purchased Licenses\Assigned Licenses"
                  data-bs-toggle="tooltip"
                  class="p-1 col-2 border-0 product-sans-bold"
                >
                  {{ sku.seatsDetails
                  }}<img
                    src="./assets/img/edit-icon.svg"
                    alt="edit icon"
                    [ngClass]="{
                      my_disabled: !canCreateOrderAction
                    }"
                    class="cursor-pointer"
                    data-bs-toggle="tooltip"
                    title="update licenses"
                    (click)="changeLicensePopup(sku, row._id, row.domain)"
                  />
                </td>
                <td
                  [ngClass]="{
                    'hidden-text':
                      !sku.plan ||
                      !sku.plan.commitmentInterval ||
                      !sku.plan.commitmentInterval.endTime
                  }"
                  data-bs-toggle="tooltip"
                  title="Expiry Date"
                  class="p-1 p-md-3 col-2 border-0 product-sans-bold"
                >
                  {{
                    sku.plan?.commitmentInterval?.endTime | date : "dd-MMM-yyyy"
                  }}
                </td>
                <td
                  *ngIf="canCreateOrderAction"
                  class="p-1 p-md-2 col-5 col-md-4 border-0 product-sans-bold"
                >
                  <img
                    *ngIf="
                      sku.plan &&
                      sku.plan.planName &&
                      sku.plan.planName.toLowerCase() == 'trial'
                    "
                    src="./assets/img/start-paid-service-icon.svg"
                    alt="paid service icon"
                    data-bs-toggle="tooltip"
                    title="start paid service"
                    (click)="startPaidServiceConfirmation(sku, row._id)"
                    class="cursor-pointer"
                  />
                  <ng-template [ngIf]="currentUser.isFinanceTeam">
                    <img
                      *ngIf="sku.status.toLowerCase().includes('act')"
                      src="./assets/img/suspend-icon.svg"
                      data-bs-toggle="tooltip"
                      title="suspend"
                      alt="suspend icon"
                      class="cursor-pointer"
                      (click)="updateStatusConfirmation(sku, true, row._id)"
                    />
                    <img
                      *ngIf="sku.status.toLowerCase().includes('suspend')"
                      src="./assets/img/activate-icon.svg"
                      data-bs-toggle="tooltip"
                      title="activate"
                      alt="activate icon"
                      class="cursor-pointer"
                      (click)="updateStatusConfirmation(sku, false, row._id)"
                    />
                  </ng-template>
                  <img
                    (click)="
                      downUpgrade(
                        sku,
                        true,
                        row._id,
                        row.domain,
                        row.organisation_name,
                        row.contact_person_email
                      )
                    "
                    src="./assets/img/upgrade-icon.svg"
                    data-bs-toggle="tooltip"
                    title="upgrade"
                    alt="upgrade icon"
                    class="cursor-pointer"
                  />
                  <img
                    (click)="
                      downUpgrade(
                        sku,
                        false,
                        row._id,
                        row.domain,
                        row.organisation_name,
                        row.contact_person_email
                      )
                    "
                    src="./assets/img/downgrade-icon.svg"
                    data-bs-toggle="tooltip"
                    title="downgrade"
                    alt="downgrade icon"
                    class="cursor-pointer"
                  />
                  <img
                    src="./assets/img/renewal-settings-icon.svg"
                    alt="renewal settings icon"
                    data-bs-toggle="tooltip"
                    title="update renewal settings"
                    class="cursor-pointer"
                    (click)="updateRenewalSettingsConfirmation(sku, row._id)"
                  />
                  <img
                    (click)="
                      renew(sku, row._id, row.domain, row.organisation_name)
                    "
                    src="./assets/img/renew-icon.svg"
                    data-bs-toggle="tooltip"
                    title="renew"
                    alt="renew icon"
                    class="cursor-pointer"
                  />
                </td>
              </tr>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- REJECTION REASONS POPUP -->
<div
  class="modal fade"
  role="alert"
  id="rejectionReasonModal"
  tabindex="-1"
  aria-labelledby="rejectionReasonModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="rejectionReasonModalLabel"
        >
          Customer Deletion Reason
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h6">Please Select a reason for deleting this customer</h2>
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <label
            for="reason"
            class="form-label text-nowrap me-3 mb-0 d-none d-md-inline"
            >Reason</label
          >
          <ng-select
            bindLabel="rs"
            appendTo="body"
            [searchable]="true"
            class="col-10 p-0"
            [(ngModel)]="CutomerDeleteReason"
            [clearable]="true"
            placeholder="{{ rejectionReasons[0] }}"
          >
            <ng-option [value]="rs" *ngFor="let rs of rejectionReasons">
              {{ rs }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="deleteCustomer()"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>

<gws-gcp-popup
  #gwsGcpOppModal
  (saveOption)="addOpportunity($event)"
></gws-gcp-popup>

<!-- ACTIONS -->
<app-sku-status-modal
  #statusModal
  [statusInfo]="statusInfo"
></app-sku-status-modal>

<app-paid-service-modal
  #startPaidServiceModal
  [paidServiceInfo]="paidServiceInfo"
></app-paid-service-modal>

<app-renewal-settings-modal
  #renewalSettingsModal
  [renewalSettingsInfo]="renewalSettingsInfo"
></app-renewal-settings-modal>
<app-license-update-modal
  #changeLicenseModal
  [changeLicenseInfo]="changeLicenseInfo"
></app-license-update-modal>

<!-- *** GWS OPP OPTION ***  -->
<div
  class="modal fade"
  bsModal
  #orderDealModl="bs-modal"
  tabindex="-1"
  id="orderDealModl"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div
        class="modal-header justify-content-center border-0 btn-color btn-color"
      >
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="orderDealModl.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h2 class="fs-5 text-center my-2">What do you want to do next?</h2>
        <div class="d-flex justify-content-evenly py-3 px-5">
          <button
            (click)="GWSTakeAction(true)"
            class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text col-4"
          >
            Create Order
          </button>
          <button
            *ngIf="canCreateOrderAction"
            (click)="GWSTakeAction(false)"
            class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text col-4"
          >
            Create Deal
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
