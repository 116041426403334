import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  NgZone,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DealModel } from 'src/models/mongo/deal.model';
import { DomainModel } from 'src/models/mongo/domain.model';
import { OpportunityModel } from 'src/models/mongo/opportunity.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Endpoint,
  Group,
  OpportunityStatus,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OpportunityService } from '../opportunity.service';
import { CustomerRequirementsModel } from 'src/models/mongo/customer_requirements.model';
import { GcpService } from 'projects/gcp/src/public-api';
// import { OpportunityConsumedTimePipe } from 'src/app/pipes/opportunity-consumed-time.pipe';

@Component({
  selector: 'lib-opporunity-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './opporunity-details.component.html',
  styleUrls: ['./opporunity-details.component.scss'],
})
export class OpporunityDetailsComponent implements OnInit {
  @HostBinding('class') class = '';
  @ViewChild('OpportunityPickedModal') OpportunityPickedModal: ModalDirective;
  @ViewChild('PickUsersModal') PickUsersModal: ModalDirective;

  @ViewChild('EditSaleAmountModal') EditSaleAmountModal: ModalDirective;
  @ViewChild('ConfirmApprovalModal') ConfirmApprovalModal: ModalDirective;
  domain = new DomainModel();
  opportunity = new OpportunityModel();
  add: boolean = true;
  isGWS: boolean = true;
  currentUser: UserModel;
  loginUsersData;
  searchResultCustomers = [];
  domainSelected;
  ispicked: boolean;
  picked_user_ids = [];
  new_picked_users = [];
  engineers = [];
  saleAmount: boolean;
  dafRequired: boolean;
  psfRequired: boolean;
  pocBillingCostInfo = {
    project_id: null,
    project_name: null,
    total_cost_usage: 0,
    total_credits: 0,
    total_budget_amount: 0,
    cost_usage_percentage: 0,
    credit_usage_percentage: 0,
    total_credits_usage: 0,
  };
  orderBillingCostInfo = {
    project_id: null,
    project_name: null,
    total_cost_usage: 0,
    total_credits: 0,
    total_budget_amount: 0,
    cost_usage_percentage: 0,
    credit_usage_percentage: 0,
    total_credits_usage: 0,
  };

  oppService = inject(OpportunityService);
  gcpService = inject(GcpService);

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef
  ) {
    this.currentUser = new UserModel(this.localStorage.getLoggedinUserModel());
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson)) this.domain = JSON.parse(cstjson);
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (routeParams) => {
      const state = history.state;
      if (state) {
        if (!Utilities.isNullOrEmpty(state.id)) {
          this.add = false;
          this.opportunity._id = state.id;
        } else if (routeParams && !Utilities.isNullOrEmpty(routeParams['Id'])) {
          this.add = false;
          this.opportunity._id = routeParams['Id'];
        } else {
          this.isGWS = state.isGWS;
          this.opportunity.opportunity_id =
            Utilities.randomNumber(1000, 1000000000) + '';
          this.opportunity.created_by_rfid = this.currentUser._id;
          this.opportunity.domain_rfid = this.domain._id;
          this.opportunity.is_gcp = !this.isGWS;
          this.opportunity.opportunity_status = 'in progress';
          this.opportunity.skip_steps = {
            deal: false,
            solution_building: false,
            poc: false,
            proposal: false,
            order: false,
          };
          this.opportunity.disabled = {
            deal: false,
            solution_building: true,
            poc: true,
            proposal: true,
            order: true,
          };
        }
      }
      //
      var that = this;
      if (!this.add) {
        var resp: OpportunityModel = await this.oppService.getOpportunityById(
          this.opportunity._id
        );
        if (resp) {
          this.opportunity = resp;
          if (resp.domain) {
            this.domain = resp.domain;
            this.localStorage.save(
              Unicode.CURRENT_CUSTOMER,
              JSON.stringify(this.domain)
            );
          }
          this.isGWS = !resp.is_gcp;
          if (this.opportunity.engineer_engagement_not_required) {
            this.opportunity.skip_steps = {
              solution_building: true,
              poc: true,
              proposal: this.opportunity.skip_steps.proposal,
              order: this.opportunity.skip_steps.order,
            };
          }
          //DEAL
          if (this.opportunity.deal) {
            this.opportunity.deal.customer_domain = this.domain.domain;
            this.opportunity.deal.customer_organisation =
              this.domain.organisation_name;
            if (!this.opportunity.deal.daf) this.opportunity.deal.daf = {};
            else {
              this.opportunity.deal.daf.file = this.opportunity.deal.daf
                .attachment_proof_name
                ? { name: this.opportunity.deal.daf.attachment_proof_name }
                : null;
            }
            if (!this.opportunity.deal.psf) this.opportunity.deal.psf = {};
            else {
              this.opportunity.deal.psf.file = this.opportunity.deal.psf
                .attachment_proof_name
                ? { name: this.opportunity.deal.psf.attachment_proof_name }
                : null;
            }
          }
          var managerPhase = this.opportunity.deal?.approval_phases
            .sort((a, b) => {
              return <any>new Date(b.date) - <any>new Date(a.date);
            })
            .find((x) =>
              Utilities.areStringsEqualIgnoringCase(x.role, Group.Sale_Manager)
            );
          this.opportunity.deal.managerApprovalPart = managerPhase;
          // if (
          //   !Utilities.isNullOrEmpty(
          //     this.opportunity.deal.manager_user_email
          //   )
          // ) {
          //   var managerPhase = this.opportunity.deal?.approval_phases
          //     ?.filter((x) => x.date)
          //     .sort((a, b) => {
          //       return <any>new Date(b.date) - <any>new Date(a.date);
          //     })
          //     .find(
          //       (x) => x.role && x.role.toLowerCase() == Group.Sale_Manager
          //     );
          //   if (managerPhase?.date != null) {
          //     this.opportunity.deal.approved_by_sales_manager = true;
          //     this.opportunity.deal.sales_manager_consumed_time =
          //       managerPhase.consumed_time;
          //     this.opportunity.deal.sales_manager_approval_string_date =
          //       Utilities.toStringDateFormat(
          //         this.datePipe,
          //         managerPhase.date,
          //         'dd MMM,yyyy-hh:mma'
          //       );
          //   } else this.opportunity.deal.approved_by_sales_manager = false;
          // }
          var gpmPhase = this.opportunity.deal?.approval_phases?.find((x) =>
            Utilities.areStringsEqualIgnoringCase(x.role, Group.GPM)
          );
          if (gpmPhase) {
            this.opportunity.deal.show_gpm_phase = true;
            gpmPhase = this.opportunity.deal?.approval_phases
              //  ?.filter((x) => x.date)
              .sort((a, b) => {
                return <any>new Date(b.date) - <any>new Date(a.date);
              })
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(x.role, Group.GPM)
              );
            if (gpmPhase?.date) {
              this.opportunity.deal.approved_by_gpm = true;
              this.opportunity.deal.gpm_approval_name = gpmPhase.name;
              this.opportunity.deal.gpm_consumed_time = gpmPhase.consumed_time;

              this.opportunity.deal.gpm_approval_date = gpmPhase.date;
              this.opportunity.deal.gpm_approval_string_date =
                Utilities.toStringDateFormat(
                  this.datePipe,
                  gpmPhase.date,
                  'dd MMM,yyyy-hh:mma'
                );
              //GOOGLE REGISTRATION & APPROVAL
              this.opportunity.deal.show_deal_google_registration_phase = true;
              if (!Utilities.isNullOrEmpty(this.opportunity.deal.dr_nbr)) {
                this.opportunity.deal.deal_google_registered = true;
                this.opportunity.deal.show_deal_google_approval_phase = true;
              }
              if (
                !Utilities.isNullOrEmpty(this.opportunity.deal.opportunity_nbr)
              ) {
                this.opportunity.deal.show_deal_google_approval_phase = true;
                this.opportunity.deal.google_approved_deal = true;
              }
            } else {
              this.opportunity.deal.approved_by_gpm = false;
            }
          } else {
            this.opportunity.deal.show_gpm_phase = false;
          }
          //
          if (!Utilities.isNullOrEmpty(this.opportunity.picked_user_ids)) {
            for (let id of this.opportunity.picked_user_ids) {
              this.picked_user_ids.push(id);
            }
            this.ispicked = this.picked_user_ids.length > 0;
          }
        }
        //SOLUTION BUILDING
        // CUST REQ & ENG RECOm
        if (
          this.opportunity?.solution_building?.customer_requirements
            ?.approval_phases
        ) {
          this.opportunity.solution_building.customer_requirements.managerApprovalPart =
            this.opportunity.solution_building.customer_requirements.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(
                  x.role,
                  Group.Engineer_Manager
                )
              );
          this.opportunity.solution_building.customer_requirements.sendingToCustomerPart =
            this.opportunity.solution_building.customer_requirements.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
              );
        }
        if (
          this.opportunity?.solution_building?.engineering_recommendations
            ?.approval_phases
        ) {
          this.opportunity.solution_building.engineering_recommendations.managerApprovalPart =
            this.opportunity.solution_building.engineering_recommendations.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(
                  x.role,
                  Group.Engineer_Manager
                )
              );
          this.opportunity.solution_building.engineering_recommendations.sendingToCustomerPart =
            this.opportunity.solution_building.engineering_recommendations.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
              );
        }
        //
        if (this.opportunity.solution_building?.sow?.approval_phases) {
          this.opportunity.solution_building.sow.managerApprovalPart =
            this.opportunity.solution_building.sow.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(
                  x.role,
                  Group.Engineer_Manager
                )
              );
          this.opportunity.solution_building.sow.saleAmountPart =
            this.opportunity.solution_building.sow.sale_amount;
          this.dafRequired =
            this.opportunity.solution_building.sow.daf_required;
          this.psfRequired =
            this.opportunity.solution_building.sow.psf_required;
          this.opportunity.solution_building.sow.googleApprovalPart =
            this.opportunity.solution_building.sow.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(x.role, 'google')
              );
          // this.opportunity.solution_building.sow.sendingToCustomerPart =
          //   this.opportunity.solution_building.sow.approval_phases
          //     .slice()
          //     .reverse()
          //     .find(
          //       (x) =>
          //         x.role &&
          //         x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
          //     );
        }
        //POC
        if (this.opportunity?.poc_phase.poc?.approval_phases) {
          this.opportunity.poc_phase.poc.managerApprovalPart =
            this.opportunity.poc_phase.poc.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(
                  x.role,
                  Group.Engineer_Manager
                )
              );
          this.opportunity.poc_phase.poc.sendingToCustomerPart =
            this.opportunity.poc_phase.poc.approval_phases
              .slice()
              .reverse()
              .find((x) =>
                Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
              );
        }
        //PROPOSAL
        if (this.opportunity?.proposal) {
          if (this.opportunity.proposal.approval_phases) {
            this.opportunity.proposal.managerApprovalPart =
              this.opportunity.proposal.approval_phases
                .slice()
                .reverse()
                .find((x) =>
                  Utilities.areStringsEqualIgnoringCase(
                    x.role,
                    Group.Engineer_Manager
                  )
                );
            this.opportunity.proposal.sendingToCustomerPart =
              this.opportunity.proposal.approval_phases
                .slice()
                .reverse()
                .find((x) =>
                  Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
                );
          }
        }

        //
        this.opportunity.solution_building.is_psf =
          this.opportunity.deal?.psf?.available;
        this.opportunity.disabled = {
          deal: false,
          solution_building:
            !this.opportunity.deal?.google_approved_deal ||
            (this.currentUser.isEngineer &&
              !this.currentUser.is_manager &&
              !this.opportunity.picked_user_ids?.includes(
                this.currentUser._id
              )),
          poc:
            !this.opportunity.deal?.google_approved_deal ||
            (this.currentUser.isEngineer &&
              !this.currentUser.is_manager &&
              !this.opportunity.picked_user_ids?.includes(
                this.currentUser._id
              )),
          proposal:
            !this.opportunity.deal?.google_approved_deal ||
            (this.currentUser.isEngineer &&
              !this.currentUser.is_manager &&
              !this.opportunity.picked_user_ids?.includes(
                this.currentUser._id
              )),
          order:
            !this.opportunity.proposal?.sendingToCustomerPart?.approval_date ||
            (this.currentUser.isEngineer &&
              !this.currentUser.is_manager &&
              !this.opportunity.picked_user_ids?.includes(
                this.currentUser._id
              )),
        };
      }
      //CREATION CASE
      else {
        var deal = new DealModel();
        deal.customer_domain = this.domain?.domain;
        deal.customer_organisation = this.domain?.organisation_name;
        this.opportunity.deal = deal;
      }

      //COST CONSUMPTION DATA
      var projectIds = [];
      if (this.opportunity.poc_phase?.gcp_project?.project_id)
        projectIds.push(this.opportunity.poc_phase.gcp_project.project_id);
      if (
        this.opportunity.order_phase?.gcp_project?.id &&
        this.opportunity.order_phase?.gcp_project?.id !==
          this.opportunity.poc_phase?.gcp_project?.project_id
      )
        projectIds.push(this.opportunity.order_phase.gcp_project.id);
      if (projectIds.length > 0) {
        var costData: any 
        = await this.gcpService.billingConsumptionData(
          projectIds
        );
        if (costData?.projects?.length > 0) {
          console.log(costData);
          var that = this;
          //POC
          var pocBudget = this.opportunity.poc_phase.billing_budget;
          if (
            !Utilities.isNullOrEmpty(
              this.opportunity.poc_phase?.gcp_project?.project_id
            )
          ) {
            var pocCost = costData.projects.find(
              (item) =>
                item.project_id ===
                this.opportunity.poc_phase.gcp_project.project_id
            );
            if (pocCost) {
              this.pocBillingCostInfo = {
                ...pocCost,
                total_budget_amount: pocBudget?.amount ?? 0,
                cost_usage_percentage:
                  pocBudget?.amount > 0
                    ? Number.parseFloat(
                        (
                          (100 * Number(pocCost.total_cost_usage)) /
                          Number(pocBudget.amount)
                        ).toFixed(2)
                      )
                    : 0,
                total_credits:
                  this.opportunity.poc_phase?.billing_account?.credits ?? 0,
                credit_usage_percentage:
                  this.opportunity.poc_phase?.billing_account?.credits > 0
                    ? Number.parseFloat(
                        (
                          (100 * Number(pocCost.total_credits_usage)) /
                          Number(
                            this.opportunity.poc_phase?.billing_account?.credits
                          )
                        ).toFixed(2)
                      )
                    : 0,
              };
            }
          }
          //ORDER
          var orderBudget = this.opportunity.order_phase.billing_budget;
          if (
            !Utilities.isNullOrEmpty(
              this.opportunity.order_phase?.gcp_project?.id
            )
          ) {
            var orderCost = costData.projects.find(
              (item) =>
                item.project_id === this.opportunity.order_phase.gcp_project.id
            );
            if (orderCost) {
              this.orderBillingCostInfo = {
                ...orderCost,
                total_budget_amount: orderBudget?.amount ?? 0,
                cost_usage_percentage:
                  orderBudget?.amount > 0
                    ? (100 * Number(pocCost.total_cost_usage)) /
                      Number(orderBudget.amount)
                    : 0,
                total_credits:
                  this.opportunity.order_phase?.billing_account?.credits ?? 0,
                credit_usage_percentage:
                  this.opportunity.order_phase?.billing_account?.credits > 0
                    ? (100 * Number(orderCost.total_credits_usage)) /
                      Number(
                        this.opportunity.order_phase.billing_account.credits
                      )
                    : 0,
              };
            }
          }
        }
      }
    });
  }

  async onSearchDomainEnter(e) {
    var value = e.term ?? e.target.value;
    if (value && value.length > 2) {
      if (!Utilities.isNullOrEmpty(value)) this.searchResultCustomers = [];
      var params = new HttpParams()
        .set(Unicode.CUSTOMER_FILTER, value)
        .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
      await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.SEARCH_FOR_CUSTOMERS_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.domains?.length > 0) {
            this.searchResultCustomers = resp.domains ?? [];
          }
        });
    }
  }

  async selectDomain() {
    if (this.domainSelected) {
      this.domain = this.domainSelected;
      this.opportunity.domain_rfid = this.domainSelected._id;
      this.localStorage.save(
        Unicode.CURRENT_CUSTOMER,
        JSON.stringify(this.domain)
      );
      //  await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  }
  startDealCreation(e) {
    this.opportunity.startDealDate = new Date();
  }

  async saveDeal_back(model) {
    var that = this;
    var receivers = [
      !Utilities.isNullOrEmpty(this.currentUser.manager_id)
        ? this.currentUser.manager_email
        : this.loginUsersData?.gpm_group_email,
    ];
    var params = new HttpParams();
    if (!model.draft)
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    this.opportunity.opportunity_status = OpportunityStatus.IN_PROGRESS;
    if (Utilities.isNullOrEmpty(this.opportunity.domain_rfid))
      this.opportunity.domain_rfid = this.domain._id;
    var opp = JSON.parse(JSON.stringify(this.opportunity));
    if (Utilities.isNullOrEmpty(model.domain_rfid))
      model.domain_rfid = this.domain._id;
    model.start_date = this.opportunity.startDealDate;
    opp.deal = model;
    opp.draft = model.draft;
    //ADD
    if (Utilities.isNullOrEmpty(model.id)) {
      var oppDependencyOn = Group.GPM;
      if (!Utilities.isNullOrEmpty(this.currentUser.manager_id))
        oppDependencyOn = Group.Sale;
      opp.dependency_on = oppDependencyOn;
      const formData = new FormData();
      if (model.daf.available && model.daf.file?.size)
        formData.append('daf_file', model.daf.file);
      if (model.psf.available && model.psf.file?.size)
        formData.append('psf_file', model.psf.file);
      formData.append('opportunity', JSON.stringify(opp));
      await this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.ADD_OPPORTUNITY, formData, {
          params: params,
        })
        .toPromise()
        .then(async (resp: any) => {
          alert(resp.message);
          this.opportunity._id = resp.opportunity_rfid;
          model.id = resp.deal_rfid;
          model.deal_time = resp.consumed_time;
          this.opportunity.deal.id = resp.deal_rfid;
          model.created_at = new Date();
          model.created_date_string = Utilities.toStringDateFormat(
            this.datePipe,
            new Date(),
            'dd MMM,yyyy-hh:mma'
          );
          model.consumed_time = resp.consumed_time;
          model.user_deal = {
            created_user_name: this.currentUser.getFullName(),
            manager_user_email: this.currentUser.manager_email,
            manager_user_name: this.currentUser.manager_name,
          };
          if (Utilities.isNullOrEmpty(this.currentUser.manager_email)) {
            model.show_gpm_phase = true;
          } else {
            model.managerApprovalPart = {
              name: this.currentUser.manager_name,
            };
          }
          this.opportunity.deal = model;
          if (this.opportunity.solution_building)
            this.opportunity.solution_building.is_psf =
              this.opportunity.deal?.psf?.available;
          this.ngZone.run(() => {
            this.opportunity.disabled = {
              deal: false,
              solution_building: false,
              poc: false,
              proposal: false,
              order: false,
            };
          });
          this.opportunity.dependency_on = Group.GPM;
        })
        .catch((err) => {
          throw err;
        });
    }
    //EDIT
    else {
      var dealTime = this.opportunity.deal.deal_time;
      this.opportunity.deal = model;
      this.opportunity.deal.deal_time = dealTime;
      this.opportunity.disabled.solution_building =
        !this.opportunity.deal.google_approved_deal ||
        (this.currentUser.isEngineer &&
          !this.currentUser.is_manager &&
          !this.picked_user_ids?.includes(this.currentUser._id));
      this.opportunity.disabled.poc =
        !this.opportunity.deal.google_approved_deal ||
        (this.currentUser.isEngineer &&
          !this.currentUser.is_manager &&
          !this.picked_user_ids?.includes(this.currentUser._id));
      this.opportunity.disabled.proposal =
        !this.opportunity.deal.google_approved_deal ||
        (this.currentUser.isEngineer &&
          !this.currentUser.is_manager &&
          !this.picked_user_ids?.includes(this.currentUser._id));
    }
  }

  viewCustomer() {
    this.router.navigate(['customers/customer-services/subscriptions'], {
      skipLocationChange: false,
    });
  }

  skipDeal(skipped: boolean) {
    this.opportunity.skip_steps.deal = skipped;
  }
  skipSolution(skipped: boolean) {
    this.opportunity.skip_steps.solution_building = skipped;
  }
  skipPoc(skipped: boolean) {
    this.opportunity.skip_steps.poc = skipped;
  }
  skipProposal(skipped: boolean) {
    this.opportunity.skip_steps.solution_building = skipped;
  }
  skipOrder(skipped: boolean) {
    this.opportunity.skip_steps.order = skipped;
  }

  engEngNotReqChecked(e) {
    var checked = e.target.checked;
    if (!Utilities.isNullOrEmpty(this.opportunity?.id)) {
      this.http
        .put(
          Endpoint.GLOBAL_URL +
            Endpoint.CHECK_ENG_ENGAGEMENT +
            '/' +
            this.opportunity.id,
          {
            checked: checked,
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true) {
            this.ngZone.run(() => {
              this.opportunity.skip_steps.solution_building = checked;
              this.opportunity.skip_steps.poc = checked;
            });
          } else {
            alert(Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    }
  }

  pickOpporunity(type) {
    if (!Utilities.isNullOrEmpty(this.opportunity?.id)) {
      if (type == 'engineer') {
        this.picked_user_ids.push(this.currentUser._id);
      } else if (type == 'manager') {
      }

      this.http
        .put(
          Endpoint.GLOBAL_URL + Endpoint.PICK_USERS + '/' + this.opportunity.id,
          {
            picked_user_ids: this.picked_user_ids,
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true) {
            if (type == 'engineer') {
              this.ispicked = true;
              this.OpportunityPickedModal.show();
            } else if (type == 'manager') {
              alert('PreSales have been updated successfully!');
            }
            //
            this.opportunity.disabled.solution_building =
              !this.opportunity.deal.google_approved_deal ||
              (this.currentUser.isEngineer &&
                !this.currentUser.is_manager &&
                !this.picked_user_ids?.includes(this.currentUser._id));
            this.opportunity.disabled.poc =
              !this.opportunity.deal.google_approved_deal ||
              (this.currentUser.isEngineer &&
                !this.currentUser.is_manager &&
                !this.picked_user_ids?.includes(this.currentUser._id));
            this.opportunity.disabled.proposal =
              !this.opportunity.deal.google_approved_deal ||
              (this.currentUser.isEngineer &&
                !this.currentUser.is_manager &&
                !this.picked_user_ids?.includes(this.currentUser._id));
          } else {
            alert(Unicode.INTERNAL_SERVER_ERROR);
          }
        });
    }
  }

  checkPickedUser(engineer_id) {
    if (
      Array.isArray(this.picked_user_ids) &&
      this.picked_user_ids.length > 0
    ) {
      for (let id of this.picked_user_ids) {
        if (engineer_id == id) {
          return true;
        }
      }
      return false;
    }
  }

  getEngineers() {
    this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.GET_ENGINEERS, {})
      .toPromise()
      .then((resp: any) => {
        if (resp && resp.data && resp.data.length > 0) {
          this.engineers = resp.data ?? [];
        }
      });
  }

  pickEngineer(engineer_id) {
    if (this.picked_user_ids.includes(engineer_id)) {
      this.picked_user_ids = this.picked_user_ids.filter(
        (item) => item !== engineer_id
      );
    } else {
      this.picked_user_ids.push(engineer_id);
    }
  }

  showEditSaleAmount() {
    this.EditSaleAmountModal.show();
  }
  async editDafPsf() {
    var that = this;
    const formData = new FormData();
    this.opportunity.deal.daf.available =
      !Utilities.isNullOrEmpty(this.opportunity.deal.daf.file) ||
      !Utilities.isNullOrEmpty(this.opportunity.deal.daf.amount);
    this.opportunity.deal.psf.available =
      !Utilities.isNullOrEmpty(this.opportunity.deal.psf.file) ||
      !Utilities.isNullOrEmpty(this.opportunity.deal.psf.amount);
    if (this.opportunity.deal.daf.file?.size)
      formData.append('daf_file', this.opportunity.deal.daf.file);
    if (this.opportunity.deal.psf.file?.size)
      formData.append('psf_file', this.opportunity.deal.psf.file);
    if (
      (this.opportunity.deal.daf.available &&
        !this.opportunity.deal.daf.file) ||
      (this.opportunity.deal.psf.available && !this.opportunity.deal.psf.file)
    ) {
      alert('Attachement Required');
      return;
    }
    this.opportunity.deal.daf.file = null;
    this.opportunity.deal.psf.file = null;
    var body = {
      daf: this.opportunity.deal.daf,
      psf: this.opportunity.deal.psf,
    };
    formData.append('info', JSON.stringify(body));
    var receivers = [];
    var createdUseremail = '';
    if (
      this.currentUser.email.toLowerCase() !==
      this.opportunity.deal?.user_deal?.created_user_email
    ) {
      createdUseremail = this.opportunity.deal?.user_deal?.created_user_email;
    }
    receivers = [
      createdUseremail,
      this.opportunity.deal?.user_deal?.manager_email,
    ];
    receivers.push(
      ...this.opportunity.deal?.approval_phases
        .filter(
          (x) =>
            x.email &&
            x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
        )
        .map((x) => x.email)
    );
    var params = {
      [Unicode.EMAIL_RECEIVERS]: JSON.stringify(receivers),
    };

    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }
    await this.http
      .post(
        Endpoint.GLOBAL_URL + Endpoint.EDIT_DAF_PSF_INFO + this.opportunity._id,
        formData
      )
      .toPromise()
      .then((resp: any) => {
        if (resp?.success) {
          this.opportunity.deal.daf.attachment_proof_drive_id =
            resp?.daf_attachment_proof_drive_id;
          this.opportunity.deal.psf.attachment_proof_drive_id =
            resp?.psf_attachment_proof_drive_id;
          alert(resp.message);
          this.opportunity.solution_building.is_psf =
            this.opportunity.deal?.psf?.available;
          this.EditSaleAmountModal.hide();
        }
      });
  }

  markAsGoogleApproved(daf: boolean) {
    if (daf) {
      this.opportunity.deal.daf.google = {
        approved: true,
        marked_at: new Date(),
        marked_by_id: this.currentUser._id,
        marked_by_name: this.currentUser.user_name,
        marked_by_email: this.currentUser.email,
      };
    } else {
      this.opportunity.deal.psf.google = {
        approved: true,
        marked_at: new Date(),
        marked_by_id: this.currentUser._id,
        marked_by_name: this.currentUser.user_name,
        marked_by_email: this.currentUser.email,
      };
    }
  }
  async onAttachDafPsfFile(daf: boolean, event) {
    const file = event.target.files[0];
    if (file) {
      if (daf) {
        this.opportunity.deal.daf.file = file;
        this.opportunity.deal.daf.attachment_proof_name = file.name;
      } else {
        this.opportunity.deal.psf.file = file;
        this.opportunity.deal.psf.attachment_proof_name = file.name;
      }
    }
  }

  markAsDone(psf: boolean) {
    if (psf) {
      this.opportunity.deal.psf.marked_as_done = true;
    } else {
      this.opportunity.deal.daf.marked_as_done = true;
    }
  }
  servicesDelivered(psf: boolean) {
    if (psf) {
      this.opportunity.deal.psf.services_delivered = true;
    } else {
      this.opportunity.deal.daf.services_delivered = true;
    }
  }
  deploymentFinished(psf: boolean) {
    if (psf) {
      this.opportunity.deal.psf.deployment_finished = true;
    } else {
      this.opportunity.deal.daf.deployment_finished = true;
    }
  }
}
